import React, { useEffect, useState } from "react";
import { PatientArticulatedGoals } from "../PatientArticulatedGoals/PatientArticulatedGoals";
import { PatientStrengths } from "../PatientStrengths/PatientStrengths";
import { MedicalCautions } from "../MedicalCautions/MedicalCautions";
import { api } from "../../../../../APIRequests";
import { useDispatch } from "react-redux";
import "./styles.scss";
import { hideLoader, showLoader } from "../../../../../redux/actions/loader";

export const PatientGoalsStrengthsCautions = (props) => {
    const [patientStrengthOptions, setPatientStrengthOptions] = useState([]);
    const [patientMedicalCautionOptions, setPatientMedicalCautionOptions] = useState([]);
    const [existingGoals, setExistingGoals] = useState(null);
    const [existingMedicalCautions, setExistingMedicalCautions] = useState(null);
    const [existingStrengths, setExistingStrengths] = useState(null);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const dispatch = useDispatch();

    useEffect(async () => {
        if (!props.patientId) {
            return;
        }

        dispatch(showLoader(true));
        await Promise.allSettled([
            refreshPatientStrengthAndCautionOptions(),
            refreshPatientArticulatedGoals(),
            refreshMedicalCautions(),
            refreshPatientStrengths(),
        ]);
        dispatch(hideLoader());

        setInitialLoadComplete(true);
    }, []);

    const refreshPatientStrengthAndCautionOptions = async () => {
        const response = await api.carePlan.get_patient_strength_and_caution_options({
            urlParams: { patientId: props.patientId },
            options: { params: { showLoader: false } },
        });
        setPatientStrengthOptions(response.patient_strength_options);
        setPatientMedicalCautionOptions(response.medical_caution_options);
    };

    const refreshPatientArticulatedGoals = async () => {
        const goals = await api.carePlan.get_patient_articulated_goals({
            urlParams: { patientId: props.patientId },
            params: { showLoader: false },
        });
        setExistingGoals(goals);
    };

    const refreshMedicalCautions = async () => {
        const patientMedicalCautions = await api.carePlan.get_patient_medical_cautions({
            urlParams: { patientId: props.patientId },
            params: { showLoader: false },
        });
        setExistingMedicalCautions(patientMedicalCautions);
    };

    const refreshPatientStrengths = async () => {
        const patientStrengths = await api.carePlan.get_patient_strengths({
            urlParams: { patientId: props.patientId },
            params: { showLoader: false },
        });
        setExistingStrengths(patientStrengths);
    };

    return (
        initialLoadComplete && (
            <div className={"PatientGoalsStrengthsCautions"}>
                {existingGoals && (
                    <PatientArticulatedGoals
                        patientId={props.patientId}
                        existingGoals={existingGoals}
                        refreshGoals={refreshPatientArticulatedGoals}
                    />
                )}
                {existingStrengths && (
                    <PatientStrengths
                        patientId={props.patientId}
                        patientStrengthOptions={patientStrengthOptions}
                        existingStrengths={existingStrengths}
                        refreshStrengths={refreshPatientStrengths}
                    />
                )}
                {existingMedicalCautions && (
                    <MedicalCautions
                        patientId={props.patientId}
                        patientMedicalCautionOptions={patientMedicalCautionOptions}
                        existingMedicalCautions={existingMedicalCautions}
                        refreshCautions={refreshMedicalCautions}
                    />
                )}
            </div>
        )
    );
};
