// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { Form, Formik, getIn } from "formik";
import { Link } from "react-router-dom";
import { CardPrimary } from "../../../components/UI/Card";
import FormikInput from "../../../components/UI/FormikInput";
import Button from "../../../components/UI/Button";
import {
    PROVIDER_INITIAL_VALUES,
    BUILD_PROVIDER_SCHEMA,
    BUILD_PROVIDER_VALIDATION_SCHEMA,
} from "./schema/newProviderSchema";
import { adminRoute } from "../../../config/roles";
import { cloneDeep } from "lodash";
import { api } from "../../../APIRequests";
import Input from "../../../components/UI/Input";
import Label from "../../../components/UI/Label";
import Select from "react-select";
import InputError from "../../../components/UI/InputError";
import AcceptedAgeRange from "../../../components/Provider/Profile/AcceptedAgeRange";
import { connect } from "react-redux";
import { CustomForm } from "../../../component-library/CustomForm";

class NewProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            practiceId: this.props.match.params.practiceId,
            showAcceptNewPatients: false,
            acceptNewPatients: true,
            intakePracticeOptions: [],
            showAcceptedAgeRanges: false,
            acceptedAgeRanges: [
                {
                    min: 18,
                    max: 200,
                },
            ],
            isIdpEnabled: false,
        };
    }

    async componentDidMount() {
        let urlParams = { practiceId: this.state.practiceId };
        let practice = await api.shared.fetch_practice({ urlParams });
        let isPracticeIdp = practice.config.idp;
        this.setState({ isIdpEnabled: isPracticeIdp });
        let schema = cloneDeep(BUILD_PROVIDER_SCHEMA(isPracticeIdp));
        let initialValues = cloneDeep(PROVIDER_INITIAL_VALUES);

        if (isPracticeIdp) {
            initialValues.Password = null;
        }

        initialValues.practice = practice.name;
        initialValues.address = practice.address;

        const options = { params: { showLoader: false } };
        const response = await api.admin.fetch_all_practices({ options });
        const intakePracticeOptions = response
            .filter((practice) => practice["practice_type"] === "Primary Care")
            .map((practice) => {
                return {
                    value: practice["id"],
                    label: practice["name"],
                };
            });

        this.setState({
            schema,
            initialValues,
            intakePracticeOptions,
        });

        document.addEventListener("change", this.providerTypeChangeHandler, {
            capture: true,
        });
    }

    providerTypeChangeHandler = (e) => {
        if (e.target.name === "provider_type" && (e.target.value.startsWith("BEHAVIORAL") || e.target.value === "PEER" || e.target.value === "CASE_MANAGER")) {
            this.setState({ showAcceptNewPatients: true });
        } else if (e.target.name === "provider_type") {
            this.setState({ showAcceptNewPatients: false });
        }

        if (
            e.target.name === "provider_type" &&
            (e.target.value.startsWith("BEHAVIORAL") || e.target.value.startsWith("PRESCRIBE") || e.target.value === "PEER" || e.target.value === "CASE_MANAGER")
        ) {
            this.setState({ showAcceptedAgeRanges: true });
        } else if (e.target.name === "provider_type") {
            this.setState({ showAcceptedAgeRanges: false });
        }
    };

    acceptNewPatientsChangeHandler = () => {
        this.setState((prevState) => ({
            acceptNewPatients: !prevState.acceptNewPatients,
        }));
    };

    setAcceptedAgeRanges = (ageRanges) => {
        this.setState({ acceptedAgeRanges: ageRanges });
    };

    submitHandler = async (formData, actions) => {
        let provider = cloneDeep(formData);

        if (provider.provider_type !== "CARE_MANAGER" && provider.npi_number === "") {
            actions.setErrors({ npi_number: "NPI Number is required" });
            return;
        }

        actions.setSubmitting(true);

        provider["group_type"] = "provider";
        provider["practice_id"] = this.state.practiceId;
        provider["practice_ids"] = [this.state.practiceId];
        provider["accept_new_patients"] = this.state.acceptNewPatients;
        delete provider["ConfirmPassword"];

        if (!provider["mobile"]) {
            provider["mobile"] = "None";
        }

        provider["intake_practice_ids"] = provider["intake_practice_ids"]
            ? provider["intake_practice_ids"].map((option) =>
                  option.value ? option.value : option,
              )
            : null;

        if (this.state.showAcceptedAgeRanges) {
            provider["accepted_age_range"] = this.state.acceptedAgeRanges;
        }

        try {
            await api.admin.create_user({ user: provider });
            this.props.history.push(`/app/practice/${this.state.practiceId}`);
        } catch (e) {
            console.log(e);
        }

        actions.setSubmitting(false);
    };

    renderForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <div className="container">
                        <div className="mx-auto px-0 ">
                            <div className="container">
                                <div className="row">
                                    {this.state.schema.map((field) => {
                                        if (field.elementType === "segment") {
                                            return (
                                                <h6 key={field.displayName} className="col-12">
                                                    <b>{field.displayName}</b>
                                                </h6>
                                            );
                                        } else if (
                                            field.name === "accept_new_patients" &&
                                            this.state.showAcceptNewPatients
                                        ) {
                                            return (
                                                <div className="col-12 col-lg-6 d-flex mt-lg-5 mb-3">
                                                    <Input
                                                        checked={this.state.acceptNewPatients}
                                                        type="checkbox"
                                                        name="accept_new_patients"
                                                        className="AddPatientInput mr-2"
                                                        onClick={
                                                            this.acceptNewPatientsChangeHandler
                                                        }
                                                    />
                                                    <div className="fw-bold fs-14">
                                                        {field.elementConfig.label}
                                                    </div>
                                                </div>
                                            );
                                        } else if (
                                            field.name === "accept_new_patients" &&
                                            !this.state.showAcceptNewPatients
                                        ) {
                                            return <div />;
                                        } else if (field.name === "intake_practice_ids") {
                                            const error = getIn(formikProps.errors, field.name);
                                            const touched = getIn(formikProps.touched, field.name);
                                            return (
                                                <>
                                                    {formikProps.values["provider_type"] ===
                                                        "BEHAVIORAL_INTAKE" && (
                                                        <div
                                                            key={field.name}
                                                            className={"col-12 col-lg-6"}>
                                                            <Label>
                                                                {field.elementConfig.label}
                                                            </Label>
                                                            <Select
                                                                components={{
                                                                    ClearIndicator: null,
                                                                }}
                                                                isMulti
                                                                options={
                                                                    this.state.intakePracticeOptions
                                                                }
                                                                defaultValue={[]}
                                                                className={"basic-multi-select"}
                                                                classNamePrefix="select"
                                                                onChange={async (selectedOptions) =>
                                                                    await formikProps.setFieldValue(
                                                                        field.name,
                                                                        selectedOptions,
                                                                    )
                                                                }
                                                            />
                                                            {error && touched ? (
                                                                <InputError
                                                                    classes={"custom-error"}>
                                                                    {error}
                                                                </InputError>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                </>
                                            );
                                        } else {
                                            return (
                                                <div key={field.name} className="col-12 col-lg-6">
                                                    <FormikInput
                                                        formEl={field}
                                                        errors={formikProps.errors}
                                                        disabled={field.disabled ? true : null}
                                                        touched={formikProps.touched}
                                                    />
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                                {this.state.showAcceptedAgeRanges && (
                                    <AcceptedAgeRange
                                        noBoxShadow={true}
                                        acceptedAgeRanges={this.state.acceptedAgeRanges}
                                        setAcceptedAgeRanges={this.setAcceptedAgeRanges}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center justify-content-lg-end">
                    <Button
                        type="button"
                        onClick={() => this.props.history.push("/app/practice/")}
                        className="Btn Btn--otl-pri Btn-sm mr-3">
                        Cancel
                    </Button>
                    <Button type="submit" className="Btn Btn--pri Btn-sm">
                        + Add
                    </Button>
                </div>
            </Form>
        );
    };

    render() {
        if (!this.state.schema || !this.state.initialValues) {
            return <div />;
        }

        return (
            <div>
                <div className="survey-back-link-wpr mx-auto fs-14 my-3 py-2">
                    <Link to={adminRoute + "/practice"}>
                        <p className="d-inline ml-0">Practices</p>
                    </Link>
                    <Link to={adminRoute + "/practice/" + this.state.practiceId}>
                        <p className="d-inline ml-0"> {">"} Practice Details</p>
                    </Link>
                    <span> {">"} Add Provider</span>
                </div>
                <CardPrimary className="mx-auto" style={{ marginTop: 0 }}>
                    <div>
                        <CustomForm
                            initialValues={this.state.initialValues}
                            validationSchema={BUILD_PROVIDER_VALIDATION_SCHEMA()}
                            onSubmit={this.submitHandler}
                            render={this.renderForm}
                        />
                    </div>
                </CardPrimary>
            </div>
        );
    }
}

export default NewProvider;
