import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { api } from "../../../APIRequests";
import { addToast, toastMessageTypes } from "../../../redux/actions/toaster";
import { CardHeading } from "../../UI/Headings";
import Hr from "../../UI/Hr";
import {
    Button,
    ButtonTypes,
    Card,
    Checkbox,
    CustomForm,
    Modal,
    Text,
    TextTypes,
    Toggle,
} from "../../../component-library";
import { isAuthorized, isUserAdmin } from "../../../redux/actions/auth";
import { admin_cse } from "../../../config/roles";
import CloseOnOutsideClick from "../../Common/CloseOnOutsideClick/CloseOnOutsideClick";
import { CloseIcon } from "../../Icons/CloseIcon";
import { ICON_SIZES, ICON_TYPES } from "../../../constants/icons";
import { Form } from "formik";
import * as Yup from "yup";
import { FormTextInput } from "../../../component-library/FormFields/FormTextInput/FormTextInput";
import { FormFieldError } from "../../../component-library/FormFields/FormFieldError/FormFieldError";
import "./styles.scss";
import moment from "moment/moment";

const ProviderUniversalAccessManagementCard = ({ providerDetails, profile }) => {
    const dispatch = useDispatch();
    const { providerId } = useParams();

    const [isProviderUniversalAccessEnabled, setIsProviderUniversalAccessEnabled] = useState(
        providerDetails.is_universal_access_enabled,
    );
    const [lastModifiedByName, setLastModifiedByName] = useState(
        providerDetails.is_universal_access_enabled_modified_by_name,
    );
    const [lastModifiedTimestamp, setLastModifiedTimestamp] = useState(
        providerDetails.is_universal_access_enabled_modified_timestamp,
    );
    const [lastModifiedReasons, setLastModifiedReasons] = useState(
        providerDetails.is_universal_access_enabled_reason,
    );

    const [showReasonModal, setShowReasonModal] = useState(false);

    const buildAuditInformation = () => {
        try {
            if (!lastModifiedTimestamp && isUserAdmin()) {
                return "";
            } else if (!lastModifiedTimestamp && !isUserAdmin()) {
                return "Contact a supervisor to enable global access";
            }

            let auditInfo = `${
                isProviderUniversalAccessEnabled ? "Enabled" : "Disabled"
            } by ${lastModifiedByName} ${moment
                .utc(lastModifiedTimestamp)
                .local()
                .format("M/D/YYYY")}.`;

            if (isProviderUniversalAccessEnabled) {
                auditInfo += ` Reason: "${buildReasonList()}."`;
            }
            return auditInfo;
        } catch (e) {
            console.log(e);
            return "";
        }
    };

    const buildReasonList = () => {
        if (!isProviderUniversalAccessEnabled) {
            return "";
        } else {
            const knownReasonDisplayMap = {
                on_call: "On-Call",
            };
            return Object.entries(lastModifiedReasons)
                .filter(([reason_key, reason_value]) => reason_key !== "other" && reason_value)
                .map(([reason_key, reason_value]) => {
                    if (reason_key === "other_free_text") {
                        return reason_value;
                    } else if (knownReasonDisplayMap[reason_key]) {
                        return knownReasonDisplayMap[reason_key];
                    } else {
                        return reason_key;
                    }
                })
                .join(", ");
        }
    };

    const onToggleChange = async () => {
        const updatedIsProviderUniversalAccessEnabled = !isProviderUniversalAccessEnabled;
        try {
            if (!updatedIsProviderUniversalAccessEnabled) {
                const data = {
                    provider_id: providerId,
                    is_universal_access_enabled: updatedIsProviderUniversalAccessEnabled,
                };
                await api.admin.set_provider_universal_access({ data });
                dispatch(
                    addToast({
                        message: "Universal access has been disabled.",
                        messageType: toastMessageTypes.success,
                    }),
                );
                setLastModifiedByName(profile.name);
                setLastModifiedTimestamp(moment().utc().toISOString());
                setIsProviderUniversalAccessEnabled(updatedIsProviderUniversalAccessEnabled);
            } else {
                setShowReasonModal(true);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const submitForm = async (formData) => {
        const updatedIsProviderUniversalAccessEnabled = !isProviderUniversalAccessEnabled;
        try {
            const data = {
                provider_id: providerId,
                is_universal_access_enabled: updatedIsProviderUniversalAccessEnabled,
                reason: {
                    on_call: formData["on_call"],
                    other: formData["other"],
                    other_free_text: formData["other"] ? formData["other_free_text"] : "",
                },
            };
            await api.admin.set_provider_universal_access({ data });
            dispatch(
                addToast({
                    message: "Universal access has been enabled.",
                    messageType: toastMessageTypes.success,
                }),
            );
            setLastModifiedByName(profile.name);
            setLastModifiedTimestamp(moment().utc().toISOString());
            setLastModifiedReasons(data["reason"]);
            setIsProviderUniversalAccessEnabled(updatedIsProviderUniversalAccessEnabled);
            setShowReasonModal(false);
        } catch (e) {
            console.log(e);
        }
    };

    const closeModal = () => {
        setShowReasonModal(false);
    };

    const renderForm = (formikProps) => {
        const formikInputProps = {
            inputClasses: "Input--sm",
            errors: formikProps.errors,
            touched: formikProps.touched,
        };
        return (
            <Form>
                <div className="text-align-start">
                    <div>
                        <Checkbox
                            onClick={async () => {
                                await formikProps.setFieldValue(
                                    "on_call",
                                    !formikProps.values.on_call,
                                );
                            }}
                            name={"on_call"}
                            value={formikProps.values.on_call}
                            checked={formikProps.values.on_call}
                            label="On-Call"
                        />
                    </div>
                    <div className={"d-flex mt-1"}>
                        <div className={"pr-2 mt-2"}>
                            <Checkbox
                                onClick={async () => {
                                    if (formikProps.values.other) {
                                        await formikProps.setFieldValue("other_free_text", "");
                                    }

                                    await formikProps.setFieldValue(
                                        "other",
                                        !formikProps.values.other,
                                    );
                                }}
                                value={formikProps.values.other}
                                checked={formikProps.values.other}
                                name={"other"}
                                label="Other"
                            />
                        </div>
                        <FormTextInput
                            {...formikInputProps}
                            title=""
                            className={"provider-universal-access-modal--other-free-text-input"}
                            name="other_free_text"
                            placeholder={"Specify"}
                            formikProps={formikProps}
                            disabled={!formikProps.values.other}
                            triggerErrorOnChange
                        />
                    </div>
                </div>
                {formikProps.errors.atLeastOneOptionSelected && (
                    <FormFieldError>{formikProps.errors.atLeastOneOptionSelected}</FormFieldError>
                )}
                {!formikProps.errors.atLeastOneOptionSelected && <div className={"pt-4"}></div>}
                <div className="d-flex justify-content-end mt-2">
                    <Button
                        style={{ minWidth: 105 }}
                        className={"mr-3"}
                        type="button"
                        onClick={closeModal}
                        buttonType={ButtonTypes.primaryOutlineV2}>
                        Cancel
                    </Button>
                    <Button
                        style={{ minWidth: 105 }}
                        type="submit"
                        buttonType={ButtonTypes.primaryV2}>
                        Submit
                    </Button>
                </div>
            </Form>
        );
    };

    const validationSchema = Yup.object().shape({
        on_call: Yup.boolean(),
        other: Yup.boolean(),
        atLeastOneOptionSelected: Yup.mixed().test(
            "at-least-one-checked",
            "At least one reason must be selected.",
            function () {
                const { on_call, other } = this.parent;
                return on_call || other;
            },
        ),
        other_free_text: Yup.string().when("other", {
            is: true,
            then: Yup.string()
                .matches(/^[a-zA-Z0-9, ']+$/, "Special characters are not allowed.")
                .required("This is required if other is selected."),
            otherwise: Yup.string(),
        }),
    });

    const initialValues = {
        on_call: false,
        other: false,
        other_free_text: "",
    };

    return (
        <>
            {showReasonModal && (
                <Modal cardClassName="provider-universal-access-modal" show={true}>
                    <CloseOnOutsideClick setShowComponent={closeModal}>
                        <div className={"d-flex justify-content-end"}>
                            <CloseIcon
                                onClick={closeModal}
                                className={"provider-universal-access-modal--close-icon"}
                                iconSize={ICON_SIZES.SMALL}
                                iconType={ICON_TYPES.OUTLINE}
                            />
                        </div>
                        <div className={"mx-3 mb-3"}>
                            <div className={"mb-4"}>
                                <Text type={TextTypes.label}>
                                    Choose a reason for enabling global access
                                </Text>
                            </div>
                            <CustomForm
                                validationSchema={validationSchema}
                                onSubmit={submitForm}
                                initialValues={initialValues}
                                render={renderForm}
                            />
                        </div>
                    </CloseOnOutsideClick>
                </Modal>
            )}
            <Card className={"p-3 p-lg-4 mb-3 mb-lg-4"}>
                <CardHeading className="fs-16 m-0 mb-3" text={"Universal Access"} />
                <Hr />
                <div className="w-100">
                    <div className="mt-4">
                        <p>
                            {
                                "Enable access to on-call, crisis management, and global search workflows."
                            }
                        </p>
                        <div className={"mt-3"} style={{ display: "flex", alignItems: "center" }}>
                            <Toggle
                                name="set_is_universal_access_enabled_toggle"
                                disabled={!isAuthorized(admin_cse)}
                                checked={isProviderUniversalAccessEnabled}
                                onChange={onToggleChange}
                                style={{ marginRight: 10 }}
                            />
                            <Text type={TextTypes.paragraph}>Universal Access</Text>
                        </div>
                        <Text type={TextTypes.light}>{buildAuditInformation()}</Text>
                    </div>
                </div>
            </Card>
        </>
    );
};

ProviderUniversalAccessManagementCard.propTypes = {
    providerDetails: PropTypes.object,
};

ProviderUniversalAccessManagementCard.defaultProps = {
    providerDetails: {},
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps, null)(ProviderUniversalAccessManagementCard);
