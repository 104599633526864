// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Fragment, useEffect, useState } from "react";
import "./styles.scss";
import {
    List,
    ListBody,
    ListHeader,
    ListItem,
    ListTitleBar,
    TitleBarItem,
} from "../../UI/ListView";
import Badge from "../../UI/Badge";
import images from "../../../utils/images";
import { formatMobileNumber, getQueryParams, toTitleCase } from "../../../utils/filters";
import { Link, withRouter } from "react-router-dom";
import { providerRoute } from "../../../config/roles";
import moment from "moment-timezone";
import { isUserAdmin, isUserPCP } from "../../../redux/actions/auth";
import { api } from "../../../APIRequests";
import Select from "react-select";
import { connect } from "react-redux";
import ImageFullPreview from "../../Common/ImageFullPreview";
import { showAlertWithAction } from "../../../redux/actions/alerts";
import { bindActionCreators } from "redux";
import { fetchCrisisRiskPatients } from "../../../redux/actions/reports";
import { hideLoader, showLoader } from "../../../redux/actions/loader";
import ReactTooltip from "react-tooltip";
import { getReportPresignedUrl, viewReport } from "../../../utils/fileUtil";
import {Button, ButtonSizes, ButtonTypes} from "../../../component-library";

const IN_PROGRESS = "In-Progress";
const COMPLETE = "Complete";
const FAILED = "Failed";
const Spinner = () => <div className="loader"></div>;

const A5_REPORT = "A5";
const A5_REPEAT_REPORT = "A5-R";

const ReportList = (props) => {
    const {
        dispatch,
        reportList,
        searchHandler,
        allReports,
        practiceOptions,
        practiceIdChangeHandler,
        selectedPractice,
        patientName,
        practiceId,
        reportType,
        reportTypeChangeHandler,
        fetchAdminReportList,
        fetchPatientReportList,
        providerReportsNextToken,
        patientReportsNextToken,
        setProviderReportsNextToken,
        setPatientReportsNextToken,
        setReportList,
        clearRetryInterval,
    } = props;

    const patientId = getQueryParams("patient-id", props.location.search);

    const [patientChecked, setPatientChecked] = useState(true);
    const [adminChecked, setAdminChecked] = useState(false);
    const [crisisRiskChecked, setCrisisRiskChecked] = useState(false);
    const [progressNotes, setProgressNotes] = useState([]);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const [scanNotesForward, setScanNotesForward] = useState(false);
    const [showReportModal, setShowReportModal] = useState(null);
    const [crisisRiskPracticeId, setCrisisRiskPracticeId] = useState(undefined);
    const [crisisRiskReportList, setCrisisRiskReportList] = useState([]);
    const [crisisRiskLastEvaluatedKey, setCrisisRiskLastEvaluatedKey] = useState(undefined);
    const [crisisScoreSource, setCrisisScoreSource] = useState(A5_REPORT);

    const reportDropdownOption = {
        label: "Show Report Activity",
        value: "reports",
    };

    const noteDropdownOption = {
        label: "Show Progress Note Activity",
        value: "notes",
    };

    const a5ReportDropdownOption = {
        label: A5_REPORT,
        value: A5_REPORT,
    };
    const a5RepeatReportDropdownOption = {
        label: A5_REPEAT_REPORT,
        value: A5_REPEAT_REPORT,
    };

    const defaultPatientReportOption = () => {
        if (isUserPCP() || isUserAdmin()) {
            return reportDropdownOption;
        } else {
            return noteDropdownOption;
        }
    };

    const [viewOption, setViewOption] = useState(defaultPatientReportOption());

    useEffect(() => {
        (async () => {
            await fetchNotes();
        })();
    }, [viewOption, practiceId, scanNotesForward, adminChecked]);

    const fetchNotes = async (lastEvaluatedKey) => {
        if (viewOption.value === "notes" && !adminChecked) {
            let notes = [];
            if (isUserAdmin()) {
                if (practiceId !== "all_practices") {
                    notes = await fetchProgressNotesByPractice(practiceId, lastEvaluatedKey);
                }
            } else if (isUserPCP()) {
                notes = await fetchProgressNotesByPCPPractice(
                    props.profile.practice_id,
                    lastEvaluatedKey,
                );
            } else {
                notes = await fetchProgressNotesByProvider(
                    props.profile.username,
                    lastEvaluatedKey,
                );
            }

            if (!notes) {
                notes = [];
            }

            notes = notes.filter(
                (note) =>
                    note["note_type"] !== "Initial Clinical Assessment" &&
                    note["note_type"] !== "Outcome Clinical Assessment" &&
                    note["note_type"] !== "Discharge",
            );
            setProgressNotes(notes);
        } else {
            setProgressNotes([]);
            setLastEvaluatedKey(null);
        }
    };

    const fetchProgressNotesByPractice = async (practiceId, lastEvaluatedKey) => {
        const queryParams = {
            practiceId: practiceId,
        };

        let data = {
            scan_forward: scanNotesForward,
        };

        if (lastEvaluatedKey) {
            data["last_evaluated_key"] = lastEvaluatedKey;
        }
        const response = await api.shared.get_progress_notes_by_practice_id({ queryParams, data });
        if (response.results.length === 0) {
            return;
        }

        setLastEvaluatedKey(response.last_evaluated_key);
        return [...progressNotes, ...response.results];
    };

    const fetchProgressNotesByPCPPractice = async (practiceId, lastEvaluatedKey) => {
        const queryParams = {
            pcpPracticeId: practiceId,
        };

        let data = {
            scan_forward: scanNotesForward,
        };

        if (lastEvaluatedKey) {
            data["last_evaluated_key"] = lastEvaluatedKey;
        }
        const response = await api.shared.get_progress_notes_by_pcp_practice_id({
            queryParams,
            data,
        });
        if (response.results.length === 0) {
            return;
        }

        setLastEvaluatedKey(response.last_evaluated_key);
        return [...progressNotes, ...response.results];
    };

    const fetchProgressNotesByProvider = async (providerId, lastEvaluatedKey) => {
        const queryParams = {
            providerId: providerId,
        };

        let data = {
            scan_forward: scanNotesForward,
        };

        if (lastEvaluatedKey) {
            data["last_evaluated_key"] = lastEvaluatedKey;
        }
        const response = await api.shared.get_progress_notes_by_provider_id({ queryParams, data });
        if (response.results.length === 0) {
            return;
        }

        setLastEvaluatedKey(response.last_evaluated_key);
        return [...progressNotes, ...response.results];
    };

    const addNewAdminReport = () => {
        let url = "/app/reports/add";
        if (isUserAdmin()) {
            url += "?practiceId=" + practiceId;
        }
        props.history.push(url);
    };

    const crisisRiskPracticeChangeHandler = (e) => {
        if (!e) {
            return;
        }

        setCrisisRiskPracticeId(e.value);
    };

    const getCrisisRiskReport = async ({
        practiceId = crisisRiskPracticeId,
        lastEvaluatedKey = crisisRiskLastEvaluatedKey,
        sourceReport = crisisScoreSource,
    }) => {
        try {
            dispatch(showLoader());
            const options = { params: { showLoader: false } };
            const data = {
                practice_id: practiceId ? practiceId : undefined,
                last_evaluated_key: lastEvaluatedKey ? lastEvaluatedKey : undefined,
                assessment_type: sourceReport,
            };
            const response = await fetchCrisisRiskPatients({
                data,
                options,
                dispatch,
            });

            const crisisRiskReportResponseList = response.reports;

            const shouldRetainReportList = practiceId === crisisRiskPracticeId && lastEvaluatedKey;
            const currentDisplayedCrisisRisks =
                shouldRetainReportList ? crisisRiskReportList : [];

            setCrisisRiskReportList([
                ...currentDisplayedCrisisRisks,
                ...crisisRiskReportResponseList,
            ]);
            setCrisisRiskLastEvaluatedKey(response.last_evaluated_key);
            setCrisisRiskPracticeId(practiceId);
            dispatch(hideLoader());
        } catch (error) {
            console.error(error);
        }
    };

    const reportTypeChangeHanderWithCrisisRisk = (e) => {
        let reportType = e.value;
        if (!reportType) {
            reportType = e.target.value;
        }
        if (reportType === "crisis_risk") {
            setCrisisRiskChecked(true);
            setAdminChecked(false);
            setPatientChecked(false);
            getCrisisRiskReport(e);
        } else {
            reportTypeChangeHandler(e);
        }
    };

    let addAdminReportEnabled =
        (reportType === "admin" &&
            isUserAdmin() &&
            practiceId !== "all_practices" &&
            practiceId != null) ||
        !isUserAdmin();

    const buildPatientReportOptions = () => {
        const options = [noteDropdownOption];

        if (isUserAdmin() || isUserPCP()) {
            options.unshift(reportDropdownOption);
        }

        return options;
    };

    // ListView TitleBar controls
    const renderControls = () => {
        return (
            /*DESKTOP*/
            <div className="d-sm-flex d-none flex-column flex-lg-row align-items-lg-center justify-content-lg-end">
                {isUserAdmin() && practiceOptions && (
                    <div className={"d-flex align-items-center "}>
                        <span className={"fw-bold mr-2"}>Select Your Practice: </span>
                        <div style={{ width: 280 }} className={"mr-lg-4"}>
                            <Select
                                className="mr-lg-2 practice-search"
                                placeholder="Search"
                                options={practiceOptions}
                                value={selectedPractice}
                                onChange={(e) => {
                                    setReportList([]);
                                    setProgressNotes([]);
                                    if (crisisRiskChecked) {
                                        getCrisisRiskReport({
                                            practiceId: e.value,
                                            lastEvaluatedKey: null,
                                        });
                                    } else {
                                        practiceIdChangeHandler(e);
                                    }
                                }}
                                isSearchable={true}
                            />
                        </div>
                    </div>
                )}
                {/*<SearchBar className="mr-lg-2" onchange={searchHandler} />*/}
                {adminChecked && (
                    <Button
                        onClick={addNewAdminReport}
                        disabled={addAdminReportEnabled ? null : true}
                        buttonType={ButtonTypes.primaryV2}>
                        Create New
                    </Button>
                )}
            </div>
            /*MOBILE*/
        );
    };

    const updateCrisisReportType = (reportName) => {
        setCrisisRiskLastEvaluatedKey(undefined);
        setCrisisRiskReportList([]);
        setCrisisScoreSource(reportName);
        getCrisisRiskReport({
            lastEvaluatedKey: null,
            sourceReport: reportName
        });
    };

    const renderCrisisScoreSourceRadioButton = (reportName) => {
        return <div className={"CrisisScoreSourceOptions-radio--input"}>
            <input
                type="radio"
                value={reportName}
                onChange={() => {
                    updateCrisisReportType(reportName);
                }}
                checked={crisisScoreSource === reportName}
            />
            <span className={"CrisisScoreSourceOptions-radio--input-label"}>
                {reportName}
            </span>
        </div>;
    };

    const renderCrisisScoreSourceRadioButtonGroup = () => {
        return <div className={"CrisisScoreSourceOptions-radio mx-lg-4"}>
            <span className={"CrisisScoreSourceOptions-radio--group-name"}>
                Triggered by:
            </span>
            {renderCrisisScoreSourceRadioButton(A5_REPORT)}
            {renderCrisisScoreSourceRadioButton(A5_REPEAT_REPORT)}
        </div>;
    };

    const renderCrisisScoreSourceSelect = () => {
        return <div className={"CrisisScoreSourceOptions-select"}>
            <span className={"CrisisScoreSourceOptions-select--label"}>
                Triggered by
            </span>
            <Select
                onChange={(option) => {
                    updateCrisisReportType(option.value);
                }}
                value={{label: crisisScoreSource, value: crisisScoreSource}}
                options={[a5ReportDropdownOption, a5RepeatReportDropdownOption]}
            />
        </div>;
    };

    const renderCrisisScoreSourceOptions = () => {
        return <div className="CrisisScoreSourceOptions" data-public="true">
            {renderCrisisScoreSourceRadioButtonGroup()}
            {renderCrisisScoreSourceSelect()}
            <div className={"CrisisScoreSourceOptions-explanation mx-lg-4"}>
                Note that the risk score calculation and scale varies between A5 and A5-R
            </div>
        </div>;
    };

    const renderSecondaryControls = () => {
        return (
            <>
                {/*DESKTOP*/}
                <div
                    className="d-sm-flex d-none align-items-lg-center justify-content-lg-between"
                    data-public="true"
                    style={{
                        padding: 4,
                        borderBottom: "1px solid #AEAEAE",
                        borderTop: "1px solid #AEAEAE",
                    }}>
                    <div
                        style={{ width: 490, height: 50, display: "flex", alignItems: "center" }}
                        className={"mx-lg-4"}>
                        <span className={"fw-bold"} style={{width: "115px"}}>Report View: </span>
                        <div
                            style={{
                                width: 150,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <input
                                type="radio"
                                value={"patient"}
                                onChange={(e) => {
                                    if (clearRetryInterval) {
                                        clearInterval(clearRetryInterval);
                                    }
                                    setCrisisRiskChecked(false);
                                    setPatientChecked(true);
                                    setAdminChecked(false);
                                    setLastEvaluatedKey(null);
                                    setProviderReportsNextToken(null);
                                    setPatientReportsNextToken(null);
                                    setReportList([]);

                                    if (viewOption.value !== "notes") {
                                        reportTypeChangeHandler(e);
                                    }
                                }}
                                checked={patientChecked}
                            />
                            <span style={{ marginLeft: "10px" }}>Patients</span>
                        </div>
                        {isUserAdmin() && (
                            <div
                                style={{
                                    width: 150,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                <input
                                    type="radio"
                                    value={"admin"}
                                    onChange={(e) => {
                                        setCrisisRiskChecked(false);
                                        setAdminChecked(true);
                                        setPatientChecked(false);
                                        setLastEvaluatedKey(null);
                                        setProviderReportsNextToken(null);
                                        setPatientReportsNextToken(null);
                                        setReportList([]);

                                        reportTypeChangeHandler(e);
                                    }}
                                    checked={adminChecked}
                                />
                                <span style={{ marginLeft: "10px" }}>Administration</span>
                            </div>
                        )}
                        {(isUserAdmin() || props.profile?.is_universal_access_enabled) && (
                            <div
                                style={{
                                    width: 150,
                                    marginLeft: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                <input
                                    type="radio"
                                    value={"crisis-risk"}
                                    onChange={(e) => {
                                        setCrisisRiskChecked(true);
                                        setAdminChecked(false);
                                        setPatientChecked(false);
                                        setLastEvaluatedKey(null);
                                        setProviderReportsNextToken(null);
                                        setPatientReportsNextToken(null);
                                        setReportList([]);
                                        setCrisisRiskReportList([]);
                                        setCrisisRiskLastEvaluatedKey(null);

                                        getCrisisRiskReport(e);
                                    }}
                                    checked={crisisRiskChecked}
                                />
                                <span style={{ marginLeft: "10px" }}>Crisis Risk</span>
                            </div>
                        )}
                    </div>
                    {!crisisRiskChecked && !adminChecked && (
                        <Select
                            onChange={(option) => {
                                setViewOption(option);
                                setLastEvaluatedKey(null);
                                setProviderReportsNextToken(null);
                                setPatientReportsNextToken(null);
                                setReportList([]);
                            }}
                            value={viewOption}
                            options={buildPatientReportOptions()}
                            className={"mx-lg-4 reports-select"}
                        />
                    )}
                </div>
                {/*MOBILE*/}
                <div
                    style={{ width: "100vw" }}
                    className="d-sm-none d-flex align-items-center justify-content-between">
                    <div className={"practice-search-small"}>
                        <span className="fw-bold fs-14">Report View</span>
                        <Select
                            onChange={(option) => {
                                setViewOption(option);
                                setLastEvaluatedKey(null);
                            }}
                            value={viewOption}
                            options={[
                                {
                                    label: "Show Report Activity",
                                    value: "reports",
                                },
                                {
                                    label: "Show Progress Note Activity",
                                    value: "notes",
                                },
                            ]}
                            className={"w-100"}
                        />
                    </div>
                    <div className={"practice-search-small"}>
                        <span className="fw-bold fs-14">Type</span>
                        <Select
                            className="w-100"
                            placeholder="Search"
                            options={[
                                {
                                    value: "patient",
                                    label: "Patient Report",
                                },
                                {
                                    value: "admin",
                                    label: "Administrator Report",
                                },
                                {
                                    value: "crisis_risk",
                                    label: "Crisis Risk Report",
                                },
                            ]}
                            onChange={reportTypeChangeHanderWithCrisisRisk}
                            onSelect={reportTypeChangeHanderWithCrisisRisk}
                        />
                    </div>
                </div>
                {isUserAdmin() && practiceOptions && !crisisRiskChecked && (
                    <div
                        style={{ width: "100vw" }}
                        className="d-sm-none d-flex mt-2 mb-2 flex-column justify-content-between">
                        <span className="fw-bold fs-14">Practice</span>
                        <Select
                            className="practice-search-mobile"
                            placeholder="Search"
                            options={practiceOptions}
                            onChange={practiceIdChangeHandler}
                            onSelect={practiceIdChangeHandler}
                        />
                    </div>
                )}
                {isUserAdmin() && practiceOptions && crisisRiskChecked && (
                    <div
                        style={{ width: "100vw" }}
                        className="d-sm-none d-flex mt-2 mb-2 flex-column justify-content-between">
                        <span className="fw-bold fs-14">Practice</span>
                        <Select
                            className="practice-search-mobile crisis-risk"
                            placeholder="Search"
                            options={practiceOptions}
                            onChange={crisisRiskPracticeChangeHandler}
                            onSelect={crisisRiskPracticeChangeHandler}
                        />
                    </div>
                )}
                {addAdminReportEnabled && viewOption.value !== "notes" && !crisisRiskChecked && (
                    <div
                        className={"circle d-sm-none d-flex"}
                        role={"button"}
                        onClick={addNewAdminReport}>
                        <img
                            className="img-fluid plus"
                            src={images("./icons/add-white-btn.svg")}
                            alt={"add new report"}
                        />
                    </div>
                )}
            </>
        );
    };

    const showReport = async (report) => {
        let file_url = await getReportPresignedUrl(report);
        setShowReportModal(file_url);
    };

    const renderBadge = (status = "Not available") => {
        let type;

        switch (status) {
            case IN_PROGRESS:
                type = "progress";
                break;
            case COMPLETE:
                type = "success";
                break;
            case "Not available":
                type = "grey";
                break;
            default:
                type = "grey";
        }
        return <Badge type={type} text={status} />;
    };

    const renderBadgeNotes = (status) => {
        let type;

        switch (status) {
            case "draft":
                status = "Draft";
                type = "progress";
                break;
            default:
                status = "Complete";
                type = "success";
                break;
        }
        return <Badge type={type} text={status} />;
    };

    const archiveReport = async (id, timestamp) => {
        try {
            const data = { timestamp: timestamp, status: "ARCHIVED" };
            const queryParams = {};
            queryParams["providerId"] = id;
            await api.shared.update_provider_report({ queryParams, data });
            const reportIndex = reportList.findIndex(
                (report) => report["id"] === id && report["timestamp"] === timestamp,
            );
            let newReportList = [...reportList];
            newReportList.splice(reportIndex, 1);
            setReportList(newReportList);
        } catch (err) {
            console.log(err);
        }
    };

    // A utility function to generate list items for mobile and desktop devices
    const getListChildren = (report) => {
        let {
            patient_id,
            type,
            report_url,
            first_name,
            last_name,
            modified_at,
            action,
            status,
            name,
            timestamp,
            id,
            entity_id,
        } = report;
        if (timestamp) {
            modified_at = moment.utc(timestamp).tz(props.timezone).format("MM/DD/YYYY hh:mm A");
        }
        let reportLink;
        function constructReportLink(type) {
            reportLink = `${providerRoute}/reports/${type}`;
        }

        switch (type) {
            case "I-BAR":
                constructReportLink("ibar");
                break;
            case "I-PSR":
                constructReportLink("ipsr");
                break;
            case "O-BAR":
                constructReportLink("obar");
                break;
            case "O-PSR":
                constructReportLink("opsr");
                break;
            default:
                reportLink = `${providerRoute}/reports`;
        }
        const patientName = `${first_name} ${last_name}`;
        return (
            <Fragment>
                {/* for mobile devices */}
                <div className="d-flex d-lg-none justify-content-between">
                    <div className="w-75 d-flex fs-14 flex-column">
                        <span className="fs-16">{modified_at}</span>
                        {allReports && reportType === "patient" && (
                            <span className="fs-16">
                                {first_name && last_name
                                    ? toTitleCase(first_name) + " " + toTitleCase(last_name)
                                    : ""}
                            </span>
                        )}
                        {allReports && reportType === "admin" && (
                            <span className="fs-16">{name}</span>
                        )}
                        <span>{type}</span>
                    </div>
                    <div className="cursor d-flex  ml-5 mr-2">
                        {allReports && reportType === "patient" && (
                            <span className="mr-3">{renderBadge(status)}</span>
                        )}
                        <a target="_blank" href={report_url} rel="noreferrer">
                            <img
                                onClick={() => {
                                    showReport(report);
                                }}
                                src={images("./common/view.png")}
                                alt="view icon"
                            />
                        </a>
                    </div>
                </div>
                {/* for desktop */}
                <div className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
                    <span className="col-2">{modified_at}</span>
                    {allReports && reportType === "patient" && (
                        <span className="col-5">
                            {first_name && last_name
                                ? toTitleCase(first_name) + " " + toTitleCase(last_name)
                                : ""}
                        </span>
                    )}
                    {allReports && reportType === "admin" && <span className="col-5">{name}</span>}
                    <span className="col-2">{type}</span>
                    {allReports && reportType === "patient" && (
                        <div className="col-1">{renderBadge(status)}</div>
                    )}
                    {allReports && reportType === "admin" && (
                        <div className="col-1">
                            <Button
                                buttonType={ButtonTypes.primaryOutlineV2}
                                buttonSize={ButtonSizes.small}
                                className={"fs-12"}
                                onClick={() => archiveReport(id, timestamp)}>
                                Archive
                            </Button>
                        </div>
                    )}
                    <div className="col-2 d-flex d-flex justify-content-center">
                        {allReports && reportType === "admin" && (
                            <div className="ml-5">{renderDownloadStatusIcon(report)}</div>
                        )}
                        {allReports && reportType === "patient" && (
                            <div className="ml-5">{renderPatientReportDownloadLink(report)}</div>
                        )}
                        {allReports && reportType === "patient" && (
                            <div className="cursor ml-5">
                                <img
                                    onClick={() => {
                                        showReport(report);
                                    }}
                                    src={images("./common/view.png")}
                                    alt="view icon"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Fragment>
        );
    };

    const renderDownloadStatusIcon = (report) => {
        const { status, report_url, multipart_report, report_part_urls } = report;
        let icon;
        let tooltipText;
        let linkClass = null;

        if (status === IN_PROGRESS && !report_url) {
            icon = <Spinner />;
            tooltipText = "Report Generating";
            return renderLinkWithTooltip(icon, tooltipText, null, null);
        } else if (status === FAILED) {
            icon = <img src={images("./icons/error-icon.svg")} alt="error icon" />;
            tooltipText = "Failed to Download";
            return renderLinkWithTooltip(icon, tooltipText, null, null);
        } else {
            tooltipText = "Ready to Download";
            linkClass = "cursor";
            if (multipart_report) {
                icon = <img src={images("./icons/download-icon.svg")} alt="download icon" />;
                return renderMultipartLinkWithTooltips(
                    icon,
                    tooltipText,
                    report_part_urls,
                    linkClass,
                );
            } else {
                icon = <img src={images("./icons/download-icon.svg")} alt="download icon" />;
                return renderLinkWithTooltip(icon, tooltipText, report_url, linkClass);
            }
        }
    };

    const renderPatientReportDownloadLink = (report) => {
        if (!report.file_key) {
            return <></>;
        }

        const icon = <img src={images("./icons/download-icon.svg")} alt="download icon" />;

        return (
            <div
                role={"button"}
                onClick={async () => {
                    await viewReport(report);
                }}>
                {icon}
            </div>
        );
    };

    const renderLinkWithTooltip = (icon, tooltipText, linkUrl, linkClass) => {
        return (
            <>
                <a
                    target="_blank"
                    href={linkUrl}
                    data-for="downloadStatusLink"
                    data-tip={tooltipText}
                    data-iscapture="true"
                    className={linkClass}
                    rel="noreferrer">
                    {icon}
                </a>
                <ReactTooltip
                    id="downloadStatusLink"
                    place="top"
                    effect="solid"
                    backgroundColor={"#E0E0E0"}
                    textColor={"black"}
                />
            </>
        );
    };

    const renderMultipartLinkWithTooltips = (icon, tooltipText, report_part_urls, linkClass) => {
        const retVal = [];
        report_part_urls.forEach((report_part_url, i) => {
            if (i !== 0) {
                retVal.push(<hr />);
            }
            retVal.push(
                <span className={"d-inline-block mr-2"}>{`Part ${i + 1}/${
                    report_part_urls.length
                }`}</span>,
                renderLinkWithTooltip(icon, tooltipText, report_part_urls[i], linkClass),
            );
        });
        return retVal;
    };

    // A utility function to generate list items for mobile and desktop devices
    const getListChildrenProgressNote = (note) => {
        let {
            patient_first_name,
            patient_last_name,
            note_state,
            patient_id,
            id,
            session_date,
            provider_type,
        } = note;
        const patientName = `${patient_first_name} ${patient_last_name}`;
        const providerType = provider_type === "PRESCRIBE" ? "PRESCRIBER" : "THERAPIST";
        return (
            <Fragment>
                {/* for mobile devices */}
                <div onClick={() => {}} className="d-flex d-lg-none justify-content-between">
                    <div className="w-75 d-flex fs-14 flex-column">
                        <span className="fs-16">{patientName}</span>
                        <span className="fs-16">{session_date}</span>
                        <span className="fs-16">{providerType}</span>
                    </div>
                    <div className="flex-column d-flex ml-5">
                        <span className="mr-3">{renderBadgeNotes(note_state)}</span>
                    </div>
                </div>
                {/* for desktop */}
                <div className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
                    <span className="col-2">{session_date}</span>
                    <span className="col-5">{patientName}</span>
                    <span className="col-2">{providerType}</span>
                    <div className="col-1">{renderBadgeNotes(note_state)}</div>
                    <div className="col-2 d-flex d-flex justify-content-center">
                        <div className="cursor ml-5">
                            <img
                                onClick={() =>
                                    props.history.push(
                                        `/app/patient/${patient_id}/export_notes?noteIds=${id}`,
                                    )
                                }
                                src={images("./icons/print.svg")}
                                alt="print icon"
                                style={{ height: "45px" }}
                            />
                        </div>
                        <div className="cursor ml-5 d-flex justify-content-center align-items-center">
                            <img
                                onClick={() => {
                                    props.history.push(`/app/patient/${patient_id}/note/${id}/v2`);
                                }}
                                src={images("./common/view.png")}
                                alt="view icon"
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    const getViewButton = (report) => {
        if (report?.segment_id) {
            report = transformCrisisRiskIntoReport(report);
        }

        return (
            <img
                src={images("./common/show.svg")}
                className={"cursor view-report-icon"}
                alt="show"
                onClick={async () => {
                    let file_url = await getReportPresignedUrl(report);
                    setShowReportModal(file_url);
                }}
            />
        );
    };

    const transformCrisisRiskIntoReport = (report) => {
        return {
            entity_id: report?.metadata?.patient_id,
            file_type: "CORE_REPORT",
            file_sub_type: A5_REPORT,
            file_key: report?.report_key,
        };
    };

    const getCrisisRiskInfo = (report) => {
        const { metadata, report_key, report_url, iso_timestamp, rank } = report;
        const { patient_id, first_name, last_name, mobile, created_date } = metadata;

        const profileUrl =
            patient_id && `/app/patient/${patient_id}/profile/v2?selectedTab=Reports`;
        const completedDateTime = moment.utc(iso_timestamp).tz("America/New_York");

        return (
            <>
                {/* for mobile devices */}
                <div onClick={() => {}} className="d-flex d-lg-none justify-content-between">
                    <div className="w-75 d-flex fs-14 flex-column">
                        <span className="fs-16">Score: {rank}</span>
                        <span className="fs-16">
                            {completedDateTime.format("MM/DD/YYYY h:mm A")}
                        </span>
                        <span className="fs-16">
                            {first_name} {last_name} {formatMobileNumber(mobile)}
                        </span>
                    </div>
                    <div
                        className={`${
                            profileUrl ? "w-25" : "w-10"
                        } cursor d-flex align-items-center justify-content-between ml-5 mr-2`}>
                        {profileUrl && (
                            <img
                                onClick={() => props.history.push(profileUrl)}
                                src={images("./common/avatar.png")}
                                width="30"
                                height="30"
                                alt="view icon"
                            />
                        )}
                        <a target="_blank" href={report_url} rel="noreferrer">
                            <img src={images("./icons/download-icon.svg")} alt="download icon" />
                        </a>
                        {getViewButton(report)}
                    </div>
                </div>
                {/* for desktop */}
                <div className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
                    <span className="col-1">{rank}</span>
                    <span className="col-2">{completedDateTime.format("h:mm A")}</span>
                    <span className="col-2">{completedDateTime.format("MM/DD/YYYY")}</span>
                    <span className="col-3">
                        {first_name} {last_name}
                    </span>
                    <div className="col-2">{formatMobileNumber(mobile)}</div>
                    <div className="col-1 cursor">
                        {profileUrl && (
                            <img
                                onClick={() => props.history.push(profileUrl)}
                                src={images("./common/avatar.png")}
                                width="30"
                                alt="view icon"
                            />
                        )}
                    </div>
                    <div className="col-1 d-flex d-flex justify-content-flex-start">
                        {report_key && (
                            <>
                                <div className="cursor">
                                    <a target="_blank" href={report_url} rel="noreferrer">
                                        <img
                                            src={images("./icons/download-icon.svg")}
                                            alt="download icon"
                                        />
                                    </a>
                                </div>
                                <div className="cursor d-flex align-items-center ml-2">
                                    {getViewButton(report)}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const toggleNoteScanDirection = () => {
        if (viewOption.value === "notes") {
            setProgressNotes([]);
            setScanNotesForward(!scanNotesForward);
        }
    };

    let listItems = (
        <Fragment>
            {reportList.length > 0 &&
                reportList.map((report) => {
                    let key = report.type;
                    if (report.patient_id) {
                        key += report.patient_id;
                    }
                    if (report.name) {
                        key += report.name;
                    }
                    if (report.created_date) {
                        key += report.created_date + Math.random();
                    }
                    return (
                        <ListItem
                            key={key}
                            clickOnMobile
                            clickHandler={() => console.log("clicked")}>
                            {getListChildren(report)}
                        </ListItem>
                    );
                })}
        </Fragment>
    );

    if (viewOption.value === "notes" && !adminChecked) {
        if (progressNotes.length === 0) {
            listItems = null;
        } else {
            listItems = (
                <Fragment>
                    {progressNotes.map((note) => {
                        return (
                            <ListItem className={"smaller-list-item"} key={note.id}>
                                {getListChildrenProgressNote(note)}
                            </ListItem>
                        );
                    })}
                </Fragment>
            );
        }
    }

    if (crisisRiskChecked) {
        listItems = (
            <>
                {crisisRiskReportList.length > 0 &&
                    crisisRiskReportList.map((report) => {
                        return (
                            <ListItem className={"smaller-list-item"} key={report.patient_id}>
                                {getCrisisRiskInfo(report)}
                            </ListItem>
                        );
                    })}
            </>
        );
    }

    let sessionDate = (
        <div style={{ cursor: "pointer" }}>
            <span>Session Date</span> <img src={images("./common/sort_arrows.svg")} alt="sort" />
        </div>
    );
    return (
        <div>
            {!allReports && (
                <div className="backLink-wpr mx-auto mt-4 d-none d-lg-block">
                    <Link
                        className="PatientProfile-back-link"
                        to={`/app/patient/${patientId}/profile`}>
                        <img className="img-fluid" src={images("./common/solidBckBtn.svg")} />
                        <p className="d-inline ml-2">Back to Patient Profile</p>
                    </Link>
                </div>
            )}
            <ListBody>
                {
                    <ListHeader
                        title={allReports ? "Reports" : patientName + "'s Reports"}
                        render={allReports ? renderControls : null}
                    />
                }
                {allReports && renderSecondaryControls()}
                { crisisRiskChecked && renderCrisisScoreSourceOptions()}
                <ListTitleBar>
                    {!crisisRiskChecked ? (
                        <>
                            <TitleBarItem
                                onClick={toggleNoteScanDirection}
                                classes="col-2"
                                text={viewOption.value === "notes" ? sessionDate : "Date Modified"}
                            />
                            {allReports && (
                                <TitleBarItem
                                    classes="col-5"
                                    text={reportType === "patient" ? "Patient Name" : "Name"}
                                />
                            )}
                            <TitleBarItem classes="col-2" text="Report Type" />
                            {allReports && reportType === "admin" && (
                                <TitleBarItem classes="col-1" text="Archive" />
                            )}
                            {allReports && reportType === "patient" && (
                                <TitleBarItem classes="col-1" text="Status" />
                            )}
                            <TitleBarItem classes="col-2" text="" />
                        </>
                    ) : (
                        <>
                            <TitleBarItem classes="col-1" text={<>Score<br/>({crisisScoreSource})</>} />
                            <TitleBarItem classes="col-2" text={"Time (EST)"} />
                            <TitleBarItem classes="col-2" text={"Date"} />
                            <TitleBarItem classes="col-3" text={"Patient Name"} />
                            <TitleBarItem classes="col-2" text={"Phone"} />
                            <TitleBarItem classes="col-1" text={"Profile"} />
                            <TitleBarItem classes="col-1" text={"Report"} />
                        </>
                    )}
                </ListTitleBar>
                <List>{listItems}</List>
            </ListBody>
            {patientChecked && viewOption.value === "notes" && lastEvaluatedKey && (
                <div className="d-flex align-center justify-content-center mb-4">
                    <Button
                        onClick={() => fetchNotes(lastEvaluatedKey)}
                        disabled={lastEvaluatedKey ? null : true}
                        buttonType={ButtonTypes.primaryV2}>
                        Load More Notes
                    </Button>
                </div>
            )}

            {adminChecked && providerReportsNextToken && (
                <div className="d-flex align-center justify-content-center mb-4">
                    <Button
                        onClick={() => fetchAdminReportList(providerReportsNextToken)}
                        disabled={providerReportsNextToken ? null : true}
                        buttonType={ButtonTypes.primaryV2}>
                        Load More Administration Reports
                    </Button>
                </div>
            )}

            {patientChecked && viewOption.value === "reports" && patientReportsNextToken && (
                <div className="d-flex align-center justify-content-center mb-4">
                    <Button
                        onClick={() => fetchPatientReportList(practiceId, patientReportsNextToken)}
                        disabled={patientReportsNextToken ? null : true}
                        buttonType={ButtonTypes.primaryV2}>
                        Load More Patient Reports
                    </Button>
                </div>
            )}

            {crisisRiskChecked && crisisRiskLastEvaluatedKey && (
                <div className="d-flex align-center justify-content-center mb-4">
                    <Button
                        onClick={getCrisisRiskReport}
                        disabled={crisisRiskLastEvaluatedKey ? null : true}
                        buttonType={ButtonTypes.primaryV2}>
                        Load More Crisis Risk Reports
                    </Button>
                </div>
            )}

            {showReportModal && (
                <ImageFullPreview
                    contentType={"pdf"}
                    url={showReportModal}
                    close={() => setShowReportModal(false)}
                    wideView={true}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        timezone: state.auth.timezone,
        profile: state.auth.profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        ...bindActionCreators({ showAlertWithAction }, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportList));
