// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component, Fragment } from "react";
import { api } from "../../../APIRequests";
import { getQueryParams, toTitleCase } from "../../../utils/filters";
import { Link, withRouter } from "react-router-dom";
import images from "../../../utils/images";
import {
    List,
    ListBody,
    ListHeader,
    ListItem,
    ListTitleBar,
    TitleBarItem,
} from "../../../components/UI/ListView";
import Input from "../../../components/UI/Input";
import { eventTypeDisplayNameFactory } from "../../../components/Provider/ScheduleAvailability/ActiveScheduleDetails";
import moment from "moment-timezone";
import Button from "../../../components/UI/Button";
import ToggleButton from "react-toggle-button";
import DatePicker from "../../../components/UI/DatePicker";
import { Select } from "../../../components/UI/StyledInput";
import { isUserAdmin } from "../../../redux/actions/auth";
import { showAlertWithAction } from "../../../redux/actions/alerts";
import { connect } from "react-redux";
import { TOAST_ALERT } from "../../../components/UI/Alert";

class NewReport extends Component {
    state = {
        practiceId: getQueryParams("practiceId", this.props.location.search),
        providerId: "all_providers",
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().add(1, "days").format("YYYY-MM-DD"),
        isProcessed: false,
        activityList: [],
        includeAll: false,
        includeKeys: [],
        activityMap: null,
        showDatePicker: false,
        selectedDateRangeOption: "custom",
        providerOptions: [],
    };

    componentDidMount = async () => {
        if (isUserAdmin()) {
            const urlParams = { practiceId: this.state.practiceId };
            const providers = await api.admin.fetch_practice_providers({ urlParams });
            const providerOptions = providers.map((provider) => {
                return { value: provider.username, display: provider.name };
            });
            providerOptions.unshift({ value: "all_providers", display: "All Providers" });
            this.setState({
                providerOptions: providerOptions,
            });
            await this.fetchActivityList();
        } else {
            await this.fetchActivityList();
        }
    };

    fetchActivityList = async () => {
        try {
            const queryParams = {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                isProcessed: this.state.isProcessed,
            };
            if (this.state.practiceId) {
                queryParams["practiceId"] = this.state.practiceId;
            }
            if (this.state.providerId !== "all_providers") {
                queryParams["providerId"] = this.state.providerId;
            }
            if (
                moment(this.state.startDate, "YYYY-MM-DD") >
                moment(this.state.endDate, "YYYY-MM-DD")
            ) {
                this.setState({
                    activityList: [],
                    activityMap: null,
                });
                this.props.showAlertWithAction("Invalid date range selected");
            } else {
                let results;
                if (this.props.configs.ENHANCED_BILLING_REPORTS) {
                    const response = await api.shared.fetch_billable_activities({ queryParams });
                    results = response["results"];
                    if (response.last_evaluated_key) {
                        this.props.showAlertWithAction(
                            "Your search returned a large number of activities. Narrow your search, or click the " +
                                "first checkbox on the list below to select and download all activities.",
                            TOAST_ALERT,
                        );
                    }
                } else {
                    results = await api.shared.fetch_provider_activity({ queryParams });
                }

                const activityMap = results.reduce((obj, item) => {
                    obj[item["key"]] = { isChecked: false, key: item["key"] };
                    return obj;
                }, {});
                this.setState({
                    activityList: results,
                    activityMap: activityMap,
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    generateBillingReport = async () => {
        let new_keys = [];
        this.state.includeKeys.forEach((key) => {
            let new_key = key.substring(key.indexOf("_") + 1);
            new_keys.push(new_key);
        });
        try {
            const data = {
                start_date: this.state.startDate,
                end_date: this.state.endDate,
                include_all: this.state.includeAll,
                is_processed: this.state.isProcessed,
                provider_id: this.state.providerId,
                practice_id: this.state.practiceId,
                include_keys: this.state.includeAll ? [] : new_keys,
            };
            await api.shared.process_billing_activity({ data });
            this.props.history.push(
                "/app/reports?reportType=admin&practiceId=" + this.state.practiceId,
            );
        } catch (error) {
            console.log(error);
        }
    };

    generateActivityReport = async () => {
        try {
            const queryParams = {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                includeAll: this.state.includeAll,
                isProcessed: this.state.isProcessed,
            };
            const data = {
                include_keys: this.state.includeKeys,
            };
            if (this.state.providerId !== "all_providers") {
                queryParams["providerId"] = this.state.providerId;
            }
            if (this.state.practiceId) {
                queryParams["practiceId"] = this.state.practiceId;
            }
            let response = await api.shared.process_provider_activity({ queryParams, data });
            window.open(response["billable_activity_report_url"]);
            window.open(response["patient_list_report_url"]);
            this.props.history.push(
                "/app/reports?reportType=admin&practiceId=" + this.state.practiceId,
            );
        } catch (err) {
            console.log(err);
        }
    };

    renderControls = () => {
        const createReportFunction = this.props.configs.ENHANCED_BILLING_REPORTS
            ? this.generateBillingReport
            : this.generateActivityReport;
        return (
            <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-end justify-content-center">
                <span className={"fw-bold pr-3"}>Select Time Range of Activities: </span>

                <div className={"my-lg-0 my-2"}>{this.getShowDatePickerBtn()}</div>
                <Button
                    style={{ padding: "10px 5px 10px 5px" }}
                    /* set a ternary check below depending on feature flag */
                    onClick={() => createReportFunction()}
                    disabled={!this.state.includeKeys.length}
                    className={"Btn Btn--pri ml-lg-2 my-lg-0 my-2"}
                    type={"button"}>
                    Add to Generate Report
                </Button>
                <div className={"my-2 d-lg-none d-flex"}>
                    <span className={"mx-2"}>Select All</span>
                    <div>{this.getCheckAllCheckbox()}</div>
                </div>
            </div>
        );
    };

    renderSecondaryControls = () => {
        return (
            <div
                className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between"
                style={{
                    paddingRight: 36,
                    paddingLeft: 36,
                    paddingTop: 5,
                    paddingBottom: 5,
                    borderBottom: "1px solid #AEAEAE",
                    borderTop: "1px solid #AEAEAE",
                }}
                data-public={"true"}>
                <div className="d-flex align-items-center">
                    <span className={"fw-bold"}>Select Your Provider: </span>
                    <div style={{ width: 280 }} className={"mx-lg-4"} data-private={"true"}>
                        <Select
                            name="providerId"
                            changeHandler={this.providerIdChangeHandler}
                            value={this.state.providerId}
                            options={this.state.providerOptions}
                        />
                    </div>
                </div>
                <div className="d-flex my-lg-0 my-2">
                    <div
                        className={
                            "fs-14 mr-2 " + (this.state.isProcessed ? "toggle-inactive " : "")
                        }>
                        Need to Process
                    </div>
                    <ToggleButton
                        colors={{
                            activeThumb: {
                                base: "white",
                            },
                            inactiveThumb: {
                                base: "white",
                            },
                            active: {
                                base: "#5B57DC",
                            },
                            inactive: {
                                base: "#5B57DC",
                            },
                        }}
                        inactiveLabel={""}
                        activeLabel={""}
                        value={this.state.isProcessed}
                        onToggle={(value) => {
                            const isProcessed = !this.state.isProcessed;
                            const newState = {
                                isProcessed: isProcessed,
                            };
                            if (isProcessed) {
                                newState["includeKeys"] = [];
                                newState["includeAll"] = false;
                            }
                            this.setState(newState, () => this.fetchActivityList());
                        }}
                    />
                    <div
                        className={
                            "fs-14 ml-2 " + (!this.state.isProcessed ? "toggle-inactive " : "")
                        }>
                        Already Processed
                    </div>
                </div>
            </div>
        );
    };

    providerIdChangeHandler = async (e) => {
        const providerId = e.target.value;
        this.setState({ providerId: providerId }, () => this.fetchActivityList());
    };

    userFriendlyDate = (dateStr) => {
        return moment(dateStr, "YYYY-MM-DD").format("MM/DD/YYYY");
    };

    getShowDatePickerBtn = () => {
        return (
            <div className={"CustomSelect__wpr d-flex flex-column"} style={{ minHeight: "unset" }}>
                <button
                    type="button"
                    className={"CustomSelect__btn cursor d-flex align-items-center text-left"}
                    onClick={() => this.setState({ showDatePicker: !this.state.showDatePicker })}>
                    <span style={{ width: 200 }}>
                        {this.userFriendlyDate(this.state.startDate)} -{" "}
                        {this.userFriendlyDate(this.state.endDate)}
                    </span>
                </button>
            </div>
        );
    };

    checkBoxAllBoxesHandler = () => {
        const activityMap = { ...this.state.activityMap };
        const includeAll = !this.state.includeAll;
        for (let id of Object.keys({ ...this.state.activityMap })) {
            activityMap[id].isChecked = includeAll;
        }
        const newState = { activityMap: activityMap, includeAll: includeAll };
        newState["includeKeys"] = [];
        if (includeAll) {
            Object.values({ ...this.state.activityMap }).forEach((item) => {
                newState["includeKeys"].push(item.key);
            });
        }
        this.setState(newState);
    };

    checkBoxHandler = (item) => {
        let activityMap = { ...this.state.activityMap };
        activityMap[item["key"]].isChecked = !activityMap[item["key"]].isChecked;
        let includeKeys = [...this.state.includeKeys];
        const newState = { activityMap: activityMap, includeKeys: includeKeys };
        if (activityMap[item["key"]].isChecked) {
            includeKeys.push(item["key"]);
        } else {
            let indexToDelete = includeKeys.findIndex((key) => key === item["key"]);
            if (indexToDelete !== undefined) {
                includeKeys.splice(indexToDelete, 1);
            }
            newState["includeAll"] = false;
        }
        this.setState(newState);
    };

    getActivityCheckBox = (item) => {
        return (
            <Input
                checked={this.state.activityMap[item["key"]]["isChecked"]}
                type="checkbox"
                name="screening_checkbox"
                className="AddPatientInput mr-2"
                onClick={() => this.checkBoxHandler(item)}
            />
        );
    };

    getCheckAllCheckbox = () => {
        return (
            <Input
                checked={this.state.includeAll}
                type="checkbox"
                name="screening_checkbox"
                className="AddPatientInput mr-2"
                onClick={this.checkBoxAllBoxesHandler}
            />
        );
    };

    dateRangeOptionChangeHandler = (e) => {
        const selectedDateRangeOption = e.target.value;
        if (selectedDateRangeOption !== "custom") {
            let startDate = moment(),
                endDate = moment();
            switch (selectedDateRangeOption) {
                case "this_week": {
                    startDate = startDate.startOf("week");
                    endDate = endDate.endOf("week");
                    break;
                }
                case "last_week": {
                    startDate = startDate.startOf("week").subtract(7, "days");
                    endDate = endDate.startOf("week");
                    break;
                }
                case "last_30": {
                    startDate = startDate.subtract(30, "days");
                    break;
                }
                default:
                    break;
            }
            startDate = startDate.format("YYYY-MM-DD");
            endDate = endDate.format("YYYY-MM-DD");
            this.setState(
                {
                    selectedDateRangeOption: selectedDateRangeOption,
                    showDatePicker: !this.state.showDatePicker,
                    startDate: startDate,
                    endDate: endDate,
                    includeAll: false,
                    includeKeys: [],
                },
                () => this.fetchActivityList(),
            );
        } else {
            this.setState({ selectedDateRangeOption: selectedDateRangeOption });
        }
    };

    getDatePicker = () => {
        return (
            <div data-public={"true"}>
                <div className="date-picker row mb-2" style={{ marginRight: 0, marginLeft: 0 }}>
                    <div
                        className={"col-12 date-picker-label d-flex justify-content-between"}
                        style={{ marginTop: 0 }}>
                        <div style={{ width: 280 }} className={"mr-lg-4"}>
                            <Select
                                placeholder={"Custom Date"}
                                changeHandler={this.dateRangeOptionChangeHandler}
                                value={this.state.selectedDateRangeOption}
                                options={[
                                    { value: "this_week", display: "This Week" },
                                    { value: "last_week", display: "Last Week" },
                                    { value: "last_30", display: "Last 30 Days" },
                                    { value: "custom", display: "Custom Date" },
                                ]}
                            />
                        </div>
                        <img
                            className="date-picker-close"
                            src={images("./icons/cross.svg")}
                            role="button"
                            onClick={() =>
                                this.setState({ showDatePicker: !this.state.showDatePicker })
                            }
                            alt="close"
                        />
                    </div>
                    <div className={"col-lg-6 col-md-12 col-12"}>
                        <div className={"d-flex justify-content-between date-picker-label"}>
                            <div className={"date-picker-label-txt"}>
                                <b>From:</b>
                            </div>
                            <div>
                                <b>{this.userFriendlyDate(this.state.startDate)}</b>
                            </div>
                        </div>
                        <DatePicker
                            externalCtx={moment(this.state.startDate, "YYYY-MM-DD")}
                            onDaySelect={(date) => {
                                this.setState(
                                    {
                                        startDate: date,
                                        includeAll: false,
                                        includeKeys: [],
                                    },
                                    () => this.fetchActivityList(),
                                );
                            }}
                        />
                    </div>
                    <div className={"col-lg-6 col-md-12 col-12"}>
                        <div className={"d-flex justify-content-between date-picker-label"}>
                            <div className={"date-picker-label-txt"}>
                                <b>To:</b>
                            </div>
                            <div>
                                <b>{this.userFriendlyDate(this.state.endDate)}</b>
                            </div>
                        </div>
                        <DatePicker
                            externalCtx={moment(this.state.endDate, "YYYY-MM-DD")}
                            onDaySelect={(date) => {
                                this.setState(
                                    {
                                        endDate: date,
                                        includeAll: false,
                                        includeKeys: [],
                                    },
                                    () => this.fetchActivityList(),
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    listItems = () => {
        return (
            <Fragment>
                {this.state.activityList.map((activity) => {
                    return (
                        <ListItem key={activity["key"]} clickOnMobile clickHandler={() => {}}>
                            {this.getListChildren(activity)}
                        </ListItem>
                    );
                })}
            </Fragment>
        );
    };

    getListChildren = (activity) => {
        let { timestamp, patient_name, activity_type, activity_name } = activity;
        const date = moment.unix(timestamp).format("MM/DD/YYYY");
        if (activity["cancelled_type"]) {
            activity_name = activity["cancelled_type"];
        }
        return (
            <Fragment>
                {/* for mobile devices */}
                <div onClick={() => {}} className="d-flex d-lg-none justify-content-between">
                    <div className="w-75 d-flex fs-14 flex-column">
                        <span className="fs-16">{toTitleCase(patient_name)}</span>
                        <span className="fs-16">
                            {toTitleCase(activity_type.replace("_", " "))}
                        </span>
                        <span>{date}</span>
                        <span>{eventTypeDisplayNameFactory(activity_name)}</span>
                    </div>
                    <div className="d-flex  ml-5 mr-2">
                        <span className="mr-3">{this.getActivityCheckBox(activity)}</span>
                    </div>
                </div>
                {/* for desktop */}
                <div className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
                    <span className="col-3">{toTitleCase(patient_name)}</span>
                    <span className="col-2">{toTitleCase(activity_type.replace("_", " "))}</span>
                    <div className="col-2">{date}</div>
                    <span className="col-4">{eventTypeDisplayNameFactory(activity_name)}</span>
                    <div className="col-1 d-flex d-flex justify-content-center">
                        {this.getActivityCheckBox(activity)}
                    </div>
                </div>
            </Fragment>
        );
    };

    render() {
        return (
            <div>
                <div className="backLink-wpr mx-auto mt-4 d-none d-lg-block">
                    <Link className="PatientProfile-back-link" to={"/app/reports"}>
                        <img className="img-fluid" src={images("./common/solidBckBtn.svg")} />
                        <p className="d-inline ml-2">Back to Reports</p>
                    </Link>
                </div>
                <ListBody>
                    <ListHeader title={"Activities"} render={this.renderControls} />
                    {this.state.showDatePicker && this.getDatePicker()}
                    {isUserAdmin() && this.renderSecondaryControls()}
                    <ListTitleBar>
                        <TitleBarItem classes="col-3" text="Full Name" />
                        <TitleBarItem classes="col-2" text="Activity Type" />
                        <TitleBarItem classes="col-2" text="Date of Activity" />
                        <TitleBarItem classes="col-4" text="Activity Details" />
                        <TitleBarItem
                            classes="col-1 d-flex justify-content-center"
                            text={this.getCheckAllCheckbox()}
                        />
                    </ListTitleBar>
                    <List>{this.listItems()}</List>
                </ListBody>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        configs: state.configs,
    };
};

export default connect(mapStateToProps, { showAlertWithAction })(withRouter(NewReport));
