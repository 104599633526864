// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { api } from "../../../APIRequests";
import CardSecondary from "../../../components/UI/Card/CardSecondary";
import CardHeading from "../../../components/UI/Headings/CardHeading";
import Button from "../../../components/UI/Button";
import FormikInput from "../../../components/UI/FormikInput";
import { Form, Formik } from "formik";
import moment from "moment-timezone";
import CommonUserDetails from "../../../components/Provider/PatientView/CommonUserDetails";
import images from "../../../utils/images";
import MenuPopUp from "../../../components/UI/MenuPopUp";
import ProgressSlider from "../../../components/UI/ProgressSlider";
import { withRouter } from "react-router-dom";
import * as yup from "yup";
import { connect } from "react-redux";
import PatientReferralInfo from "./PatientReferralInfo";
import AssociatedFiles from "../../../components/Provider/PatientView/AssociatedFiles";
import { viewReferralAssociatedFile, getReferralAssociatedFiles } from "../../../utils/fileUtil";
import { getUsernameFromToken } from "../../../redux/actions/auth";
import { OutreachEntries } from "../../../components/Provider/PatientView/MemberProfile/Outreach";
import { CustomForm } from "../../../component-library/CustomForm";

class ReferralView extends Component {
    state = {
        showStatusPopup: false,
        showOutreachPopup: false,
        outreachCount: 0,
        referral: {
            email: "",
            mobile: "",
            dob: "",
            outreach: {},
            address: {
                state: "",
                city: "",
                street: "",
                zip_code: "",
            },
            referralCode: "",
            gender: "male",
            urgent_patient: false,
            last_name: "",
            first_name: "",
            referral_status: "ACTIVE",
            referral_status_reason: "",
            emergency_contact_number: "",
            emergency_contact_person: "",
            contacted: false,
            contactedDate: "",
            primary_insurance: {
                card_back: "",
                card_front: "",
                carrier: "",
                member_id: "",
                policy_dob: "",
                state: "",
            },
            secondary_insurance: {
                card_back: "",
                card_front: "",
                carrier: "",
                member_id: "",
                policy_dob: "",
                state: "",
            },
        },
        newOutreach: {
            note: "",
            contacted: false,
        },
        markerData: [
            {
                status: "done",
                caption: "PCP Referred",
            },
            {
                status: "active",
                caption: "Initial Contact",
            },
            {
                status: "default",
                caption: "Screening",
            },
        ],
        pcpName: "",
        practiceName: "",
        files: [],
    };

    componentDidMount = async () => {
        try {
            await this.getReferral();
            if (this.state.referral.pcp_practice_id) {
                await this.getPractice();
            }
            if (this.state.referral.pcp_practice_id && this.state.referral.pcp_id) {
                await this.getProvider();
            }
            await this.getAssociatedFiles();
        } catch (e) {
            console.log(e);
        }
    };

    getPractice = async () => {
        let urlParams = { practiceId: this.state.referral.pcp_practice_id };
        let cleanResponse = await api.shared.fetch_practice({ urlParams });
        this.setState({
            practiceName: cleanResponse.name,
        });
    };

    getAssociatedFiles = async () => {
        const files = await getReferralAssociatedFiles(this.props.match.params.referralCode);
        this.setState({ files });
    };

    getProvider = async () => {
        let urlParams = {
            practiceId: this.state.referral.pcp_practice_id,
            providerId: this.state.referral.pcp_id,
        };
        let cleanResponse = await api.shared.fetch_practice_provider({ urlParams });
        this.setState({
            pcpName: cleanResponse.name,
        });
    };

    getReferral = async () => {
        let queryParams = { referralCode: this.props.match.params.referralCode };
        let response = await api.shared.fetch_referral({ queryParams });
        let newState = { ...this.state, referral: response };
        if (response.outreach) {
            newState["outreachCount"] = Object.keys(response.outreach).length;
            let entry = Object.entries(response.outreach).find((entry) => entry[1].contacted);
            if (entry) {
                newState["contacted"] = true;
                let utcDate = moment.utc(entry[0]);
                let { date, time, am_pm } = this.getDateTimeParts(utcDate);
                newState["contactedDate"] = this.getDateStr(date, time, am_pm);
                newState["markerData"] = [...this.state.markerData];
                newState["markerData"][1].status = "done";
                newState["markerData"][2].status = "active";
            }
        }
        this.setState(newState);
    };

    submitStatusHandler = async (formData, actions) => {
        actions.setSubmitting(false);
        try {
            let data = { ...formData };
            data["referralCode"] = this.state.referral.referralCode;
            data["referral_status"] = "INACTIVE";
            let response = await api.shared.update_referral({ data });
            this.setState({ referral: response, showStatusPopup: false });
        } catch (e) {
            console.log(e);
        }
    };

    submitOutreachHandler = async (formData, actions) => {
        actions.setSubmitting(false);
        try {
            let outreach = { ...this.state.referral.outreach };
            const dateStr = moment.utc().format();
            outreach[dateStr] = {
                contacted: this.state.newOutreach.contacted,
                note: formData["note"],
                created_by_username: getUsernameFromToken(),
                created_by_name: this.props.profile.name,
                credential: this.props?.profile?.cp_credentials ?? null,
            };
            let data = {
                referralCode: this.state.referral.referralCode,
                outreach: outreach,
                contacted: this.state.newOutreach.contacted,
                contactedDate: this.state.newOutreach.contacted ? dateStr : null,
            };
            let response = await api.shared.update_referral({ data });
            const newState = {
                referral: response,
                showOutreachPopup: false,
                outreachCount: this.state.outreachCount + 1,
                newOutreach: {
                    note: "",
                    contacted: false,
                },
            };
            if (data["contacted"]) {
                newState["markerData"] = [...this.state.markerData];
                newState["markerData"][1].status = "done";
                newState["markerData"][2].status = "active";
            }
            this.setState(newState);
        } catch (e) {
            console.log(e);
        }
    };

    getDateStr(date, time, am_pm) {
        return (
            date.substring(0, 2) +
            "/" +
            date.substring(2, 4) +
            "/" +
            date.substring(4, 8) +
            " " +
            time.substring(0, 2) +
            ":" +
            time.substring(2, 4) +
            " " +
            am_pm
        );
    }

    getDateTimeParts(dateTime) {
        let date = dateTime.format("MM/DD/YYYY").replace(/\//g, "");
        let time = dateTime.format("hh:mm").replace(/:/g, "");
        let am_pm = dateTime.format("a");
        return { date, time, am_pm };
    }

    radioInputHandler = (name, option) => {
        let newOutreach = { ...this.state.newOutreach };
        newOutreach[name] = option.value;
        this.setState({ newOutreach });
    };

    renderOutreachForm = (formikProps) => {
        return (
            <Form className="py-4">
                <div className="mx-auto">
                    <div className="container px-0">
                        <div className="row">
                            {[
                                {
                                    name: "contacted",
                                    elementType: "radio",
                                    onchange: this.radioInputHandler,
                                    elementConfig: {
                                        label: "Contact Attempt",
                                        options: [
                                            { value: true, display: "Successfully Contacted" },
                                            { value: false, display: "Contact Unsuccessful" },
                                        ],
                                    },
                                },
                                {
                                    name: "note",
                                    elementType: "textarea",
                                    elementConfig: {
                                        type: "textarea",
                                        label: "Notes",
                                    },
                                },
                            ].map((formEl) => {
                                return (
                                    <div className={"col-12"} key={formEl.name}>
                                        <FormikInput
                                            formEl={formEl}
                                            errors={formikProps.errors}
                                            touched={formikProps.touched}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="d-lg-flex d-block justify-content-center text-center justify-content-lg-start">
                    <Button
                        type="button"
                        onClick={() => this.setState({ showOutreachPopup: false })}
                        className="Btn Btn--otl-pri Btn-sm mr-lg-3 mb-3 mb-lg-0">
                        Cancel
                    </Button>
                    <Button type="submit" className="Btn Btn--pri Btn-sm">
                        Add
                    </Button>
                </div>
            </Form>
        );
    };

    setHeader = (details) => {
        let status =
            details && "INACTIVE" !== details.referral_status ? (
                <div className="PatientProfile__ActiveStatus d-inline fs-12">
                    {details.referral_status}
                </div>
            ) : (
                <div className="PatientProfile__InactiveStatus d-inline fs-12">
                    {details.referral_status}
                </div>
            );

        return (
            <div>
                <CardHeading
                    text={details.first_name + " " + details.last_name}
                    className="fs-18 fs-lg-22 mb-0 mt-2 text-break"
                />
                {status}
            </div>
        );
    };

    resendReferral = () => {
        const options = { params: { showLoader: false } };
        api.provider
            .resend_referral_code({ referralCode: this.state.referral.referralCode }, options)
            .then((cleanResponse) => {
                this.setState({
                    showResendReferralPopup: true,
                    menuData: null,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    hideResendReferralPopup = () => {
        this.setState({
            showResendReferralPopup: false,
        });
    };

    showStatusPopup = () => {
        this.setState({
            showStatusPopup: true,
            menuData: null,
        });
    };

    menuClickHandler = (e) => {
        let rect = e.target.getBoundingClientRect();
        let popUpLeft = rect.left - 145;
        let popUpTop = rect.top + 40;
        this.setState((ps) => {
            if (!ps.menuData) {
                return {
                    menuData: {
                        actions: {
                            resendReferral: {
                                caption: "Resend Referral",
                                handler: this.resendReferral,
                            },
                            changeStatus: {
                                caption: "Change Status",
                                handler: this.showStatusPopup,
                            },
                        },
                        position: { top: popUpTop, left: popUpLeft },
                    },
                };
            }
            return {
                menuData: null,
            };
        });
    };

    render() {
        return (
            <div className={"row container mx-auto"}>
                {this.state.showResendReferralPopup && (
                    <div className="admitPatientPopUp position-fixed d-flex align-items-center justify-content-center">
                        <div
                            className="row position-absolute px-4 m-2 text-center"
                            style={{
                                maxWidth: "300px",
                                backgroundColor: "white",
                                borderRadius: 3,
                                padding: 20,
                            }}>
                            <div className={"col-12"}>
                                <img
                                    src={images("./common/successCheck.svg")}
                                    alt="success"
                                    className="mb-4"
                                />
                            </div>
                            <div className={"col-12"}>
                                <h5>Referral Sent</h5>
                            </div>
                            <div className={"col-12"}>
                                <p>
                                    Referral sent to {this.state.referral.first_name}{" "}
                                    {this.state.referral.last_name} successfully!
                                </p>
                            </div>
                            <div className={"col-12"}>
                                <Button
                                    className={"Btn Btn--pri"}
                                    onClick={this.hideResendReferralPopup}>
                                    OK
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.showStatusPopup && (
                    <div className="admitPatientPopUp position-fixed d-flex align-items-center justify-content-center">
                        <div
                            className="row position-absolute px-4 m-2"
                            style={{
                                maxWidth: "500px",
                                backgroundColor: "white",
                                borderRadius: 3,
                            }}>
                            <CustomForm
                                initialValues={{
                                    referral_status_reason: "",
                                    referral_status_reason_context: "",
                                }}
                                validationSchema={yup.object().shape({
                                    referral_status_reason: yup
                                        .string()
                                        .required("Reason is required"),
                                })}
                                onSubmit={this.submitStatusHandler}
                                render={(formikProps) =>
                                    renderStatusForm(formikProps, () => {
                                        this.setState({
                                            showStatusPopup: false,
                                        });
                                    })
                                }
                            />
                        </div>
                    </div>
                )}
                <div className="fs-16 my-3 py-2">
                    <div
                        className="PatientProfile-back-link BackLink cursor"
                        onClick={this.props.history.goBack}>
                        <img className="img-fluid" src={images("./common/solidBckBtn.svg")} />
                        <p className="d-inline ml-2">Back to Patients</p>
                    </div>
                </div>
                <div className="w-100 mx-auto mt-4 mb-5 my-lg-5">
                    <ProgressSlider markerData={this.state.markerData} />
                </div>
                <div className={"col-lg-4 col-12"}>
                    <CommonUserDetails
                        details={this.state.referral}
                        isReferral
                        getReferral={this.getReferral}>
                        <div className="d-flex justify-content-between align-items-center pl-3 py-4">
                            <div className="d-flex align-items-start">
                                <img
                                    src={images("./common/avatar.png")}
                                    width="40"
                                    alt="Profile Picture"
                                    className="img-fluid mr-3"
                                />
                                <div className="patientProfile__name d-inline-block">
                                    {this.state.referral && this.setHeader(this.state.referral)}
                                </div>
                            </div>
                            <div
                                className="PatientStatusPopUp__three-dots-menu cursor p-2"
                                onClick={(e) => {
                                    this.menuClickHandler(e);
                                }}>
                                <img
                                    src={images("./common/menuThreeDots.svg")}
                                    alt="Menu"
                                    className="img-fluid"
                                />
                            </div>
                            {this.state.menuData && (
                                <MenuPopUp
                                    position={this.state.menuData.position}
                                    actions={this.state.menuData.actions}
                                />
                            )}
                        </div>
                    </CommonUserDetails>
                    <PatientReferralInfo
                        referralCode={this.state.referral.referralCode}
                        pcpName={this.state.pcpName}
                        referralCreatedDate={this.state.referral.created_date}
                        practiceName={this.state.practiceName}
                    />
                    {this.state.referral && (
                        <AssociatedFiles
                            viewAllLink={`/app/referral/${this.state.referral.referralCode}/file`}
                            fileList={this.state.files}
                            getFile={(key) =>
                                viewReferralAssociatedFile(this.state.referral.referralCode, key)
                            }
                        />
                    )}
                </div>
                <div className="col-lg-8 col-12 my-4">
                    <div className={"m-2 p-1"}>
                        <CardSecondary className="PatientProfile__CreatePlan-wpr PatientProfile__CardBetweenMargin mx-xl-auto py-4 d-xl-flex d-block justify-content-between align-items-center px-3 px-xl-4">
                            <div className="d-flex align-items-center">
                                <img
                                    src={images("./common/purple-white-mail.svg")}
                                    alt="mail"
                                    className="PatientProfile__90DaysPlan img-fluid"
                                    width="39px"
                                />
                                <CardHeading
                                    text="Warm Contact"
                                    className="text-white d-lg-inline-block ml-2 pl-2 mr-0 mr-lg-5 fs-16"
                                />
                            </div>
                            <div className="ml-auto">
                                <div className="d-block text-right">
                                    {!this.state.showOutreachPopup && (
                                        <Button
                                            className={"Btn Btn--pri Btn-sm"}
                                            onClick={() =>
                                                this.setState({ showOutreachPopup: true })
                                            }>
                                            Add Warm Contact Attempt
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </CardSecondary>
                        {this.state.showOutreachPopup && (
                            <CardSecondary
                                style={{ backgroundColor: "white", borderRadius: 3, padding: 20 }}>
                                <CustomForm
                                    initialValues={this.state.newOutreach}
                                    validationSchema={undefined}
                                    onSubmit={this.submitOutreachHandler}
                                    render={this.renderOutreachForm}
                                />
                            </CardSecondary>
                        )}
                    </div>
                    {this.state.referral.outreach && (
                        <OutreachEntries outreach={this.state.referral.outreach} />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        timezone: state.auth.timezone,
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps)(withRouter(ReferralView));

export const renderStatusForm = (formikProps, hidePopUp, patientName) => {
    return (
        <Form className="py-4">
            <div className="mx-auto">
                <div className="container px-0">
                    <label className={"Label pb-2"}>Archive: {patientName}</label>
                    <div className="row">
                        {[
                            {
                                name: "referral_status_reason",
                                elementType: "select",
                                elementConfig: {
                                    options: [
                                        {
                                            value: "Patient decline to proceed",
                                            display: "Patient decline to proceed",
                                        },
                                        {
                                            value: "Patient unresponsive",
                                            display: "Patient unresponsive",
                                        },
                                        {
                                            value: "Patient is not a fit for aptihealth",
                                            display: "Patient is not a fit for aptihealth",
                                        },
                                    ],
                                    label: "Reason",
                                },
                            },
                            {
                                name: "referral_status_reason_context",
                                elementType: "textarea",
                                elementConfig: {
                                    type: "textArea",
                                    label: "Reason Context",
                                },
                            },
                        ].map((formEl) => {
                            return (
                                <div key={formEl.name} className={"col-12"}>
                                    <FormikInput
                                        formEl={formEl}
                                        errors={formikProps.errors}
                                        touched={formikProps.touched}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="d-lg-flex d-block justify-content-center text-center justify-content-lg-end">
                <Button
                    type="button"
                    onClick={hidePopUp}
                    className="Btn Btn--otl-pri Btn-sm mr-lg-3 mb-3 mb-lg-0">
                    Cancel
                </Button>
                <Button type="submit" className="Btn Btn--pri Btn-sm">
                    Save
                </Button>
            </div>
        </Form>
    );
};
