import { httpAuth } from "../utils/axios";
import { getGroupAuthType } from "../redux/actions/auth";

const searchRequests = {
    search_patients: function ({ data, options }) {
        let conf = {
            method: "post",
            url: "/v1/search/patient",
            ...options,
            data,
        };
        return httpAuth(conf);
    },

    search_providers: function ({ data, options }) {
        let conf = {
            method: "post",
            url: "/v1/search/provider",
            ...options,
            data,
        };
        return httpAuth(conf);
    },

    search_practices: function ({ data, options }) {
        let conf = {
            method: "post",
            url: "/v1/search/practice",
            ...options,
            data,
        };
        return httpAuth(conf);
    },

    search_omni: function ({ data, options }) {
        let conf = {
            method: "post",
            url: "/v1/search/omni",
            ...options,
            data,
        };
        return httpAuth(conf);
    },

    hcp_practice_search: function ({ data, options }) {
        let conf = {
            method: "post",
            url: "/v1/search/hcp_practice_search",
            ...options,
            data,
        };
        return httpAuth(conf);
    },
};

export default searchRequests;
