// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "./actionTypes";
import { api } from "../../APIRequests";

const updatePaymentProcessorSession = (sessionInfo) => ({
    type: actionTypes.UPDATE_PAYMENT_PROCESSOR_SESSION,
    payload: {
        ...sessionInfo,
    },
});

export const fetchPaymentProcessorSessionToken = ({
    firstName,
    lastName,
    getToken = true,
    addToken = true,
    transactionType = "AUTHORIZATION",
    transactionAmount = 0.01,
    captchaVerificationToken,
    dispatch,
}) => {
    return api.payment
        .getPaymentSessionToken({
            data: {
                first_name: firstName,
                last_name: lastName,
                get_token: getToken,
                add_token: addToken,
                transaction_type: transactionType,
                transaction_amount: transactionAmount,
                captcha_response_key: captchaVerificationToken,
            },
        })
        .then((body) => {
            const { session_token } = body;
            dispatch(updatePaymentProcessorSession({ sessionToken: session_token }));

            return session_token;
        });
};
