// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "./actionTypes";
import { uuidv4 } from "../../utils/filters";
import { FastField } from "formik";

export const toastMessageTypes = {
    success: "success",
    success_v2: "success_v2",
    warning: "warning",
    error: "error",
    error_v2: "error_v2",
};

export const addToast = ({
    message,
    messageType,
    hideDismissButton,
    dismissMessage,
    dismissTimeout,
}) => {
    const toastId = uuidv4();

    return {
        type: actionTypes.ADD_TOAST,
        payload: {
            message,
            messageType,
            toastId,
            hideDismissButton,
            dismissMessage,
            dismissTimeout,
        },
    };
};

export const removeToast = ({ toastId }) => ({
    type: actionTypes.REMOVE_TOAST,
    payload: {
        toastId,
    },
});

export const clearAllToasts = () => ({
    type: actionTypes.CLEAR_ALL_TOASTS,
});
