// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import "./styles.scss";

const Button = (props) => {
    return (
        <button type={props.type} {...props}>
            {props.children}
        </button>
    );
};

export default Button;
