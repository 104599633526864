/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const CloseIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Close"
                {...props}>
                <path
                    clipRule="evenodd"
                    d="m20 40c11.0457 0 20-8.9543 20-20s-8.9543-20-20-20-20 8.9543-20 20 8.9543 20 20 20zm-7.0606-27.0607c.5858-.5857 1.5355-.5857 2.1213 0l4.9393 4.9394 4.9393-4.9393c.5858-.5858 1.5356-.5858 2.1214 0 .5857.5858.5857 1.5355 0 2.1213l-4.9393 4.9393 4.9393 4.9393c.5858.5858.5858 1.5356 0 2.1214-.5858.5857-1.5355.5857-2.1213 0l-4.9394-4.9394-4.9393 4.9394c-.5858.5858-1.5356.5858-2.1214 0-.5857-.5858-.5857-1.5355 0-2.1213l4.9394-4.9394-4.9393-4.9393c-.5858-.5858-.5858-1.5356 0-2.1214z"
                    fillRule="evenodd"
                />
            </IconWrapper>
        );
    }

    return null;
};

CloseIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

CloseIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
