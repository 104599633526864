import "../styles.scss";
import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { getIn } from "formik";
import _ from "lodash";
import { TextArea } from "../../TextArea";
import { createClassNameString } from "../../utils";
import { FormFieldError } from "../FormFieldError/FormFieldError";

export const FormTextArea = ({
    name,
    title,
    subTitle,
    placeholder,
    customError,
    className,
    inputClassName,
    formikProps,
    readOnly,
    testId,
}) => {
    const errorMessage = getIn(formikProps.errors, name);
    const isTouched = getIn(formikProps.touched, name);
    const value = _.get(formikProps.values, name);

    const handleChange = async (event) => {
        await formikProps.setFieldValue(name, event.target.value);
    };

    return (
        <div className={createClassNameString(["FormField-container", className])}>
            {(title || subTitle) && (
                <div data-public className="FormField-header_container">
                    {title && <h3 className="FormField-title">{title}</h3>}
                    {subTitle && <h3 className="FormField-subTitle">{subTitle}</h3>}
                </div>
            )}
            <div>
                <TextArea
                    error={isTouched && errorMessage}
                    value={value}
                    className={createClassNameString(["FormTextArea-input", inputClassName])}
                    placeholder={placeholder}
                    onChange={handleChange}
                    ignoreEnterSubmit={true}
                    readOnly={readOnly}
                    testId={testId}
                />
                {!customError && errorMessage && isTouched && (
                    <FormFieldError>{errorMessage}</FormFieldError>
                )}
                {/* lets the dev pick what is displayed below the text area */}
                {customError && customError(value, errorMessage, isTouched)}
            </div>
        </div>
    );
};

FormTextArea.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    customError: PropTypes.func,
    formikProps: PropTypes.object,
    testId: PropTypes.string,
};
