import moment from "moment";
import momentTZ from "moment-timezone";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { Button } from "../../../component-library/Button";
import { Image } from "../../../component-library/Image";
import { ButtonTypes } from "../../../component-library/constants";
import { capitalizeFirstLetter, createClassNameString } from "../../../component-library/utils";
import { AVAILABLE, AWAY, isSpecialEvent, BUSY } from "../../../constants/event";
import {
    closeDrawer,
    deleteAppointment,
    editAppointmentSelected,
    updateSelectedDate,
    updateSelectedDateV2,
    updateSelectedEvents,
} from "../../../redux/actions/calendar";
import { eventTypeDisplayNameFactory } from "../../Provider/ScheduleAvailability/ActiveScheduleDetails";
import ScheduleDeleteDialog from "../../Provider/ScheduleAvailability/ScheduleDeleteDialog";
import "./styles.scss";
import { getCanStartCall } from "../../../utils/calendar/util";
import CancellationInfo from "./CancellationInfo";
import { isUserPeerOrCM } from "../../../redux/actions/auth";

const CalendarAppointmentView = ({ className, event, history, calendarRect, ...props }) => {
    const {
        patient_name: patientName,
        patient_username: patientUsername,
        event_type: eventType,
        allotted_time: duration,
        patient_email: patientEmail,
        provider_email: providerEmail,
        series_data: seriesData,
        deleted: cancelled,
        callId,
    } = event || {};

    const timestamp = moment.utc(event?.timestamp).tz(momentTZ.tz.guess());
    const participantsEmails = [patientEmail, providerEmail];

    const busyEvent = eventType === BUSY;
    const userPeerOrCm = isUserPeerOrCM();

    const onJoin = () => {
        history.push(`/app/video-call?callId=${callId}`);
    };

    const onDelete = () => {
        setScheduleCallIdToDelete(callId);
    };

    const onEdit = () => {
        props.editAppointmentSelected(event);
    };

    const [scheduleCallIdToDelete, setScheduleCallIdToDelete] = useState(null);
    const hideDeleteDialog = () => {
        setScheduleCallIdToDelete(null);
    };
    const scheduleDeleteHandler = (cancellation_reason, twenty_four_hour_notice, delete_series) => {
        props.deleteAppointment(
            callId,
            cancellation_reason,
            twenty_four_hour_notice,
            delete_series,
        );
        setScheduleCallIdToDelete(null);
    };

    const scheduleDeleteDialogJsx = scheduleCallIdToDelete && (
        <ScheduleDeleteDialog
            type={eventType === AWAY ? "away" : "video"}
            callId={scheduleCallIdToDelete}
            scheduleDelete={scheduleDeleteHandler}
            cancelDelete={hideDeleteDialog}
        />
    );

    const renderParticipants = () => {
        return participantsEmails.map((email) => {
            return <span>{email}</span>;
        });
    };

    const shouldRenderJoinButton = () => {
        if (cancelled || isSpecialEvent(eventType) || busyEvent || userPeerOrCm) {
            return false;
        }
        return true;
    };

    const shouldRenderGuestList = () => {
        return ![AWAY, BUSY].includes(eventType);
    };

    const shouldRenderEditDeleteButtons = () => {
        if ([AWAY, AVAILABLE].includes(eventType) && userPeerOrCm) {
            return false;
        }
        // intentionally allows providers to delete available time
        // to give them complete control of their schedule
        if (eventType === AVAILABLE) {
            return true;
        }
        if (cancelled || hasAppointmentPast || busyEvent) {
            return false;
        }

        return true;
    };

    const hasAppointmentPast = timestamp.clone().add(duration, "minutes") < moment();

    const createEventTypeClassName = () => {
        return eventType;
    };

    const renderReoccurringText = () => {
        const seriesDaysArray = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
        ];
        const seriesDays = seriesData?.frequency_days
            .sort()
            .map((day) => ` ${seriesDaysArray[day]}`);

        const frequencyWeeks = seriesData?.frequency_weeks || 0;
        const frequencyWeeksText = frequencyWeeks > 0 ? `${frequencyWeeks + 1} weeks` : "week";

        const reoccuringText = `Reoccurs every ${frequencyWeeksText} on ${seriesDays} for ${seriesData?.number_of_occurrences} times`;
        return (
            <div className={"apti-CalendarAppointmentView--reoccuringTextContainer"}>
                <span className={"apti-CalendarAppointmentView--reoccuringText"}>
                    {reoccuringText}
                </span>
            </div>
        );
    };

    const formatTimeStamp = () => {
        return timestamp.format("MMMM DD, YYYY");
    };

    const renderEventType = () => {
        if (isSpecialEvent(eventType)) {
            return capitalizeFirstLetter(eventType);
        }

        return eventTypeDisplayNameFactory(eventType);
    };

    const renderParticipantName = () => {
        if (eventType === AVAILABLE) {
            return (
                <span className={"apti-CalendarAppointmentView--awayTitle"}>
                    {capitalizeFirstLetter(eventType)}
                </span>
            );
        }

        if (eventType === AWAY) {
            return <span className={"apti-CalendarAppointmentView--awayTitle"}>{event.label}</span>;
        }

        return (
            <span
                className={"apti-CalendarAppointmentView--participantName"}
                onClick={() => {
                    history.push({
                        pathname: `/app/patient/${patientUsername}/profile/v2`,
                    });
                }}>
                {patientName}
            </span>
        );
    };

    return (
        <div className={createClassNameString(["apti-CalendarAppointmentView", className])}>
            {cancelled ? (
                <>
                    <CancellationInfo event={event} calendarRect={calendarRect} />
                </>
            ) : null}

            {!busyEvent && renderParticipantName()}
            <div className="apti-CalendarAppointmentView--containerDot">
                {!busyEvent && <span
                    className={`apti-CalendarAppointmentView--dot ${createEventTypeClassName()}`}
                />}
                <span className={`apti-CalendarAppointmentView--text ${createEventTypeClassName()}`}>{renderEventType()}</span>
            </div>
            <div>
                <div className={"apti-CalendarAppointmentView--headersContainer"}>
                    <Image
                        className={"apti-CalendarAppointmentView--icon"}
                        src={"icons/calendar-outline.svg"}
                        alt={"Calendar Icon"}
                    />
                    <div>
                        <span>{formatTimeStamp()}</span>
                        <span className="ml-2 mr-2">&#x2022;</span>
                        <span>
                            {timestamp.format("hh:mm")}&thinsp;&ndash;&thinsp;
                            {timestamp.clone().add(duration, "minutes").format("hh:mm A")}
                        </span>
                    </div>
                </div>
                {seriesData && renderReoccurringText()}
            </div>
            {shouldRenderJoinButton() && (
                <div className={"apti-CalendarAppointmentView--container my-2"}>
                    <Image
                        className="apti-CalendarAppointmentView--icon"
                        src="icons/camera-outline.svg"
                        alt="Camera Icon"
                    />
                    <div className="apti-CalendarAppointmentView--joinButtonContainer">
                        <Button
                            className={"apti-CalendarAppointmentView--joinButton"}
                            disabled={
                                !getCanStartCall(
                                    moment().utc().format("YYYY-MM-DD HH:mm"),
                                    event.timestamp,
                                    duration,
                                )
                            }
                            onClick={onJoin}>
                            Join
                        </Button>
                        <span className={"apti-CalendarAppointmentView--joinButtonSubText"}>
                            {hasAppointmentPast ? "This session ended" : "Up to 15 min before"}
                        </span>
                    </div>
                </div>
            )}
            {shouldRenderGuestList() && (
                <div>
                    <div className={"apti-CalendarAppointmentView--headers-container"}>
                        <Image
                            className={"apti-CalendarAppointmentView--icon"}
                            src="icons/user-outline.svg"
                            alt="User Icon"
                        />
                        <span className={"apti-CalendarAppointmentView--text"}>Guests</span>
                    </div>
                    <div className={"apti-CalendarAppointmentView--participantsContainer"}>
                        {renderParticipants()}
                    </div>
                </div>
            )}
            {shouldRenderEditDeleteButtons() && (
                <div className={"apti-CalendarAppointmentView--editDeleteButtonsContainer"}>
                    <Button
                        buttonType={ButtonTypes.primaryOutline}
                        className={"apti-CalendarAppointmentView--deleteButton"}
                        onClick={onDelete}>
                        Delete
                    </Button>
                    <Button
                        buttonType={ButtonTypes.primaryOutlineV2}
                        className={"apti-CalendarAppointmentView--editDeleteButtons"}
                        onClick={onEdit}>
                        Edit
                    </Button>
                </div>
            )}
            {scheduleDeleteDialogJsx}
        </div>
    );
};

CalendarAppointmentView.propTypes = {
    className: PropTypes.string,
    event: PropTypes.object,
    calendarRect: PropTypes.object,
};

CalendarAppointmentView.defaultProps = {
    className: undefined,
    event: {},
    calendarRect: undefined,
};

const mapStateToProps = (state) => {
    return {
        calendar: state.calendar,
    };
};

const mapDispatchToProps = {
    deleteAppointment,
    closeDrawer,
    editAppointmentSelected,
    updateSelectedDate,
    updateSelectedDateV2,
    updateSelectedEvents,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CalendarAppointmentView),
);
