// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { useEffect } from "react";
import "./styles.scss";
import images from "../../../utils/images";

import {
    TOAST_ERROR,
    TOAST_SUCCESS,
    TOAST_ALERT,
    TOAST_ALERT_2,
    TOAST_SUCCESS_2,
    EXTENSIBLE_TOAST_ERROR,
    CUSTOM_TOAST_ALERT,
} from "../Alert";

const SnackBar = ({
    dismiss,
    message,
    type = TOAST_ERROR,
    requestId,
    show_exception_handling,
    buttonText,
    buttonAction,
    customHTML = undefined,
    ...rest
}) => {
    let timeoutRef = setTimeout(dismiss, 10000);
    let classNames = {
        snackBar: ["SnackBar", "p-2", "position-fixed"],
        snackBarWpr: [
            "SnackBar__wpr",
            "d-flex",
            "flex-column",
            "flex-md-row",
            "align-items-md-center",
            "justify-content-between",
        ],
    };

    useEffect(() => {
        return () => {
            try {
                clearTimeout(timeoutRef);
            } catch (e) {
                console.log(e);
            }
        };
    }, []);

    let iconArt;

    if (type === TOAST_ERROR || type === EXTENSIBLE_TOAST_ERROR) {
        classNames.snackBar.push("SnackBar--error");
        iconArt = (
            <img
                className="mr-2 mb-1"
                width="20"
                src={images("./icons/warning-triangle.svg")}
                alt="warning icon"
            />
        );
    } else if (type === TOAST_SUCCESS) {
        classNames.snackBar.push("SnackBar--success");
        iconArt = (
            <img
                className="mr-2 mb-1"
                width="20"
                src={images("./icons/circle-tick-success.svg")}
                alt="success icon"
            />
        );
    } else if (type === TOAST_SUCCESS_2) {
        classNames.snackBar.push("SnackBar--success-2");
        iconArt = (
            <img
                className="mr-2 mb-1"
                width="20"
                src={images("./icons/circle-tick-success.svg")}
                alt="success icon"
            />
        );
    } else if (type === TOAST_ALERT) {
        classNames.snackBar.push("SnackBar--alert");
        iconArt = (
            <img
                className="mr-2 mb-1"
                width="20"
                src={images("./icons/time-alert.svg")}
                alt="time icon"
            />
        );
    } else if (type === TOAST_ALERT_2) {
        classNames.snackBar.push("SnackBar--alert");
        iconArt = (
            <img
                className="mr-2 mb-1"
                width="20"
                src={images("./icons/toast_rect_sec.svg")}
                alt="info icon"
            />
        );
    } else if (type === CUSTOM_TOAST_ALERT) {
        classNames.snackBar.push("SnackBar--success-2");
        iconArt = (
            <img
                className="mr-2 mb-1"
                width="20"
                src={images("./icons/toast_rect_success.svg")}
                alt="success icon"
            />
        );
    }
    return (
        <div className={classNames.snackBar.join(" ")}>
            <div className={classNames.snackBarWpr.join(" ")}>
                <div>
                    <span>{iconArt}</span>
                </div>
                <div className="ml-1 ml-md-3">
                    {type !== CUSTOM_TOAST_ALERT && (
                        <>
                            <span className="fs-14">{message}</span>
                            <br />
                        </>
                    )}
                    {type === CUSTOM_TOAST_ALERT && (
                        <>
                            <span className="fs-14">{React.cloneElement(customHTML)}</span>
                        </>
                    )}
                    {type === TOAST_ERROR && requestId && (
                        <span className="fs-12">{`Request ID: ${requestId}.`}</span>
                    )}
                </div>
                {type === TOAST_ERROR ? (
                    <div>
                        {requestId && (
                            <button
                                className="SnackBar__dismiss-btn align-self-end align-self-md-center mr-2"
                                onClick={() => {
                                    show_exception_handling();
                                    dismiss();
                                }}>
                                <strong>Report</strong>
                            </button>
                        )}
                        <button
                            className="SnackBar__dismiss-btn align-self-end align-self-md-center"
                            onClick={() => {
                                clearTimeout(timeoutRef);
                                dismiss();
                            }}>
                            <strong>Dismiss</strong>
                        </button>
                    </div>
                ) : null}
                {type === TOAST_ALERT ? (
                    <button
                        className="SnackBar__dismiss-btn_alert align-self-end align-self-md-center"
                        onClick={() => {
                            clearTimeout(timeoutRef);
                            dismiss();
                        }}>
                        <strong>Dismiss</strong>
                    </button>
                ) : null}
                {type === TOAST_ALERT_2 && customHTML === undefined ? (
                    <button
                        className="SnackBar__dismiss-btn_alert mobile_align purple_bg_btn align-self-end align-self-md-center"
                        onClick={() => {
                            clearTimeout(timeoutRef);
                            dismiss();
                        }}>
                        <strong>Dismiss</strong>
                    </button>
                ) : null}
                {type === TOAST_ALERT_2 && customHTML !== undefined ? (
                    <>
                        <span className="fs-14">{React.cloneElement(customHTML)}</span>
                    </>
                ) : null}
                {type === TOAST_SUCCESS_2 ? (
                    <button
                        className="Btn Btn--otl-pri Btn--sm-2 align-self-end align-self-md-center"
                        onClick={() => {
                            clearTimeout(timeoutRef);
                            dismiss();
                        }}>
                        <strong>Dismiss</strong>
                    </button>
                ) : null}
                {type === TOAST_ALERT_2 && customHTML !== undefined ? (
                    <button
                        className="Btn purple_bg_btn Btn--sm-2 align-self-start align-self-md-center"
                        onClick={() => {
                            clearTimeout(timeoutRef);
                            dismiss();
                        }}>
                        <span className="btn_txt_sm">Dismiss</span>
                    </button>
                ) : null}
                {type === CUSTOM_TOAST_ALERT ? (
                    <button
                        className="Btn pri_bg_btn Btn--sm-2 align-self-start align-self-md-center"
                        onClick={() => {
                            clearTimeout(timeoutRef);
                            dismiss();
                        }}>
                        <span className="btn_txt_sm">Dismiss</span>
                    </button>
                ) : null}
                {type === EXTENSIBLE_TOAST_ERROR ? (
                    <button
                        className="SnackBar__dismiss-btn align-self-end align-self-md-center"
                        onClick={() => {
                            clearTimeout(timeoutRef);
                            dismiss();
                            buttonAction();
                        }}>
                        <strong>{buttonText ? buttonText : "Dismiss"}</strong>
                    </button>
                ) : null}
            </div>
        </div>
    );
};

export default SnackBar;
