import React, { useState } from "react";
import { CarePlanCard } from "../CarePlanCard";
import { CarePlanIconButton } from "../CarePlanIconButton";
import {
    Button,
    ButtonSizes,
    ButtonTypes,
    Checkbox,
    CustomForm,
    EditIcon,
    Modal,
} from "../../../../../component-library";
import { CloseIcon } from "../../../../Icons/CloseIcon";
import { ICON_SIZES, ICON_TYPES } from "../../../../../constants/icons";
import { Form } from "formik";
import { FormTextArea } from "../../../../../component-library/FormFields/FormTextArea/FormTextArea";
import * as yup from "yup";
import { CharacterCounter } from "../../../../../component-library/CharacterCounter/CharacterCounter";
import { api } from "../../../../../APIRequests";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../../../redux/actions/loader";
import { localTimestampFromStandardTimestamp } from "../../../../../utils/momentUtils";
import { FormFieldError } from "../../../../../component-library/FormFields/FormFieldError/FormFieldError";
import "./styles.scss";
import { addToast, toastMessageTypes } from "../../../../../redux/actions/toaster";
import { ProviderTypes } from "../../../../../constants/user";
import CloseOnOutsideClick from "../../../../Common/CloseOnOutsideClick/CloseOnOutsideClick";
import { XSS_REGEX } from "../../../../../constants/security";
import { getAutomationDataAttr } from "../../../../../utils/automationUtils";

const DESCRIPTION_TEXT =
    "Medical conditions or surgeries that may impact long-term medication prescribing.";
const OTHER_OPTION_ID = "MC.OTHER";

export const MedicalCautions = (props) => {
    const [showModal, setShowModal] = useState(false);
    const profile = useSelector((state) => state.auth.profile);
    const { existingMedicalCautions, patientMedicalCautionOptions, refreshCautions } = props;
    const dispatch = useDispatch();

    const onModalSubmission = async (medicalCautions, otherFreeText) => {
        dispatch(showLoader(true));
        const response = await api.carePlan.update_patient_medical_cautions({
            data: {
                current_version: existingMedicalCautions.current_version,
                patient_medical_cautions: medicalCautions,
                patient_medical_cautions_other_free_text: otherFreeText,
            },
            urlParams: { patientId: props.patientId },
            params: { showLoader: false },
            errorTypeConfig: {
                CarePlanInvalidVersionException: {
                    toastErrorType: toastMessageTypes.error_v2,
                },
            },
        });
        await refreshCautions();
        dispatch(hideLoader());
        closeModal();

        dispatch(
            addToast({
                message: response?.message || "Updated",
                messageType: toastMessageTypes.success_v2,
            }),
        );
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const renderExistingMedicalCautions = () => {
        const {
            patient_medical_cautions,
            patient_medical_cautions_other_free_text,
            updated_by_name,
            updated_timestamp,
        } = existingMedicalCautions;

        const formattedCautions = patient_medical_cautions.map((cautionId) => {
            const medicalCautionOption = patientMedicalCautionOptions.find(
                (option) => option.caution_id === cautionId,
            );
            const cautionText =
                medicalCautionOption.caution_id === OTHER_OPTION_ID
                    ? patient_medical_cautions_other_free_text
                    : medicalCautionOption.caution_text;

            return (
                <p key={cautionId} className={"PatientGoalsStrengthsCautions--readonly-text"}>
                    - {cautionText}
                </p>
            );
        });

        return (
            <div {...getAutomationDataAttr("PatientMedicalCautions_existingCautions")}>
                {formattedCautions}
                <div className="PatientGoalsStrengthsCautions--meta-data">
                    <p className="PatientGoalsStrengthsCautions--meta-data-text">
                        Last Updated by {updated_by_name}
                    </p>
                    <p className="PatientGoalsStrengthsCautions--meta-data-text">
                        {localTimestampFromStandardTimestamp(updated_timestamp, "M/D/YYYY")}
                    </p>
                </div>
            </div>
        );
    };

    const renderNoMedicalCautionsSpecified = () => {
        const textBackgroundClass = `PatientGoalsStrengthsCautions--empty${
            isAuthorizedToEdit() ? "" : " no-permission"
        }`;

        return (
            <div
                className={"PatientGoalsStrengthsCautions--empty-container"}
                {...getAutomationDataAttr("PatientMedicalCautions_emptyCautions")}>
                <div className={textBackgroundClass}>
                    <p className={"PatientGoalsStrengthsCautions--empty-text"}>
                        No known medical cautions.
                    </p>
                </div>
            </div>
        );
    };

    const isAuthorizedToEdit = () => {
        return profile?.provider_type && [ProviderTypes.PRESCRIBE].includes(profile.provider_type);
    };

    return (
        <CarePlanCard
            title="Medical Cautions"
            testId={"PatientMedicalCautions_container"}
            className="PatientGoalsStrengthsCautions--element-container"
            actions={
                isAuthorizedToEdit() && (
                    <CarePlanIconButton
                        testId={"PatientMedicalCautions_editButton"}
                        onClick={() => setShowModal(true)}
                        text={
                            existingMedicalCautions?.patient_medical_cautions?.length
                                ? "Edit"
                                : "Add"
                        }
                        Icon={EditIcon}
                        iconClassName="PatientGoalsStrengthsCautions--icon"
                        iconButtonClassName="PatientGoalsStrengthsCautions--icon-button"
                    />
                )
            }>
            <p className="PatientGoalsStrengthsCautions--subtitle">{DESCRIPTION_TEXT}</p>
            {existingMedicalCautions?.patient_medical_cautions?.length
                ? renderExistingMedicalCautions()
                : renderNoMedicalCautionsSpecified()}
            {showModal && (
                <MedicalCautionsModal
                    show={showModal}
                    onClose={closeModal}
                    patientMedicalCautionOptions={patientMedicalCautionOptions}
                    onModalSubmission={onModalSubmission}
                    existingMedicalCautions={existingMedicalCautions}
                />
            )}
        </CarePlanCard>
    );
};

const MedicalCautionsModal = (props) => {
    return (
        <Modal
            className="PatientGoalsStrengthsCautions--modal"
            show={props.show}
            cardClassName="PatientGoalsStrengthsCautions--modal_dialog">
            <CloseOnOutsideClick setShowComponent={props.onClose}>
                <CloseIcon
                    onClick={props.onClose}
                    className={"PatientGoalsStrengthsCautions--modal_close_icon"}
                    iconSize={ICON_SIZES.SMALL}
                    iconType={ICON_TYPES.OUTLINE}
                />
                <MedicalCautionsForm
                    onClose={props.onClose}
                    onModalSubmission={props.onModalSubmission}
                    patientMedicalCautionOptions={props.patientMedicalCautionOptions}
                    existingMedicalCautions={props.existingMedicalCautions}
                />
            </CloseOnOutsideClick>
        </Modal>
    );
};

const MedicalCautionsForm = (props) => {
    const { patientMedicalCautionOptions, existingMedicalCautions } = props;
    const {
        patient_medical_cautions: initialPatientMedicalCautions,
        patient_medical_cautions_other_free_text: initialOtherFreeText,
    } = existingMedicalCautions;

    const maxOtherTextCharacters = 500;
    const medicalCautionsSchema = yup.object().shape({
        patientMedicalCautions: yup.array().of(yup.string()),
        otherFreeText: yup.string().when("patientMedicalCautions", (cautions, schema) => {
            return cautions.includes(OTHER_OPTION_ID)
                ? yup
                      .string()
                      .required("Other reason must be specified")
                      .max(
                          maxOtherTextCharacters,
                          `Limit text to ${maxOtherTextCharacters} characters`,
                      )
                      .test("special characters", "Special characters are not allowed", (value) => {
                          return !XSS_REGEX.test(value);
                      })
                : schema;
        }),
    });

    const checkboxHandler = async (formikProps, selectedOption) => {
        const updatedPatientMedicalCautions = new Set(formikProps.values.patientMedicalCautions);
        let updatedOtherFreeText = formikProps.values.otherFreeText;

        if (updatedPatientMedicalCautions.has(selectedOption.caution_id)) {
            updatedPatientMedicalCautions.delete(selectedOption.caution_id);

            // Reset the other text data
            if (selectedOption.caution_id === OTHER_OPTION_ID) {
                updatedOtherFreeText = "";
            }
        } else {
            updatedPatientMedicalCautions.add(selectedOption.caution_id);
        }

        await formikProps.setValues({
            patientMedicalCautions: Array.from(updatedPatientMedicalCautions),
            otherFreeText: updatedOtherFreeText,
        });
    };

    const renderOptions = (formikProps) => {
        const formikInputProps = {
            inputClasses: "Input--sm",
            errors: formikProps.errors,
            touched: formikProps.touched,
        };

        return patientMedicalCautionOptions.map((option, index) => {
            return (
                <div key={index}>
                    <Checkbox
                        onClick={async () => checkboxHandler(formikProps, option)}
                        name={"patientMedicalCautions"}
                        value={option.caution_id}
                        checked={formikProps.values.patientMedicalCautions.includes(
                            option.caution_id,
                        )}
                        label={option.caution_text}
                        testId={`PatientMedicalCautions_input_${option.caution_id}`}
                    />
                    {option.caution_id === OTHER_OPTION_ID && (
                        <>
                            <FormTextArea
                                {...formikInputProps}
                                inputClassName="MedicalCautions--modal-free-text-input"
                                name="otherFreeText"
                                formikProps={formikProps}
                                readOnly={
                                    !formikProps?.values?.patientMedicalCautions?.includes(
                                        OTHER_OPTION_ID,
                                    )
                                }
                                triggerErrorOnChange
                                testId={"PatientMedicalCautions_otherTextInput"}
                            />
                            <CharacterCounter
                                className={"PatientGoalsStrengthsCautions--input_character_counter"}
                                length={formikProps.values?.otherFreeText?.length}
                                characterLimit={maxOtherTextCharacters}
                            />
                        </>
                    )}
                </div>
            );
        });
    };

    const renderForm = (formikProps) => {
        return (
            <Form>
                <div className="PatientGoalsStrengthsCautions--modal_form">
                    <div data-public className="FormField-header_container">
                        <p className="PatientGoalsStrengthsCautions--modal_subtitle">
                            {DESCRIPTION_TEXT}
                        </p>
                    </div>

                    <div className={"MedicalCautions--modal-option-container"}>
                        {renderOptions(formikProps)}
                        {formikProps.errors?.patientMedicalCautions && (
                            <FormFieldError>
                                {formikProps.errors.patientMedicalCautions}
                            </FormFieldError>
                        )}
                    </div>

                    <div className="PatientGoalsStrengthsCautions-button_wrapper">
                        <Button
                            type="submit"
                            buttonSize={ButtonSizes.small}
                            buttonType={ButtonTypes.primaryV2}>
                            Save
                        </Button>
                        <Button
                            type="button"
                            onClick={props.onClose}
                            buttonType={ButtonTypes.primaryOutlineV2}
                            buttonSize={ButtonSizes.small}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <div className="PatientGoalsStrengthsCautions--modal_internal">
            <div className="PatientGoalsStrengthsCautions--modal_header">
                <h1 className="PatientGoalsStrengthsCautions--modal_title">
                    {`${initialPatientMedicalCautions?.length ? "Edit" : "Add"} Medical Cautions`}
                </h1>
            </div>
            <CustomForm
                initialValues={{
                    patientMedicalCautions: [...initialPatientMedicalCautions],
                    otherFreeText: initialOtherFreeText ?? "",
                }}
                validationSchema={medicalCautionsSchema}
                validateOnChange={true}
                validateOnBlur={true}
                render={renderForm}
                onSubmit={async (formData) => {
                    await props.onModalSubmission(
                        formData.patientMedicalCautions,
                        formData.otherFreeText,
                    );
                }}
            />
        </div>
    );
};
