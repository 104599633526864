// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import PatientProfileDetailsCard from "./PatientProfileDetailsCard";
import { Form, Formik } from "formik";
import Label from "../../UI/Label";
import InputError from "../../UI/InputError";
import images from "../../../utils/images";
import Button from "../../UI/Button";
import ImageFullPreview from "../../Common/ImageFullPreview";
import FormikInput from "../../UI/FormikInput";
import { uploadInsImage } from "../../../redux/actions/auth";
import { api } from "../../../APIRequests";
import {
    PRI_INSURANCE_SCHEMA,
    SEC_INSURANCE_SCHEMA,
} from "../../../containers/Dashboard/Patient/schema/PatientProfileInfoSchema";
import * as yup from "yup";
import { connect } from "react-redux";
import SubmitControls from "./SubmitControls";
import Hr from "../../UI/Hr";
import FormikMobileInput from "../../UI/formikMobileInput";
import {
    dobValidationSchemaFactory,
    noInsuranceCarrierValidationSchemaFactory,
    formatDate,
} from "../../../utils/yupSchemaUtils";
import { PRI, SEC } from ".";
import { ReadOnlyInsuranceInfo } from "./ReadOnlyInsuranceInfo";
import _ from "lodash";
import InsuranceInfo from "./InsuranceInfo";
import { trackPolicyAcknowledgement } from "../../../utils/userAgreementUtil";

yup.addMethod(yup.date, "format", formatDate); // we are extending data schema by adding our custom method
const dobValidationSchema = dobValidationSchemaFactory("MMDDYYYY");

const insuranceValidationSchema = yup.object().shape({
    state: yup.string().nullable(),
    carrier: yup.string().required("Carrier is required").nullable(),
    member_id: noInsuranceCarrierValidationSchemaFactory(
        "carrier",
        yup
            .string()
            .required("Member id is required")
            .matches(/^[a-zA-Z0-9]*$/, "Enter a valid Member ID")
            .nullable(),
    ),
    policy_holder_name: noInsuranceCarrierValidationSchemaFactory(
        "carrier",
        yup
            .string()
            .matches(/^[a-z A-Z]+$/, "Digits, space & special characters are not allowed")
            .required("Policy holder name is required")
            .nullable(),
    ),
    policy_dob: dobValidationSchema.nullable(),
});

class PatientProfileInsuranceInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editableData: null,
            tempUrls: props.insuranceImageUrls,
            tempContentTypes: props.insuranceImageTypes,
            contentType: null,
            contentTypes: props.insuranceImageTypes,
            savedImgOnRemove: {},
            validationSchema:
                props.type === "PRI"
                    ? yup.object().shape({
                          primary_insurance: insuranceValidationSchema,
                      })
                    : yup.object().shape({
                          secondary_insurance: insuranceValidationSchema,
                      }),
            uploadImgApi: props.uploadImgApi ? props.uploadImgApi : api.auth.patient_upload_ins,
            acknowledgements: {},
        };
    }

    componentDidMount() {
        const options = { params: { showLoader: false } };
        api.patient
            .fetch_insurance_list({ options })
            .then((cleanResponse) => {
                let carriersOptions = cleanResponse.carriers.map((carriers) => {
                    return { value: carriers, display: carriers };
                });
                let statesOptions = cleanResponse.state.map((states) => {
                    return { value: states, display: states };
                });
                // modify original schema with received options list defined and set them in state
                PRI_INSURANCE_SCHEMA.forEach((el) =>
                    this.setInsOptions(el, carriersOptions, statesOptions),
                );
                SEC_INSURANCE_SCHEMA.forEach((el) =>
                    this.setInsOptions(el, carriersOptions, statesOptions),
                );
                this.setState({
                    pri_insurance_schema: PRI_INSURANCE_SCHEMA,
                    sec_insurance_schema: SEC_INSURANCE_SCHEMA,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    setInsOptions = (el, carriersOptions, statesOptions) => {
        if (el.elementConfig && el.elementConfig.label === "Carrier") {
            el.elementConfig.options = carriersOptions;
        } else if (el.elementConfig && el.elementConfig.label === "State") {
            el.elementConfig.options = statesOptions;
        }
    };

    toggleEdit = () => {
        this.setState((ps) => {
            return {
                edit: !ps.edit,
                tempUrls: ps.insuranceImageUrls,
            };
        });
    };

    renderInsForm = (formikProps) => {
        let props =
            this.props.type === PRI
                ? {
                      schema: this.state.pri_insurance_schema,
                      fUrl: this.state.tempUrls.pfUrl,
                      // fContentType: this.props.insuranceImageTypes.pfType,
                      bUrl: this.state.tempUrls.pbUrl,
                      // bContentType: this.props.insuranceImageTypes.pbType,
                  }
                : {
                      schema: this.state.sec_insurance_schema,
                      fUrl: this.state.tempUrls.sfUrl,
                      bUrl: this.state.tempUrls.sbUrl,
                  };
        return (
            <Form style={{ width: "100%" }}>
                <div className="mx-auto px-0 ">
                    <div className="container px-0">
                        <div className="row">
                            {props.schema &&
                                props.schema.map((formEl, i) => {
                                    if (formEl.elementType === "InsuranceCard") {
                                        let url = formEl.type === "f" ? props.fUrl : props.bUrl;
                                        let nameArr = formEl.name.split(".");
                                        let error;
                                        if (formikProps.errors && formikProps.errors[nameArr[0]]) {
                                            error = formikProps.errors[nameArr[0]][nameArr[1]];
                                        }
                                        return (
                                            <div
                                                key={i}
                                                className="col-6 col-lg-3 "
                                                style={{ paddingBottom: 10 }}>
                                                <Label>
                                                    {formEl.type === "f"
                                                        ? "Card Front"
                                                        : "Card Back"}
                                                </Label>
                                                <div className="d-flex align-items-center">
                                                    {url && (
                                                        <img
                                                            className="PatientProfile__insurance-image-preview"
                                                            src={url}
                                                            alt=""
                                                        />
                                                    )}
                                                    {url && (
                                                        <div
                                                            role="button"
                                                            onClick={() =>
                                                                this.removeImg(formEl, formikProps)
                                                            }
                                                            className="PatientProfile__ins-delete-img">
                                                            <img
                                                                width="8"
                                                                src={images(
                                                                    "./icons/cross-white-sm.svg",
                                                                )}
                                                                alt=""
                                                            />
                                                        </div>
                                                    )}
                                                    {!url && (
                                                        <div className="position-relative mt-2 PatientProfile__insuranceUpload">
                                                            <Button
                                                                className="Btn Btn--pri Btn-sm"
                                                                style={{
                                                                    marginTop: -10,
                                                                    minWidth: 10,
                                                                }}>
                                                                Upload
                                                            </Button>
                                                            <input
                                                                type="file"
                                                                name="front"
                                                                className="Btn Btn--pri Btn-sm"
                                                                style={{
                                                                    marginTop: -10,
                                                                    minWidth: 10,
                                                                }}
                                                                onChange={(e) =>
                                                                    this.imgUploadHandler(
                                                                        e,
                                                                        formEl.name,
                                                                        formikProps,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                {error && (
                                                    <div className="position-relative w-100">
                                                        <InputError>{error}</InputError>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div key={formEl.name} className={"col-12 col-lg-6"}>
                                                {formEl.name == "primary_insurance.policy_dob" ? (
                                                    <FormikMobileInput
                                                        value={
                                                            formikProps &&
                                                            formikProps.values.primary_insurance
                                                                .policy_dob
                                                        }
                                                        onChange={async (val) => {
                                                            await formikProps.setFieldValue(
                                                                "primary_insurance.policy_dob",
                                                                val.value,
                                                            );
                                                            formikProps.setFieldTouched(
                                                                "primary_insurance.policy_dob",
                                                            );
                                                        }}
                                                        formEl={formEl}
                                                        errors={formikProps.errors}
                                                        touched={formikProps.touched}
                                                    />
                                                ) : formEl.name ==
                                                  "secondary_insurance.policy_dob" ? (
                                                    <FormikMobileInput
                                                        value={
                                                            formikProps &&
                                                            formikProps.values.secondary_insurance
                                                                .policy_dob
                                                        }
                                                        onChange={async (val) => {
                                                            await formikProps.setFieldValue(
                                                                "secondary_insurance.policy_dob",
                                                                val.value,
                                                            );
                                                            formikProps.setFieldTouched(
                                                                "secondary_insurance.policy_dob",
                                                            );
                                                        }}
                                                        formEl={formEl}
                                                        errors={formikProps.errors}
                                                        touched={formikProps.touched}
                                                    />
                                                ) : (
                                                    <FormikInput formEl={formEl} {...formikProps} />
                                                )}
                                            </div>
                                        );
                                    }
                                })}
                        </div>
                    </div>
                </div>
                <div className="mb-3 d-lg-none">
                    <Hr />
                </div>
                <SubmitControls cancelHandler={() => this.cancelHandler(formikProps)} />
            </Form>
        );
    };

    imgUploadHandler = (e, name, formikProps) => {
        let baseName, urlType;
        switch (name) {
            case "primary_insurance.card_front": {
                baseName = "primary_front_";
                urlType = "pfUrl";
                break;
            }
            case "primary_insurance.card_back": {
                baseName = "primary_back_";
                urlType = "pbUrl";
                break;
            }
            case "secondary_insurance.card_front": {
                baseName = "secondary_front_";
                urlType = "sfUrl";
                break;
            }
            case "secondary_insurance.card_back": {
                baseName = "secondary_back_";
                urlType = "sbUrl";
                break;
            }
            default:
        }
        let selectedFile = e.target.files[0];
        let fileName = baseName + this.props.data.username;
        let fileType = selectedFile.type;
        let base64Img;
        let reader = new FileReader();
        reader.onload = (e) => {
            base64Img = reader.result;
        };
        reader.readAsDataURL(selectedFile);
        try {
            let data = { fileName, fileType };
            this.state
                .uploadImgApi({ data: data })
                .then((cleanResponse) => {
                    let url = cleanResponse && cleanResponse.url;
                    const options = { params: { showLoader: false } };
                    if (url) {
                        api.auth
                            .file_upload({ data: selectedFile, url, fileType, options })
                            .then(async (cleanResponse) => {
                                let insuranceImageUrls = { ...this.state.tempUrls };
                                insuranceImageUrls[urlType] = base64Img;
                                let profileData = { ...this.props.data };
                                let insurance = name.split(".");
                                const composedImgUrl = "backend/insurance_cards/" + fileName;
                                profileData[insurance[0]][insurance[1]] = composedImgUrl;
                                await formikProps.setFieldValue(name, composedImgUrl);
                                await formikProps.validateForm();
                                this.setState({
                                    tempUrls: insuranceImageUrls,
                                });
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    };

    removeImg = async (el, formikProps) => {
        let url;
        let insuranceImageUrls = { ...this.state.tempUrls };
        let backedUpUrl = { ...this.state.savedImgOnRemove };
        switch (el.name) {
            case "primary_insurance.card_front":
                url = "pfUrl";
                break;
            case "primary_insurance.card_back":
                url = "pbUrl";
                break;
            case "secondary_insurance.card_front":
                url = "sfUrl";
                break;
            case "secondary_insurance.card_back":
                url = "sbUrl";
                break;
            default:
        }
        backedUpUrl[el.name] = insuranceImageUrls[url];
        insuranceImageUrls[url] = null;
        await formikProps.setFieldValue(el.name, "");
        this.setState({
            tempUrls: insuranceImageUrls,
            savedImgOnRemove: backedUpUrl,
        });
    };

    alteredFormData = (data) => {
        let alteredData = { ...data };
        let DOBnumber = {
            primaryDOB: alteredData.primary_insurance.policy_dob,
            secondaryDOB: alteredData.secondary_insurance.policy_dob,
        };
        if (DOBnumber.primaryDOB && !DOBnumber.primaryDOB.includes("/")) {
            DOBnumber = {
                primaryDOB: `${DOBnumber.primaryDOB.substring(
                    0,
                    2,
                )}/${DOBnumber.primaryDOB.substring(2, 4)}/${DOBnumber.primaryDOB.substring(4, 8)}`,
                secondaryDOB: DOBnumber.secondaryDOB,
            };
        }
        if (DOBnumber.secondaryDOB && !DOBnumber.secondaryDOB.includes("/")) {
            DOBnumber = {
                primaryDOB: DOBnumber.primaryDOB,
                secondaryDOB: `${DOBnumber.secondaryDOB.substring(
                    0,
                    2,
                )}/${DOBnumber.secondaryDOB.substring(2, 4)}/${DOBnumber.secondaryDOB.substring(
                    4,
                    8,
                )}`,
            };
        }

        return DOBnumber;
    };

    cancelHandler = async (formikProps) => {
        let removedInsObjKeys = Object.keys(this.state.savedImgOnRemove);

        //restoring insurance imgs back to original if cancelled after img removed.
        if (removedInsObjKeys.length) {
            removedInsObjKeys.forEach((key) => {
                (async () => {
                    await formikProps.setFieldValue(key, this.state.savedImgOnRemove[key]);
                })();
            });
            this.setState({
                tempUrls: { ...this.state.insuranceImageUrls },
            });
        }
        this.props.onEdit(this.props.type);
    };

    submitInsHandler = (formData, action) => {
        const data = {
            ...this.props.data,
            ...formData,
        };
        data.acknowledgements = {
            ...data.acknowledgements,
            ...this.state.acknowledgements,
        };
        this.props.submit(data, action, this.props.type);
    };

    renderImgFullPreview = (url, contentType) => {
        this.setState({
            insFullPreviewUrl: url,
            contentType: contentType,
        });
    };
    closeFullPreview = () => {
        this.setState({
            insFullPreviewUrl: null,
        });
    };

    addAcknowledgement = (policyName, acknowledgement) => {
        const { data } = this.props;
        if (data.acknowledgements && !data.acknowledgements[policyName]) {
            const acknowledgements = {
                ...this.state.acknowledgements,
                [policyName]: acknowledgement,
            };
            this.setState({ acknowledgements });
            trackPolicyAcknowledgement(policyName);
        }
    };

    render() {
        let fUrl, bUrl, ins, fContentType, bContentType;
        if (PRI === this.props.type) {
            fUrl = this.props.insuranceImageUrls.pfUrl;
            fContentType = this.props.insuranceImageTypes.pfType;
            bContentType = this.props.insuranceImageTypes.pbType;
            bUrl = this.props.insuranceImageUrls.pbUrl;
            ins = this.props.data.primary_insurance;
        } else {
            fUrl = this.props.insuranceImageUrls.sfUrl;
            fContentType = this.props.insuranceImageTypes.sfType;
            bContentType = this.props.insuranceImageTypes.sbType;
            bUrl = this.props.insuranceImageUrls.sbUrl;
            ins = this.props.data.secondary_insurance;
        }
        return (
            <>
                {this.state.insFullPreviewUrl && (
                    <ImageFullPreview
                        url={this.state.insFullPreviewUrl}
                        contentType={this.state.contentType}
                        close={this.closeFullPreview}
                    />
                )}
                <InsuranceInfo
                    {...this.props}
                    insuranceImageUrls={this.props.insuranceImageUrls}
                    tempContentTypes={this.props.insuranceImageTypes}
                    submit={this.submitInsHandler}
                    edit={this.props.edit}
                    onEdit={this.props.onEdit}
                    type={this.props.type}
                    emptyTitle={this.props.emptyTitle}
                    emptyDetails={this.props.emptyDetails}
                    editTitle={this.props.editTitle}
                    completedTitle={this.props.completedTitle}
                    isEditing={this.props.edit}
                    onEditButtonClick={this.props.onEdit}
                    onAddButtonClick={this.props.onEdit}
                    onCancelButtonClick={this.props.onEdit}
                    onFrontImageClick={() => {
                        this.renderImgFullPreview(fUrl, fContentType);
                    }}
                    onBackImageClick={() => this.renderImgFullPreview(bUrl, bContentType)}
                    insurance={ins}
                    addAcknowledgement={this.addAcknowledgement}
                />
            </>
        );
    }
}
export default connect(null, { uploadInsImage })(PatientProfileInsuranceInfo);
