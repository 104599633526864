// Copyright aptihealth, inc. 2019 All Rights Reserved
import { httpAuth, http } from "../utils/axios";
import { getGroupAuthType } from "../redux/actions/auth";
import { GROUP_AUTH_HEADER_NAME } from "../constants/axios";

const authRequests = {
    new_id_token: function ({ data }) {
        let conf = { method: "post", url: "/v1/auth/new_id_token", data };
        return httpAuth(conf);
    },

    fetch_profile: function ({ options }) {
        let conf = {
            method: "post", url: "/v1/patient/profile",
            ...options,
        };
        return httpAuth(conf);
    },

    login: function ({ data }) {
        let conf = { method: "post", url: "/v1/auth/login", data };
        return http(conf);
    },

    // sign_out: function () {
    //     let conf = {
    //         method: 'post',
    //         url: '/v1/auth/sign_out',
    //         params: { accessToken: window.localStorage.getItem('accessToken') }
    //     };
    //     return httpAuth(conf);
    // },

    sign_up: function ({ data, showLoader = false }) {
        let conf = {
            method: "post",
            url: "/v1/auth/signup",
            params: { showLoader },
            data,
        };
        return http(conf);
    },

    mail_forgot_pw_code: function ({ data }) {
        let conf = { method: "post", url: "/v1/auth/forgot_password", data };
        return http(conf);
    },

    reset_forgotten_pw: function ({ data }) {
        let conf = { method: "post", url: "/v1/auth/forgot_password_confirm", data };
        return http(conf);
    },

    auth_challenge: function ({ data }) {
        let conf = { method: "post", url: "/v1/auth/auth_challenge", data };
        return http(conf);
    },

    complete_auto_signup: function ({ data }) {
        let conf = { method: "post", url: "/v1/auth/complete_auto_signup", data };
        return http(conf);
    },

    /** X-custom-header v2 */
    change_password: function ({ data, accessToken = window.localStorage.getItem("accessToken") }) {
        let conf = {
            method: "post",
            url: "/v1/auth/change_password",
            data,
            params: { accessToken },
        };
        return httpAuth(conf);
    },

    validate_referral: function ({ data }) {
        let conf = { method: "post", url: "/v2/patient/referral_code", data };
        return http(conf);
    },

    patient_upload_ins: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/patient/upload_insurance_image",
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    file_upload: function ({ data, url, fileType, progressCb }) {
        let options = {
            headers: {
                "Content-Type": fileType,
            },
            onUploadProgress: (progress) => {
                let loaded = progress.loaded;
                let total = progress.total;
                let percentage = parseInt(Math.round((loaded * 100) / total));
                progressCb && progressCb(percentage);
            },
        };

        return http.put(url, data, options);
    },
    verify_otc: function ({ data }) {
        let conf = { method: "post", url: "/v1/auth/verify_otc", data };
        return http(conf);
    },

    get_user_idp: function ({ data }) {
        let conf = { method: "post", url: "/v1/auth/get_user_idp", data };
        return http(conf);
    },

    custom_auth: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/auth/ext_user",
            data,
            params: { showLoader: false },
        };
        return http(conf);
    },
};

export default authRequests;
