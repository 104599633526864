// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { createRef, useState } from "react";
import _ from "lodash";
import { toSentenceCase, toTitleCase } from "../../../utils/filters";
import "./styles.scss";
import ViewablePassword from "../ViewablePassword";
import InputToolTip from "../InputToolTip";

const Input = (props) => {
    const [state, setState] = useState({
        showDropdown: false,
    });

    let inputElement = null;
    let selectElRef = createRef(null);
    // onMouseDown handler
    //mouseDownHandler is selected instead of click cox blur event is caused after mousedown but before click
    let customSelectHandler = (e) => {
        if (e.target.nodeName == "LI") {
            let index = e.target.getAttribute("data-index");
            selectElRef.current.selectedIndex = index;
            let event = new Event("change", { bubbles: true });
            selectElRef.current.dispatchEvent(event);
            setState({
                showDropdown: false,
            });
        }
    };

    let toggleDropdownHandler = (e) => {
        /**--------------- */
        setState((prevState) => {
            return {
                showDropdown: !prevState.showDropdown,
            };
        });
    };

    let dropDownBlurHandler = (e) => {
        setSelectFieldTouched();
        setState({
            showDropdown: false,
        });
    };

    /**
     * --------------------
     * manually setting the `touched` state to `true` in case of custom select
     * as the Formik is not able to deal with customised select.
     * This will show validation error if selected value is not
     * present in custom select element on toggling.
     */
    const setSelectFieldTouched = () => {
        //checking if `setFieldTouched` method exists
        if (props.form && props.form.setFieldTouched) {
            const name = props.field.name;
            props.form.setFieldTouched(name);
        }
    };

    let showProperDisplay = (value, options, sentenceCase) => {
        let target = options.filter((item) => {
            return item.value === value;
        });
        if (target.length > 0) {
            return sentenceCase ? toTitleCase(target[0].display) : target[0].display;
        } else {
            return "";
        }
    };

    let getCheckbox = (name, checked, id, display, classNames, option) => {
        return (
            <div className={"custom-control custom-checkbox " + classNames}>
                <input
                    type="checkbox"
                    className="custom-control-input"
                    id={id}
                    name={id}
                    checked={checked}
                    onClick={(event) => {
                        props.onclick(name, option, event);
                    }}
                />
                <label className="custom-control-label w-100" htmlFor={id}>
                    {option && option.hasOwnProperty("openText") ? (
                        <input
                            className={"Input Input--sm"}
                            style={{ marginTop: -10 }}
                            onChange={option.onChange}
                            disabled={!option.checked ? "disabled" : null}
                            placeholder={option.display}
                        />
                    ) : (
                        <span className={props.elementConfig.inputClasses}>{display}</span>
                    )}
                </label>
            </div>
        );
    };

    let onKeyDown = (e) => {
        if (e.keyCode == 13 && e.shiftKey) {
            let content = e.target.value;
            let pointerPosition = getPointer(e.target);
            e.target.value =
                content.substring(0, pointerPosition) +
                content.substring(pointerPosition, content.length);
            e.stopPropagation();
        } else if (e.keyCode == 13) {
            let target = document.querySelector('button[type="submit"]');
            target && target.focus();
        }
    };

    let getPointer = (el) => {
        if (el.selectionStart) {
            return el.selectionStart;
        }
        return 0;
    };

    switch (props.elementtype) {
        case "input":
            {
                if (props.type === "checkbox" && !props.options) {
                    inputElement = getCheckbox(
                        props.field.name,
                        props.checked,
                        props.field.name,
                        props.elementConfig.displayName
                            ? props.elementConfig.displayName
                            : props.field.name,
                        props.elementConfig.classNames ? props.elementConfig.classNames : "",
                    );
                } else if (props.type === "checkbox" && props.options) {
                    inputElement = (
                        <div className="row" style={{ marginLeft: 0 }}>
                            {props.options.map((option) => {
                                let className = "col-lg-4 col-12 py-2";
                                if (option.optionClasses) {
                                    className = option.optionClasses;
                                } else if (props.elementConfig.optionClasses) {
                                    className = props.elementConfig.optionClasses;
                                }
                                return getCheckbox(
                                    props.field.name,
                                    option.checked,
                                    props.field.name + option.value,
                                    option.display,
                                    className,
                                    option,
                                );
                            })}
                        </div>
                    );
                } else if (props.type === "viewablePassword") {
                    inputElement = (
                        <ViewablePassword
                            onKeyDown={onKeyDown}
                            {...props.field}
                            {...props}
                            type={"password"}
                        />
                    );
                } else if (props.type === "inputTooltip") {
                    inputElement = (
                        <InputToolTip onKeyDown={onKeyDown} {...props.field} {...props} />
                    );
                } else {
                    inputElement = <input onKeyDown={onKeyDown} {...props.field} {...props} />;
                }
            }
            break;
        case "textarea":
            inputElement = (
                <>
                    {props.elementConfig && props.elementConfig.showNewLineTip && (
                        <span className={"fs-12 txt-gry"}>Use Shift + Enter for a new line</span>
                    )}
                    <textarea onKeyDown={onKeyDown} rows="4" {...props.field} {...props} />
                </>
            );
            break;
        case "select":
            const classes = props.className.split(" ");
            const invalid_class = classes.find((item) => {
                return item === "is-invalid";
            });
            const smallClass = props.className && props.className.includes("Input--sm-dropdown");
            inputElement = (
                <div
                    className={`CustomSelect ${state.showDropdown ? "opened" : ""} ${
                        smallClass ? "Input--sm" : ""
                    }`}>
                    <div
                        className={`CustomSelect__wpr d-flex flex-column graySelect ${
                            invalid_class ? "is-invalid" : ""
                        } ${smallClass ? "Input--sm-dropdown" : ""}`}>
                        <button
                            type="button"
                            className={`CustomSelect__btn cursor d-flex align-items-center text-left ${
                                state.showDropdown ? "opened" : ""
                            } ${smallClass ? "Input--sm Input--sm-dropdown" : ""}`}
                            onBlur={dropDownBlurHandler}
                            disabled={props.disabled ? "disabled" : null}
                            onClick={toggleDropdownHandler}>
                            {/* <span>{(props.field && toSentenceCase(props.field.value)) || props.value || props.placeholder}</span> */}
                            <span>
                                {(props.field &&
                                    toSentenceCase(props.field.value) &&
                                    showProperDisplay(
                                        props.field.value,
                                        props.options,
                                        _.get(props, "elementConfig.sentenceCase", true),
                                    )) ||
                                    props.value ||
                                    props.placeholder}
                            </span>
                        </button>
                        <div
                            onMouseDown={(e) => {
                                customSelectHandler(e);
                                if (props.onchange) {
                                    props.onchange(e);
                                }
                            }}
                            className={
                                "CustomSelect__dropdown" +
                                (state.showDropdown ? " d-block opened" : " d-none")
                            }>
                            <ul className="m-0 p-0">
                                {props.options.map((option, i) => {
                                    return (
                                        <li
                                            className="cursor px-3 py-1"
                                            key={option.value}
                                            data-index={i}>
                                            {option.display}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <select ref={selectElRef} {...props.field} {...props}>
                        {props.options.map((option) => {
                            return (
                                <option key={option.value} value={option.value}>
                                    {option.display}
                                </option>
                            );
                        })}
                    </select>
                </div>
            );
            break;
        case "radio":
            inputElement = (
                <div className="row" style={{ paddingLeft: 15, paddingTop: 15 }}>
                    {props.options.map((option, i) => {
                        const classes = option.displayClasses
                            ? option.displayClasses
                            : "col-12 col-lg-4 custom-control custom-radio";
                        return (
                            <div className={classes} style={{ paddingBottom: 15 }}>
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    id={props.field.name + i}
                                    onChange={() => props.onchange(props.field.name, option)}
                                    key={option.value}
                                    name={props.field.name}
                                    value={option.value}
                                    checked={option.checked}
                                />
                                <label
                                    htmlFor={props.field.name + i}
                                    className="custom-control-label">
                                    {option.display}
                                </label>
                            </div>
                        );
                    })}
                </div>
            );
            break;
        case "radio-single":
            inputElement = (
                <div className="custom-control custom-radio d-flex">
                    <input
                        type="radio"
                        className="custom-control-input"
                        id={props.elementConfig.id}
                        onChange={() => props.onchange(props.field.name, props.elementConfig.value)}
                        name={props.field.name}
                        value={props.field.value}
                        checked={props.elementConfig.checked}
                    />

                    <label className="custom-control-label w-100" htmlFor={props.elementConfig.id}>
                        {props.elementConfig && props.elementConfig.hasOwnProperty("openText") ? (
                            <input
                                className={"Input Input--sm"}
                                style={{ marginTop: -10 }}
                                onChange={props.elementConfig.onChange}
                                disabled={!props.elementConfig.checked ? "disabled" : null}
                                placeholder={props.elementConfig.label}
                            />
                        ) : (
                            <span className={props.elementConfig.inputClasses}>
                                {props.elementConfig.label}
                            </span>
                        )}
                    </label>
                </div>
            );
            break;
        default:
            inputElement = <input {...props.field} {...props} />;
    }

    return inputElement;
};

export default Input;
