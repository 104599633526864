/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const ReScreeningIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ReScreening"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 1.5C17.4806 1.5 18.7733 2.3044 19.4649 3.5H21C21.9319 3.5 22.715 4.13739 22.937 5H26V29H6V5H9.06301C9.28503 4.13739 10.0681 3.5 11 3.5H12.5351C13.2267 2.3044 14.5194 1.5 16 1.5ZM17.7337 4.50145L18.3113 5.5H21V6.5H11L11 5.5H13.6887L14.2663 4.50145C14.6152 3.89843 15.2622 3.5 16 3.5C16.7378 3.5 17.3848 3.89843 17.7337 4.50145ZM22.937 7C22.715 7.86262 21.9319 8.5 21 8.5H11C10.0681 8.5 9.28503 7.86261 9.06301 7H8V27H24V7H22.937Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 13.0022C13.8167 13.0022 12 14.8156 12 16.9584C12 17.8911 12.2939 18.7632 12.7792 19.3589C13.128 19.7871 13.0637 20.417 12.6355 20.7658C12.2073 21.1146 11.5774 21.0503 11.2286 20.6221C10.4092 19.6162 10 18.2774 10 16.9584C10 13.6945 12.7288 11.0022 16 11.0022C19.2712 11.0022 22 13.6945 22 16.9584C22 19.2847 20.6139 21.3206 18.6331 22.2983L19.6793 22.8048C20.1764 23.0454 20.3843 23.6435 20.1437 24.1406C19.903 24.6377 19.305 24.8456 18.8079 24.6049L14.2102 22.3792L16.4272 17.7923C16.6676 17.295 17.2655 17.0867 17.7627 17.3271C18.26 17.5674 18.4683 18.1653 18.2279 18.6626L17.2359 20.715C18.8283 20.1877 20 18.6793 20 16.9584C20 14.8156 18.1833 13.0022 16 13.0022Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ReScreening"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 1.5C17.4806 1.5 18.7733 2.3044 19.4649 3.5H21C21.9319 3.5 22.715 4.13739 22.937 5H26V29H6V5H9.06301C9.28503 4.13739 10.0681 3.5 11 3.5H12.5351C13.2267 2.3044 14.5194 1.5 16 1.5ZM22.937 7C22.715 7.86262 21.9319 8.5 21 8.5H11C10.0681 8.5 9.28503 7.86261 9.06301 7H8V27H24V7H22.937Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 13.0022C13.8167 13.0022 12 14.8156 12 16.9584C12 17.8911 12.2939 18.7632 12.7792 19.3589C13.128 19.7871 13.0637 20.417 12.6355 20.7658C12.2073 21.1146 11.5774 21.0503 11.2286 20.6221C10.4092 19.6162 10 18.2774 10 16.9584C10 13.6945 12.7288 11.0022 16 11.0022C19.2712 11.0022 22 13.6945 22 16.9584C22 19.2847 20.6139 21.3206 18.6331 22.2983L19.6793 22.8048C20.1764 23.0454 20.3843 23.6435 20.1437 24.1406C19.903 24.6377 19.305 24.8456 18.8079 24.6049L14.2102 22.3792L16.4272 17.7923C16.6676 17.295 17.2655 17.0867 17.7627 17.3271C18.26 17.5674 18.4683 18.1653 18.2279 18.6626L17.2359 20.715C18.8283 20.1877 20 18.6793 20 16.9584C20 14.8156 18.1833 13.0022 16 13.0022Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="ReScreening"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.75 14.375C24.4489 14.375 25.0362 13.897 25.2027 13.25H26V28.25H14V13.25H14.7973C14.9638 13.897 15.5511 14.375 16.25 14.375H23.75ZM17 20.7188C17 19.1117 18.3625 17.7516 20 17.7516C21.6375 17.7516 23 19.1117 23 20.7188C23 22.0095 22.1212 23.1408 20.9269 23.5363L21.6709 21.9969C21.8512 21.624 21.695 21.1756 21.3221 20.9953C20.9491 20.8151 20.5007 20.9713 20.3204 21.3442L18.6577 24.7844L22.1059 26.4537C22.4787 26.6342 22.9273 26.4783 23.1078 26.1054C23.2882 25.7326 23.1323 25.2841 22.7595 25.1036L21.9748 24.7237C23.4604 23.9904 24.5 22.4635 24.5 20.7188C24.5 18.2708 22.4534 16.2516 20 16.2516C17.5466 16.2516 15.5 18.2708 15.5 20.7188C15.5 21.708 15.8069 22.7121 16.4215 23.4665C16.6831 23.7877 17.1555 23.8359 17.4766 23.5743C17.7978 23.3127 17.846 22.8403 17.5844 22.5192C17.2205 22.0724 17 21.4183 17 20.7188Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM22.5987 10.625C22.0799 9.7283 21.1104 9.125 20 9.125C18.8896 9.125 17.9201 9.7283 17.4013 10.625H16.25C15.5511 10.625 14.9638 11.103 14.7973 11.75H12.5V29.75H27.5V11.75H25.2027C25.0362 11.103 24.4489 10.625 23.75 10.625H22.5987Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

ReScreeningIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

ReScreeningIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
