const signUpDefaultMessage =
    "Error occurred while completing sign up. Please contact support if the issue persists.";

export const ERROR_CODES = {
    "An account with the given email already exists.": {
        message: signUpDefaultMessage,
        code: "EC409", // Email Conflict 409
    },
    "Email Address Already Referred. Please use another or contact support@aptihealth.com": {
        message:
            "Error occurred while completing referral. Please contact support if the issue persists.",
        code: "EC409", // Email Conflict 409
    },
    WorkflowDefault: {
        message: "Error updating workflow.",
        code: "EUNK", // Error Unknown
    },
    SignUpDefault: {
        message: signUpDefaultMessage,
        code: "EUNK", // Error Unknown
    },
};
