// Copyright aptihealth, inc. 2021 All Rights Reserved

import React, { useEffect, useState } from "react";
import "../styles.scss";
import { withRouter } from "react-router-dom";
import images from "../../../../utils/images";
import Button from "../../../UI/Button";
import { connect } from "react-redux";
import { getPatientProfile } from "../../../../redux/actions/auth";
import { toTitleCase } from "../../../../utils/filters";
import _ from "lodash";
import { getMemberAssessmentType } from "../../../../utils/getMemberAssessmentType";
import { TEENS_AND_KIDS_DIAGNOSTIC_INTERVIEW_90791_ALLOTTED_TIME } from "../../../Provider/ScheduleAvailability/constants";
import { CATCH_ALL_INSURANCE_OPTIONS } from "../../../../constants/insurnaceIconMap";
import { A5_ASSESSMENT_TYPE, A5_V2_ASSESSMENT_TYPE } from "../../../../constants/assessments";
import { LockIcon } from "../../../../component-library/Icons/components/LockIcon";
import { ICON_SIZES, ICON_TYPES } from "../../../../constants/icons";
import { createClassNameString } from "../../../../component-library/utils";

const NextStepWidget = (props) => {
    const { profile } = props;
    const [steps, setSteps] = useState([]);

    let assessmentNoun = "assessment";
    let timeNeeded = "30 minutes";

    let assessment_type = _.get(profile, "assessment_config.default_assessment");
    if (
        assessment_type === "A5" ||
        assessment_type === A5_ASSESSMENT_TYPE ||
        assessment_type === A5_V2_ASSESSMENT_TYPE
    ) {
        assessmentNoun = "screening";
        timeNeeded = "5 minutes";
    }

    let assessmentNounTitle = toTitleCase(assessmentNoun);

    const thereIsAssignmentTask = (assessment_type, status) => {
        const tasks = profile?.tasks;
        if (tasks && tasks.length > 0) {
            const task = tasks.find((task) => task.assessment_type === assessment_type);
            if (task) {
                return task.status === status;
            }
        }
        return false;
    };

    const buildNextStepsConfig = (profile) => {
        const showChildCare = profile.interested_in_guardianship;
        const carrier = _.get(profile, "primary_insurance.carrier");
        const memberId = _.get(profile, "primary_insurance.member_id");
        const hasPrimaryInsurance = !!memberId || CATCH_ALL_INSURANCE_OPTIONS.includes(carrier);

        const assessmentTitle = showChildCare
            ? "Your Fast Track to Better Mental Health"
            : "It’s Time to Complete Your " + assessmentNounTitle;

        const assessmentContent = showChildCare ? (
            <>
                If you'd also like to receive care from your own dedicated team, click the button
                below to take the next step.
            </>
        ) : (
            <>
                The <b>aptihealth {assessmentNoun}</b> is an important first step on your journey to
                better health! It’s important to complete the comprehensive {assessmentNoun} prior
                to your first visit. Completing the aptihealth {assessmentNoun} will help us get to
                know you better. Once we understand your needs, we’ll connect you with a care team
                that’s dedicated to your success.
            </>
        );

        const assessmentSubContent = showChildCare ? (
            <></>
        ) : (
            <>
                To take the next step on your journey, select ‘Start {assessmentNounTitle}’ below!
                The {assessmentNoun} will take approximately {timeNeeded}. You can pause and resume
                at any time.
            </>
        );
        return [
            {
                title: "aptihealth's Teens + Kids Care Program",
                shouldShow: !profile.assessment_status && showChildCare,
                description: [
                    {
                        content: (
                            <>
                                To get your child care with aptihealth, click the Add Child button
                                to complete their registration.
                            </>
                        ),
                    },
                ],
                className: "col-lg-6 col-sm-12 col-md-6",
                subClassName: "dashboard-widget-card-pri",
                button: {
                    text: "Add Child",
                    icon: "./icons/user-icon.svg",
                    action: () => {
                        props.history.push("/app/teens-and-kids/program-details");
                    },
                },
            },
            {
                title: assessmentTitle,
                shouldShow: !profile.assessment_status && profile.status !== "INACTIVE",
                description: [
                    {
                        content: assessmentContent,
                    },
                    {
                        content: assessmentSubContent,
                        className: "next-step-sub-desc",
                    },
                ],
                icon: !showChildCare && "./icons/common/task/task-white-sec.svg",
                className: showChildCare ? "col-lg-6 col-sm-12 col-md-6" : "col-12",
                subClassName: "dashboard-widget-card-sec",
                button: hasPrimaryInsurance
                    ? {
                          text:
                              (profile.assessment_started_date ? "Continue " : "Start ") +
                              assessmentNounTitle,
                          icon: "./icons/common/task/task-white-none.svg",
                          // TODO update button path based on child care condition
                          action: () => {
                              props.history.push(
                                  `/app/assessment?assessmentType=${getMemberAssessmentType(
                                      profile,
                                  )}`,
                              );
                          },
                      }
                    : {
                          text: "Complete Insurance",
                          action: () => {
                              props.history.push("/app/patient-insurance");
                          },
                      },
            },
            {
                title: "It’s Time to Schedule Your Therapist Consultation",
                shouldShow:
                    profile.assessment_status &&
                    !profile.schedule_video_call &&
                    !profile.initial_video_consult &&
                    !profile.is_wait_listed &&
                    !profile.is_scheduling_restricted,
                description: [
                    {
                        content: (
                            <>
                                It’s time to schedule your first session, your{" "}
                                <b>therapist consultation</b> with an aptihealth intake coordinator.
                                During your consultation, you’ll meet with your intake coordinator
                                to review your goals for your first 90 days of therapy. They’ll
                                match you with a licensed therapist and a licensed prescriber, if
                                needed.
                            </>
                        ),
                    },
                    {
                        content: (
                            <>
                                To take the next step on your journey, select ‘Schedule’ below! The
                                consultation will take approximately{" "}
                                {profile.group_type === "teens_kids_program"
                                    ? TEENS_AND_KIDS_DIAGNOSTIC_INTERVIEW_90791_ALLOTTED_TIME
                                    : 45}{" "}
                                minutes.
                            </>
                        ),
                        className: "next-step-sub-desc",
                    },
                ],
                icon: "./icons/common/task/task-white-pri.svg",
                className: "col-12",
                subClassName: "dashboard-widget-card-pri",
                button: {
                    text: "Schedule",
                    icon: "./icons/common/schedule/schedule-white-none.svg",
                    action: () => {
                        props.history.push("/app/schedule/assign?mode=new");
                    },
                },
            },
            {
                title: "It's Time to Answer a Few Questions",
                shouldShow: thereIsAssignmentTask("A5-R", "ASSIGNED"),
                description: [
                    {
                        content: (
                            <>
                                We’ll ask you to do this every 90 days while in care. Your care team
                                learns what’s changed to celebrate your progress or adjust your
                                care.
                            </>
                        ),
                    },
                ],
                className: "col-12",
                subClassName: "dashboard-widget-card-sec",
                button: {
                    text: "Start Questions",
                    action: () => {
                        props.history.push("/app/assessment?assessmentType=A5-R");
                    },
                    type: "primary",
                },
                footer: {
                    content: (
                        <>
                            <div className={"widget-footer-text"}>
                                Only your care team can see your answers. You can pause and resume
                                at any time.
                            </div>
                        </>
                    ),
                    iconComponent: (
                        <LockIcon
                            iconSize={ICON_SIZES.SMALL}
                            iconType={ICON_TYPES.OUTLINE}
                            className="NextStepWidget-a5r_lock_icon"
                        />
                    ),
                    footerClassName: "NextStepWidget-a5r_footer",
                },
            },
            {
                title: "Finish your Questionnaire",
                shouldShow: thereIsAssignmentTask("A5-R", "IN_PROGRESS"),
                description: [
                    {
                        content: (
                            <>
                                Your follow-up questionnaire is still in progress. Finish it so your
                                care team can learn what’s changed to celebrate your progress or
                                adjust your care.
                            </>
                        ),
                    },
                ],
                className: "col-12",
                subClassName: "dashboard-widget-card-sec",
                button: {
                    text: "Finish Questions",
                    action: () => {
                        props.history.push("/app/assessment?assessmentType=A5-R");
                    },
                    type: "primary",
                },
                footer: {
                    content: (
                        <>
                            <div className={"widget-footer-text"}>
                                Only your care team can see your answers. You can pause and resume
                                at any time.
                            </div>
                        </>
                    ),
                    iconComponent: (
                        <LockIcon
                            iconSize={ICON_SIZES.SMALL}
                            iconType={ICON_TYPES.OUTLINE}
                            className="NextStepWidget-a5r_lock_icon"
                        />
                    ),
                    footerClassName: "NextStepWidget-a5r_footer",
                },
            },
        ];
    };

    useEffect(() => {
        if (profile) {
            const stepsToSet = buildNextStepsConfig(profile).filter((step) => step.shouldShow);
            setSteps(stepsToSet);
        }
    }, [profile]);

    const renderNextSteps = (steps) => {
        function getActionButton(step) {
            const buttonType = step.button.type || "transparent";
            switch (buttonType) {
                case "primary":
                    return (
                        <Button
                            className="Btn Btn-xs Btn-xs-2 Btn-otl-white Btn-primary fw-bold"
                            style={{
                                textDecoration: "none",
                                padding: "9px 16px",
                                color: "#4545BA",
                            }}
                            onClick={step.button.action}>
                            <div className={"txt-white d-flex"}>
                                {step.button.icon && (
                                    <img src={images(step.button.icon)} alt={""} />
                                )}
                                <div className={step.button.icon ? "ml-2 mt-1 mb-1" : "mt-1 mb-1"}>
                                    {step.button.text}
                                </div>
                            </div>
                        </Button>
                    );
                case "transparent":
                default:
                    return (
                        <Button
                            className="Btn Btn-xs Btn-xs-2 Btn-rounded Btn-otl-white Btn-transparent fw-bold"
                            style={{ textDecoration: "none", padding: "1px 10px" }}
                            onClick={step.button.action}>
                            <div className={"txt-white d-flex"}>
                                {step.button.icon && (
                                    <img src={images(step.button.icon)} alt={""} />
                                )}
                                <div className={step.button.icon ? "ml-2 mt-1 mb-1" : "mt-1 mb-1"}>
                                    {step.button.text}
                                </div>
                            </div>
                        </Button>
                    );
            }
        }

        return (
            <>
                {steps.map((step, stepIndex) => {
                    return (
                        <div className={step.className ? step.className : ""}>
                            <div
                                className={
                                    "dashboard-widget-card px-lg-4 px-3 py-3 mb-3 text-white " +
                                    (step.subClassName ? step.subClassName : "")
                                }>
                                <div className={"fs-lg-22 fs-18 fw-bold mb-1 d-inline-flex"}>
                                    {step.title}
                                </div>
                                <div
                                    key={`next-step-widget-${step.title}`}
                                    className={"py-2 d-flex"}>
                                    {step.icon && (
                                        <div className={"pr-lg-3 pr-2"}>
                                            <img src={images(step.icon)} alt={stepIndex} />
                                        </div>
                                    )}
                                    <div>
                                        {step.description &&
                                            step.description.map((paragraph) => {
                                                return (
                                                    <div
                                                        className={
                                                            "mb-3 " +
                                                            (paragraph.className
                                                                ? paragraph.className
                                                                : "")
                                                        }>
                                                        {paragraph.content}
                                                    </div>
                                                );
                                            })}
                                        {step.button && (
                                            <div className={"d-lg-block d-none"}>
                                                {getActionButton(step)}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {step.button && (
                                    <div className={"d-lg-none d-flex justify-content-center"}>
                                        {getActionButton(step)}
                                    </div>
                                )}
                                {step.footer && (
                                    <div className={"mt-3"}>
                                        <div
                                            className={createClassNameString([
                                                "d-flex",
                                                step.footer?.footerClassName,
                                            ])}>
                                            {step.footer?.iconComponent &&
                                                step.footer?.iconComponent}

                                            <div className={"ml-2"}>{step.footer.content}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    return <>{renderNextSteps(steps)}</>;
};

export default connect(null, { getPatientProfile })(withRouter(NextStepWidget));
