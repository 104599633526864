// Copyright aptihealth, inc. 2021 All Rights Reserved
import React from "react";
import Button from "../../../UI/Button";
import { Form } from "formik";
import FormikInputFormat from "../../../UI/formikMobileInput";
import FormikInput from "../../../UI/FormikInput";
import * as yup from "yup";
import { ALL_STATES, STATES } from "../../../../constants/states";
import images from "../../../../utils/images";
import { CustomForm } from "../../../../component-library/CustomForm";

const SecondaryAccountInfo = (props) => {
    const { accountData, setAccountData, nextStep } = props;

    const submitHandler = async (formData, actions) => {
        const mergedAccountData = { ...accountData, ...formData };
        setAccountData(mergedAccountData);
        nextStep();
    };

    const referral_care_navigator_facilities = [
        {
            value: "albany_medical_center",
            display: "Albany Medical Center",

        },
        {
            value: "samaritan_hospital",
            display: "Samaritan Hospital",
        },
    ].sort((f1, f2) => {return f1.value < f2.value ? -1 : 1})

    const renderForm = (formikProps) => {
        const formikInputProps = {
            errors: formikProps.errors,
            touched: formikProps.touched,
        };
        return (
            <Form>
                <div className="mx-auto px-0 mb-3">
                    <div className="container px-0">
                        <div>
                            <h6 className="w-100 mt-xl-3">
                                <b>Address</b>
                            </h6>

                            <div className={"d-lg-flex justify-content-lg-between d-block"}>
                                <div className={"justify-content-between-input"}>
                                    <FormikInput
                                        {...formikInputProps}
                                        formEl={{
                                            name: "address.street",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "text",
                                                label: "Street",
                                                placeholder: "Enter Street",
                                            },
                                        }}
                                    />
                                </div>

                                <div className={"justify-content-between-input"}>
                                    <FormikInput
                                        {...formikInputProps}
                                        formEl={{
                                            name: "address.city",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "text",
                                                label: "City",
                                                placeholder: "Enter City",
                                            },
                                        }}
                                    />
                                </div>
                            </div>

                            <div className={"d-lg-flex justify-content-lg-between d-block"}>
                                <div className={"justify-content-between-input"}>
                                    <FormikInput
                                        {...formikInputProps}
                                        formEl={{
                                            name: "address.state",
                                            elementType: "select",
                                            elementConfig: {
                                                options: accountData.interested_in_guardianship
                                                    ? ALL_STATES
                                                    : STATES,
                                                label: "State",
                                                placeholder: "Enter State",
                                            },
                                        }}
                                    />
                                </div>

                                <div className={"justify-content-between-input"}>
                                    <FormikInput
                                        {...formikInputProps}
                                        formEl={{
                                            name: "address.zip_code",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "text",
                                                label: "Zip",
                                                placeholder: "Enter Zip",
                                            },
                                        }}
                                    />
                                </div>
                            </div>

                            <hr />

                            <div className={"d-lg-flex justify-content-lg-between d-block"}>
                                <div className={"justify-content-between-input"}>
                                    <FormikInput
                                        {...formikInputProps}
                                        formEl={{
                                            name: "referral_source",
                                            elementType: "select",
                                            elementConfig: {
                                                options: [
                                                    {
                                                        value: "another_doctor",
                                                        display:
                                                            "Medical specialist (i.e., not primary care)",
                                                    },
                                                    {
                                                        value: "aptihealth_care_navigator",
                                                        display: "aptihealth Care Navigator",
                                                    },
                                                    {
                                                        value: "communication_from_aptihealth",
                                                        display: "Communication from aptihealth",
                                                    },
                                                    {
                                                        value: "friend_or_family",
                                                        display: "Friend or family",
                                                    },
                                                    {
                                                        value: "hospital_or_emergency_room",
                                                        display: "Hospital or emergency room",
                                                    },
                                                    {
                                                        value: "my_employer",
                                                        display: "My employer",
                                                    },
                                                    {
                                                        value: "my_health_insurance",
                                                        display: "My health insurance",
                                                    },
                                                    {
                                                        value: "my_primary_care_provider",
                                                        display: "My primary care provider",
                                                    },
                                                    {
                                                        value: "search_engine",
                                                        display: "Search engine",
                                                    },
                                                    { value: "other", display: "Other" },
                                                ],
                                                sentenceCase: false,
                                                label: "How did you hear about aptihealth?",
                                                placeholder: "Select one...",
                                            },
                                        }}
                                    />
                                </div>

                                <div className={"justify-content-between-input"}>
                                    {formikProps?.values["referral_source"] === "other" && (
                                            <FormikInput
                                                {...formikInputProps}
                                                formEl={{
                                                    name: "referral_source_other",
                                                    elementType: "input",
                                                    elementConfig: {
                                                        type: "text",
                                                        label: "Please Specify (Optional)",
                                                        placeholder: "",
                                                    },
                                                }}
                                            />
                                        )}

                                    {formikProps?.values["referral_source"] === "aptihealth_care_navigator" && (
                                            <FormikInput
                                                {...formikInputProps}
                                                formEl={{
                                                    name: "referral_care_navigator_facility",
                                                    elementType: "select",
                                                    elementConfig: {
                                                        options: referral_care_navigator_facilities,
                                                        label: "Facility Name",
                                                        placeholder: "Select one...",
                                                    },
                                                }}
                                            />
                                        )}
                                </div>
                            </div>

                            <hr />

                            <div className={"d-lg-flex justify-content-lg-between d-block"}>
                                <div className={"justify-content-between-input"}>
                                    <FormikInput
                                        {...formikInputProps}
                                        formEl={{
                                            name: "emergency_contact_person",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "text",
                                                label: "Emergency Contact Person (Optional)",
                                                placeholder: "Enter Emergency Contact Person",
                                            },
                                        }}
                                    />
                                </div>

                                <div className={"justify-content-between-input"}>
                                    <FormikInputFormat
                                        {...formikInputProps}
                                        formEl={{
                                            name: "emergency_contact_number",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "text",
                                                label: "Emergency Contact Number (Optional)",
                                                placeholder: "Enter Emergency Contact Number",
                                            },
                                        }}
                                        value={
                                            formikProps &&
                                            formikProps.values["emergency_contact_number"]
                                        }
                                        onChange={async (val) => {
                                            await formikProps.setFieldValue(
                                                "emergency_contact_number",
                                                val.value,
                                            );
                                            formikProps.setFieldTouched("emergency_contact_number");
                                        }}
                                    />
                                </div>
                            </div>

                            <div className={"d-lg-flex justify-content-lg-between d-block"}>
                                <div className={"justify-content-between-input"}>
                                    <FormikInput
                                        {...formikInputProps}
                                        formEl={{
                                            name: "pcp_name",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "text",
                                                label: "Primary Care Provider (Optional)",
                                                placeholder: "Enter PCP Name",
                                            },
                                        }}
                                    />
                                </div>

                                <div className={"justify-content-between-input"}>
                                    <FormikInput
                                        {...formikInputProps}
                                        formEl={{
                                            id: "pcp_location",
                                            name: "pcp_location",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "text",
                                                label: "Primary Care Location (Optional)",
                                                placeholder: "Enter Primary Care Location",
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <Button type="submit" className="Btn Btn--pri self-signup-btn">
                        Continue
                    </Button>
                </div>
            </Form>
        );
    };

    return (
        <>
            <div className={"p-5"}>
                <div className={"fs-22 txt-pri fw-bold mb-5 text-center"}>
                    Please complete your profile
                </div>
                <div className={"text-center my-5"}>
                    <img
                        src={images("./signup/progress-2.png")}
                        alt={"Step 2/3"}
                        className={"signup-progress-bar"}
                    />
                </div>
                <CustomForm
                    initialValues={accountData}
                    validationSchema={accountPart2Validation}
                    onSubmit={submitHandler}
                    render={renderForm}
                />
            </div>
        </>
    );
};

export default SecondaryAccountInfo;

const accountPart2Validation = yup.object().shape({
    address: yup.object().shape({
        street: yup.string().trim().required("Street is required"),
        city: yup
            .string()
            .required("City is required")
            .matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, "Enter a valid city")
            .nullable(),
        state: yup.string().required("State is required").nullable(),
        zip_code: yup
            .string()
            .required("Zip code is required")
            .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Enter a valid zip code")
            .nullable(),
    }),
    referral_source: yup.string().required("How did you hear about aptihealth is required"),
});
