import React, { useState, createRef, useRef, useEffect } from "react";
import images from "../../../utils/images";
import Hr from "../../UI/Hr";
import Button from "../../UI/Button";
import _ from "lodash";
import * as yup from "yup";
import { Formik } from "formik";
import FormikInput from "../../UI/FormikInput";
import { CustomForm } from "../../../component-library/CustomForm";

const AcceptedAgeRangeModal = (props) => {
    const { acceptedAgeRanges, setAcceptedAgeRanges, closeModal } = props;

    const [tempAcceptedAgeRanges, setTempAcceptedAgeRanges] = useState(
        acceptedAgeRanges.map((range) => {
            range["id"] = Math.random();
            return range;
        }),
    );

    const renderAcceptedAgeRangeRows = () => {
        let acceptedAgeRangeRows = [];

        if (tempAcceptedAgeRanges && tempAcceptedAgeRanges.length) {
            const existingAcceptedAgeRangeRows = tempAcceptedAgeRanges.map((ageRange, index) => {
                return (
                    <AcceptedAgeRangeModalRow
                        key={ageRange["id"]}
                        index={index}
                        tempAcceptedAgeRanges={tempAcceptedAgeRanges}
                        setTempAcceptedAgeRanges={setTempAcceptedAgeRanges}
                    />
                );
            });
            acceptedAgeRangeRows = [...existingAcceptedAgeRangeRows];
        }
        return acceptedAgeRangeRows;
    };

    // When rendering lists that can change in react we need a unique id for each row so react can keep track of the elements in the dom
    // since adding a new row has no uniqueness, we assign a random id and then clean it up in cleanUpData on submission
    const addNewRow = () => {
        tempAcceptedAgeRanges.push({ id: Math.random() });
        setTempAcceptedAgeRanges(_.cloneDeep(tempAcceptedAgeRanges));
    };

    const close = () => {
        setTempAcceptedAgeRanges(acceptedAgeRanges);
        closeModal();
    };

    const onSubmit = () => {
        const validation = tempAcceptedAgeRanges.map(validateAgeRange);
        if (validation.every(Boolean)) {
            const ageRanges = tempAcceptedAgeRanges.map(cleanUpData);
            setAcceptedAgeRanges(ageRanges);
            closeModal();
        }
    };

    const validateAgeRange = (ageRange) => {
        let min;
        let max;

        // Regex for digits only
        if (!/^\d+$/.test(ageRange.min) || !/^\d+$/.test(ageRange.max)) {
            return false;
        } else {
            min = parseInt(ageRange.min);
            max = parseInt(ageRange.max);
        }

        if (!min || !max) {
            return false;
        }

        if (min < 5 || min > 200) {
            return false;
        }

        if (max < 5 || max > 200) {
            return false;
        }

        return true;
    };

    const cleanUpData = (ageRange) => {
        delete ageRange["id"];
        ageRange.min = parseInt(ageRange.min);
        ageRange.max = parseInt(ageRange.max);
        return ageRange;
    };

    return (
        <div
            className="insurancePopUp position-fixed d-flex align-items-center justify-content-center"
            data-public={"true"}>
            <div className="insurancePopUp__inner d-flex flex-column position-absolute">
                <div className="d-flex justify-content-between">
                    <h1 className="fs-16 fw-bold p-3 mb-0">Accepted Age Range</h1>
                    <img
                        className="fs-16 fw-bold p-2 pointer"
                        src={images("./icons/grey-x-circle.svg")}
                        onClick={close}
                        alt={"Delete"}
                    />
                </div>
                <Hr />
                <div className="p-3">
                    <p className="fs-14">
                        Please enter the lowest and highest age of patients you will see. Both
                        fields must be completed. If you are seeing all adult patients, please set
                        maximum age as 200.
                    </p>
                </div>
                <div>{renderAcceptedAgeRangeRows()}</div>
                <div className="p-3 d-flex justify-content-between">
                    <Button
                        type="button"
                        className="Btn Btn--otl-pri Btn-sm mx-2"
                        onClick={addNewRow}>
                        Add Age Range
                    </Button>
                    <Button type="button" className="Btn Btn--pri Btn-sm mx-2" onClick={onSubmit}>
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AcceptedAgeRangeModal;

const AcceptedAgeRangeModalRow = (props) => {
    const { tempAcceptedAgeRanges, index, setTempAcceptedAgeRanges } = props;

    const [min, setMin] = useState(_.get(tempAcceptedAgeRanges[index], "min"));
    const [max, setMax] = useState(_.get(tempAcceptedAgeRanges[index], "max"));

    const onChange = (value, type) => {
        const cloneRanges = _.cloneDeep(tempAcceptedAgeRanges);
        if (type === "min") {
            setMin(value);
            cloneRanges[index].min = value;
            setTempAcceptedAgeRanges(cloneRanges);
        }

        if (type === "max") {
            setMax(value);
            cloneRanges[index].max = value;
            setTempAcceptedAgeRanges(cloneRanges);
        }
    };

    const removeRow = () => {
        tempAcceptedAgeRanges.splice(index, 1);
        setTempAcceptedAgeRanges(_.cloneDeep(tempAcceptedAgeRanges));
    };

    const values = {
        min: min,
        max: max,
    };

    const renderForm = (formikProps) => {
        return (
            <div className="p-3 d-flex justify-content-between">
                {SCHEMA.map((element) => {
                    return (
                        <div key={element.name}>
                            <FormikInput
                                formEl={element}
                                errors={formikProps.errors}
                                touched={formikProps.touched}
                                inputClasses={"Input--sm"}
                                change={async (event) => {
                                    const target = event.target;
                                    await formikProps.setFieldValue(element.name, target.value);
                                    formikProps.setFieldTouched(element.name);
                                    onChange(target.value, element.name);
                                }}
                            />
                        </div>
                    );
                })}
                {index !== 0 ? (
                    <img
                        className={"pointer"}
                        src={images("./icons/delete-trash.svg")}
                        alt="delete"
                        onClick={removeRow}
                    />
                ) : (
                    <span style={{ width: "16px" }} />
                )}
            </div>
        );
    };

    return (
        <div key={index}>
            <CustomForm
                initialValues={values}
                validationSchema={VALIDATION}
                onSubmit={() => {}}
                render={renderForm}
            />
            <Hr />
        </div>
    );
};

const SCHEMA = [
    {
        name: "min",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Minimum Age",
            placeholder: "None",
        },
    },
    {
        name: "max",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Maximum Age",
            placeholder: "None",
        },
    },
];

const VALIDATION = yup.object().shape({
    min: yup
        .number()
        .typeError("Please enter a valid number between 5 and 200")
        .min(5, "Minimum is 5")
        .max(200, "Maximum is 200"),
    max: yup
        .number()
        .typeError("Please enter a valid number between 5 and 200")
        .min(5, "Minimum is 5")
        .max(200, "Maximum is 200"),
});
