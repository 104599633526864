// Copyright aptihealth, inc. 2019 All Rights Reserved
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import { ALL_STATES } from "../../../constants/states";
import images from "../../../utils/images";
import { Portal } from "../../Common/Modal/Portal";
import Button from "../../UI/Button";
import DatePicker from "../../UI/DatePicker";
import ProfileDetailsCard from "./ProfileDetailsCard";
import { isUserAdmin } from "../../../redux/actions/auth";

const convertYyyyMmDdToMmDdYyyy = (str) => {
    const [year, month, day] = str.split("-");
    return `${month}/${day}/${year}`;
};

const isLicensedExpired = (mmddyyyyDate) => {
    const date = moment(mmddyyyyDate, "MM/DD/YYYY");
    return moment() >= date;
};

const maybeExclamationGlyphicon = (mmddyyyyDate) => {
    if (isLicensedExpired(mmddyyyyDate)) {
        return (
            <>
                <span className={"glyphicon glyphicon-exclamation-sign"}></span>
            </>
        );
    }

    return;
};

const redTextIfExpired = (mmddyyyyDate) => {
    if (isLicensedExpired(mmddyyyyDate)) {
        return { color: "red" };
    }

    return {};
};

const makeStateInDropdown = (state) => {
    return <option key={state.display}>{state.display}</option>;
};

const datepickerModal = (index, state, props) => {
    const {
        displayDatepicker,
        markDatepickerAsClosed,
        dateContext,
        updateStateLicenseExpirationFromDatePicker,
        commitDate,
        toggleDatepicker,
    } = props;

    return (
        <Portal
            isBottomCloseActive={false}
            openButtonRender={
                <img
                    src={images("./icons/common/schedule/schedule-ter-none-2.svg")}
                    alt="message"
                />
            }
            headerRender={<b>{`Please Select Expiration Date for ${state} License`}</b>}
            triggerModalStateChange={displayDatepicker}
            isOpenOnRender={false}
            onModalClose={markDatepickerAsClosed(index)}>
            <DatePicker
                externalCtx={dateContext}
                loadingSlots={false}
                onDaySelect={(date) =>
                    updateStateLicenseExpirationFromDatePicker(
                        index,
                        convertYyyyMmDdToMmDdYyyy(date),
                    )
                }
            />
            <Button
                type={"submit"}
                className={"Btn Btn--pri Btn--sm-2 workflow-btn float-right"}
                onClick={() => {
                    commitDate(index);
                    toggleDatepicker(index);
                }}>
                Confirm Date
            </Button>
        </Portal>
    );
};

const makeStateRow = (stateOfPractice, index, props) => {
    const { state, licenseNumber, expirationDate, displayDatepicker } = stateOfPractice;

    const {
        updateStateValue,
        updateStateLicenseNumber,
        markDatepickerAsClosed,
        dateContext,
        updateStateLicenseExpirationFromDatePicker,
        commitDate,
        toggleDatepicker,
        deleteStateLicense,
    } = props;

    const datepickerProps = {
        displayDatepicker,
        markDatepickerAsClosed,
        dateContext,
        updateStateLicenseExpirationFromDatePicker,
        commitDate,
        toggleDatepicker,
    };
    return (
        <>
            <div className={"row"} key={`stateLicense-${index}`}>
                <div className={"col-md-3"}>
                    <label>
                        <b>State</b>
                    </label>
                    <select
                        className="form-control"
                        key={`state-${index}`}
                        value={state}
                        onChange={updateStateValue(index)}>
                        {ALL_STATES.map((s) => makeStateInDropdown(s))}
                    </select>
                </div>
                <div className={"col-md-3"}>
                    <label>
                        <b>License Number</b>
                    </label>
                    <input
                        className="form-control"
                        placeholder="Enter license number"
                        value={licenseNumber}
                        onChange={updateStateLicenseNumber(index)}
                    />
                </div>
                <div className={"col-md-3"}>
                    <label style={redTextIfExpired(expirationDate)}>
                        <b>Expiration Date</b>
                        {maybeExclamationGlyphicon(expirationDate)}
                    </label>
                    <input
                        className="form-control"
                        value={expirationDate}
                        style={redTextIfExpired(expirationDate)}
                        readOnly
                    />
                </div>
                <div className="col-md-2">
                    <br />
                    {datepickerModal(index, state, datepickerProps)}
                </div>
                <div className={"col-md-1"}>
                    <div
                        className="mx-auto mt-3 d-flex align-item-center justify-content-center cursor"
                        style={{ "padding-top": "12px" }}>
                        <img
                            onClick={deleteStateLicense(index)}
                            src={images("./icons/delete-trash.svg")}
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <br />
        </>
    );
};

const UpdateStateArea = (props) => {
    const {
        states_to_practice,
        updateStateValue,
        updateStateLicenseNumber,
        markDatepickerAsClosed,
        dateContext,
        updateStateLicenseExpirationFromDatePicker,
        commitDate,
        toggleDatepicker,
        deleteStateLicense,
    } = props;

    const makeStateRowProps = {
        updateStateValue,
        updateStateLicenseNumber,
        markDatepickerAsClosed,
        dateContext,
        updateStateLicenseExpirationFromDatePicker,
        commitDate,
        toggleDatepicker,
        deleteStateLicense,
    };

    if (_.isNil(states_to_practice)) {
        return;
    }

    return (
        <>
            {states_to_practice.map((stateOfPractice, index) =>
                makeStateRow(stateOfPractice, index, makeStateRowProps),
            )}
        </>
    );
};

const makeSingleStateDisplayArea = (state) => {
    if (_.isNil(state)) {
        return;
    }
    const licenseNumberToDisplay = state.licenseNumber || "";
    const expirationToDisplay = state.expirationDate || "";

    return (
        <div className="col-md-6">
            {/* Indentation madness */}
            <div className="row" style={{ fontWeight: "bold", paddingLeft: "5px" }}>
                {`• ${state.state}`}
            </div>
            <div className="row">
                <div className={"col-md-6"}>{`License Number: ${licenseNumberToDisplay}`}</div>
                <div className={"col-md-6"} style={redTextIfExpired(state.expirationDate)}>
                    {`Exp: ${expirationToDisplay}`}
                </div>
            </div>
            <br />
        </div>
    );
};

const makeStateDisplayRow = (leftColumnState, rightColumnState, index) => {
    return (
        <div className="row" key={`row-index-${index}`}>
            {makeSingleStateDisplayArea(leftColumnState)}
            {makeSingleStateDisplayArea(rightColumnState)}
        </div>
    );
};

const licensedStateDisplayArea = (states) => {
    if (_.isNil(states) || (_.isEmpty(states) && isUserAdmin())) {
        return (
            <div className="ProviderProfile__BoldPrimarySubtext">
                Add Authorized States with the Edit Button
            </div>
        );
    }

    // Design calls for rows of 2 (left and right)
    // This function breaks the states into a structure representing the row
    // Example [{stateData}, {stateData}, {stateData}] => [[{stateData}, {stateData}], [{stateData}]]
    const rowData = _.chunk(states, 2);
    return (
        <>
            {rowData.map((data, index) => {
                const [leftColumnState, rightColumnState] = data;
                return makeStateDisplayRow(leftColumnState, rightColumnState, index);
            })}
        </>
    );
};

/**
 * Resets the Datepicker visibility
 * The Portal element is designed to be visible when an attribute is true and not visible
 * when in null | false state
 * For historical reasons, the DatePicker must instantiate with null and not false
 * In a complex workflow such this component with lists of Datepickers, to prevent
 * prior datepickers from being rendered on a re-render, it is easier to set all datepickers
 * to closed via null attribute
 * This approach will be used any time a datepicker from the list of datepickers (embedded in a modal)
 * are invoked or interacted with
 */
const resetDatepickerClosed = (stateToPractice) => {
    const clonedStateToPractice = _.cloneDeep(stateToPractice);
    clonedStateToPractice["displayDatepicker"] = null;
    return clonedStateToPractice;
};

const StatesToPractice = (props) => {
    const {
        flags,
        toggleStateModal,
        showUpdateStateModal,
        states_to_practice,
        dateContext,
        commitAndSubmitStateLicensureDataToServer,
    } = props;

    const [uncommitedStatesToPractice, setUncommitedStatesToPractice] = useState(
        _.cloneDeep(states_to_practice),
    );

    const updateStateValue = (index) => (e) => {
        const newUncommitedStatesToPractice = _.cloneDeep(uncommitedStatesToPractice);
        newUncommitedStatesToPractice[index].state = e.target.value;
        setUncommitedStatesToPractice(newUncommitedStatesToPractice);
    };

    const addAuthorizedState = () => {
        const newState = {
            state: "New York",
            licenseNumber: null,
            expirationDate: null,
            displayDatepicker: null,
        };
        if (_.isNil(uncommitedStatesToPractice) || _.isEmpty(uncommitedStatesToPractice)) {
            setUncommitedStatesToPractice([newState]);
        } else {
            // Slightly odd edge case: make sure every node has a visibility setting (this is not stored in db)
            const cleanStatesToPractice = _.cloneDeep(uncommitedStatesToPractice).map(
                (stateToPractice) => resetDatepickerClosed(stateToPractice),
            );
            // If the list already exists, create a new with the the placeholder newState on the end
            cleanStatesToPractice.push(newState);
            setUncommitedStatesToPractice(cleanStatesToPractice);
        }
    };

    const updateStateLicenseNumber = (index) => (e) => {
        const newUncommitedStatesToPractice = _.cloneDeep(uncommitedStatesToPractice);
        newUncommitedStatesToPractice[index].licenseNumber = e.target.value;
        setUncommitedStatesToPractice(newUncommitedStatesToPractice);
    };

    const markDatepickerAsClosed = (index) => () => {
        const newUncommitedStatesToPractice = _.cloneDeep(uncommitedStatesToPractice);
        newUncommitedStatesToPractice[index]["displayDatepicker"] = null;
        setUncommitedStatesToPractice(newUncommitedStatesToPractice);
    };

    // Required as someone can bail from the modal and not "commit" the datepicker changes
    const updateStateLicenseExpirationFromDatePicker = (index, value) => {
        const newUncommitedStatesToPractice = _.cloneDeep(uncommitedStatesToPractice);
        newUncommitedStatesToPractice[index].dateToCommit = value;
        setUncommitedStatesToPractice(newUncommitedStatesToPractice);
    };

    const commitDate = (index) => {
        const newUncommitedStatesToPractice = [...uncommitedStatesToPractice];
        newUncommitedStatesToPractice[index].expirationDate =
            newUncommitedStatesToPractice[index].dateToCommit;
        setUncommitedStatesToPractice(newUncommitedStatesToPractice);
    };

    const toggleDatepicker = (index) => {
        // Only toggle the specific display attribute of the stateOfPractice (selected by index)
        const cleanStatesToPractice = _.cloneDeep(uncommitedStatesToPractice).map(
            (stateToPractice) => resetDatepickerClosed(stateToPractice),
        );
        const newStateOfInterest = cleanStatesToPractice[index];
        // Reverse the state of the datepicker for that state (hide/show modal for that state datepicker)
        newStateOfInterest["displayDatepicker"] = !newStateOfInterest["displayDatepicker"];
        // Set updated element in list
        cleanStatesToPractice[index] = newStateOfInterest;
        setUncommitedStatesToPractice(cleanStatesToPractice);
    };

    const deleteStateLicense = (index) => (e) => {
        const cleanStatesToPractice = _.cloneDeep(uncommitedStatesToPractice)
            .map((stateToPractice) => resetDatepickerClosed(stateToPractice))
            .filter((_stateLicenseData, listIndex) => listIndex !== index);
        setUncommitedStatesToPractice(cleanStatesToPractice);
    };

    const updateStateAreaProps = {
        states_to_practice: uncommitedStatesToPractice,
        updateStateValue,
        updateStateLicenseNumber,
        markDatepickerAsClosed,
        dateContext,
        updateStateLicenseExpirationFromDatePicker,
        commitDate,
        toggleDatepicker,
        deleteStateLicense,
    };

    return (
        <>
            {isUserAdmin() && (
                <ProfileDetailsCard
                    heading="Authorized States to Practice"
                    onclick={() => toggleStateModal()}
                    isEditable>
                    <div className="ProviderProfile__states-to-practice my-2 pt-4 px-2">
                        <div>
                            <ul className="ProviderProfile__insurance-info-list fs-14">
                                <Portal
                                    isBottomCloseActive={false}
                                    headerRender={
                                        <div className={"fs-22 fw-bold mb-5 text-center"}>
                                            Authorized States to Practice
                                        </div>
                                    }
                                    isOpenOnRender={false}
                                    triggerModalStateChange={showUpdateStateModal}
                                    onModalClose={() =>
                                        setUncommitedStatesToPractice(states_to_practice)
                                    }>
                                    <UpdateStateArea {...updateStateAreaProps} />
                                    <br />
                                    <div className={"row"}>
                                        <div className={"col-md-12"}>
                                            <Button
                                                type={"button"}
                                                className={
                                                    "Btn Btn--otl-gry Btn--sm-2 workflow-btn float-left"
                                                }
                                                onClick={() => addAuthorizedState()}>
                                                Add State
                                            </Button>
                                            <Button
                                                type={"submit"}
                                                className={
                                                    "Btn Btn--pri Btn--sm-2 workflow-btn float-right"
                                                }
                                                onClick={() => {
                                                    commitAndSubmitStateLicensureDataToServer(
                                                        uncommitedStatesToPractice,
                                                    );
                                                }}>
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </Portal>
                                {props.isEditable &&
                                    licensedStateDisplayArea(uncommitedStatesToPractice)}
                            </ul>
                        </div>
                    </div>
                </ProfileDetailsCard>
            )}

            {!isUserAdmin() && (
                <ProfileDetailsCard
                    heading="Authorized States to Practice"
                    onclick={() => toggleStateModal()}
                    edit={props.isEditable}>
                    <div className="ProviderProfile__states-to-practice my-2 pt-4 px-2">
                        <div>
                            <ul className="ProviderProfile__insurance-info-list fs-14">
                                <Portal
                                    isBottomCloseActive={false}
                                    headerRender={
                                        <div className={"fs-22 fw-bold mb-5 text-center"}>
                                            Authorized States to Practice
                                        </div>
                                    }
                                    isOpenOnRender={false}
                                    triggerModalStateChange={showUpdateStateModal}
                                    onModalClose={() =>
                                        setUncommitedStatesToPractice(states_to_practice)
                                    }>
                                    <UpdateStateArea {...updateStateAreaProps} />
                                    <br />
                                    <div className={"row"}>
                                        <div className={"col-md-12"}>
                                            <Button
                                                type={"button"}
                                                className={
                                                    "Btn Btn--otl-gry Btn--sm-2 workflow-btn float-left"
                                                }
                                                onClick={() => addAuthorizedState()}>
                                                Add State
                                            </Button>
                                            <Button
                                                type={"submit"}
                                                className={
                                                    "Btn Btn--pri Btn--sm-2 workflow-btn float-right"
                                                }
                                                onClick={() => {
                                                    commitAndSubmitStateLicensureDataToServer(
                                                        uncommitedStatesToPractice,
                                                    );
                                                }}>
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </Portal>
                                {props.isEditable &&
                                    licensedStateDisplayArea(uncommitedStatesToPractice)}
                                {props.isEditable && (
                                    <p className="ProviderProfile__toUpdateMessage">
                                        To update authorized states, contact{" "}
                                        <a href="mailto:support@aptihealth.com">
                                            support@aptihealth.com
                                        </a>
                                        .
                                    </p>
                                )}
                            </ul>
                        </div>
                    </div>
                </ProfileDetailsCard>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        flags: state?.flags,
    };
};

export default connect(mapStateToProps)(StatesToPractice);
