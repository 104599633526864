import React from "react";
import {
    createClassNameString,
    HorizontalAnswerBubblesQuestionSet
} from "../../../../component-library";
import "./styles.scss";
import { CardHeading } from "../../../UI/Headings";
import moment from "moment";
import _ from "lodash";

export const CarePlanScreenings = ({ className, gadSummary, phqSummary }) => {

    const emptyScreening = (assessmentType) => {
        return <div className={"CarePlanScreenings--screening"}>
            <HorizontalAnswerBubblesQuestionSet
                className={`CarePlanScreenings--${assessmentType} empty`}
                questionSetCategory={assessmentType}
            />
            <div className={"CarePlanScreenings--empty-container"}>
                <div className={"CarePlanScreenings--empty"}>
                    <p className={"CarePlanScreenings--empty-text"}>
                        {`${assessmentType} hasn’t been completed yet.`}
                    </p>
                </div>
            </div>
        </div>;
    };

    const renderScreening = (assessmentType, summary) => {
        if (_.isEmpty(summary)) {
            return emptyScreening(assessmentType);
        }

        const scoreText = `Score: ${summary?.score}`;
        const formattedSubmittedDate = moment(summary.submitted_date).local().format("M/D/YYYY");

        return <div className={"CarePlanScreenings--screening"}>
            <HorizontalAnswerBubblesQuestionSet
                className={`CarePlanScreenings--${assessmentType}`}
                questionSetCategory={assessmentType}
                questionSetOptions={summary.score_range_labels}
                answers={{[scoreText]: [summary.score_index]}}
                questionSetOptionsBottomLabels={summary.score_ranges}
                optionColStyle={{width: 38, marginRight: 6}}
            />
            <div className={"CarePlanScreenings--screening-subtext"}>
                <p>Submitted by the {summary.submitted_by}</p>
                <p>{formattedSubmittedDate}</p>
            </div>
        </div>;
    };

    return (
        <div className={createClassNameString(["CarePlanScreenings--container", className])}>
            <div className="CarePlanScreenings--header">
                <CardHeading text={"Screenings"} className="CarePlanScreenings--title"/>
            </div>
            <div className={"CarePlanScreenings--screenings-container"}>
                {renderScreening("PHQ-9", phqSummary)}
                {renderScreening("GAD-7", gadSummary)}
            </div>
        </div>
    );
};
