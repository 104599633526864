// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { useState, useEffect } from "react";
import "./../styles.scss";
import Button from "../../../UI/Button";
import { api } from "../../../../APIRequests";
import { getFile, uploadFile } from "../../../../containers/Dashboard/Providers/providerAPI";
import UploadFile from "../../PatientView/UploadFile";
import images from "../../../../utils/images";
import _ from "lodash";

const CarePlanCreation__EXPERIMENTAL_GOALS_AND_OBJECTIVES__DISABLED = (props) => {
    const { patientId, isPatientInactive } = props;

    const [objectiveOne, setObjectiveOne] = useState("");
    const [objectiveTwo, setObjectiveTwo] = useState("");
    const [objectiveThree, setObjectiveThree] = useState("");
    const [showUploadFileCard, setShowUploadFileCard] = useState(false);
    const [fileName, setFileName] = useState("");
    const [files, setFiles] = useState([]);

    useEffect(() => {
        (async () => {
            if (patientId) {
                const response = await api.provider.get_care_plan({
                    data: {},
                    urlParams: { patientId: patientId },
                });
                setObjectiveOne(_.get(response, "care_plan.objectives.objective_one", ""));
                setObjectiveTwo(_.get(response, "care_plan.objectives.objective_two", ""));
                setObjectiveThree(_.get(response, "care_plan.objectives.objective_three", ""));
                let cleanResponse = await fetchCarePlanKey();
                if (cleanResponse && cleanResponse.message) {
                    setFileName(cleanResponse.message);
                }
            }
        })();
    }, []);

    // componentDidUpdate = patientIdChange.bind(this)

    const fetchCarePlanKey = () => {
        let urlParams = { patientId: patientId };
        const options = { params: { showLoader: false } };
        return api.shared.fetch_patient_care_plan({ urlParams, options });
    };

    const fileUpload = () => {
        setShowUploadFileCard(false);
        for (let selectedFile of files) {
            let fileName = "backend/care_plan/" + patientId + "/" + selectedFile.name;
            let fileType = selectedFile.type;
            uploadFile({ file: selectedFile, fileName, fileType }, patientId, (data) => {
                setFileName(data);
            });
        }
    };

    const saveCarePlan = () => {
        let data = {
            objectives: {
                objective_one: objectiveOne,
                objective_two: objectiveTwo,
                objective_three: objectiveThree,
            },
        };
        return api.provider
            .add_care_plan_objectives({ data: data, urlParams: { patientId: patientId } })
            .then(() => {
                props.close();
            });
    };

    const viewCarePlan = async () => {
        if (!fileName) {
            let cleanResponse = await fetchCarePlanKey();
            if (cleanResponse && cleanResponse.message) {
                setFileName(cleanResponse.message);
            }
        }
        getFile(patientId, fileName);
    };

    let downloadButtonDisabled = !fileName;

    const onFileInputChange = (target) => {
        let newFiles = [];
        if (target && target.files.length > 0) {
            newFiles.push(target.files[0]);
            setFiles(newFiles);
        }
    };

    const onFileFileDelete = (index) => {
        let changedFiles = [...files];
        changedFiles.splice(index, 1);
        setFiles(changedFiles);
    };

    return (
        <>
            {showUploadFileCard && (
                <UploadFile
                    hideModal={() => setShowUploadFileCard(false)}
                    files={files}
                    onInputChange={onFileInputChange}
                    handleDrop={onFileInputChange}
                    onFileRemove={onFileFileDelete}
                    onInsertFiles={fileUpload}
                />
            )}
            <div className="care-plan-upload">
                <div className="care-plan-upload__inner p-4">
                    <div className="close-button" onClick={props.close}>
                        <img src={images("./icons/cross.svg")} />
                    </div>
                    <h5 className="fw-bold text-center fs-17 pb-4 m-0">Care Plan Details</h5>
                    <div className="d-flex flex-column care-plan-upload__objectives">
                        <div className={"care-plan-upload__objective"}>
                            <h6 className="fw-bold">Care Plan Goals and/or Objectives #1</h6>
                            <input
                                disabled={isPatientInactive}
                                value={objectiveOne}
                                onChange={(e) => setObjectiveOne(e.target.value)}
                            />
                        </div>
                        <div className={"care-plan-upload__objective"}>
                            <h6 className="fw-bold">Care Plan Goals and/or Objectives #2</h6>
                            <input
                                disabled={isPatientInactive}
                                value={objectiveTwo}
                                onChange={(e) => setObjectiveTwo(e.target.value)}
                            />
                        </div>
                        <div className={"care-plan-upload__objective"}>
                            <h6 className="fw-bold">Care Plan Goals and/or Objectives #3</h6>
                            <input
                                disabled={isPatientInactive}
                                value={objectiveThree}
                                onChange={(e) => setObjectiveThree(e.target.value)}
                            />
                        </div>
                    </div>
                    {/*DESKTOP BUTTONS*/}
                    <div className="d-none d-lg-flex care-plan-upload__buttons">
                        <div>
                            <Button
                                type="submit"
                                className="Btn Btn--otl-pri Btn-sm"
                                disabled={downloadButtonDisabled}
                                onClick={() => viewCarePlan()}>
                                {"Download Care Plan"}
                            </Button>
                            <Button
                                disabled={isPatientInactive}
                                type="submit"
                                className="Btn Btn--pri Btn-sm ml-3"
                                onClick={() => setShowUploadFileCard(true)}>
                                {"Upload Document"}
                            </Button>
                        </div>
                        <Button
                            disabled={isPatientInactive}
                            type="submit"
                            className="Btn Btn--pri Btn-sm"
                            onClick={() => saveCarePlan()}>
                            {"Save"}
                        </Button>
                    </div>
                    {/*MOBILE BUTTONS*/}
                    <div className="d-flex d-lg-none care-plan-upload__buttons">
                        <div className="d-flex align-items-center">
                            <Button
                                type="submit"
                                className="Btn Btn--otl-pri Btn-xs"
                                disabled={downloadButtonDisabled}
                                onClick={() => viewCarePlan()}>
                                {"Download Care Plan"}
                            </Button>
                            <Button
                                disabled={isPatientInactive}
                                type="submit"
                                className="Btn Btn--pri Btn-xs ml-3"
                                onClick={() => setShowUploadFileCard(true)}>
                                {"Upload Document"}
                            </Button>
                        </div>
                        <Button
                            disabled={isPatientInactive}
                            type="submit"
                            className="Btn Btn--pri Btn-xs"
                            onClick={() => saveCarePlan()}
                            style={{
                                width: "150px",
                                margin: "auto",
                                "margin-top": "20px",
                                height: "50px",
                            }}>
                            {"Save"}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CarePlanCreation__EXPERIMENTAL_GOALS_AND_OBJECTIVES__DISABLED;
