// Copyright aptihealth, inc. 2019 All Rights Reserved

//core libs
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

//components
import { Link } from "react-router-dom";
import {
    List,
    ListBody,
    ListHeader,
    ListItem,
    ListTitleBar,
    TitleBarItem,
} from "../../../../components/UI/ListView";
import TitleBarItems from "./TitleBarItems";
import ActivePatientListItem from "./ActivePatientListItem";
import PendingPatientListItem from "./PendingPatientListItem";
import ArchivedReferralListItem from "./ArchivedReferralListItem";
import Badge from "../../../../components/UI/Badge";
import SearchBar from "../../../../components/UI/SearchBar";
import ListLoader from "../../../../components/Common/Loaders/ListLoader";
import ListViewNav from "../../../../components/UI/ListView/ListViewNav";
import Button from "../../../../components/UI/Button";
import Input from "../../../../components/UI/Input";
import Hr from "../../../../components/UI/Hr";

//utils
import moment from "moment";
import { toSentenceCase, toTitleCase } from "../../../../utils/filters";
import { providerRoute } from "../../../../config/roles";
import { fetchPatientList } from "../providerAPI";
import images from "../../../../utils/images";
import { setDynamicRouteConfiguration } from "../../../../redux/actions/navbar";
import { isUserPCP, isUserAdmin } from "../../../../redux/actions/auth";
import { api } from "../../../../APIRequests";
import { isTemplateElement } from "@babel/types";

//constants
import { ADMIN, PROVIDER, ACTIVE, PENDING, ARCHIVED } from "./constants";
import {
    sortByLastNameFirstName,
    sortUsersByDateLastNameFirstName,
} from "../../../../utils/sortingUtil";
import { Form, Formik } from "formik";
import FormikInput from "../../../../components/UI/FormikInput";
import { renderStatusForm } from "../ReferralView";
import * as yup from "yup";
import { CustomForm } from "../../../../component-library/CustomForm";
const REFERRED = "REFERRED";

class PatientsList extends Component {
    constructor(props) {
        super(props);
        let pageSize = 20;
        this.state = {
            patientList: props.patientList ? props.patientList : undefined,
            search: "",
            patientIdSetter: null,
            isPCP: isUserPCP(),
            isAdmin: isUserAdmin(),
            active: false,
            status: "Active",
            pageSize: pageSize,
            activePatientsToShow: pageSize,
            pendingPatientsToShow: pageSize,
            showStatusPopup: false,
            referralCode: null,
            statusPopUpPosition: 0,
        };
    }

    filterPatients = (item, status) => item.patient_status === status;

    componentDidMount() {
        if (!this.state.patientList) {
            (async () => {
                const urlParams = { providerId: this.props.match.params.providerId };
                let options = { params: { showLoader: false } };
                const cleanResponse = this.state.isAdmin
                    ? await api.admin.fetch_patient_list({ urlParams })
                    : await fetchPatientList({ options });

                /** Filter patients having REFERRED status */
                const activePatients = cleanResponse.user.filter(
                    (item) => !this.filterPatients(item, REFERRED),
                );

                /** Filter patients not having REFERRED status */
                let referredPatients = cleanResponse.user.filter((item) =>
                    this.filterPatients(item, REFERRED),
                );
                /** Create List title configuration */
                this.initResendButtonAttributes(referredPatients);

                this.setState({
                    patientList: cleanResponse.user,
                    activePatients,
                    referredPatients,
                });
            })();
        } else {
            let referredPatients = this.state.patientList.filter((item) =>
                this.filterPatients(item, REFERRED),
            );
            this.initResendButtonAttributes(referredPatients);
            this.setState({
                activePatients: this.state.patientList.filter(
                    (item) => !this.filterPatients(item, REFERRED),
                ),
                referredPatients: referredPatients,
            });
        }
    }

    initResendButtonAttributes(referredPatients) {
        for (let patients of referredPatients) {
            patients.btnText = "Resend";
            patients.btnClr = "Btn--pri";
            patients.btnStatus = false;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.patientList !== this.props.patientList) {
            this.setState(
                {
                    patientList: this.props.patientList,
                },
                () => this.componentDidMount(),
            );
        }
    }

    componentWillUnmount() {
        //reset top mobileNav controls
        this.props.setDynamicRouteConfiguration({});
    }

    getBadgeStyle = (patient_status) => {
        switch (patient_status) {
            case "REFERRED":
                return "grey";
            case "INACTIVE":
                return "danger";
            default:
                return "success";
        }
    };

    resendBtnHandler = (email) => {
        let referredPatients = [...this.state.referredPatients];
        let referredPatient = referredPatients.find((patient) => patient.email === email);
        const referralCode = referredPatient.referral_code;
        const options = { params: { showLoader: false } };
        const referralCodeObj = { referralCode };
        referredPatient.btnText = "Sending";
        referredPatient.btnClr = "Btn--gry";
        referredPatient.btnStatus = true;
        this.setState({
            referredPatients,
        });
        api.provider
            .resend_referral_code(referralCodeObj, options)
            .then((cleanResponse) => {
                referredPatient.btnText = "Sent";
                this.setState({
                    referredPatients,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    archiveBtnHandler = (referralCode, patientName) => {
        const newState = {
            showStatusPopup: true,
            referralCode: referralCode,
            patientName: patientName,
        };
        if (window.outerWidth < 990) {
            newState["statusPopUpPosition"] =
                document.getElementById(`archiveBtn-${referralCode}`).getBoundingClientRect().top -
                250;
        } else {
            newState["statusPopUpPosition"] = 300;
        }

        this.setState(newState);
    };

    submitStatusHandler = async (formData, actions) => {
        actions.setSubmitting(false);
        try {
            let data = { ...formData };
            data["referralCode"] = this.state.referralCode;
            data["referral_status"] = "INACTIVE";
            await api.shared.update_referral({ data });
            const referredPatients = [...this.state.referredPatients].map((referral) => {
                if (referral["referral_code"] === this.state.referralCode) {
                    referral["referral_status"] = data["referral_status"];
                    referral["referral_status_reason"] = data["referral_status_reason"];
                    referral["referral_status_reason_context"] =
                        data["referral_status_reason_context"];
                }
                return referral;
            });
            this.setState({
                showStatusPopup: false,
                patientName: null,
                referralCode: null,
                referredPatients: referredPatients,
            });
        } catch (e) {
            console.log(e);
        }
    };

    unarchiveBtnHandler = async (referralCode) => {
        try {
            let data = {};
            data["referralCode"] = referralCode;
            data["referral_status"] = "ACTIVE";
            await api.shared.update_referral({ data });
            const referredPatients = [...this.state.referredPatients].map((referral) => {
                if (referral["referral_code"] === referralCode) {
                    referral["referral_status"] = data["referral_status"];
                }
                return referral;
            });
            this.setState({
                showStatusPopup: false,
                patientName: null,
                referralCode: null,
                referredPatients: referredPatients,
            });
        } catch (e) {
            console.log(e);
        }
    };

    // A utility function to generate list items for mobile and desktop devices
    getRenderedListItem = (patient, i, isScreening) => {
        let badgeStyle = this.getBadgeStyle(patient.patient_status);

        // if patientList is passed in props of this component then `userType = ADMIN`
        // if patientList is not passed in props but rather fetched from api then `userType = PROVIDER`
        const userType = this.props.patientList ? ADMIN : PROVIDER;
        const isActive = patient.patient_status !== REFERRED ? true : false;
        const isPending = patient.patient_status === REFERRED ? true : false;
        const isArchived = patient.referral_status === "INACTIVE" ? true : false;

        if (isScreening) {
            return this.renderScreeningItem(patient);
        }

        if (isArchived) {
            return (
                <ArchivedReferralListItem
                    index={i}
                    userType={userType}
                    badgeStyle={badgeStyle}
                    patient={patient}
                    setPatientDetails={this.setPatientDetails}
                    unarchiveBtnHandler={this.unarchiveBtnHandler}
                />
            );
        } else if (isActive) {
            return (
                <ActivePatientListItem
                    index={i}
                    userType={userType}
                    badgeStyle={badgeStyle}
                    patient={patient}
                    setPatientDetails={this.setPatientDetails}
                />
            );
        } else if (isPending) {
            return (
                <PendingPatientListItem
                    index={i}
                    userType={userType}
                    badgeStyle={badgeStyle}
                    patient={patient}
                    resendBtnHandler={this.resendBtnHandler}
                    archiveBtnHandler={this.archiveBtnHandler}
                    setPatientDetails={this.setPatientDetails}
                />
            );
        }
    };

    renderScreeningItem = (item) => {
        const patientName = item.first_name + " " + item.last_name;
        const createDate = moment(parseFloat(item.created_date) * 1000).format("MM/DD/YYYY");
        let desktopListItem = (
            <div className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
                <span
                    className="col-2"
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={patientName}>
                    {patientName}
                </span>
                <span
                    className="col-2"
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={item.email}>
                    {item.email}
                </span>
                <span className="col-1">{item.mobile}</span>
                <span className="col-1">{createDate}</span>
                <span className="col-3" />
                <div className="col-1 d-flex d-flex justify-content-center">
                    {this.getDownloadButton(item)}
                </div>
                <div className="col-1 d-flex d-flex justify-content-center">
                    <div className="cursor ml-5">
                        <img
                            onClick={() => this.viewScreeningData(item)}
                            src={images("./common/view.png")}
                            alt="view icon"
                        />
                    </div>
                </div>
            </div>
        );

        let mobileListItem = (
            <div className="d-flex d-lg-none justify-content-between">
                <div
                    className="d-flex fs-14 flex-column"
                    style={{ width: "65%", wordWrap: "break-word" }}>
                    <span className="fs-16 fw-bold">{toTitleCase(patientName)}</span>
                    <span>{item.email}</span>
                    <span>{item.mobile}</span>
                </div>
                <div className="d-flex fs-14 flex-column">
                    {/*<div><Badge type={'black'} text={this.transformStatus(item.status)} /></div>*/}
                    <div>
                        <span>{createDate}</span>
                    </div>
                </div>
            </div>
        );

        return (
            <Fragment>
                {mobileListItem}
                {desktopListItem}
            </Fragment>
        );
    };

    getDownloadButton = (item) => {
        if (item.hasOwnProperty("report_data") && item["report_data"].hasOwnProperty("url")) {
            return (
                <div className="cursor ml-5">
                    <img
                        onClick={() => this.downloadA5Report(item)}
                        src={images("./common/download.png")}
                        alt="download"
                    />
                </div>
            );
        } else {
            return "";
        }
    };

    downloadA5Report = (item) => {
        window.open(item["report_data"]["url"]);
    };

    viewScreeningData = (item) => {
        let url = `/app/a5_screenings/${item["id"]}`;
        if (this.props.practiceId) {
            url += `&pcpPracticeId=${this.props.practiceId}`;
        }
        this.props.history.push(url);
    };

    setPatientDetails = (id, name) => {
        if (this.props.patientDetailsSetter) {
            this.props.patientDetailsSetter(id, name);
        } else {
            let url = `/app/patient/${id}/profile/v2`;

            if (this.props.practiceId) {
                url += `&practiceId=${this.props.practiceId}`;
            }
            this.props.history.push(url);
        }
    };

    //Update Search
    updateSearch = (e) => {
        this.setState({ search: e.target.value });
    };

    // ListView TitleBar controls
    renderControls = () => {
        return (
            <div className="d-flex">
                <SearchBar
                    className="mr-lg-2"
                    value={this.state.search}
                    onchange={this.updateSearch}
                />
                {this.state.isPCP && (
                    <Link
                        to={"/app/patient/add"}
                        className="Btn d-none d-lg-block Btn--pri Btn-sm ml-4 fs-16 text-center text-white">
                        Add New
                    </Link>
                )}
            </div>
        );
    };
    ListNavHandler = (status) => {
        this.setState({ status });
    };

    getFilteredPatients = (list) => {
        let filteredPatients = list.filter((patient) => {
            let patientsFN = patient.first_name.toLowerCase();
            let patientsLN = patient.last_name.toLowerCase();
            let fullName = `${patientsFN}` + " " + `${patientsLN}`;
            let patientEmail = patient.email;
            let patientMobile = patient.mobile;
            let filteredpatientsFN;
            let filteredpatientsLN;
            let filteredpatientsFullName;
            let filteredEmail;
            let filteredMobile;
            let primaryFilter = this.state.search.replace(
                new RegExp("[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]", "g"),
                "\\$&",
            );

            filteredpatientsFullName = fullName.match(
                new RegExp("^" + primaryFilter.toLowerCase()),
            );
            filteredpatientsFN = patientsFN.match(new RegExp("^" + primaryFilter.toLowerCase()));
            filteredpatientsLN = patientsLN.match(new RegExp("^" + primaryFilter.toLowerCase()));
            filteredEmail = patientEmail.match(new RegExp("^" + primaryFilter));
            filteredMobile = patientMobile.match(new RegExp(primaryFilter));

            return (
                filteredpatientsFullName ||
                filteredpatientsLN ||
                filteredpatientsFN ||
                filteredEmail ||
                filteredMobile
            );
        });
        return filteredPatients;
    };

    showMorePatients = (patientType) => {
        let newState = {};
        newState[patientType] = this.state[patientType] + this.state.pageSize;
        this.setState(newState);
    };

    getShowMoreButton = (patientType) => {
        return (
            <div className="d-flex align-items-center justify-content-end py-4">
                <Button
                    className="Btn Btn--pri Btn-sm ml-4 text-center text-white"
                    onClick={() => this.showMorePatients(patientType)}>
                    Show More
                </Button>
            </div>
        );
    };

    render() {
        let filteredAllPatients;
        let filteredReferredPatients;
        let filteredArchivedReferrals;

        if (this.state.activePatients) {
            let filteredAllInactivePatients = this.getFilteredPatients(this.state.activePatients)
                .filter((Patient) => Patient.patient_status === "INACTIVE")
                .sort((p1, p2) => sortUsersByDateLastNameFirstName(p1, p2, "referred_date"));
            let filteredAllActivePatients = this.getFilteredPatients(this.state.activePatients)
                .filter((Patient) => Patient.patient_status !== "INACTIVE")
                .sort((p1, p2) => sortUsersByDateLastNameFirstName(p1, p2, "referred_date"));
            filteredAllPatients = [...filteredAllActivePatients, ...filteredAllInactivePatients];
        }
        if (this.state.referredPatients) {
            filteredReferredPatients = this.getFilteredPatients(this.state.referredPatients)
                .filter((referral) => referral["referral_status"] !== "INACTIVE")
                .sort((p1, p2) => sortUsersByDateLastNameFirstName(p1, p2, "created_date"));
        }

        if (this.state.referredPatients) {
            filteredArchivedReferrals = this.getFilteredPatients(this.state.referredPatients)
                .filter((referral) => referral["referral_status"] === "INACTIVE")
                .sort((p1, p2) => sortUsersByDateLastNameFirstName(p1, p2, "created_date"));
        }

        // will render active as well as pending base on `filteredPatients` and `patientsToShow` passed
        let patientListItems = (filteredPatients, patientsToShow, showMoreParam) => {
            return (
                <div>
                    <Fragment>
                        {filteredPatients &&
                            filteredPatients.slice(0, patientsToShow).map((patient, i) => {
                                let patientName = patient.first_name + " " + patient.last_name;
                                return (
                                    <ListItem
                                        key={patient.patient_id}
                                        clickOnMobile
                                        clickHandler={() => {
                                            if (patient.patient_status !== "REFERRED") {
                                                this.setPatientDetails(
                                                    patient.patient_id,
                                                    patientName,
                                                );
                                            }
                                        }}>
                                        {this.getRenderedListItem(patient, i)}
                                    </ListItem>
                                );
                            })}
                    </Fragment>
                    {filteredPatients &&
                        patientsToShow <= filteredPatients.length &&
                        this.getShowMoreButton(showMoreParam)}
                </div>
            );
        };

        return (
            <div id="patient-list">
                {this.state.showStatusPopup && (
                    <div className="referralStatusPopup-wpr position-fixed d-flex justify-content-center">
                        <div
                            className="row position-absolute px-4 referralStatusPopup mx-lg-auto"
                            style={{ top: this.state.statusPopUpPosition }}>
                            <CustomForm
                                initialValues={{
                                    referral_status_reason: "",
                                }}
                                validationSchema={yup.object().shape({
                                    referral_status_reason: yup
                                        .string()
                                        .required("Reason is required"),
                                })}
                                onSubmit={this.submitStatusHandler}
                                render={(formikProps) =>
                                    renderStatusForm(
                                        formikProps,
                                        () => {
                                            this.setState({
                                                showStatusPopup: false,
                                                referralCode: null,
                                                statusPopUpPosition: 0,
                                                patientName: null,
                                            });
                                        },
                                        this.state.patientName,
                                    )
                                }
                            />
                        </div>
                    </div>
                )}
                <ListBody classes="List__body_lg" styles={{ maxWidth: 1300 }}>
                    <ListHeader title={"Patients"} render={this.renderControls} />
                    <div className="d-none d-lg-block">
                        <Hr />
                    </div>
                    <ListViewNav
                        ListNavHandler={this.ListNavHandler}
                        status={this.state.status}
                        tabs={["Active", "Pending", "Archived"]}
                    />
                    <div className="d-none d-lg-block mb-2">
                        <Hr />
                    </div>
                    <ListTitleBar>
                        <TitleBarItems
                            status={this.state.status}
                            source={this.props.patientList ? ADMIN : PROVIDER}
                        />
                    </ListTitleBar>
                    <List>
                        {this.state.patientList ? (
                            this.state.status === ACTIVE &&
                            this.state.activePatients &&
                            patientListItems(
                                filteredAllPatients,
                                this.state.activePatientsToShow,
                                "activePatientsToShow",
                            )
                        ) : (
                            <ListLoader />
                        )}

                        {this.state.patientList ? (
                            this.state.status === PENDING &&
                            this.state.referredPatients &&
                            patientListItems(
                                filteredReferredPatients,
                                this.state.pendingPatientsToShow,
                                "pendingPatientsToShow",
                            )
                        ) : (
                            <ListLoader />
                        )}

                        {this.state.patientList ? (
                            this.state.status === ARCHIVED &&
                            this.state.referredPatients &&
                            patientListItems(
                                filteredArchivedReferrals,
                                this.state.pendingPatientsToShow,
                                "pendingPatientsToShow",
                            )
                        ) : (
                            <ListLoader />
                        )}
                    </List>
                </ListBody>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps, { setDynamicRouteConfiguration })(PatientsList);
