import * as yup from "yup";
import moment from "moment-timezone";
import {
    formatDate,
    noInsuranceCarrierValidationSchemaFactory,
    standardDobValidationSchemaFactory,
} from "../../../../utils/yupSchemaUtils";
yup.addMethod(yup.date, "format", formatDate);

export const STANDARD_EMAIL_VALIDATION_CHECK = function (value) {
    if (!value) {
        return false;
    }
    return this.parent.email === value;
};

export const STANDARD_SPACE_DIGITS_SPECIAL_CHAR_MATECHS = (preBuiltValid) =>
    preBuiltValid
        ? preBuiltValid.matches(
              /^[a-zA-Z-]+$/,
              "Digits, space & special characters are not allowed",
          )
        : yup
              .string()
              .matches(/^[a-zA-Z-]+$/, "Digits, space & special characters are not allowed");

export const STANDARD_DOB_MATCHES_VALIDATION = (preBuiltValid) =>
    preBuiltValid
        ? preBuiltValid.matches(
              /^(((01|03|05|07|08|10|12)(0[1-9]|1[0-9]|2[0-9]|3[01]))|((04|06|09|11)(0[1-9]|1[0-9]|2[0-9]|30))|(02(0[1-9]|1[0-9]|2[0-9])))(19|20)\d\d$/,
              "Must be a valid date",
          )
        : yup
              .string()
              .matches(
                  /^(((01|03|05|07|08|10|12)(0[1-9]|1[0-9]|2[0-9]|3[01]))|((04|06|09|11)(0[1-9]|1[0-9]|2[0-9]|30))|(02(0[1-9]|1[0-9]|2[0-9])))(19|20)\d\d$/,
                  "Must be a valid date",
              );

export const DOB_VALIDATION_FACTORY = (
    format,
    min = 120,
    max = undefined,
    FIVE_TO_ELEVEN_CHILD_FLAG,
) => {
    const error = {
        default: "Please enter valid date.",
        min: `Must be younger than ${min}`,
        max: `To register a child for care aged ${
            FIVE_TO_ELEVEN_CHILD_FLAG ? "5" : "12"
        }-17, you must first register their parent or guardian.`,
    };
    const minDate = min ? moment().subtract(min, "years").format("MM/DD/YYYY") : "01/01/1900";
    const maxDate = max
        ? moment().subtract(max, "years").format("MM/DD/YYYY")
        : moment().format("MM/DD/YYYY");
    return standardDobValidationSchemaFactory(format, error, minDate, maxDate);
};

export const STANDARD_EMAIL_MATCH_VALIDATION = (preBuiltValid) =>
    preBuiltValid
        ? preBuiltValid.test(
              "email-match",
              "Email address does not match, please check spelling",
              STANDARD_EMAIL_VALIDATION_CHECK,
          )
        : yup
              .string()
              .test(
                  "email-match",
                  "Email address does not match, please check spelling",
                  STANDARD_EMAIL_VALIDATION_CHECK,
              );

export const initialYup = (isRequired, msg) =>
    isRequired && msg ? yup.string().required(msg) : yup.string().nullable();

const isGroupIdRequired = (isHcp, isRequired) => {
    // (isHcp, isRequired)
    // True, _ -> notRequired
    // False, True -> required
    // False, False -> notRequired
    if (!isHcp && isRequired) {
        return true;
    }

    return false;
};

export const STANDARD_INSURANCE_VALIDATION_SCHEMA = (isRequired, isHcp = false) =>
    yup.object().shape({
        carrier: initialYup(isRequired, "Carrier is required"),
        member_id: yup.string().when("carrier", {
            is: "CDPHP",
            then: initialYup(isRequired, "Member ID is required")
                .matches(/^[a-zA-Z0-9]*$/, "Enter a valid Member ID")
                .min(11, "Member ID must be 11 digits.")
                .max(11, "Member ID must be 11 digits."),
            otherwise: noInsuranceCarrierValidationSchemaFactory(
                "carrier",
                initialYup(isRequired, "Member ID is required").matches(
                    /^[a-zA-Z0-9]*$/,
                    "Enter a valid Member ID",
                ),
            ),
        }),
        group_id: initialYup(isGroupIdRequired(isHcp, isRequired), "Group ID is required").matches(
            /^[a-zA-Z0-9]*$/,
            "Enter a valid Group ID",
        ),
        policy_holder_name: noInsuranceCarrierValidationSchemaFactory(
            "carrier",
            initialYup(isRequired, "Policy holder name is required").matches(
                /^[a-z A-Z-]*$/,
                "Digits & special characters are not allowed",
            ),
        ),
    });

// If a secondary insurance carrier is selected, all fields become non-optional
export const SECONDARY_INSURANCE_VALIDATION_SCHEMA = (isHcp = false) =>
    yup.object().shape({
        carrier: initialYup(false, "Carrier is required"),
        member_id: yup.string().when("carrier", {
            is: (carrier) => !(carrier == null || carrier === ""),
            then: yup.string().when("carrier", {
                is: "CDPHP",
                then: initialYup(true, "Member ID is required")
                    .matches(/^[a-zA-Z0-9]*$/, "Enter a valid Member ID")
                    .min(11, "Member ID must be 11 digits.")
                    .max(11, "Member ID must be 11 digits."),
                otherwise: initialYup(true, "Member ID is required").matches(
                    /^[a-zA-Z0-9]*$/,
                    "Enter a valid Member ID",
                ),
            }),
        }),
        // If HCP, groupId is not required
        group_id: yup.string().when("carrier", {
            is: (carrier) => !(carrier == null || carrier === ""),
            then: initialYup(!isHcp, "Group ID is required").matches(
                /^[a-zA-Z0-9]*$/,
                "Enter a valid Group ID",
            ),
        }),

        policy_holder_name: yup.string().when("carrier", {
            is: (carrier) => !(carrier == null || carrier === ""),
            then: initialYup(true, "Policy holder name is required").matches(
                /^[a-z A-Z-]*$/,
                "Digits & special characters are not allowed",
            ),
        }),
    });

export const HCP_VALIDATION_SCHEMA = (FIVE_TO_ELEVEN_CHILD_FLAG) =>
    yup.object().shape({
        first_name: STANDARD_SPACE_DIGITS_SPECIAL_CHAR_MATECHS().required(
            "Patient’s Legal First Name is required",
        ),
        last_name: STANDARD_SPACE_DIGITS_SPECIAL_CHAR_MATECHS().required(
            "Patient’s Legal Last Name is required",
        ),
        dob: DOB_VALIDATION_FACTORY("MMDDYYYY", undefined, 18, FIVE_TO_ELEVEN_CHILD_FLAG).required(
            "Date of Birth is required",
        ),
        email: yup.string().email("Enter a valid email address").required("Email is required"),
        email_validation: STANDARD_EMAIL_MATCH_VALIDATION()
            .email("Enter a valid email address")
            .required("Email is required"),
        mobile: yup.string().matches(/^\d{10}$/, "Mobile Phone must be a valid US number"),
        insurance_company: yup.string().when("does_not_have_insurance_information", {
            is: false,
            then: yup
                .string()
                .required(
                    "Select an Insurance Company or mark that the patient's insurance is unknown",
                ),
        }),
    });
