// Copyright aptihealth, inc. 2019 All Rights Reserved
import React from "react";
import SnackBar from "../SnackBar";
import ErrorPopup from "../ErrorPopup";

export const TOAST_SUCCESS = "TOAST_SUCCESS";
export const TOAST_SUCCESS_2 = "TOAST_SUCCESS_2";
export const TOAST_ERROR = "TOAST_ERROR";
export const EXTENSIBLE_TOAST_ERROR = "EXTENSIBLE_TOAST_ERROR";
export const CUSTOM_TOAST_ALERT = "CUSTOM_TOAST_ALERT";
export const POPUP = "POPUP";
export const TOAST_ALERT = "TOAST_ALERT";
export const TOAST_ALERT_2 = "TOAST_ALERT_2";

const Alert = ({
    type,
    message,
    dismiss,
    requestId,
    show_exception_handling,
    buttonText,
    buttonAction,
    customHTML,
}) => {
    let alert;
    if (
        type === TOAST_ERROR ||
        type === TOAST_SUCCESS ||
        type === TOAST_ALERT ||
        type === TOAST_ALERT_2 ||
        TOAST_SUCCESS_2 ||
        EXTENSIBLE_TOAST_ERROR ||
        CUSTOM_TOAST_ALERT
    ) {
        alert = (
            <SnackBar
                type={type}
                message={message}
                dismiss={dismiss}
                requestId={requestId}
                show_exception_handling={show_exception_handling}
                buttonText={buttonText}
                buttonAction={buttonAction}
                customHTML={customHTML}
            />
        );
    } else if (type === POPUP) {
        alert = <ErrorPopup dismiss={dismiss} />;
    }

    return <div data-public={"true"}>{alert}</div>;
};

export default Alert;
