// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import Button from "../../../UI/Button";
import FormikInput from "../../../UI/FormikInput";
import FormikInputFormat from "../../../UI/formikMobileInput";
import Recaptcha from "react-recaptcha";
import InputError from "../../../UI/InputError";
import * as yup from "yup";
import * as Yup from "yup";
import _ from "lodash";
import {
    adultDobValidationSchemaFactory,
    yup_password_factory,
} from "../../../../utils/yupSchemaUtils";
import images from "../../../../utils/images";
import { GUARDIANSHIP_PROGRAM_TYPE } from "../../../Common/InterestInGuardianship";
import InterestInGuardianship from "../../../Common/InterestInGuardianship";
import { connect } from "react-redux";
import { ConsentToTermsOfUse } from "../../../Common/ConsentToTermsOfUse";
import { ConsentEmail } from "../../../../component-library/ConsentEmail/ConsentEmail";
import { ConsentSMS } from "../../../../component-library/ConsentSMS";
import { CustomForm } from "../../../../component-library/CustomForm";
const PrimaryAccountInfo = (props) => {
    const { accountData, setAccountData, nextStep } = props;

    let reCaptchaRef = React.createRef();

    const [mounted, setMounted] = useState(false);
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const [recaptchaError, setRecaptchaError] = useState(null);
    const [interestedInGuardianship, setInterestedInGuardianship] = useState(false);
    const [consent, setConsent] = useState({
        eula: null,
        privacy: null,
        hipaa: null,
        email: null,
        sms: null,
    });

    useEffect(() => {
        setTimeout(() => {
            setMounted(true);
        }, 500);

        if (props.configs.OVERRIDE_RECAPTCHA) {
            setRecaptchaVerified(true);
        }
    }, [props.configs.OVERRIDE_RECAPTCHA]);

    const submitHandler = async (formData, actions) => {
        if (!recaptchaVerified) {
            setRecaptchaError("reCAPTCHA is Required");
            return;
        }

        formData.interested_in_guardianship = interestedInGuardianship;

        if (interestedInGuardianship && !formData.guardianship_program_type) {
            formData.guardianship_program_type = GUARDIANSHIP_PROGRAM_TYPE.DEFAULT;
        }

        formData["consent"] = consent;

        setAccountData(formData);
        nextStep();
    };

    const emailConsentHandler = () => {
        let currConsent = _.cloneDeep(consent);
        if (consent["email"] == null) {
            currConsent["email"] = [
                {
                    date: new Date().toISOString(),
                    client: "web",
                    consent_given_by: "self",
                },
            ];
            setConsent(currConsent);
        } else {
            currConsent["email"] = null;
            setConsent(currConsent);
        }
    };

    const smsConsentHandler = () => {
        let currConsent = _.cloneDeep(consent);
        if (consent["sms"] == null) {
            currConsent["sms"] = [
                {
                    date: new Date().toISOString(),
                    client: "web",
                    consent_given_by: "self",
                },
            ];
            setConsent(currConsent);
        } else {
            currConsent["sms"] = null;
            setConsent(currConsent);
        }
    };

    const renderForm = (formikProps) => {
        const formikInputProps = {
            inputClasses: "Input--sm",
            errors: formikProps.errors,
            touched: formikProps.touched,
        };

        return (
            <Form id="selfsignupformik">
                <div className="mx-auto px-0 mb-3">
                    <div className="container px-0">
                        <div>
                            <div className={"d-lg-flex justify-content-lg-between d-block"}>
                                <div className={"justify-content-between-input"}>
                                    <FormikInput
                                        {...formikInputProps}
                                        formEl={{
                                            name: "first_name",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "text",
                                                label: "First Name",
                                                placeholder: "Enter First Name",
                                            },
                                        }}
                                    />
                                </div>

                                <div className={"justify-content-between-input"}>
                                    <FormikInput
                                        {...formikInputProps}
                                        formEl={{
                                            name: "last_name",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "text",
                                                label: "Last Name",
                                                placeholder: "Enter Last Name",
                                            },
                                        }}
                                    />
                                </div>
                            </div>

                            <div className={"d-lg-flex justify-content-lg-between d-block"}>
                                <div className={"justify-content-between-input"}>
                                    <FormikInputFormat
                                        {...formikInputProps}
                                        formEl={{
                                            name: "dob",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "text",
                                                label: "Date of Birth",
                                                placeholder: "MM/DD/YYYY",
                                            },
                                        }}
                                        value={formikProps && formikProps.values["dob"]}
                                        onChange={async (val) => {
                                            await formikProps.setFieldValue("dob", val.value);
                                            formikProps.setFieldTouched("dob");
                                        }}
                                    />
                                </div>

                                <div className={"justify-content-between-input"}>
                                    <FormikInputFormat
                                        {...formikInputProps}
                                        formEl={{
                                            name: "mobile",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "text",
                                                label: "Mobile",
                                                placeholder: "Enter Mobile",
                                            },
                                        }}
                                        value={formikProps && formikProps.values["mobile"]}
                                        onChange={async (val) => {
                                            await formikProps.setFieldValue("mobile", val.value);
                                            formikProps.setFieldTouched("mobile");
                                        }}
                                    />
                                </div>
                            </div>

                            <div className={"d-lg-flex justify-content-lg-between d-block"}>
                                <div className={"justify-content-between-input"}>
                                    <FormikInput
                                        {...formikInputProps}
                                        formEl={{
                                            name: "email",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "email",
                                                label: "Email",
                                                placeholder: "Enter Email",
                                            },
                                        }}
                                    />
                                </div>

                                <div className={"justify-content-between-input"}>
                                    <FormikInput
                                        {...formikInputProps}
                                        formEl={{
                                            name: "password",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "viewablePassword",
                                                label: "Create Password",
                                                placeholder: "Enter Password",
                                            },
                                        }}
                                    />
                                </div>
                            </div>

                            <div className={"d-lg-flex justify-content-lg-between d-block"}>
                                <div className={"justify-content-between-input"}>
                                    <FormikInput
                                        {...formikInputProps}
                                        formEl={{
                                            name: "confirm_password",
                                            elementType: "input",
                                            elementConfig: {
                                                type: "viewablePassword",
                                                label: "Confirm Password",
                                                placeholder: "Re-enter Password",
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <>
            <div className={"p-5"}>
                <div className={"fs-22 txt-pri fw-bold mb-5 text-center"}>
                    Welcome to aptihealth! Let's get started by creating your account
                </div>
                <div className={"text-center my-5"}>
                    <img
                        src={images("./signup/progress-1.png")}
                        alt={"Step 1/3"}
                        className={"signup-progress-bar"}
                    />
                </div>
                <CustomForm
                    initialValues={accountData}
                    validationSchema={accountPart1Validation(
                        props.configs.FIVE_TO_ELEVEN_CHILD_FLAG,
                    )}
                    onSubmit={submitHandler}
                    render={renderForm}
                />

                {props.configs.TEENS_AND_KIDS_PROGRAM && (
                    <div className={"my-3 d-flex fs-15"}>
                        <InterestInGuardianship
                            interestedInGuardianship={interestedInGuardianship}
                            setInterestedInGuardianship={() =>
                                setInterestedInGuardianship(!interestedInGuardianship)
                            }
                        />
                    </div>
                )}

                <div className={"fs-15 txt-gry"}>
                    <ConsentToTermsOfUse consent={consent} setConsent={setConsent} />
                </div>

                <div className={"my-3 fs-15 txt-gry"}>
                    <span id={"self-signup-consent"}>
                        <ConsentEmail consentEvent={() => emailConsentHandler()} />
                    </span>
                </div>

                <div className={"my-3 fs-15 txt-gry"}>
                    <span id={"self-signup-consent"}>
                        <ConsentSMS consentEvent={() => smsConsentHandler()} />
                    </span>
                </div>

                <div className={"my-3 text-center w-100"}>
                    <div>
                        {mounted && (
                            <Recaptcha
                                className="mx-auto recaptcha"
                                size={window.innerWidth < 768 ? "compact" : "normal"}
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                ref={reCaptchaRef}
                                verifyCallback={() => {
                                    setRecaptchaError(null);
                                    setRecaptchaVerified(true);
                                }}
                            />
                        )}
                    </div>
                    <div className={"text-center my-2"}>
                        {recaptchaError && (
                            <InputError style={{ position: "relative" }}>
                                {recaptchaError}
                            </InputError>
                        )}
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <Button
                        form="selfsignupformik"
                        type="submit"
                        className="Btn Btn--pri self-signup-btn"
                        disabled={
                            consent["eula"] && consent["privacy"] && consent["hipaa"]
                                ? null
                                : "disabled"
                        }>
                        Continue
                    </Button>
                </div>
                <div className={"text-center mt-5"}>
                    <div>Already have an account?</div>
                    <Button
                        className={"Btn-link txt-pri fw-bold"}
                        style={{ textDecoration: "none" }}
                        onClick={() => {
                            // redirect user to sign in
                            props.history.push("/auth/sign-in");
                        }}>
                        Log In
                    </Button>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        configs: state.configs,
    };
};

export default connect(mapStateToProps, {})(PrimaryAccountInfo);

const accountPart1Validation = (FIVE_TO_ELEVEN_CHILD_FLAG) =>
    yup.object().shape({
        first_name: yup
            .string()
            .required("First name is required")
            .matches(/^[a-zA-Z-]+$/, "Numerals, spaces & special characters are not allowed"),
        last_name: yup
            .string()
            .required("Last name is required")
            .matches(/^[a-zA-Z-]+$/, "Numerals, spaces & special characters are not allowed"),
        dob: adultDobValidationSchemaFactory(
            "MMDDYYYY",
            "Must be a valid date",
            `To register a child for care aged ${
                FIVE_TO_ELEVEN_CHILD_FLAG ? "5" : "12"
            }-17, you must first register yourself. ` +
                "Toggle the button below and you will be prompted to register your child when you finish.",
        ).required("Date of birth is required"),
        mobile: yup
            .string()
            .matches(/^\d{10}$/, "Contact number must be a valid US number")
            .required("Mobile number is required"),
        email: yup.string().email("Enter a valid email address").required("Email is required"),
        password: yup_password_factory(),
        confirm_password: Yup.string()
            .required("Confirm password is required")
            .oneOf([Yup.ref("password"), null], "Password does not match"),
    });
