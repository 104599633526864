// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useState, useRef, useEffect } from "react";
import { toSentenceCase } from "../../../utils/filters";

const Select = (props) => {
    const { name, placeholder, value, disabled, options, changeHandler } = props;
    const [state, setState] = useState({
        showDropdown: false,
    });

    useEffect(() => {
        if (
            document.querySelector(
                ".ScheduleEvent .Select__dropdown.d-block.opened, .EditEvent .Select__dropdown.d-block.opened",
            )
        ) {
            document
                .querySelector(
                    ".ScheduleEvent .Select__dropdown.d-block.opened, .EditEvent .Select__dropdown.d-block.opened",
                )
                .scroll(0, 0);
        }
    }, []);

    let selectElRef = useRef(null);
    // onMouseDown handler
    //mouseDownHandler is selected instead of click cox blur event is caused after mousedown but before click
    let customSelectHandler = (e) => {
        if (e.target.nodeName == "LI") {
            let index = e.target.getAttribute("data-index");
            selectElRef.current.selectedIndex = index;
            let event = new Event("change", { bubbles: true });
            selectElRef.current.dispatchEvent(event);
            setState({
                showDropdown: false,
            });
        }
    };

    let toggleDropdownHandler = () => {
        setState((prevState) => {
            return {
                showDropdown: !prevState.showDropdown,
            };
        });
    };

    let dropDownBlurHandler = (e) => {
        setState({
            showDropdown: false,
        });
    };
    /** To display on the button */
    let matchingElement =
        value === undefined ? placeholder : options.find((el) => el.value === value);
    let displayValue = matchingElement ? matchingElement.display : "";

    let selectWprClasses = ["Select__wpr", "d-flex", "flex-column"];

    /** styling the border of select wpr */
    if (disabled) {
        selectWprClasses.push("Select__wpr--disabled");
    }

    return (
        <div className={"Select" + (state.showDropdown ? " opened" : "")}>
            <div className={selectWprClasses.join(" ")}>
                <button
                    type="button"
                    disabled={disabled}
                    className={
                        "Select__btn cursor d-flex align-items-center text-left" +
                        (state.showDropdown ? " opened" : "")
                    }
                    onBlur={dropDownBlurHandler}
                    onClick={toggleDropdownHandler}>
                    <span>{displayValue}</span>
                </button>
                <div
                    onMouseDown={customSelectHandler}
                    className={
                        "Select__dropdown" + (state.showDropdown ? " d-block opened" : " d-none")
                    }>
                    <ul className="m-0 p-0">
                        {options.map((option, i) => {
                            return (
                                <li
                                    className="cursor px-3 py-1"
                                    key={option.value + i}
                                    data-index={i}>
                                    {option.display}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <select ref={selectElRef} name={name} value={value} onChange={changeHandler}>
                {options.map((option) => {
                    return (
                        <option key={option.value} value={option.value}>
                            {option.display}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

export default React.memo(Select);
