// Copyright aptihealth, inc. 2021 All Rights Reserved
import { http, httpAuth, httpWorkflowAuth } from "../utils/axios";
import { getGroupAuthType } from "../redux/actions/auth";

const workflowRequests = {
    get_workflow: function ({ options, data } = {}) {
        let conf = {
            method: "post",
            url: "/v1/workflow/get_workflow",
            ...options,
            data,
        };
        return http(conf);
    },
    create_workflow_instance: function ({ options, data, headers } = {}) {
        let conf = {
            method: "post",
            url: "/v1/workflow/create_workflow_instance",
            ...options,
            data,
            headers,
        };
        return http(conf);
    },
    get_workflow_instance: function ({ options, data, headers } = {}) {
        let conf = {
            method: "post",
            url: "/v1/workflow/get_workflow_instance",
            ...options,
            data,
            headers,
        };
        return httpAuth(conf);
    },
    undo_state_and_backtrack_one_node: function ({ options, data, headers } = {}) {
        let conf = {
            method: "post",
            url: "/v1/workflow/undo_state_and_backtrack_one_node",
            ...options,
            data,
            headers,
        };
        return http(conf);
    },
    update_state_and_get_next_node: function ({ options, data } = {}) {
        let conf = {
            method: "post",
            url: "/v1/workflow/update_state_and_get_next_node",
            ...options,
            data,
        };
        return http(conf);
    },
    eligible_states: function ({ options } = {}) {
        let conf = {
            method: "post",
            url: "/v1/workflow/eligible_states",
            ...options,
        };
        return httpAuth(conf);
    },
    screening: function ({ options, data, headers } = {}) {
        let conf = {
            method: "post",
            url: "/v1/referral/screening",
            ...options,
            data,
            headers,
        };
        return httpAuth(conf);
    },
    get_schedule_info: function ({ options, data, headers } = {}) {
        let conf = {
            method: "post",
            url: "/v1/referral/get_schedule_info",
            ...options,
            data,
            headers,
        };
        return httpAuth(conf);
    },
    intake_get_additional_appointments: function ({ options, data, headers } = {}) {
        let conf = {
            method: "post",
            url: "/v1/referral/get_other_days_for_patient_intake_appointment",
            ...options,
            data,
            headers,
        };
        return httpAuth(conf);
    },

    get_next_question: function ({ data, options, headers }) {
        const { axios, transformedHeaders, url } = workflowRequestBuilder(
            headers,
            "/v1/patient/assessment/get_question",
            "/v1/patient/assessment/public_get_question",
        );
        const conf = { data, method: "post", url, options, headers: transformedHeaders };
        return axios(conf);
    },

    get_last_question: function ({ data, options, headers }) {
        const { axios, transformedHeaders, url } = workflowRequestBuilder(
            headers,
            "/v1/patient/assessment/last_question",
            "/v1/patient/assessment/public_last_question",
        );
        const conf = { data, method: "post", url, options, headers: transformedHeaders };
        return axios(conf);
    },

    submit_next_question: function ({ data, options, headers }) {
        const { axios, transformedHeaders, url } = workflowRequestBuilder(
            headers,
            "/v1/patient/assessment/submit_question",
            "/v1/patient/assessment/public_submit_question",
        );
        const conf = { data, method: "post", url, options, headers: transformedHeaders };
        return axios(conf);
    },
};

export default workflowRequests;

const workflowRequestBuilder = (headers, authUrl, publicUrl) => {
    if (global.workflowAuthIdToken) {
        return { axios: httpWorkflowAuth, headers, url: authUrl };
    } else if (headers["dob"] && headers["X-Entity-Type"]) {
        headers["Authorization"] = "DOB " + headers["dob"];
        delete headers["dob"];
        return { axios: http, transformedHeaders: headers, url: publicUrl };
    }
};
