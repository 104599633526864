import CardSecondary from "../../../../UI/Card/CardSecondary";
import { Select } from "../../../../UI/StyledInput";
import React from "react";
import {
    _30_MIN_INDIVIDUAL_90832,
    _45_MIN_INDIVIDUAL_90834,
    _60_MIN_INDIVIDUAL_90837,
    allottedTimeFactory,
    CRISIS_EVAL_90839,
    CRISIS_EVAL_EXTENDED_90840,
    FAMILY_THERAPY_90847,
    FAMILY_THERAPY_WO_PATIENT_90846,
    MEDICATION_MGMT_99212,
    MEDICATION_MGMT_99213,
    MEDICATION_MGMT_99214,
    OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
    OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
} from "../../../../Provider/ScheduleAvailability/constants";
import { getRole } from "../../../../../redux/actions/auth";
import DatePicker from "../../../../UI/DatePicker";
import moment from "moment-timezone";
import { api } from "../../../../../APIRequests";
import TimeSlotOptions from "../../../../Patient/Schedule/TimeSlotOptions";
import { connect } from "react-redux";
import images from "../../../../../utils/images";
import _ from "lodash";
import ReoccurrenceDetails, {
    transformFrequencyDaysForServer,
} from "../../../../Provider/ScheduleAvailability/ReoccurrenceDetails";

class ScheduleNextCallComponent extends React.Component {
    DISPLAY_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
    DISPLAY_WEEKS_MAP = ["Week", "2 Weeks", "3 Weeks", "4 Weeks"];

    constructor(componentProps) {
        super(componentProps);

        this.state = {
            stage: "scheduling",
            intervalOption: 1,
            scheduleTypeOptions: scheduleOptions(),
            scheduleType: null,
            dateContext: moment().add(1, "weeks"),
            selectedTimeSlot: null,
            slots: [],
            noScheduledTime: false,
            seriesData: {
                frequencyDays: [this.getTargetedDay()],
                frequencyWeeks: 0,
                isReoccurring: false,
                numberOfOccurrences: 1,
            },
        };
    }

    handleScheduleTypeSet = async (e) => {
        await this.setState({ scheduleType: e.target.value });
        this.handleDaySelect(this.state.dateContext.format("YYYY-MM-DD"));
    };

    handleDaySelect = async (date) => {
        if (!this.state.scheduleType) {
            return;
        }

        let callTime = moment.utc(this.props.state.callDetails.timestamp).local();
        let isToday = callTime.format("YYYY-MM-DD") === date;
        let currentSlot = !isToday
            ? callTime.format("HH:mm")
            : moment().add(1, "hours").format("HH:mm");

        let response = await this.getAvailableSchedules(date);
        let slots = _.cloneDeep(response.time_slots).sort();
        if (isToday) {
            slots = slots.filter((slot) => slot >= currentSlot);
        }

        const seriesData = _.cloneDeep(this.state.seriesData);
        seriesData["frequencyDays"] = [this.getTargetedDay(date)];

        this.setState({
            slots,
            dateContext: moment(date),
            selectedTimeSlot: null,
            seriesData,
        });
        this.updateSeriesData(seriesData);
    };

    getAvailableSchedules = (date) => {
        let data = {
            date,
            timezone: this.props.timezone,
            allotted_time: allottedTimeFactory(this.state.scheduleType),
        };

        return api.provider.fetch_provider_availability({ data });
    };

    scheduleCall = async () => {
        const { dateContext, selectedTimeSlot, scheduleType, seriesData } = this.state;

        const sessionIsInvalid =
            this.state.seriesData &&
            this.state.seriesData.isReoccurring &&
            (this.state.seriesData.frequencyDays.length === 0 ||
                this.state.seriesData.frequencyWeeks === null);

        if (this.state.intervalOption === -1) {
            this.setState({
                stage: "confirmation",
                noScheduledTime: true,
            });
        }

        if (!(dateContext && selectedTimeSlot && scheduleType) || sessionIsInvalid) {
            return;
        }

        let data = {};
        const formattedTime = moment(selectedTimeSlot, "hh:mm A").format("HH:mm");
        const clonedSeriesData = _.cloneDeep(this.state.seriesData);
        clonedSeriesData["frequencyDays"] = transformFrequencyDaysForServer(seriesData);
        let timestamp = dateContext.format("YYYY-MM-DD") + " " + formattedTime;
        data["local_date_time"] = moment(timestamp).format("YYYY-MM-DD HH:mm");
        data["event_type"] = scheduleType;
        data["allotted_time"] = allottedTimeFactory(data["event_type"]);
        data["seriesData"] = clonedSeriesData;
        data["timezone"] = this.props.timezone;
        data["patient_id"] = this.props.state.callDetails.patient_username;

        try {
            await api.schedule.post_schedule({ data });
            this.setState({
                stage: "confirmation",
            });
        } catch (e) {
            console.log(e);
        }
    };

    updateSeriesData = (seriesData) => {
        this.setState({ seriesData });
    };

    getReoccurrenceTitle = () => {
        if (this.state.seriesData && this.state.seriesData.isReoccurring) {
            const days = this.state.seriesData.frequencyDays
                .sort()
                .map((day) => this.DISPLAY_DAYS[day])
                .join(", ");
            const week = this.DISPLAY_WEEKS_MAP[this.state.seriesData.frequencyWeeks];
            const numberOfOccurrences =
                this.state.seriesData.numberOfOccurrences === 1
                    ? "1 Session"
                    : `${this.state.seriesData.numberOfOccurrences} Sessions`;
            return `Reoccurs every ${week} on ${days} for ${numberOfOccurrences}`;
        }
    };

    getTargetedDay(date) {
        // Sunday is 0 and Saturday is 6
        return date ? moment(date, "YYYY-MM-DD").day() : moment().day();
    }

    getSchedulingStage = () => {
        let noTimeBtnClass = this.state.intervalOption === -1 ? "Btn--sec" : "Btn--otl-gry";
        return (
            <>
                <CardSecondary className="m-3">
                    <div className="row p-3 justify-content-between">
                        <div className="col-lg-5 fw-bold">Interval Options</div>
                    </div>
                    <div className="d-flex p-3 justify-content-between">
                        <button
                            className={`Btn Btn-sm ${noTimeBtnClass}`}
                            onClick={() =>
                                this.setState({
                                    intervalOption: this.state.intervalOption * -1,
                                })
                            }>
                            No Scheduled Time
                        </button>
                    </div>
                </CardSecondary>

                {this.state.intervalOption !== -1 && (
                    <CardSecondary className="m-3">
                        <div className="row p-3 justify-content-between">
                            <div className="col-lg-5 fw-bold">Type of Scheduling</div>
                        </div>
                        <div className="p-3">
                            <Select
                                name="schedule_type"
                                changeHandler={this.handleScheduleTypeSet}
                                value={this.state.scheduleType}
                                options={this.state.scheduleTypeOptions}
                            />
                        </div>
                    </CardSecondary>
                )}

                {this.state.intervalOption !== -1 && (
                    <CardSecondary className="m-3">
                        <div className="row p-3 justify-content-between">
                            <div className="col-lg-5 fw-bold">Time Suggestion</div>
                        </div>
                        <div className="row p-3 justify-content-between">
                            <div className="col-9">
                                <DatePicker
                                    externalCtx={this.state.dateContext}
                                    loadingSlots={false}
                                    onDaySelect={(date) => this.handleDaySelect(date)}
                                />
                            </div>
                            <div className="col-3" style={{ maxHeight: 300, overflowY: "scroll" }}>
                                {this.state.slots.length > 0 ? (
                                    this.state.slots.map((slot) => {
                                        let localTimeSlot = moment(slot, "HH:mm").format("hh:mm A");

                                        return (
                                            <TimeSlotOptions
                                                active={this.state.selectedTimeSlot}
                                                TimeOption={localTimeSlot}
                                                key={slot}
                                                click={(slot) =>
                                                    this.setState({ selectedTimeSlot: slot })
                                                }
                                            />
                                        );
                                    })
                                ) : (
                                    <span>No available slots</span>
                                )}
                            </div>
                        </div>
                    </CardSecondary>
                )}

                {this.state.intervalOption !== -1 && (
                    <CardSecondary className="m-3">
                        <div className="row p-3 justify-content-between">
                            <div className="col-12">
                                <ReoccurrenceDetails
                                    updateState={this.updateSeriesData}
                                    seriesData={this.state.seriesData}></ReoccurrenceDetails>
                            </div>
                        </div>
                    </CardSecondary>
                )}

                <div className="GuidedVideoCall__guided_widget_footer d-flex justify-content-around w-100">
                    <div
                        className="my-1 mx-3 p-1 d-flex justify-content-around align-items-center"
                        style={{
                            backgroundColor: "#6B7A81",
                            width: "35%",
                            borderRadius: 5,
                            textAlign: "right",
                        }}
                        onClick={() =>
                            this.props.jumpWorkflowStep(this.props.state.currentStep - 1)
                        }>
                        <div>
                            <img src={images("./common/back.svg")} />
                        </div>
                        <div>
                            <div>Previous Step</div>
                            <div className="fs-10">
                                {
                                    this.props.state.workflow.steps[
                                        this.props.state.currentStep - 1
                                    ].short
                                }
                            </div>
                        </div>
                    </div>

                    <div
                        className="my-1 mx-3 p-1 d-flex justify-content-around align-items-center"
                        style={{
                            backgroundColor: "#0CD0A7",
                            width: "35%",
                            borderRadius: 5,
                            textAlign: "left",
                        }}
                        onClick={() => this.scheduleCall()}>
                        <div>
                            <div>Confirm</div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    getConfirmationStage = () => {
        return (
            <>
                <CardSecondary className="m-3 p-3">
                    <div className="row p-3 justify-content-between">
                        <div className="col-lg-5 fw-bold">Next Appointment</div>
                    </div>
                    <div
                        className="p-3 m-3 text-white"
                        style={
                            this.state.noScheduledTime
                                ? { backgroundColor: "red", borderRadius: 5 }
                                : { backgroundColor: "#0CD0A7", borderRadius: 5 }
                        }>
                        {this.state.noScheduledTime ? (
                            <div>No Scheduled Time</div>
                        ) : (
                            <>
                                <div>
                                    <span>{this.state.dateContext.format("LL")}</span>
                                    <span className="float-right">
                                        {this.state.selectedTimeSlot}
                                    </span>
                                </div>
                                <div>
                                    <span>{this.getReoccurrenceTitle()}</span>
                                </div>
                            </>
                        )}
                    </div>
                </CardSecondary>

                <div className="GuidedVideoCall__guided_widget_footer d-flex justify-content-around w-100">
                    <div
                        className="my-1 mx-3 p-1 d-flex justify-content-around align-items-center"
                        style={{
                            backgroundColor: "#fa243e",
                            width: "35%",
                            borderRadius: 5,
                            textAlign: "left",
                        }}
                        onClick={() =>
                            this.props.jumpWorkflowStep(this.props.state.currentStep + 1)
                        }>
                        <div>
                            <div>End Call</div>
                        </div>
                        <div>
                            <img
                                src={images("./common/endcall.svg")}
                                style={{
                                    height: 50,
                                    width: 50,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    render() {
        return this.state.stage === "scheduling"
            ? this.getSchedulingStage()
            : this.getConfirmationStage();
    }
}

const mapStateToProps = (state) => {
    return {
        timezone: state.auth.timezone,
    };
};

export const ScheduleNextCall = connect(mapStateToProps)(ScheduleNextCallComponent);

const scheduleOptions = () => {
    let role = getRole();

    switch (role) {
        case "provider:prescribe":
            return [
                {
                    value: MEDICATION_MGMT_99212,
                    display: `Medication Management (99212) (${allottedTimeFactory(
                        MEDICATION_MGMT_99212,
                    )} minutes)`,
                },
                {
                    value: MEDICATION_MGMT_99213,
                    display: `Medication Management (99213) (${allottedTimeFactory(
                        MEDICATION_MGMT_99213,
                    )} minutes)`,
                },
                {
                    value: MEDICATION_MGMT_99214,
                    display: `Medication Management (99214) (${allottedTimeFactory(
                        MEDICATION_MGMT_99214,
                    )} minutes)`,
                },
                {
                    value: CRISIS_EVAL_90839,
                    display: `Crisis Evaluation (90839) (${allottedTimeFactory(
                        CRISIS_EVAL_90839,
                    )} minutes)`,
                },
            ];
        case "provider:behavioral":
            return [
                {
                    value: _30_MIN_INDIVIDUAL_90832,
                    display: `30 Minute Individual (90832) (${allottedTimeFactory(
                        _30_MIN_INDIVIDUAL_90832,
                    )} minutes)`,
                },
                {
                    value: _45_MIN_INDIVIDUAL_90834,
                    display: `45 Minute Individual (90834) (${allottedTimeFactory(
                        _45_MIN_INDIVIDUAL_90834,
                    )} minutes)`,
                },
                {
                    value: _60_MIN_INDIVIDUAL_90837,
                    display: `60 Minute Individual (90837) (${allottedTimeFactory(
                        _60_MIN_INDIVIDUAL_90837,
                    )} minutes)`,
                },
                {
                    value: FAMILY_THERAPY_90847,
                    display: `Family Therapy w/ Patient (90847) (${allottedTimeFactory(
                        FAMILY_THERAPY_90847,
                    )} minutes)`,
                },
                {
                    value: FAMILY_THERAPY_WO_PATIENT_90846,
                    display: `Family Therapy w/o Patient (90846) (${allottedTimeFactory(
                        FAMILY_THERAPY_WO_PATIENT_90846,
                    )} minutes)`,
                },
                {
                    value: CRISIS_EVAL_90839,
                    display: `Crisis Evaluation (90839) (${allottedTimeFactory(
                        CRISIS_EVAL_90839,
                    )} minutes)`,
                },
                {
                    value: CRISIS_EVAL_EXTENDED_90840,
                    display: `Crisis Evaluation Extended (90840) (${allottedTimeFactory(
                        CRISIS_EVAL_EXTENDED_90840,
                    )} minutes)`,
                },
                {
                    value: OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
                    display: `Office or Other Outpatient Visit w/ New Patient (99204) (${allottedTimeFactory(
                        OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
                    )} minutes)`,
                },
                {
                    value: OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
                    display: `Office or Other Outpatient Visit w/ New Patient (99205) (${allottedTimeFactory(
                        OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
                    )} minutes)`,
                },
            ];
    }
};
