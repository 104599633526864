import { Form, Formik, getIn } from "formik";
import { PROVIDER_STATUS_DETAILS_SCHEMA } from "../../../containers/Dashboard/Admin/schema/providerDetailsSchema";
import { isAuthorized } from "../../../redux/actions/auth";
import { admin_cse } from "../../../config/roles";
import Label from "../../UI/Label";
import Select from "react-select";
import _ from "lodash";
import { toSentenceCase } from "../../../utils/filters";
import InputError from "../../UI/InputError";
import FormikInput from "../../UI/FormikInput";
import Button from "../../UI/Button";
import React, { useState } from "react";
import CardSecondary from "../../UI/Card/CardSecondary";
import CardHeading from "../../UI/Headings/CardHeading";
import { api } from "../../../APIRequests";
import * as yup from "yup";
import { CustomForm } from "../../../component-library/CustomForm";

export const ProviderStatusDetails = (props) => {
    const [editProviderStatus, setEditProviderStatus] = useState(false);
    const [providerStatusDetails, setProviderStatusDetails] = useState(props.providerDetails);

    const onSubmit = (formData) => {
        const data = {
            provider_id: _.get(providerStatusDetails, "username"),
            status: _.get(formData, "status.value"),
        };
        api.admin
            .change_provider_status({ data })
            .then((response) => {
                setEditProviderStatus(!editProviderStatus);

                const clonedProviderDetails = { ...providerStatusDetails };
                clonedProviderDetails["status"] = _.get(response, "status");
                setProviderStatusDetails(clonedProviderDetails);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const renderStatusForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 mb-3">
                    <div className="container px-0">
                        {PROVIDER_STATUS_DETAILS_SCHEMA.map((formEl) => {
                            const error = getIn(formikProps.errors, formEl.name);
                            const touched = getIn(formikProps.touched, formEl.name);
                            return formEl.name === "status" && isAuthorized(admin_cse) ? (
                                <>
                                    <div className={"col-12 col-lg-6 px-0"} key={formEl.name}>
                                        <Label>{formEl.elementConfig.label}</Label>
                                        <Select
                                            components={{ ClearIndicator: null }}
                                            defaultValue={{
                                                value: _.get(providerStatusDetails, "status"),
                                                label: toSentenceCase(
                                                    _.get(providerStatusDetails, "status"),
                                                ),
                                            }}
                                            options={formEl.elementConfig.options}
                                            className={"basic-multi-select"}
                                            classNamePrefix="react-select"
                                            onChange={async (selectedOptions) =>
                                                await formikProps.setFieldValue(
                                                    formEl.name,
                                                    selectedOptions,
                                                )
                                            }
                                        />
                                        {error && touched ? (
                                            <InputError classes={"custom-error"}>
                                                {error}
                                            </InputError>
                                        ) : null}
                                    </div>
                                </>
                            ) : (
                                <FormikInput
                                    formEl={formEl}
                                    errors={formikProps.errors}
                                    touched={formikProps.touched}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="d-lg-flex d-block justify-content-center text-center justify-content-lg-end">
                    <Button
                        type="button"
                        onClick={() => setEditProviderStatus(!editProviderStatus)}
                        className="Btn Btn--otl-pri Btn-sm mr-lg-3 mb-3 mb-lg-0">
                        Cancel
                    </Button>
                    <Button type="submit" className="Btn Btn--pri Btn-sm">
                        Save
                    </Button>
                </div>
            </Form>
        );
    };

    if (!providerStatusDetails) {
        return <></>;
    }

    return (
        <div data-public={"true"}>
            <CardSecondary className="mx-auto container Practice__Details p-2">
                <div className="d-flex justify-content-between pt-4">
                    <CardHeading
                        className="Heading Heading--card fw-bold fs-18 m-0 px-3 px-lg-4"
                        text="Status"
                    />
                    {isAuthorized(admin_cse) && (
                        <Button
                            onClick={() => setEditProviderStatus(!editProviderStatus)}
                            className="ProviderProfile__ProfileDetailsCard-controls cursor mr-2 fs-14 fw-bold mb-3">
                            Edit
                        </Button>
                    )}
                </div>
                {isAuthorized(admin_cse) && <div className={"ml-4 mt-2 mb-4"}></div>}
                <hr className="m-0 d-none d-md-block" />
                <div className="no-gutters my-4 col-12 px-3 px-lg-4">
                    <p className={"fs-14 font-color-gray"}>
                        Setting a provider's status to inactive will sign the provider out from all
                        devices and remove working hours and payers from their account.
                    </p>
                </div>
                {!editProviderStatus && (
                    <div className="no-gutters my-4 col-12 px-3 px-lg-4">
                        <div className="col-12 col-lg-6">
                            <CardHeading text="Provider Status" className="fs-14 mx-0" />
                            <p className="fs-14 mx-0 mb-4">
                                {toSentenceCase(providerStatusDetails.status)}
                            </p>
                        </div>
                    </div>
                )}
                {editProviderStatus && (
                    <div className="no-gutters my-4 col-12 px-3 px-lg-4">
                        <CustomForm
                            initialValues={{
                                status: {
                                    value: _.get(providerStatusDetails, "status"),
                                    label: toSentenceCase(_.get(providerStatusDetails, "status")),
                                },
                            }}
                            validationSchema={yup.object().shape({
                                status: yup
                                    .string()
                                    .required("Status is required")
                                    .test(
                                        "status-match",
                                        "Cannot change status to same status",
                                        function (value) {
                                            const newVal = _.get(
                                                this,
                                                "options.originalValue.value",
                                                value,
                                            );
                                            return (
                                                newVal !== _.get(providerStatusDetails, "status")
                                            );
                                        },
                                    ),
                            })}
                            onSubmit={onSubmit}
                            render={renderStatusForm}
                        />
                    </div>
                )}
            </CardSecondary>
        </div>
    );
};
