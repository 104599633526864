import React, { useEffect, useState } from "react";
import CardSecondary from "../../../UI/Card/CardSecondary";
import InputError from "../../../UI/InputError";
import { Select } from "../../../UI/StyledInput";
import { eventTypeDisplayNameFactory } from "../../ScheduleAvailability/ActiveScheduleDetails";
import {
    allottedTimeFactory,
    PRESCRIBE,
    timeSlotArray,
} from "../../ScheduleAvailability/constants";
import moment from "moment";
import DatePicker from "../../../UI/DatePicker";
import { formatAllottedTime } from "../../../../containers/Dashboard/Providers/PatientProfileView/NewNoteV2";
import { transformTimeSlotArray } from "../../ScheduleAvailability/ScheduleEvent";
import { api } from "../../../../APIRequests";
import { serviceLocationOptions } from "./constants";

export const NotesLinkCall = (props) => {
    //completedCalls indicates that the note is being created manually
    const {
        note,
        editHandler,
        errors,
        completedCalls,
        workflow,
        patient,
        initialPatientQuestionSets,
        handleCallTypeChange,
        resetValidationHandler,
        cptOptions,
        lastNotesOther,
    } = props;

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [timeSlotFromOptions, setTimeSlotFromOptions] = useState([]);
    const [timeSlotToOptions, setTimeSlotToOptions] = useState([]);

    useEffect(() => {
        setSessionTimeslotOptions(note.session_from, note.call_type);
    }, []);

    const setSessionTimeslotOptions = (sessionFromStr, callType) => {
        let newTimeSlotOptions = transformTimeSlotArray([...timeSlotArray]);
        const sessionFrom = moment(sessionFromStr, "HH:mm");
        const sessionTo = moment(sessionFromStr, "HH:mm").add(
            allottedTimeFactory(callType),
            "minutes",
        );
        newTimeSlotOptions.push(
            { value: sessionFrom.format("HH:mm"), display: sessionFrom.format("hh:mm a") },
            { value: sessionTo.format("HH:mm"), display: sessionTo.format("hh:mm a") },
        );
        setTimeSlotFromOptions(newTimeSlotOptions);
        setTimeSlotToOptions(
            newTimeSlotOptions.filter((option) => option.value > sessionFrom.format("HH:mm")),
        );
    };

    function getNewNoteTitle(sessionDate, sessionFrom, sessionTo) {
        if (note.provider_type.startsWith("BEHAVIORAL")) {
            return `Therapy Session ${sessionDate} - (${sessionFrom}-${sessionTo})`;
        } else {
            return `Prescriber Guided Session ${sessionDate} - (${sessionFrom}-${sessionTo})`;
        }
    }

    function updateSessionFields(timestamp, eventType) {
        const newSessionFrom = timestamp.format("HH:mm");
        const newNoteTitle = getNewNoteTitle(
            timestamp.format("MM/DD/YYYY"),
            timestamp.format("hh:mm A"),
            moment(newSessionFrom, "HH:mm")
                .add(allottedTimeFactory(eventType), "minutes")
                .format("hh:mm A"),
        );

        editHandler("call_type", eventType, false);
        editHandler("call_name", eventTypeDisplayNameFactory(eventType), false);

        editHandler("session_date", timestamp.format("MM/DD/YYYY"), false);
        editHandler("session_from", newSessionFrom, false);
        editHandler(
            "session_to",
            moment(newSessionFrom, "HH:mm")
                .add(allottedTimeFactory(eventType), "minutes")
                .format("HH:mm"),
            false,
        );
        editHandler("title", newNoteTitle, false);
        editHandler("call_timer", formatAllottedTime(allottedTimeFactory(eventType)), true);
    }

    function mergeContent(questionSet, note, workflow) {
        const newContent = workflow.details.noteEnricher(
            patient,
            questionSet,
            workflow.details.relevantPatientQuestionSections,
            { existingNote: note, lastNotesOther },
        );
        editHandler("content", newContent, false);
    }

    async function getPatientQuestionSets(callId) {
        const data = {
            call_id: callId,
        };
        const response = await api.shared.fetch_twilio_token({ data });
        return (
            response.hasOwnProperty("call_details") &&
            response.call_details.hasOwnProperty("patient_question_sets") &&
            response.call_details.patient_question_sets
        );
    }

    function resetSessionToOptions(startTime) {
        const newTimeSlotFromOptions = timeSlotFromOptions.filter(
            (option) => option.value > startTime,
        );
        setTimeSlotToOptions(newTimeSlotFromOptions);
        return newTimeSlotFromOptions;
    }

    const handleCallSelection = async (e) => {
        const call = completedCalls.find((call) => call.value === e.target.value);
        const callId = call ? call.value : null;
        let callContext = { note, workflow, questionSet: initialPatientQuestionSets };
        let date, eventType, sessionFromStr;
        if (callId && callId !== "no_linked_call" && callId !== "non_billable") {
            if (call.event_type !== note.call_type) {
                callContext = await handleCallTypeChange(call.event_type);
            }
            sessionFromStr = moment.utc(call.timestamp, "YYYY-MM-DD HH:mm").local().format("HH:mm");
            const patientQuestionSets = await getPatientQuestionSets(callId);
            if (callContext.workflow.details.noteEnricher && patientQuestionSets) {
                mergeContent(patientQuestionSets, callContext.note, callContext.workflow);
            } else {
                mergeContent(callContext.questionSet, callContext.note, callContext.workflow);
            }
            date = moment.utc(call.timestamp, "YYYY-MM-DD HH:mm").local();
            eventType = call.event_type;
            setSessionTimeslotOptions(sessionFromStr, eventType);
            updateSessionFields(date, eventType);
        } else {
            if (workflow.details.noteEnricher) {
                mergeContent(callContext.questionSet, callContext.note, callContext.workflow);
            }
            eventType = callContext.note.call_type;
        }
        editHandler("call_id", callId, false);
        resetValidationHandler("call_id", "");
    };

    const handleEventTypeSelection = async (e) => {
        const newSessionTo = moment(note.session_from, "HH:mm")
            .add(allottedTimeFactory(e.target.value), "minutes")
            .format("HH:mm");
        const newNoteTitle =
            note.title.split(" - ")[0] +
            ` - (${moment(note["session_from"], "HH:mm").format("hh:mm A")}-${moment(
                newSessionTo,
                "HH:mm",
            ).format("hh:mm A")})`;
        editHandler("call_type", e.target.value, false);
        editHandler("session_to", newSessionTo, false);
        editHandler("title", newNoteTitle, false);
        editHandler("call_name", eventTypeDisplayNameFactory(e.target.value), false);
        editHandler("call_timer", formatAllottedTime(allottedTimeFactory(e.target.value)), true);
        setSessionTimeslotOptions(note.session_from, e.target.value);
        if (!cptOptions.map((option) => option.value).includes(e.target.value)) {
            await handleCallTypeChange(e.target.value);
        }
    };

    const handleSessionDateSelection = (dateStr, eventType) => {
        if (eventType !== "monthChange") {
            setShowDatePicker(false);
        }
        let parts = dateStr.split("-");
        const sessionDate = parts[1] + "/" + parts[2] + "/" + parts[0];
        editHandler("session_date", sessionDate, false);
        editHandler(
            "title",
            getNewNoteTitle(
                sessionDate,
                moment(note.session_from, "HH:mm").format("hh:mm A"),
                moment(note.session_to, "HH:mm").format("hh:mm A"),
            ),
            false,
        );
    };

    const handleSessionFromSelection = (e) => {
        editHandler("session_from", e.target.value, false);
        const sessionFromMoment = moment(e.target.value, "HH:mm");
        const newTimeSlotToOptions = resetSessionToOptions(e.target.value);
        let sessionToMoment;
        if (!newTimeSlotToOptions.map((option) => option.value).includes(note.session_to)) {
            // reset session to so it's not invalid (session_to < session_from)
            sessionToMoment = moment(e.target.value, "HH:mm").add(
                allottedTimeFactory(note.call_type),
                "minutes",
            );
            setSessionTimeslotOptions(e.target.value, note.call_type);
        } else {
            sessionToMoment = moment(note.session_to, "HH:mm");
        }
        editHandler(
            "title",
            getNewNoteTitle(
                note.session_date,
                sessionFromMoment.format("hh:mm A"),
                sessionToMoment.format("hh:mm A"),
            ),
            false,
        );
        editHandler("session_to", sessionToMoment.format("HH:mm"), false);
        editHandler(
            "call_timer",
            formatAllottedTime(sessionToMoment.diff(sessionFromMoment, "minutes")),
            true,
        );
    };

    const handleSessionToSelection = (e) => {
        editHandler("session_to", e.target.value, false);
        const sessionFromMoment = moment(note.session_from, "HH:mm");
        const sessionToMoment = moment(e.target.value, "HH:mm");
        editHandler(
            "title",
            getNewNoteTitle(
                note.session_date,
                sessionFromMoment.format("hh:mm A"),
                sessionToMoment.format("hh:mm A"),
            ),
            false,
        );
        editHandler(
            "call_timer",
            formatAllottedTime(sessionToMoment.diff(sessionFromMoment, "minutes")),
            true,
        );
    };

    const handleServiceLocationSelection = (e) => {
        editHandler("service_location", e.target.value, false);
    };

    let innerContent = (
        <>
            <div>
                <div className="clr-sec fw-bold px-3 py-2">Call Linking and Type</div>
            </div>
            <div className="row px-4">
                <div className="col-lg-6 col-12 pb-3">
                    <span className={"font-weight-bold"}>Call Link</span>
                    <Select
                        name="call_id"
                        changeHandler={handleCallSelection}
                        value={note["call_id"]}
                        options={completedCalls}
                    />
                    {errors["call_id"] ? (
                        <InputError classes={"custom-error"}>{errors["call_id"]}</InputError>
                    ) : (
                        ""
                    )}
                </div>
                <div className="col-lg-6 col-12 pb-3">
                    <span className={"font-weight-bold"}>Select CPT Code</span>
                    <Select
                        name="call_type"
                        changeHandler={handleEventTypeSelection}
                        value={note["call_type"]}
                        options={cptOptions}
                    />
                    {errors["call_type"] ? (
                        <InputError classes={"custom-error"}>{errors["call_type"]}</InputError>
                    ) : (
                        ""
                    )}
                </div>
                <div className="col-lg-6 col-12 pb-3">
                    <span className={"font-weight-bold"}>Session Date</span>
                    <div className={"CustomSelect__wpr d-flex flex-column"}>
                        <button
                            type="button"
                            className={
                                "CustomSelect__btn cursor d-flex align-items-center text-left"
                            }
                            onClick={() => setShowDatePicker(true)}>
                            <span>{note.session_date}</span>
                        </button>
                    </div>
                    {showDatePicker && (
                        <div className={"my-3"}>
                            <DatePicker
                                externalCtx={moment(note.session_date, "MM/DD/YYYY")}
                                onDaySelect={handleSessionDateSelection}
                            />
                        </div>
                    )}
                </div>
                <div className="col-lg-3 col-6 pb-3">
                    <span className={"font-weight-bold"}>Session From</span>
                    <Select
                        name="session_from"
                        changeHandler={handleSessionFromSelection}
                        value={note.session_from}
                        options={timeSlotFromOptions}
                    />
                    {errors["session_from"] ? (
                        <InputError classes={"custom-error"}>{errors["session_from"]}</InputError>
                    ) : (
                        ""
                    )}
                </div>
                <div className="col-lg-3 col-6 pb-3">
                    <span className={"font-weight-bold"}>Session To</span>
                    <Select
                        name="session_to"
                        changeHandler={handleSessionToSelection}
                        value={note.session_to}
                        options={timeSlotToOptions}
                    />
                    {errors["session_to"] ? (
                        <InputError classes={"custom-error"}>{errors["session_to"]}</InputError>
                    ) : (
                        ""
                    )}
                </div>
                {note.provider_type === PRESCRIBE && (
                    <div className="col-lg-6 col-12 pb-3">
                        <span className={"font-weight-bold"}>Service Location</span>
                        <Select
                            name="service_location"
                            changeHandler={handleServiceLocationSelection}
                            value={note.service_location}
                            options={serviceLocationOptions}
                        />
                        {errors.service_location ? (
                            <InputError classes={"custom-error"}>
                                {errors.service_location}
                            </InputError>
                        ) : (
                            ""
                        )}
                    </div>
                )}
            </div>
        </>
    );

    return (
        <>
            <CardSecondary className="mx-0 px-3 py-3 my-3 max-width" style={{ minHeight: 200 }}>
                {innerContent}
            </CardSecondary>
        </>
    );
};
