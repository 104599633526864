// Copyright aptihealth, inc. 2019 All Rights Reserved
import * as actionTypes from "../actions/actionTypes";

let initialState = {
    toasts: [],
};

const logout = (state, action) => {
    return {
        ...initialState,
    };
};

export const toasterReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_TOAST:
            return {
                ...state,
                toasts: [
                    ...state.toasts,
                    {
                        message: action.payload.message,
                        messageType: action.payload.messageType,
                        toastId: action.payload.toastId,
                        hideDismissButton: action.payload.hideDismissButton,
                        dismissMessage: action.payload.dismissMessage,
                        dismissTimeout: action.payload.dismissTimeout,
                    },
                ],
            };
        case actionTypes.REMOVE_TOAST:
            return {
                ...state,
                toasts: state.toasts.filter((toast) => toast.toastId !== action.payload.toastId),
            };
        case actionTypes.CLEAR_ALL_TOASTS:
            return {
                ...state,
                toasts: [],
            };
        case actionTypes.LOG_OUT:
            return logout(state, action);
        default:
            return state;
    }
};
