import React from "react";
import "../styles.scss";

const GuidedCallProgress = (props) => {
    const { workflow, currentStep } = props;

    return (
        <div className="GuidedVideoCall__Progress d-flex flex-row align-items-center justify-content-center">
            {workflow.steps.map((workflowStep, index) => {
                let classes = "";
                if (index === 0) {
                    classes = "first ";
                }

                if (index < currentStep) {
                    classes += "arrow-completed ";
                } else if (index === currentStep) {
                    classes += "arrow-active ";
                } else {
                    classes += "arrow ";
                }

                return (
                    <div key={index} className={classes}>
                        <b>
                            {index + 1}. {workflowStep.short}
                        </b>
                    </div>
                );
            })}
        </div>
    );
};

export default React.memo(GuidedCallProgress);
