// Copyright aptihealth, inc. 2019 All Rights Reserved
import { http, httpAuth, httpBackground } from "../utils/axios";
import { getGroupAuthType } from "../redux/actions/auth";
import { GROUP_AUTH_HEADER_NAME } from "../constants/axios";
import { buildRequestURLWithQueryParamObject } from "../utils/requestUtil";
/**
 * The code logic is outsourced to this function to enforce with DRY code techniques.
 * @param {Object} urlParams These are passed in the form of object from the site where request is made
 * @param {String} version Version as follows v1/v2/etc.
 * @param {String} lastPath This is the last path.
 * @returns {Promise} The http `Promise` is returned.
 */
export const commonPatientRequest = (urlParams, version, lastPath, options) => {
    let conf = {
        method: "post",
        url: `/${version}/patient_management/${urlParams.patientId}/${lastPath}`,
        ...options,
        headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
    };
    return httpAuth(conf);
};

const sharedRequests = {
    fetch_twilio_token: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v3/video_call/twilio_token",
            data,
            conditionalExceptionReporting: true,
        };
        return httpAuth(conf);
    },

    report_video_call_error: function ({ data }) {
        let conf = { method: "post", url: "/v1/video_call/error_logging", data };
        return httpBackground(conf);
    },

    post_ratings: function ({ data }) {
        let conf = { method: "post", url: "/v1/video_call/post_ratings", data };
        return httpAuth(conf);
    },

    post_ratings_v2: function ({ data }) {
        const conf = {
            method: "post",
            url: "/v2/video_call/post_ratings",
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    remove_appointment: function ({ queryParams, data }) {
        const { callId } = queryParams;
        let conf = {
            method: "post",
            url: `/v2/video_call/delete_appointment?call_id=${callId}`,
            data,
        };
        return httpAuth(conf);
    },

    reschedule_appointment: function ({ data, queryParams, headers }) {
        let conf = {
            method: "post",
            url: `/v2/video_call/reschedule_appointment?timezone=${encodeURIComponent(
                queryParams.timezone,
            )}`,
            data,
        };

        if (headers != null) {
            conf["headers"] = headers;
        }

        return httpAuth(conf);
    },
    set_video_call_start_time: function ({ data }) {
        let conf = { method: "post", url: "/v1/video_call/call_time", data };
        return httpAuth(conf);
    },

    set_video_call_end_time: function ({ data }) {
        let conf = { method: "post", url: "/v1/video_call/call_time", data };
        return httpAuth(conf);
    },
    fetch_patient_file: function ({ data, urlParams, options }) {
        let conf = {
            method: "post",
            url: `/v1/patient_management/${urlParams.patientId}/get_file`,
            data,
            ...options,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    fetch_patient_phq9_gad7: function ({ urlParams, options }) {
        let conf = {
            method: "post",
            url: `/v1/patient_management/${urlParams.patientId}/screening-data?type=${urlParams.type}&day=${urlParams.day}`,
            ...options,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    fetch_patient_details: function ({ urlParams, options }) {
        return commonPatientRequest(urlParams, "v1", "patient_details", options);
    },

    fetch_patient_report: function ({ urlParams, options }) {
        return commonPatientRequest(urlParams, "v1", "report", options);
    },

    fetch_patient_activity_feed: function ({ urlParams, options }) {
        return commonPatientRequest(urlParams, "v1", "activity", options);
    },

    fetch_patient_careteam: function ({ urlParams, options, queryParams = {} }) {
        let url = `/v1/patient_management/${urlParams.patientId}/care_team`;
        let conf = {
            method: "post",
            url: buildRequestURLWithQueryParamObject(url, queryParams),
            ...options,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            options: { params: { showLoader: true } },
        };
        return httpAuth(conf);
    },
    fetch_patient_care_plan: function ({ urlParams, options }) {
        return commonPatientRequest(urlParams, "v1", "care_plan", options);
    },

    fetch_patient_care_plan_v2: function ({ urlParams, options }) {
        let url = `/v1/care-plan/${urlParams.patientId}/get_care_plan`;
        let conf = {
            method: "post",
            url: url,
            ...options,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            options: { params: { showLoader: true } },
        };
        return httpAuth(conf);
    },

    fetch_patient_avatar: function ({ data, urlParams, options }) {
        return this.fetch_patient_file({ data, urlParams, options });
    },

    fetch_patient_progress_notes: function ({ urlParams, options, queryParams = {} }) {
        let url = `/v1/patient_management/${urlParams.patientId}/get_progress_notes`;
        let conf = {
            method: "post",
            url: buildRequestURLWithQueryParamObject(url, queryParams),
            ...options,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    fetch_patient_notes_summary: function ({ urlParams, options, queryParams }) {
        let url = `/v1/patient_management/${urlParams.patientId}/get_notes_summary`;
        let conf = {
            method: "post",
            url: url,
            ...options,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    fetch_patient_clinical_assessment: function ({ urlParams, queryParams }) {
        return commonPatientRequest(
            urlParams,
            "v1",
            `get_clinical_assessment?type=${queryParams.type}`,
        );
    },

    fetch_providers: function ({ queryParams } = {}) {
        const { insurance, provider_type } = queryParams;
        let url = "/v1/provider/get_providers";
        if (insurance) {
            url += `?insurance=${insurance}`;
        }
        if (provider_type && !insurance) {
            url += `?provider_type=${provider_type}`;
        } else if (provider_type && insurance) {
            url += `&provider_type=${provider_type}`;
        }
        let conf = {
            method: "post",
            url: url,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    add_provider_to_care_team: function ({ urlParams, queryParams }) {
        const { providerId } = queryParams;
        const { patientId } = urlParams;
        let conf = {
            method: "post",
            url: `/v1/patient_management/${patientId}/add_provider_to_care_team?providerId=${providerId}`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    post_exception_report({ report }) {
        let conf = {
            method: "post",
            url: "/v1/user_support/exception_report",
            data: report,
        };
        return httpAuth(conf);
    },

    fetch_a5_screening_list: function ({ queryParams }) {
        let url = "/v1/a5_screening/list";
        if (queryParams.practice_id) {
            url += `?practice_id=${queryParams.practice_id}`;
        }
        let conf = {
            method: "post",
            url: url,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    fetch_a5_screening_details: function ({ queryParams, headers }) {
        let conf = {
            method: "post",
            url: `/v1/a5_screening/get?screening_id=${queryParams.screening_id}`,
        };
        if (window.localStorage.getItem("token")) {
            conf["headers"] = { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() };
            return httpAuth(conf);
        } else {
            conf["headers"] = { Authorization: "DOB " + headers["dob"] };
            return http(conf);
        }
    },

    create_a5_screening_details: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/a5_screening/create",
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    update_a5_screening_details: function ({ data, headers }) {
        let conf = {
            method: "post",
            url: "/v1/a5_screening/update",
            data,
        };
        if (window.localStorage.getItem("token")) {
            conf["headers"] = { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() };
            return httpAuth(conf);
        } else {
            conf["headers"] = { Authorization: "DOB " + headers["dob"] };
            return http(conf);
        }
    },

    resend_a5_screening: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/a5_screening/resend",
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    fetch_patients_reports: function ({ queryParams, data } = {}) {
        let url = `/v2/provider/patient-reports?practice_id=${queryParams["practice_id"]}`;
        let conf = {
            method: "post",
            url: url,
            data,
            options: { params: { showLoader: false } },
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    fetch_report: function ({ urlParams, queryParams }) {
        let conf = {
            method: "get",
            url: `/v1/reports/data/${urlParams.patientId}?type=${queryParams.type}`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    get_progress_notes_by_practice_id: function ({ queryParams, data }) {
        let url = "v1/patient_management/x/get_progress_notes_by_practice_id";
        if (queryParams.hasOwnProperty("practiceId")) {
            url += "?practice_id=" + queryParams["practiceId"];
        }
        let conf = {
            method: "post",
            url: url,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    get_progress_notes_by_pcp_practice_id: function ({ queryParams, data }) {
        let url = "v1/patient_management/x/get_progress_notes_by_pcp_practice_id";
        if (queryParams.hasOwnProperty("pcpPracticeId")) {
            url += "?pcp_practice_id=" + queryParams["pcpPracticeId"];
        }
        let conf = {
            method: "post",
            url: url,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    get_progress_notes_by_provider_id: function ({ queryParams, data }) {
        let url = "v1/patient_management/x/get_progress_notes_by_provider_id";
        if (queryParams.hasOwnProperty("providerId")) {
            url += "?provider_id=" + queryParams["providerId"];
        }
        let conf = {
            method: "post",
            url: url,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    fetch_note_addendums: function ({ urlParams, data }) {
        let url = `v1/patient_management/${urlParams.patientId}/get_note_addendums`;
        let conf = {
            method: "post",
            url: url,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    create_note_addendum: function ({ urlParams, data }) {
        let url = `v1/patient_management/${urlParams.patientId}/create_note_addendum`;
        let conf = {
            method: "post",
            url: url,
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    available_schedules: function ({ urlParams, options }) {
        let conf = {
            method: "post",
            url: `/v2/patient/available_schedule?date=${
                urlParams.dateString
            }&timezone=${encodeURIComponent(urlParams.tz)}`,
            ...options,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };

        if (urlParams.providerId) {
            conf.url += `&provider_id=${urlParams.providerId}`;
        }

        if (urlParams.patientId) {
            conf.url += `&patient_id=${urlParams.patientId}`;
        }

        if (urlParams.providerType) {
            conf.url += `&provider_type=${urlParams.providerType}`;
        }

        if (urlParams.allottedTime) {
            conf.url += `&allotted_time=${urlParams.allottedTime}`;
        }

        if (urlParams.eventType) {
            conf.url += `&event_type=${urlParams.eventType}`;
        }

        return httpAuth(conf);
    },

    fetch_referral: function ({ queryParams }) {
        let conf = {
            method: "post",
            url: `/v1/provider/get_referral?referralCode=${queryParams.referralCode}`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    update_referral: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/provider/update_referral",
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    fetch_provider_activity: function ({ queryParams }) {
        let url = `/v2/provider/get_provider_activity?startDate=${queryParams["startDate"]}&endDate=${queryParams["endDate"]}&isProcessed=${queryParams["isProcessed"]}`;
        if (queryParams.hasOwnProperty("providerId")) {
            url += "&providerId=" + queryParams["providerId"];
        }
        if (queryParams.hasOwnProperty("practiceId")) {
            url += "&practiceId=" + queryParams["practiceId"];
        }
        let conf = {
            method: "post",
            url: url,
            options: { params: { showLoader: false } },
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    fetch_billable_activities: function ({ queryParams }) {
        let url = `/v1/billing/get_activities?startDate=${queryParams["startDate"]}&endDate=${queryParams["endDate"]}&isProcessed=${queryParams["isProcessed"]}`;
        if (queryParams.hasOwnProperty("providerId")) {
            url += "&providerId=" + queryParams["providerId"];
        }
        if (queryParams.hasOwnProperty("practiceId")) {
            url += "&practiceId=" + queryParams["practiceId"];
        }
        let conf = {
            method: "post",
            url: url,
            options: { params: { showLoader: false } },
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    poll_billing_reports: function ({ data, queryParams }) {
        let url = `/v1/billing/get_report_meta_data?query_type=${queryParams["query_type"]}`;
        if (queryParams.hasOwnProperty("providerId")) {
            url += "&providerId=" + queryParams["providerId"];
        }
        if (queryParams.hasOwnProperty("practiceId")) {
            url += "&practiceId=" + queryParams["practiceId"];
        }
        let conf = {
            method: "post",
            url: url,
            data,
            params: { showLoader: false },
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    process_billing_activity: function ({ data }) {
        let url = "/v1/billing/create_report";
        let conf = {
            method: "post",
            url: url,
            data,
            options: { params: { showLoader: false } },
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    process_provider_activity: function ({ queryParams, data }) {
        let url = `/v2/provider/process_provider_activity?startDate=${queryParams["startDate"]}&endDate=${queryParams["endDate"]}&includeAll=${queryParams["includeAll"]}&isProcessed=${queryParams["isProcessed"]}`;
        if (queryParams.hasOwnProperty("providerId")) {
            url += "&providerId=" + queryParams["providerId"];
        }
        if (queryParams.hasOwnProperty("practiceId")) {
            url += "&practiceId=" + queryParams["practiceId"];
        }
        let conf = {
            method: "post",
            url: url,
            data,
            options: { params: { showLoader: false } },
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    get_provider_reports: function ({ queryParams, data }) {
        let url = "/v1/provider/provider_reports";
        if (queryParams.hasOwnProperty("practiceId")) {
            url += "?practiceId=" + queryParams["practiceId"];
        }
        let conf = {
            method: "post",
            url: url,
            data,
            options: { params: { showLoader: false } },
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    get_billing_reports: function ({ queryParams, data }) {
        let url = "/v1/billing/get_reports";
        if (queryParams.hasOwnProperty("practiceId")) {
            url += "?practiceId=" + queryParams["practiceId"];
        }
        let conf = {
            method: "post",
            url: url,
            data,
            options: { params: { showLoader: false } },
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    update_provider_report: function ({ queryParams, data }) {
        let url = "/v1/provider/update_provider_report";
        if (queryParams.hasOwnProperty("practiceId")) {
            url += "?practiceId=" + queryParams["practiceId"];
        }
        if (queryParams.hasOwnProperty("providerId")) {
            url += "?providerId=" + queryParams["providerId"];
        }
        let conf = {
            method: "post",
            url: url,
            data,
            options: { params: { showLoader: false } },
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    fetch_practice: function ({ urlParams }) {
        let conf = {
            method: "post",
            url: `/v1/practice/practice?practice_id=${urlParams.practiceId}`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
            params: { showLoader: false },
        };
        return httpAuth(conf);
    },
    fetch_practice_provider: function ({ urlParams }) {
        let conf = {
            method: "post",
            url: `/v1/practice/practice_provider?practice_id=${urlParams.practiceId}&provider_id=${urlParams.providerId}`,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
    get_presigned_url: function ({ version = "v2", data }) {
        let conf = {
            method: "post",
            url: `/${version}/provider/get_presigned_url`,
            data,
            options: { params: { showLoader: false } },
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    get_patient_call_question_sets: function ({ data }) {
        let conf = { method: "post", url: "/v2/video_call/get_patient_question_sets", data };
        return httpAuth(conf);
    },

    get_post_session_survey_question_set: function (callId, errorTypeConfig) {
        let conf = {
            method: "post",
            url: `/v1/video_call/get_post_session_survey_question_set?call_id=${callId}`,
            data: {},
            errorTypeConfig,
        };
        return httpAuth(conf);
    },

    update_call: function ({ data }) {
        let conf = { method: "post", url: "/v1/video_call/update_call", data };
        return httpAuth(conf);
    },
    account_lookup: function ({ options, data } = {}) {
        let conf = {
            method: "post",
            url: "/v1/referral/account_lookup",
            ...options,
            data,
        };
        return http(conf);
    },
    update_insurance: function ({ options, data, headers } = {}) {
        let conf = {
            method: "post",
            url: "/v1/referral/update_insurance",
            ...options,
            data,
            headers,
        };
        return httpAuth(conf);
    },
    set_password: function ({ options, data } = {}) {
        let conf = {
            method: "post",
            url: "/v1/auth/account_set_up",
            ...options,
            data,
        };
        return httpAuth(conf);
    },
    demographic_eligibility: function ({ options, data } = {}) {
        let conf = {
            method: "post",
            url: "/v1/referral/demographic_eligibility",
            ...options,
            data,
        };
        return http(conf);
    },
    eligible_states: function ({ options } = {}) {
        let conf = {
            method: "post",
            url: "/v1/referral/eligible_states",
            ...options,
        };
        return http(conf);
    },

    generate_otc: function ({ queryParams }) {
        let url = `v1/auth/generate_otc?patient_id=${queryParams.patientId}`;
        let conf = {
            method: "post",
            url: url,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    create_event_notification: function ({ data } = {}) {
        let url = "v1/event_notifications/create";
        let conf = {
            method: "post",
            url: url,
            data,
        };
        return httpAuth(conf);
    },

    start_file_generation: function ({ data, headers } = {}) {
        let url = "v1/file_generation/start_execution";
        let conf = {
            method: "post",
            url: url,
            data,
            headers,
        };
        return httpAuth(conf);
    },

    get_patient_diagnosis: async function ({ urlParams } = {}) {
        let url = `v1/patient_management/${urlParams.patientId}/get_patient_diagnosis`;
        let conf = {
            method: "post",
            url: url,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    send_on_demand_welcome_email: async function ({ urlParams } = {}) {
        let url = `v1/patient_management/${urlParams.patientId}/send_on_demand_welcome_email`;
        let conf = {
            method: "post",
            url: url,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    write_file: async function (data) {
        let conf = {
            method: "post",
            url: "/v1/provider/write_file",
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },

    get_files: async function (data) {
        let conf = {
            method: "post",
            url: "/v1/provider/get_files",
            data,
            headers: { [GROUP_AUTH_HEADER_NAME]: getGroupAuthType() },
        };
        return httpAuth(conf);
    },
};

export default sharedRequests;
