// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import ReportList from "../../../components/Provider/ReportsList";
import { api } from "../../../APIRequests";
import moment from "moment-timezone";
import { filterPractices, getQueryParams } from "../../../utils/filters";
import { isUserAdmin, isUserPCP } from "../../../redux/actions/auth";
import _ from "lodash";
import { connect } from "react-redux";

class Reports extends Component {
    practiceId = getQueryParams("practiceId", this.props.location.search);
    reportType = getQueryParams("reportType", this.props.location.search);
    state = {
        retryingReports: false,
        clearRetryInterval: false,
        reportLookup: {},
        unprocessedLookup: {},
        originalReportList: [],
        filteredReportList: [],
        allReportsList: this.props.location.pathname === "/app/reports",
        patientId: this.props.match.params.patientId,
        patientName: "",
        practiceId: this.practiceId ? this.practiceId : "all_practices",
        reportType: this.reportType ? this.reportType : "patient",
        allPractices: [],
        pcpPracticeOptions: null,
        bhsPracticeOptions: null,
        practiceOptions: [],
        providerReportsNextToken: null,
        patientReportsNextToken: null,
    };

    componentDidMount = async () => {
        const options = { params: { showLoader: false } };
        if (this.state.patientId) {
            let urlParams = { patientId: this.state.patientId };
            const options = { params: { showLoader: false } };
            const patientResponse = await api.shared.fetch_patient_details({ urlParams, options });
            this.setState({
                patientName: patientResponse.user.first_name + " " + patientResponse.user.last_name,
            });
        }
        if (isUserAdmin() && this.state.allReportsList) {
            const practices = await api.admin.fetch_all_practices({ options });
            const pcpPracticeOptions = filterPractices(
                practices,
                (practice) => practice["practice_type"] === "Primary Care",
                false,
            );
            const bhsPracticeOptions = filterPractices(
                practices,
                (practice) => practice["practice_type"] === "Behavioral",
                false,
            );
            this.setState({
                pcpPracticeOptions: pcpPracticeOptions,
                bhsPracticeOptions: bhsPracticeOptions,
                practiceOptions:
                    this.state.reportType === "patient" ? pcpPracticeOptions : bhsPracticeOptions,
            });
            if (this.state.reportType === "admin" && this.state.practiceId !== "all_practices") {
                await this.fetchReports();
            }
        } else if (this.props.profile) {
            await this.fetchReports();
        }
    };

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.profile && this.props.profile && !isUserAdmin()) {
            await this.fetchReports();
        }
    }

    componentWillUnmount = () => {
        if (this.state.clearRetryInterval) {
            clearInterval(this.state.clearRetryInterval);
        }
    };
    practiceIdChangeHandler = async (e) => {
        if (!e) {
            return;
        }
        const practiceId = e.value;
        this.state.unprocessedLookup = {};
        this.state.reportLookup = {};
        this.setState({ selectedPractice: e, practiceId: practiceId }, () => this.fetchReports());
    };

    reportTypeChangeHandler = async (e) => {
        let reportType = e.value;
        if (!reportType) {
            reportType = e.target.value;
        }
        const newState = { reportType: reportType, practiceId: null, selectedPractice: null };
        if (reportType === "patient") {
            newState["practiceOptions"] = this.state.pcpPracticeOptions;
        } else {
            newState["practiceOptions"] = this.state.bhsPracticeOptions;
        }
        if (isUserAdmin()) {
            newState["originalReportList"] = [];
            newState["filteredReportList"] = [];
        }

        this.setState(newState, () => {
            if (!isUserAdmin()) {
                this.fetchReports();
            }
        });
    };

    async fetchReports() {
        if (this.state.reportType === "patient" && (isUserPCP() || isUserAdmin())) {
            await this.fetchPatientReportList(this.state.practiceId);
        } else if (isUserAdmin()) {
            await this.fetchAdminReportList();
        }
    }

    fetchAdminReportList = async (nextToken) => {
        try {
            const queryParams = {};
            if (isUserAdmin() && this.state.practiceId === "all_practices") {
                return;
            }
            if (isUserAdmin() && this.state.practiceId !== "all_practices") {
                queryParams["practiceId"] = this.state.practiceId;
            }
            const data = {
                limit: 10,
                nextToken,
            };
            let response;
            if (this.props.configs.ENHANCED_BILLING_REPORTS) {
                response = await api.shared.get_billing_reports({ queryParams, data });
            } else {
                response = await api.shared.get_provider_reports({ queryParams, data });
            }

            const reports = response["results"];
            const providerReportsNextToken = response["last_evaluated_key"];

            if (this.props.configs.ENHANCED_BILLING_REPORTS) {
                this.separateProcessedAndUnprocessedReports(reports);
            }
            this.sortReports([...reports]);

            this.setState({
                originalReportList: !this.state.originalReportList
                    ? Object.values(this.state.reportLookup)
                    : this.state.originalReportList.concat(Object.values(this.state.reportLookup)),
                filteredReportList: !this.state.filteredReportList
                    ? Object.values(this.state.reportLookup)
                    : this.state.filteredReportList.concat(Object.values(this.state.reportLookup)),
                providerReportsNextToken: providerReportsNextToken,
            });
        } catch (err) {
            console.log(err);
        }
    };

    fetchPatientReportList = async (practiceId, nextToken) => {
        try {
            if (this.props.location.pathname === "/app/reports") {
                let practice_id = practiceId;

                const queryParams = {
                    practice_id: isUserAdmin() ? practiceId : this.props.profile.practice_id,
                };
                const data = {
                    limit: 50,
                    next_token: nextToken,
                    eager_fetch_report_url: false,
                };
                let response = await api.shared.fetch_patients_reports({ queryParams, data });
                let reports = response["items"];
                const last_evaluated_key = response["last_evaluated_key"];

                this.setState({
                    originalReportList: !this.state.originalReportList
                        ? reports
                        : this.state.originalReportList.concat(reports),
                    filteredReportList: !this.state.filteredReportList
                        ? reports
                        : this.state.filteredReportList.concat(reports),
                    patientReportsNextToken: last_evaluated_key,
                });
            }
            if (this.props.match.path === "/app/patient/:patientId/report") {
                let urlParams = { patientId: this.state.patientId };
                let options = { params: { showLoader: false } };
                let response = await api.shared.fetch_patient_report({ urlParams, options });
                let chronologicallySortedReports = this.sortReports([...response]);
                this.setState({
                    originalReportList: chronologicallySortedReports,
                    filteredReportList: chronologicallySortedReports,
                    allReportsList: false,
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    retryProcessingReports = () => {
        let report_keys = [];

        Object.values(this.state.unprocessedLookup).forEach((report) => {
            const report_key = {
                id: report["id"],
                timestamp: report["timestamp"],
            };
            if (_.has(report, "entity_type")) {
                report_key["entity_type"] = report["entity_type"];
            }
            if (_.has(report, "practice_id")) {
                report_key["practice_id"] = report["practice_id"];
            }
            report_keys.push(report_key);
        });

        if (report_keys.length > 0) {
            let interval = setInterval(async () => {
                const queryParams = {
                    query_type: "report_keys",
                };
                const data = {
                    report_keys,
                };
                const response = await api.shared.poll_billing_reports({ data, queryParams });

                if (response) {
                    response.results.forEach((report) => {
                        if (
                            _.has(report, "report_url") ||
                            _.has(report, "report_part_urls") ||
                            report.status === "Failed"
                        ) {
                            // set the ID of the updated report, update the reportLookUp hash with the new report
                            const key = report["id"] + report["timestamp"];
                            let updateReportLookup = this.state.reportLookup;
                            updateReportLookup[key] = report;
                            this.setState({ reportLookup: updateReportLookup });
                            // remove that key/val pair from the unprocessed hash
                            let updateUnprocessedLookup = _.omit(this.state.unprocessedLookup, key);
                            this.setState({ unprocessedLookup: updateUnprocessedLookup });
                            this.setState({
                                filteredReportList: _.merge(
                                    this.state.filteredReportList,
                                    Object.values(this.state.reportLookup),
                                ),
                            });
                            if (Object.keys(this.state.unprocessedLookup).length === 0) {
                                clearInterval(this.state.clearRetryInterval);
                            }
                        }
                    });
                }
            }, 5000);
            return interval;
        }
    };

    separateProcessedAndUnprocessedReports = (reportsArray) => {
        let unprocessedReports = reportsArray.filter(
            (report) => !report.report_url && report.status === "In-Progress",
        );
        const unprocessedLookup = {};
        unprocessedReports.forEach((report) => {
            const key = report["id"] + report["timestamp"];
            unprocessedLookup[key] = report;
        });

        this.setState({
            unprocessedLookup: _.merge(unprocessedLookup, this.state.unprocessedLookup),
        });

        if (Object.values(this.state.unprocessedLookup).length > 0) {
            this.state.retryingReports = true;
            if (this.state.clearRetryInterval) {
                clearInterval(this.state.clearRetryInterval);
                this.setState({ clearRetryInterval: false });
            }
            let interval = this.retryProcessingReports();
            this.setState({ clearRetryInterval: interval });
        } else {
            if (this.state.retryingReports) {
                clearInterval(this.state.clearRetryInterval);
                this.setState({ clearRetryInterval: false, retryingReports: false });
            }
        }
    };

    sortReports = (reportsArray) => {
        reportsArray = reportsArray.filter((report) => report.status !== "ARCHIVED");

        let sortedReports = reportsArray.sort((a, b) => {
            let d1, d2;
            if (a.hasOwnProperty("timestamp") && b.hasOwnProperty("timestamp")) {
                d1 = moment.utc(a.timestamp);
                d2 = moment.utc(b.timestamp);
            } else {
                d1 = moment(a.modified_at, "MM/DD/YYYY").unix();
                d2 = moment(b.modified_at, "MM/DD/YYYY").unix();
            }

            return d2 - d1;
        });

        const report_lookup = {};
        sortedReports.forEach((report) => {
            const key = report["id"] + report["timestamp"];
            report_lookup[key] = report;
        });
        this.setState({ reportLookup: report_lookup });
        return sortedReports;
    };

    onSearch = (e) => {
        let searchStr = e.target.value;
        let filteredReports;
        if (this.state.reportType === "patient") {
            filteredReports = [...this.state.originalReportList].filter((report) => {
                let patientFN = report.first_name.toLowerCase();
                let patientLN = report.last_name.toLowerCase();
                let firstNameMatched = patientFN.match(new RegExp("^" + searchStr.toLowerCase()));
                let lastNameMatched = patientLN.match(new RegExp("^" + searchStr.toLowerCase()));
                let fullNameMatched = (patientFN + " " + patientLN).match(
                    new RegExp("^" + searchStr.toLowerCase()),
                );
                return firstNameMatched || lastNameMatched || fullNameMatched;
            });
        } else {
            filteredReports = [...this.state.originalReportList].filter((report) => {
                const reportName = report.name.toLowerCase();
                const reportType = report.type.toLowerCase();
                const partsToCheck = reportName.split(" ");
                partsToCheck.unshift(reportName, reportType);
                return partsToCheck.some((part) =>
                    part.match(new RegExp("^" + searchStr.toLowerCase())),
                );
            });
        }

        this.setState({
            filteredReportList: filteredReports,
        });
    };

    render() {
        return (
            <ReportList
                searchHandler={this.onSearch}
                reportList={this.state.filteredReportList}
                allReports={this.state.allReportsList}
                patientId={this.state.patientId}
                patientName={this.state.patientName}
                selectedPractice={this.state.selectedPractice}
                practiceOptions={this.state.practiceOptions}
                practiceId={this.state.practiceId}
                practiceIdChangeHandler={this.practiceIdChangeHandler}
                reportTypeChangeHandler={this.reportTypeChangeHandler}
                reportType={this.state.reportType}
                fetchAdminReportList={this.fetchAdminReportList}
                providerReportsNextToken={this.state.providerReportsNextToken}
                patientReportsNextToken={this.state.patientReportsNextToken}
                setProviderReportsNextToken={(providerReportsNextToken) =>
                    this.setState({ providerReportsNextToken })
                }
                setPatientReportsNextToken={(patientReportsNextToken) =>
                    this.setState({ patientReportsNextToken })
                }
                fetchPatientReportList={this.fetchPatientReportList}
                setReportList={(reportList) => this.setState({ filteredReportList: reportList })}
                clearRetryInterval={this.state.clearRetryInterval}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        configs: state.configs,
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps, {})(Reports);
