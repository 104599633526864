import React from "react";
import PropTypes from "prop-types";
import { Portal } from "../Portal";
import { Toast } from "../Toast";

export const Toaster = ({ toasts, onDismissButtonClick, portalRoot }) => {
    return (
        <Portal portalRoot={portalRoot}>
            {toasts.map((toast, index) => (
                <Toast
                    key={index}
                    toastId={toast.toastId}
                    messageType={toast.messageType}
                    hideDismissButton={toast.hideDismissButton}
                    onDismissButtonClick={onDismissButtonClick}>
                    {toast.message}
                </Toast>
            ))}
        </Portal>
    );
};

Toaster.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    className: PropTypes.string,
    messageType: PropTypes.oneOf(["success", "success_v2", "warning", "error"]),
    dismissMessage: PropTypes.string,
    hideDismissButton: PropTypes.bool,
};

Toaster.defaultProps = {
    className: undefined,
    messageType: "success",
    dismissMessage: "Dismiss",
    hideDismissButton: false,
};
