import React from "react";
import CardSecondary from "../../../UI/Card/CardSecondary";
import moment from "moment";
import { ICON_SIZES, ICON_TYPES } from "../../../../constants/icons";
import { AlertIcon, Button, ButtonTypes } from "../../../../component-library";

export const CallScheduledReferOtherPatient = ({
    sharedWorkflowData,
    currentStepName,
    update_state_and_get_next_node,
}) => {
    const localizedCallDateTime = moment
        .utc(
            `${sharedWorkflowData.workflow_instance.state.call_date} ${sharedWorkflowData.workflow_instance.state.call_time}`,
            "YYYY-MM-DD HH:mm",
        )
        .local();
    const localizedCallDate = localizedCallDateTime.format("MMMM DD, YYYY");
    const localizedCallTime = localizedCallDateTime.format("hh:mm A");

    return (
        <div className={"container hcp-mobile-container px-sm-3 py-sm-5"}>
            <CardSecondary className="HCPWorkflowShared-card">
                <div className="HCPWorkflowShared-header_container">
                    <h1 className="HCPWorkflowShared-title">All set! We’ve got it from here.</h1>
                    <p className="HCPWorkflowShared-sub_title">
                        <span style={{ display: "inline" }} data-private>
                            {sharedWorkflowData.workflow_instance.state.patient_info?.first_name}
                        </span>
                        's first session is scheduled. They'll receive an email from us soon.
                    </p>
                </div>
                <div className="CallScheduledReferOtherPatient-call_details_container">
                    <p className="CallScheduledReferOtherPatient-call_details_text">
                        Diagnostic Intake with{" "}
                        {sharedWorkflowData.workflow_instance.state.provider_name}
                    </p>

                    <div className={"d-flex justify-content-center"}>
                        <p>{localizedCallDate}</p>
                        <span className="mx-1">&bull;</span>
                        <p>{localizedCallTime}</p>
                    </div>
                </div>
                <div className="CallScheduledReferOtherPatient-message_container">
                    <AlertIcon
                        className="CallScheduledReferOtherPatient-message_icon"
                        iconType={ICON_TYPES.OUTLINE}
                        iconSize={ICON_SIZES.SMALL}
                    />
                    <p className="CallScheduledReferOtherPatient-message">
                        Encourage your patient to complete their registration as soon as possible to
                        hold their session time. If your patient does not register 24 hours before
                        their session, they will need to reschedule.
                    </p>
                </div>

                <div className="ScheduleCallOrSkip-button_container">
                    <Button
                        buttonType={ButtonTypes.primaryV2}
                        onClick={async () => {
                            await update_state_and_get_next_node(
                                { refer_another_patient: false },
                                currentStepName,
                            );
                        }}>
                        Done
                    </Button>
                    <div className="ScheduleCallOrSkip-or_text_container">
                        <div className="ScheduleCallOrSkip-hr" style={{ width: 81 }} />
                        <p className="ScheduleCallOrSkip-or_text">OR</p>
                        <div className="ScheduleCallOrSkip-hr" style={{ width: 81 }} />
                    </div>
                    <Button
                        buttonType={ButtonTypes.underline}
                        className="ScheduleCallOrSkip-skip_step"
                        onClick={async () => {
                            await update_state_and_get_next_node(
                                { refer_another_patient: true },
                                currentStepName,
                            );
                        }}>
                        Refer another patient
                    </Button>
                </div>
            </CardSecondary>
        </div>
    );
};
