// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useState } from "react";
import MemberProfileAccordion from "./MemberProfileAccordion";
import moment from "moment-timezone";
import images from "../../../../utils/images";
import ImageFullPreview from "../../../Common/ImageFullPreview";
import { getReportPresignedUrl, viewReport } from "../../../../utils/fileUtil";
import { memberProfileBadgeColorTypes, MemberProfileHeaderBadge } from "./MemberProfileHeaderBadge";
import {isUserPCP} from "../../../../redux/actions/auth";

export const MemberProfileReports = (props) => {
    const [showReportModal, setShowReportModal] = useState(null);

    const getReport = (reportType) => {
        const reports = props?.data || [];

        for (const report of reports) {
            if (report?.file_sub_type === reportType) {
                return report;
            }
        }
        return null;
    };

    const isReportPresent = (reportType) => {
        if (getReport(reportType)) {
            return true;
        }

        return false;
    };

    const isA30ReportPresent = isReportPresent("A30");
    const isIPSRReportPresent = isReportPresent("I-PSR");
    const isIBARReportPresent = isReportPresent("I-BAR");
    const isOPSRReportPresent = isReportPresent("O-PSR");
    const isOBARReportPresent = isReportPresent("O-BAR");

    const getContentMetaDataByReportType = (reportType) => {
        const report = getReport(reportType);
        return getContentMetaData(report);
    };

    const getContentMetaData = (report, isAccessible = true) => {
        const timestamp = report.hasOwnProperty("timestamp") && report.timestamp;

        return (
            <div className={"d-flex justify-content-between"}>
                <div>
                    <div className={"fw-bold"}>Created On</div>
                    <div>{moment.utc(timestamp).tz("America/New_York").format("MM/DD/YYYY")}</div>
                </div>
                {isAccessible && <img
                    src={images("./icons/download-icon-pri.svg")}
                    className={"cursor"}
                    alt="download"
                    onClick={async () => {
                        await viewReport(report);
                    }}
                />}
            </div>
        );
    };

    const getViewButtonByReportType = (reportType) => {
        const report = getReport(reportType);
        return getViewButton(report);
    };

    const getViewButton = (report) => {
        return (
            <img
                src={images("./common/show.svg")}
                className={"cursor view-report-icon"}
                alt="show"
                onClick={async () => {
                    let file_url = await getReportPresignedUrl(report);
                    setShowReportModal(file_url);
                }}
            />
        );
    };

    const getMultipleReports = (fileSubType) => {
        const reports = props?.data || [];
        return reports
            .filter((report) => report.file_sub_type === fileSubType)
            .sort((a, b) => moment.utc(b.timestamp) - moment.utc(a.timestamp));
    };

    const renderA5Report = (report, showMostRecentBadge = false, a5Type = "A5") => {
        const isAccessible = !(isUserPCP() && a5Type === "A5-R");
        const mostRecentBadge = showMostRecentBadge ? (
            <MemberProfileHeaderBadge
                text={"Most Recent"}
                type={memberProfileBadgeColorTypes.mostRecent}
            />
        ) : null;
        return (
            <>
                <MemberProfileAccordion
                    id={a5Type}
                    title={`${a5Type} Report`}
                    subTitle={mostRecentBadge}
                    metaData={report ? getContentMetaData(report, isAccessible) : null}
                    isEnabled={report !== null}
                    isAccessible={isAccessible}
                    customButton={report && isAccessible ? getViewButton(report) : <></>}></MemberProfileAccordion>
            </>
        );
    };

    const renderA5Reports = (a5Type = "A5") => {
        const a5Reports = getMultipleReports(a5Type);

        if (a5Reports.length === 0) {
            a5Reports.push(null);
        }

        return a5Reports.map((report, i) => {
            const showMostRecentBadge = i === 0 && a5Reports.length > 1;
            return renderA5Report(report, showMostRecentBadge, a5Type);
        });
    };


    return (
        <>
            {renderA5Reports("A5-R")}
            {renderA5Reports("A5")}
            <MemberProfileAccordion
                id={"A30"}
                title={"A30 Report"}
                metaData={isA30ReportPresent ? getContentMetaDataByReportType("A30") : null}
                isEnabled={isA30ReportPresent}
                customButton={
                    isA30ReportPresent ? getViewButtonByReportType("A30") : <></>
                }></MemberProfileAccordion>
            <MemberProfileAccordion
                id={"I-PSR"}
                title={"I-PSR Report"}
                metaData={isIPSRReportPresent ? getContentMetaDataByReportType("I-PSR") : null}
                isEnabled={isIPSRReportPresent}
                customButton={
                    isIPSRReportPresent ? getViewButtonByReportType("I-PSR") : <></>
                }></MemberProfileAccordion>

            <MemberProfileAccordion
                id={"I-BAR"}
                title={"I-BAR Report"}
                metaData={isIBARReportPresent ? getContentMetaDataByReportType("I-BAR") : null}
                isEnabled={isIBARReportPresent}
                customButton={
                    isIBARReportPresent ? getViewButtonByReportType("I-BAR") : <></>
                }></MemberProfileAccordion>

            <MemberProfileAccordion
                id={"O-PSR"}
                title={"O-PSR Report"}
                metaData={isOPSRReportPresent ? getContentMetaDataByReportType("O-PSR") : null}
                isEnabled={isOPSRReportPresent}
                customButton={
                    isOPSRReportPresent ? getViewButtonByReportType("O-PSR") : <></>
                }></MemberProfileAccordion>

            <MemberProfileAccordion
                id={"O-BAR"}
                title={"O-BAR Report"}
                metaData={isOBARReportPresent ? getContentMetaDataByReportType("O-BAR") : null}
                isEnabled={isOBARReportPresent}
                customButton={
                    isOBARReportPresent ? getViewButtonByReportType("O-BAR") : <></>
                }></MemberProfileAccordion>
            {showReportModal && (
                <ImageFullPreview
                    url={showReportModal}
                    close={() => setShowReportModal(false)}
                    fileType="pdf"
                    wideView={true}
                />
            )}
        </>
    );
};
