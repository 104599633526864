import moment from "moment/moment";
import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { EllipsisVerticalIcon } from "../../../../../component-library";
import { Modal } from "../../../../../component-library/Modal";
import { Text } from "../../../../../component-library/Text";
import { TextColors, TextTypes } from "../../../../../component-library/constants";
import { ICON_SIZES, ICON_TYPES } from "../../../../../constants/icons";
import {
    useGoalAndObjectiveText,
    usePatientGoalAndObjectives,
} from "../../../../../utils/hooks/useCarePlan";
import CloseOnOutsideClick from "../../../../Common/CloseOnOutsideClick/CloseOnOutsideClick";
import { CloseIcon } from "../../../../Icons/CloseIcon";
import { CarePlanCard } from "../CarePlanCard";
import {
    AddGoalForm,
    AddObjectiveForm,
    CompleteObjectiveForm,
    EditObjectiveForm,
    RemoveObjectiveForm,
} from "./CareGoalsForms";
import { OTHER_TEXT } from "./CareGoalsValidation";
import "./styles.scss";
import { EXPERIMENTAL_MODALITY_FREQUENCY_DURATION } from "../../../../../constants/featureFlags";
import FeatureGate from "../../../../Common/FeatureGate/FeatureGate";

const ALLOWED_ROLES = ["Behavioral Specialist", "Prescriber", "Case Manager", "Peer"];
const MODAL_MODES = {
    ADD_GOAL: "addGoal",
    ADD_OBJECTIVE: "addObjective",
    EDIT_OBJECTIVE: "editObjective",
    COMPLETE_OBJECTIVE: "completeObjective",
    REMOVE_OBJECTIVE: "removeObjective",
    CLOSE_AND_ADD_OBJECTIVE: "closeAndAddObjective",
    EMPTY: "empty",
};

const filterActiveGoals = (goals, goalOptions) => {
    const activeGoalTextIds = [];
    goals.forEach((goal) => {
        activeGoalTextIds.push(goal.goal_text_id);
    });

    let result = [];
    goalOptions.forEach((goalOption) => {
        if (goalOption.label === OTHER_TEXT || !activeGoalTextIds.includes(goalOption.value)) {
            result.push(goalOption);
        }
    });

    return result;
};

const getUniqueCurrentAndPastCareTeamRoles = (careTeam) => {
    const roles = new Set();

    careTeam.forEach((obj) => {
        if (ALLOWED_ROLES.includes(obj.type)) {
            roles.add(obj.type);
        }
    });

    return Array.from(roles);
};

const getCurrentCareTeamMembersByType = (careTeam) => {
    const membersByRole = new Object();

    ALLOWED_ROLES.forEach((role) => {
        membersByRole[role] = [];
    });

    careTeam.forEach((member) => {
        if (ALLOWED_ROLES.includes(member.type) && member.is_deleted === false) {
            membersByRole[member.type].push(member);
        }
    });

    return membersByRole;
};

const flattenObjectives = (goalsAndObjectives) => {
    const outer = {};

    goalsAndObjectives.forEach((goal) => {
        const inner = [];

        goal.objectives.forEach((objective) => {
            inner.push({
                label: objective.objective_text,
                value: objective.objective_text_id,
            });
        });

        outer[goal.goal_text_id] = inner;
    });

    return outer;
};

const flattenGoals = (goals) => {
    const result = [];

    goals.forEach((goal) => {
        result.push({
            label: goal.goal_text,
            value: goal.goal_text_id,
        });
    });

    return result;
};

const getGoalsMap = (goals) => {
    const result = {};

    goals.forEach((goal) => {
        result[goal.goal_text_id] = goal.goal_text;
    });

    return result;
};

const getObjectivesMap = (goalsAndObjectives) => {
    const outer = {};

    goalsAndObjectives.forEach((goal) => {
        const inner = {};

        goal.objectives.forEach((objective) => {
            inner[objective.objective_text_id] = objective.objective_text;
        });

        outer[goal.goal_text_id] = inner;
    });

    return outer;
};

const EmptyGoalsDisplay = () => {
    return (
        <div className={"CarePlanScreenings--empty-container"}>
            <div className={"CarePlanScreenings--empty"}>
                <p className={"CarePlanScreenings--empty-text"}>No goals or objectives.</p>
            </div>
        </div>
    );
};

const NonEmptyGoalsDisplay = (props) => {
    const { currentGoalsAndObjectives, isActiveProvider } = props;
    if (!currentGoalsAndObjectives) {
        return <></>;
    }

    return (
        <div className={"CareGoalsCard--non-empty-goals-display"}>
            {currentGoalsAndObjectives.map((goal) => {
                return (
                    <ActiveGoal
                        key={`ActiveGoal-${goal.goal_id}`}
                        isActiveProvider={isActiveProvider}
                        goal={goal}
                        onAddObjectiveClick={props.onAddObjectiveClick}
                        onEditObjectiveClick={props.onEditObjectiveClick}
                        onCompleteObjectiveClick={props.onCompleteObjectiveClick}
                        onRemoveObjectiveClick={props.onRemoveObjectiveClick}
                    />
                );
            })}
        </div>
    );
};

const ActiveGoal = (props) => {
    const { goal, isActiveProvider } = props;
    const objectives = goal.objectives || [];

    const goalText = goal?.goal_other_text || goal.goal_text;

    return (
        <div key={goal.goal_id} className={"CareGoalsCard--active-goal"}>
            <div className={"CareGoalsCard--active-goal-text"}>
                <Text type={TextTypes.label}>{goalText}</Text>
            </div>
            <div className={"CareGoalsCard--objective-list"}>
                <div className={"CareGoalsCard--objective-list-header"}>
                    <div className={"CareGoalsCard--objective-list-text-col"}>
                        <Text type={TextTypes.light}>Objective</Text>
                    </div>
                    <div className={"CareGoalsCard--objective-list-meta-data-cols"}>
                        <div className={"CareGoalsCard--objective-list-start-date-col"}>
                            <Text type={TextTypes.light}>Start Date</Text>
                        </div>
                        <div className={"CareGoalsCard--objective-list-target-date-col"}>
                            <Text type={TextTypes.light}>Target Date</Text>
                        </div>
                        <div className={"CareGoalsCard--objective-list-more-col"}></div>
                    </div>
                </div>
                {objectives.map((objective) => {
                    return (
                        <ActiveObjective
                            isActiveProvider={isActiveProvider}
                            objective={objective}
                            goal={goal}
                            key={`ActiveObjective-${goal.goal_id}.${objective.objective_id}`}
                            onEditObjectiveClick={props.onEditObjectiveClick}
                            onCompleteObjectiveClick={props.onCompleteObjectiveClick}
                            onRemoveObjectiveClick={props.onRemoveObjectiveClick}
                        />
                    );
                })}
            </div>
            {isActiveProvider && objectives.length < 3 && (
                <div className={"d-flex"}>
                    <button
                        onClick={() => props.onAddObjectiveClick(goal)}
                        className="CarePlanIconButton--icon_button CarePlanIconButton--add-objective">
                        <p className="apti-LinkV2 CareGoalsCard--add_goal_text">Add Objective</p>
                    </button>
                    <p className="CareGoalsCard--add_goal_subtext pl-2">(Max 3)</p>
                </div>
            )}
        </div>
    );
};

const ActiveObjective = (props) => {
    const { objective, goal, isActiveProvider } = props;
    const isPastTargetDate = moment(objective.objective_target_timestamp).isBefore(moment());
    const [showObjectiveMoreMenu, setShowObjectiveMoreMenu] = useState(false);

    const objectiveText = objective?.objective_other_text || objective.objective_text;

    return (
        <div className={"CareGoalsCard--objective-list-item"}>
            <div className={"CareGoalsCard--objective-list-text-col"}>
                <Text>{objectiveText}</Text>
            </div>
            <div className={"CareGoalsCard--objective-list-meta-data-cols"}>
                <div className={"CareGoalsCard--objective-list-start-date-col"}>
                    <Text>{moment(objective.objective_start_timestamp).format("MM/DD/YY")}</Text>
                </div>
                <div className={"CareGoalsCard--objective-list-target-date-col"}>
                    <Text color={isPastTargetDate ? TextColors.yellow : TextColors.black}>
                        {moment(objective.objective_target_timestamp).format("MM/DD/YY")}
                    </Text>
                </div>
                <div className={"CareGoalsCard--objective-list-more-col"}>
                    <button
                        className="CarePlanIconButton--icon_button CarePlanIconButton--more"
                        onClick={() => setShowObjectiveMoreMenu(true)}
                        disabled={!isActiveProvider}>
                        <EllipsisVerticalIcon
                            iconType={ICON_TYPES.FILLED}
                            iconSize={ICON_SIZES.SMALL}
                            className={
                                isActiveProvider
                                    ? "CareGoalsCard--more-icon"
                                    : "CareGoalsCard--more-icon-disabled"
                            }
                        />
                    </button>
                    {showObjectiveMoreMenu && (
                        <ActiveObjectiveMoreMenu
                            objective={objective}
                            goal={goal}
                            onCloseHandler={() => setShowObjectiveMoreMenu(false)}
                            onEditObjectiveClick={props.onEditObjectiveClick}
                            onCompleteObjectiveClick={props.onCompleteObjectiveClick}
                            onRemoveObjectiveClick={props.onRemoveObjectiveClick}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

const ActiveObjectiveMoreMenu = (props) => {
    const { onCloseHandler, objective, goal } = props;

    return (
        <CloseOnOutsideClick setShowComponent={onCloseHandler}>
            <div className={"CareGoalsCard--more-menu"}>
                <button
                    onClick={() => {
                        props.onEditObjectiveClick(goal, objective);
                        onCloseHandler();
                    }}
                    className={"CareGoalsCard--more-menu-item CareGoalsCard--more-menu-item-edit"}>
                    <Text>Edit Target Date</Text>
                </button>
                <button
                    onClick={() => {
                        props.onCompleteObjectiveClick(goal, objective);
                        onCloseHandler();
                    }}
                    className={
                        "CareGoalsCard--more-menu-item CareGoalsCard--more-menu-item-complete"
                    }>
                    <Text>Achieve Objective</Text>
                </button>
                <button
                    onClick={() => {
                        props.onRemoveObjectiveClick(goal, objective);
                        onCloseHandler();
                    }}
                    className={
                        "CareGoalsCard--more-menu-item CareGoalsCard--more-menu-item-remove"
                    }>
                    <Text>Remove Objective</Text>
                </button>
            </div>
        </CloseOnOutsideClick>
    );
};

const CareGoalModal = (props) => {
    const filteredProviderGoalOptions = filterActiveGoals(
        props.providerTypeCurrentGoalsAndObjectives || [],
        props.providerGoalOptions || [],
    );

    const isNestedFlow = ["closeAndAddObjective"].includes(props.modalMode);

    return (
        <Modal
            className="CareGoalsCard--modal"
            show={props.show}
            cardClassName="CareGoalsCard--modal_dialog">
            {!isNestedFlow && (
                <CloseIcon
                    onClick={props.onClose}
                    className={"CareGoalsCard--modal_close_icon"}
                    iconSize={ICON_SIZES.SMALL}
                    iconType={ICON_TYPES.OUTLINE}
                />
            )}

            {props.modalMode === MODAL_MODES.ADD_GOAL && (
                <AddGoalForm
                    // action handlers
                    onClose={props.onClose}
                    onModalSubmission={props.onModalSubmission}
                    // state
                    patientDetails={props.patientDetails}
                    providerLabel={props.providerLabel}
                    // dropdown options
                    providerGoalOptions={filteredProviderGoalOptions}
                    providerGoalOptionsMap={props.providerGoalOptionsMap}
                    providerObjectiveOptions={props.providerObjectiveOptions}
                    providerObjectiveOptionsMap={props.providerObjectiveOptionsMap}
                />
            )}
            {props.modalMode === MODAL_MODES.ADD_OBJECTIVE && (
                <AddObjectiveForm
                    // action handlers
                    onClose={props.onClose}
                    onModalSubmission={props.onModalSubmission}
                    // state
                    goal={props.goal}
                    objective={null}
                    objectiveCloseMode={null}
                    patientDetails={props.patientDetails}
                    // dropdown options
                    providerObjectiveOptions={props.providerObjectiveOptions}
                    providerObjectiveOptionsMap={props.providerObjectiveOptionsMap}
                />
            )}
            {props.modalMode === MODAL_MODES.EDIT_OBJECTIVE && (
                <EditObjectiveForm
                    // action handlers
                    onClose={props.onClose}
                    onModalSubmission={props.onModalSubmission}
                    // state
                    goal={props.goal}
                    objective={props.objective}
                    patientDetails={props.patientDetails}
                />
            )}
            {props.modalMode === MODAL_MODES.COMPLETE_OBJECTIVE && (
                <CompleteObjectiveForm
                    // action handlers
                    onClose={props.onClose}
                    onModalSubmission={props.onModalSubmission}
                    onCloseAndAddObjective={props.onCloseAndAddObjective}
                    // state
                    goal={props.goal}
                    objective={props.objective}
                    patientDetails={props.patientDetails}
                />
            )}
            {props.modalMode === MODAL_MODES.REMOVE_OBJECTIVE && (
                <RemoveObjectiveForm
                    // action handlers
                    onClose={props.onClose}
                    onModalSubmission={props.onModalSubmission}
                    onCloseAndAddObjective={props.onCloseAndAddObjective}
                    // state
                    goal={props.goal}
                    objective={props.objective}
                    patientDetails={props.patientDetails}
                />
            )}
            {props.modalMode === MODAL_MODES.CLOSE_AND_ADD_OBJECTIVE && (
                <AddObjectiveForm
                    // action handlers
                    onClose={props.onClose}
                    onModalSubmission={props.onModalSubmission}
                    onCompleteObjectiveClick={props.onCompleteObjectiveClick}
                    onRemoveObjectiveClick={props.onRemoveObjectiveClick}
                    // state
                    objectiveCloseMode={props.objectiveCloseMode}
                    goal={props.goal}
                    objective={props.objective}
                    patientDetails={props.patientDetails}
                    // dropdown options
                    providerObjectiveOptions={props.providerObjectiveOptions}
                    providerObjectiveOptionsMap={props.providerObjectiveOptionsMap}
                />
            )}
        </Modal>
    );
};

const CareGoalRoleEntry = (props) => {
    const privateFlags = useSelector((state) => state.flags.privateFlags);
    const privateFlagsHaveLoaded = useSelector((state) => state.flags.privateFlagsHaveLoaded);
    const label = props.label;
    const providerType = props?.providerType;
    const providerTypeCurrentGoalsAndObjectives =
        props?.providerTypeCurrentGoalsAndObjectives || [];

    const isActiveProvider = providerType && providerType === props.role;
    const careTeamMembersForRole = props.careTeamMembersForRole;

    return (
        <div className="CareGoalsCard--role_entry">
            <div className="CareGoalsCard--role_entry_header">
                <div className="d-flex">
                    <Text type={TextTypes.label} color={TextColors.purple}>
                        {label}
                    </Text>

                    {isActiveProvider && providerTypeCurrentGoalsAndObjectives.length < 3 && (
                        <div className={"d-flex ml-auto"}>
                            <button
                                onClick={() => props.onAddGoalClick()}
                                className="CarePlanIconButton--icon_button">
                                <p className="apti-LinkV2 CareGoalsCard--add_goal_text">Add Goal</p>
                            </button>
                            <p className="CareGoalsCard--add_goal_subtext pl-2">(Max 3)</p>
                        </div>
                    )}
                </div>

                <FeatureGate
                    defaultFlagBehavior={true}
                    flagName={EXPERIMENTAL_MODALITY_FREQUENCY_DURATION}
                    enabled={privateFlags?.[EXPERIMENTAL_MODALITY_FREQUENCY_DURATION]}
                    hasLoaded={privateFlagsHaveLoaded}
                    alternatedFlagDisabledBehavior={<></>}>
                    <div>
                        {careTeamMembersForRole.map((member) => {
                            if (!member?.modality) {
                                return;
                            }

                            return (
                                <div
                                    className="CareGoalsCard--role_entry_modality"
                                    key={member.username}>
                                    <Text type={TextTypes.light} className={"text-break"}>
                                        {member?.modality_other || member.modality}
                                    </Text>
                                    <Text type={TextTypes.light}> • </Text>
                                    <Text type={TextTypes.light} className="d-inline">
                                        {member.frequency}
                                    </Text>
                                    {member?.default_appointment_type && (
                                        <>
                                            <Text type={TextTypes.light}> • </Text>
                                            <Text type={TextTypes.light} className="d-inline">
                                                {member.default_appointment_time} Min
                                            </Text>
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </FeatureGate>
            </div>

            {!providerTypeCurrentGoalsAndObjectives.length ? (
                <EmptyGoalsDisplay />
            ) : (
                <NonEmptyGoalsDisplay
                    isActiveProvider={isActiveProvider}
                    currentGoalsAndObjectives={providerTypeCurrentGoalsAndObjectives}
                    onAddObjectiveClick={props.onAddObjectiveClick}
                    onEditObjectiveClick={props.onEditObjectiveClick}
                    onCompleteObjectiveClick={props.onCompleteObjectiveClick}
                    onRemoveObjectiveClick={props.onRemoveObjectiveClick}
                />
            )}
        </div>
    );
};

const CareGoalsCard = (props) => {
    const providerTypeToLabelMap = {
        BEHAVIORAL: "Behavioral Specialist",
        PRESCRIBE: "Prescriber",
        CASE_MANAGER: "Case Manager",
        PEER: "Peer",
    };
    const { goalAndObjectives, refreshState } = usePatientGoalAndObjectives({
        patientId: props.patientId,
    });
    const goalsAndObjectiveOptions = useGoalAndObjectiveText({ patientId: props.patientId });

    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState("addGoal");
    const [selectedGoal, setSelectedGoal] = useState(null);
    const [selectedObjective, setSelectedObjective] = useState(null);
    const [objectiveCloseMode, setObjectiveCloseMode] = useState(null);

    const providerType = props.provider?.provider_type;
    const providerLabel = providerTypeToLabelMap?.[providerType] || "";
    const providerGoalsAndObjectiveOptions = goalsAndObjectiveOptions?.[providerType] || [];
    const rolesToShow = getUniqueCurrentAndPastCareTeamRoles(props.careTeamMembers);
    const careTeamMembersByRole = getCurrentCareTeamMembersByType(props.careTeamMembers);

    const providerGoalOptions = flattenGoals(providerGoalsAndObjectiveOptions);
    const providerObjectiveOptions = flattenObjectives(providerGoalsAndObjectiveOptions);

    const providerGoalOptionsMap = getGoalsMap(providerGoalsAndObjectiveOptions);
    const providerObjectiveOptionsMap = getObjectivesMap(providerGoalsAndObjectiveOptions);

    const onCloseModal = () => {
        setShowModal(false);
        setModalMode(MODAL_MODES.EMPTY);
        setSelectedGoal(null);
        setSelectedObjective(null);
        setObjectiveCloseMode(null);
    };

    const onModalSubmission = async () => {
        await refreshState({ showLoader: false });
        onCloseModal();
    };

    const openAddGoalModal = () => {
        setModalMode(MODAL_MODES.ADD_GOAL);
        setShowModal(true);
    };

    const openAddObjectiveModal = (goal) => {
        setModalMode(MODAL_MODES.ADD_OBJECTIVE);
        setSelectedGoal(goal);
        setShowModal(true);
    };

    const openEditObjectiveModal = (goal, objective) => {
        setModalMode(MODAL_MODES.EDIT_OBJECTIVE);
        setSelectedGoal(goal);
        setSelectedObjective(objective);
        setShowModal(true);
    };

    const openCompleteObjectiveModal = (goal, objective) => {
        setModalMode(MODAL_MODES.COMPLETE_OBJECTIVE);
        setSelectedGoal(goal);
        setSelectedObjective(objective);
        setShowModal(true);
    };

    const openRemoveObjectiveModal = (goal, objective) => {
        setModalMode(MODAL_MODES.REMOVE_OBJECTIVE);
        setSelectedGoal(goal);
        setSelectedObjective(objective);
        setShowModal(true);
    };

    const openCloseAndAddObjectiveModal = (goal, objective, newObjectiveStatus) => {
        setModalMode(MODAL_MODES.CLOSE_AND_ADD_OBJECTIVE);
        setSelectedObjective(objective);
        setSelectedGoal(goal);
        setObjectiveCloseMode(newObjectiveStatus);
        setShowModal(true);
    };

    // Only show card if there are providers to show
    if (rolesToShow.length === 0) {
        return null;
    }

    return (
        <CarePlanCard title="Care Goals & Objectives" className="CareGoalsCard--container">
            <div className="CareGoalsCard--role_container">
                <CareGoalModal
                    // state
                    show={showModal}
                    modalMode={modalMode}
                    providerLabel={providerLabel}
                    patientDetails={props.patientDetails}
                    goal={selectedGoal}
                    objective={selectedObjective}
                    objectiveCloseMode={objectiveCloseMode}
                    providerTypeCurrentGoalsAndObjectives={goalAndObjectives[providerType]}
                    // dropdown options
                    providerGoalOptions={providerGoalOptions}
                    providerGoalOptionsMap={providerGoalOptionsMap}
                    providerObjectiveOptions={providerObjectiveOptions}
                    providerObjectiveOptionsMap={providerObjectiveOptionsMap}
                    // action handlers
                    onClose={onCloseModal}
                    onCloseAndAddObjective={openCloseAndAddObjectiveModal}
                    onCompleteObjectiveClick={openCompleteObjectiveModal}
                    onRemoveObjectiveClick={openRemoveObjectiveModal}
                    onModalSubmission={onModalSubmission}
                />

                {/* Map out the role entries */}
                {Object.keys(providerTypeToLabelMap).map((key) => {
                    // Only show a role if currently or previously on the care team
                    if (!rolesToShow.includes(providerTypeToLabelMap[key])) {
                        return null;
                    }

                    const roleEntryProps = {
                        role: key,
                        providerType: providerType,
                        label: providerTypeToLabelMap[key],
                        onAddGoalClick: openAddGoalModal,
                        onAddObjectiveClick: openAddObjectiveModal,
                        onEditObjectiveClick: openEditObjectiveModal,
                        onCompleteObjectiveClick: openCompleteObjectiveModal,
                        onRemoveObjectiveClick: openRemoveObjectiveModal,
                        providerTypeCurrentGoalsAndObjectives: goalAndObjectives[key],
                        careTeamMembersForRole:
                            careTeamMembersByRole?.[providerTypeToLabelMap[key]],
                    };
                    return <CareGoalRoleEntry key={key} {...roleEntryProps} />;
                })}
            </div>
        </CarePlanCard>
    );
};

const mapStateToProps = (state) => {
    return {
        provider: state.auth.profile,
    };
};

export default connect(mapStateToProps, null)(CareGoalsCard);
