// Copyright aptihealth, inc. 2019 All Rights Reserved

import axios from "axios";
import store from "../redux/store";
import { logOut, refreshAccessToken } from "../redux/actions/auth";
import { hideLoader, showLoader } from "../redux/actions/loader";
import { showAlert } from "../redux/actions/alerts";
import { TOAST_ERROR } from "../components/UI/Alert";
import { api } from "../APIRequests";
import { addToast } from "../redux/actions/toaster";

const BASE_URL = process.env.REACT_APP_BASE_URL;

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

/**
 * This "http" axios instance send request without authentication token
 */
export const http = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
    },
});

http.interceptors.request.use((request) => {
    if (!(request.params && request.params.showLoader === false)) {
        store.dispatch(showLoader());
    }
    return request;
});

http.interceptors.response.use(
    (response) => {
        store.dispatch(hideLoader());
        return response.data.data;
    },
    (error) => {
        store.dispatch(hideLoader());
        let cleanError = (error.response && error.response.data.error) || error;
        return Promise.reject(cleanError);
    },
);

/**
 * This "httpAuth" axios instance send request with authentication token
 */
export const httpAuth = axios.create({
    baseURL: BASE_URL,
});

httpAuth.interceptors.request.use((request) => {
    if (!(request.params && request.params.showLoader === false)) {
        store.dispatch(showLoader());
    }
    return requestHandler(request);
});

httpAuth.interceptors.response.use(
    (response) => {
        const state = store.getState();
        if (state.loader.isLoading && !state.loader.isMultiPartRequest) {
            store.dispatch(hideLoader());
        }
        return response.data.data;
    },
    (error) => {
        store.dispatch(hideLoader());
        return retryError(error);
    },
);

/**
 * This "httpBackground" axios instance send request with authentication token in the background
 */
export const httpBackground = axios.create({
    baseURL: BASE_URL,
});

httpBackground.interceptors.request.use((request) => requestHandler(request));

httpBackground.interceptors.response.use(
    (response) => response.data.data,
    (error) => retryError(error),
);

const requestHandler = (request) => {
    let accessToken =
        (request.params && request.params.accessToken) || window.localStorage.getItem("token");
    delete request["params"];

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    request.cancelToken = source.token;

    if (!accessToken) {
        console.log("Missing token, logging out.");

        // Doesn't abort immediately, this rejects the request after this function completes.
        source.cancel("Canceling request: missing token.");

        store.dispatch(logOut());
    }

    if (isHandlerEnabled(request)) {
        // Modify request here
        request.headers["Authorization"] = accessToken;
        request.headers["Accept"] = "application/json";
        request.headers["Content-Type"] = "application/json";
    }
    return request;
};

const isHandlerEnabled = (config = {}) => {
    return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled ? false : true;
};

const onAccessTokenFetched = (access_token) => {
    subscribers = subscribers.filter((callback) => callback(access_token));
};

const addSubscriber = (callback) => {
    subscribers.push(callback);
};

/**
 * This "httpWorkflowAuth" axios instance send request with authentication token from global variables
 */
export const httpWorkflowAuth = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

httpWorkflowAuth.interceptors.request.use((request) => {
    if (!(request.params && request.params.showLoader === false)) {
        store.dispatch(showLoader());
    }
    request.headers["Authorization"] = global.workflowAuthIdToken;
    return request;
});

httpWorkflowAuth.interceptors.response.use(
    (response) => {
        const state = store.getState();
        if (state.loader.isLoading && !state.loader.isMultiPartRequest) {
            store.dispatch(hideLoader());
        }

        return response.data.data;
    },
    (error) => {
        store.dispatch(hideLoader());
        return retryError(error, httpWorkflowAuth, refreshGlobalTokens);
    },
);

const retryError = (error, interceptor = httpAuth, refreshFunc = refreshLocalStorageTokens) => {
    const cleanError = (error.response && error.response.data.error) || error;
    const config = error["config"] || {};
    const response = error["response"] || {};
    const status = response["status"] || null;
    const originalRequest = config;

    if (status) {
        if (status === 403) {
            if (!isAlreadyFetchingAccessToken) {
                isAlreadyFetchingAccessToken = true;
                refreshFunc();
            }

            return new Promise((resolve) => {
                addSubscriber((access_token) => {
                    originalRequest.headers.Authorization = access_token;
                    resolve(interceptor(originalRequest));
                });
            });
        }

        if (isNonAuthFailure(status)) {
            handleNonAuthFailure(cleanError, status, config);
        }
    }

    return Promise.reject(cleanError);
};

const isNonAuthFailure = (status) => {
    return status !== 401 || status !== 403;
};

const handleNonAuthFailure = (cleanError, status, config) => {
    const {
        conditionalExceptionReporting,
        errorTypeConfig,
        toastErrorType: defaultToastErrorType,
    } = config;
    const { message, request_id: requestId, type } = cleanError;
    const configForErrorType = errorTypeConfig?.[type];
    const toastErrorType = configForErrorType?.toastErrorType || defaultToastErrorType;

    if (toastErrorType) {
        store.dispatch(
            addToast({
                message,
                messageType: toastErrorType,
            }),
        );
    } else {
        // Used to conditionally show exception reporting, i.e., "Report" button and request ID
        // inside the toast, based on status code. Show for 5XX codes, hide otherwise.
        const optionalRequestId = conditionalExceptionReporting
            ? status >= 500
                ? requestId
                : null
            : requestId;
        showAlert(message, store.dispatch, TOAST_ERROR, optionalRequestId);
    }

    if (configForErrorType?.errorCallback) {
        configForErrorType.errorCallback(cleanError);
    }
};

const refreshLocalStorageTokens = () => {
    store.dispatch(showLoader());
    store
        .dispatch(refreshAccessToken())
        .then((newIdAccessToken) => {
            isAlreadyFetchingAccessToken = false;
            store.dispatch(hideLoader());
            onAccessTokenFetched(newIdAccessToken);
        })
        .catch(() => {
            store.dispatch(hideLoader());
            isAlreadyFetchingAccessToken = false;
        });
};

const refreshGlobalTokens = () => {
    const data = {
        refresh_token: global.workflowAuthRefreshToken,
        id_token: global.workflowAuthIdToken,
    };
    api.auth
        .new_id_token({ data })
        .then((cleanResponse) => {
            global.workflowAuthIdToken = cleanResponse.AuthenticationResult.IdToken;
            global.workflowAuthAccessToken = cleanResponse.AuthenticationResult.AccessToken;
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(global.workflowAuthIdToken);
        })
        .catch((err) => {
            isAlreadyFetchingAccessToken = false;
            global.workflowAuthIdToken = null;
            global.workflowAuthAccessToken = null;
            global.workflowAuthRefreshToken = null;
        });
};
