import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";

import "./styles.scss";

export const Text = ({ children, className, type, color, layout, ...props }) => (
    <span
        className={createClassNameString(["apti-CL apti-Text", className, type, color, layout])}
        {...props}>
        {children}
    </span>
);

Text.propTypes = {
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
    type: PropTypes.string,
    color: PropTypes.string,
    layout: PropTypes.oneOf(["block", "inline"]),
};

Text.defaultProps = {
    children: "",
    className: undefined,
    type: undefined,
    color: undefined,
    layout: "inline",
};
