import React, { Component } from "react";
import { api } from "../APIRequests";
import images from "../utils/images";
import { Button } from "../component-library";
import { SourceMapConsumer } from "source-map";

export class ErrorBoundaries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    getMainBundleURL() {
        const scriptTags = Array.from(document.getElementsByTagName("script"));
        const mainBundleTag = scriptTags.find((tag) => tag.src.includes("/static/js/main"));

        return mainBundleTag ? mainBundleTag.src : null;
    }

    async findSourceMappingURL(jsFileURL) {
        try {
            // Fetch the JavaScript file content
            const response = await fetch(jsFileURL);
            const fileContent = await response.text();

            // Check for sourceMappingURL in the content
            const sourceMappingUrlMatch = fileContent.match(/\/\/# sourceMappingURL=(.*)$/m);

            if (sourceMappingUrlMatch) {
                const sourceMappingUrl = sourceMappingUrlMatch[1];

                // If sourceMappingUrl is an inline source map (base64-encoded), return it directly
                if (sourceMappingUrl.startsWith("data:")) {
                    return sourceMappingUrl;
                }

                // If sourceMappingUrl is a relative path, resolve it to an absolute URL
                const baseURL = new URL(jsFileURL);
                return new URL(sourceMappingUrl, baseURL).toString();
            }

            throw new Error("sourceMappingURL not found in the JavaScript file");
        } catch (error) {
            console.error("Error finding sourceMappingURL:", error);
            return null;
        }
    }

    async fetchWASM(url) {
        const response = await fetch(url);
        const buffer = await response.arrayBuffer();
        return buffer;
    }

    async originalStackTrace(stackTrace, sourceMappingURL) {
        // Fetch the source map
        const response = await fetch(sourceMappingURL);

        const sourceMapData = await response.json();

        // // Fetch and decode the WASM file
        const wasmURL = process.env.PUBLIC_URL + "/mappings.wasm";
        const wasmBuffer = await this.fetchWASM(wasmURL);

        // Initialize the SourceMapConsumer with the WASM file
        await SourceMapConsumer.initialize({ "lib/mappings.wasm": wasmBuffer });

        // Create a SourceMapConsumer instance
        const consumer = await new SourceMapConsumer(sourceMapData);

        // Parse the input stack trace
        const stackTraceLines = stackTrace.split("\n");

        // Map the minified stack trace to the original source code stack trace
        const originalStack = stackTraceLines.map((line) => {
            const match = line.match(/(.*?\sat\s|)(.+?):(\d+):(\d+)/);

            if (match) {
                const [, prefix, url, line, column] = match;
                const pos = consumer.originalPositionFor({
                    line: parseInt(line),
                    column: parseInt(column),
                });

                if (pos.source) {
                    return `${prefix}${pos.source}:${pos.line}:${pos.column}`;
                }
            }
            return line;
        });

        // Dispose the consumer to free up memory
        consumer.destroy();

        // Return the original stack trace
        return originalStack.join("\n");
    }

    async componentDidCatch(error, errorInfo) {
        console.log(`caught error: ${error}`);
        try {
            this.setState({ hasError: true });

            const jsFileURL = this.getMainBundleURL();

            const sourceMappingURL = await this.findSourceMappingURL(jsFileURL);

            const sourceStackTrace = await this.originalStackTrace(error.stack, sourceMappingURL);

            let report = {
                type: "frontend_error",
                description: `${error.name} ${error.message}`,
                context: {
                    error_name: error.name,
                    error_message: error.message,
                    error_stack: sourceStackTrace
                        .replaceAll("http://", "")
                        .replaceAll("https://", "")
                        .replaceAll("localhost:3000", ""),
                    original_error_stack: error.stack
                        .replaceAll("http://", "")
                        .replaceAll("https://", "")
                        .replaceAll("localhost:3000", ""),
                    error_component_stack: errorInfo.componentStack,
                },
            };
            await api.shared.post_exception_report({ report });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        if (this.state?.hasError) {
            return (
                <div className={"d-flex justify-content-center text-center"}>
                    <div className={"my-5 mx-2"} style={{ maxWidth: 450 }}>
                        <p className={"fw-bold fs-26 my-3"}>The website experienced a problem.</p>
                        <p className={"fs-18"}>
                            Please refresh the page. If the problem persists, please try again
                            later. We apologize for the inconvenience.
                        </p>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}
