/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const TourIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Tour"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 4C11.3431 4 10 5.34315 10 7V25C10 26.6569 11.3431 28 13 28H19C20.6569 28 22 26.6569 22 25V7C22 5.34315 20.6569 4 19 4H13ZM19 6H13C12.4477 6 12 6.44772 12 7V25C12 25.5523 12.4477 26 13 26H19C19.5523 26 20 25.5523 20 25V7C20 6.44772 19.5523 6 19 6Z"
                />
                <path d="M25 9H24V7H25C26.6569 7 28 8.34315 28 10V22C28 23.6569 26.6569 25 25 25H24V23H25C25.5523 23 26 22.5523 26 22V10C26 9.44772 25.5523 9 25 9Z" />
                <path d="M8 9H7C6.44772 9 6 9.44772 6 10V22C6 22.5523 6.44772 23 7 23H8V25H7C5.34315 25 4 23.6569 4 22V10C4 8.34315 5.34315 7 7 7H8V9Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Tour"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 4C11.3431 4 10 5.34315 10 7V25C10 26.6569 11.3431 28 13 28H19C20.6569 28 22 26.6569 22 25V7C22 5.34315 20.6569 4 19 4H13Z"
                />
                <path d="M24 7H25C26.6569 7 28 8.34315 28 10V22C28 23.6569 26.6569 25 25 25H24C24 19.8233 24 13.5048 24 7Z" />
                <path d="M8 25H7C5.34315 25 4 23.6569 4 22V10C4 8.34315 5.34315 7 7 7H8C8 11.7113 8 18.1546 8 25Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Tour"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM17.75 11C16.5074 11 15.5 12.0074 15.5 13.25V26.75C15.5 27.9926 16.5074 29 17.75 29H22.25C23.4926 29 24.5 27.9926 24.5 26.75V13.25C24.5 12.0074 23.4926 11 22.25 11H17.75ZM26 13.25H26.75C27.9926 13.25 29 14.2574 29 15.5V24.5C29 25.7426 27.9926 26.75 26.75 26.75H26V13.25ZM13.25 26.75H14V13.25H13.25C12.0074 13.25 11 14.2574 11 15.5V24.5C11 25.7426 12.0074 26.75 13.25 26.75Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

TourIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

TourIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
