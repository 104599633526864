// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { useEffect, useState } from "react";
import withRouter from "react-router-dom/es/withRouter";
import {
    renderBehavioralData,
    renderICD10,
    renderPrescribeData,
    renderSessionMetaData,
} from "../../../../containers/Dashboard/Providers/PatientProfileView/ViewNote";
import CardSecondary from "../../../UI/Card/CardSecondary";
import images from "../../../../utils/images";
import { BehavioralGoals } from "./MemberProfileNotesBehavioralGoals";
import { BehavioralSymptomTrend } from "./MemberProfileNotesSymptomTrend";
import { GenericSectionNotes } from "./MemberProfileNotesGenericNotes";
import { GenericMultiselect } from "./MemberProfileNotesGenericMultiselect";
import { BehavioralMentalStatus } from "./MemberProfileNotesMentalStatus";
import { NotesDiagnosis } from "./MemberProfileNotesDiagnosis";
import {
    CHECK_IN_SCHEMA,
    getCallFocusCheckboxes,
    getPatientOutcomeRatings,
    RATING_OPTION_LABEL_MAP,
} from "./CheckInPopUp";
import Label from "../../../UI/Label";
import Select from "react-select";
import moment from "moment";
import SearchBar from "../../../UI/SearchBar";
import { CARE_COORDINATION_SCHEMA } from "./schema/CareCoordinationSchema";
import _, { get } from "lodash";
import { eventTypeDisplayNameFactory } from "../../ScheduleAvailability/ActiveScheduleDetails";
import { connect, useSelector } from "react-redux";
import NotesDuration from "./MemberProfileNotesDuration";
import { PrescriberMedicationsV2 } from "./MemberProfileNotesMedications";
import { PrescribeDiagnosisProgress } from "./MemberProfileNotesPrescribeDiagnosisProgress";
import {
    ExpandingSelect,
    IndividualSymptomTrend,
} from "../../../Common/GuidedVideoCall/Steps/ProviderWorkflow/DisplayWorkflowStepWithVideoClient";
import { PrescribeLabFindings } from "./MemberProfileNotesLabFindings";
import { isAuthorized } from "../../../../redux/actions/auth";
import DischargeContent from "./DischargeContent";
import { admin_cse, provider_case_manager, provider_peer } from "../../../../config/roles";
import { Button, CheckboxOld } from "../../../../component-library";
import { showAlertWithAction } from "../../../../redux/actions/alerts";
import { MemberProfileNoteTypeOption } from "./Notes/MemberProfileNoteTypeOption";
import { DateInputPicker } from "../../../../component-library/DateInput/DateInputPicker";
import CloseOnOutsideClick from "../../../Common/CloseOnOutsideClick/CloseOnOutsideClick";
import { MemberProfileExportableNote } from "./Notes/MemberProfileExportableNote";
import {
    hasAccessToCreateAdvancedCareActivityNote,
    hasAccessToViewAdvancedCareActivityNote,
} from "./AdvancedCareActivity/AdvancedCareActivityScheme";

const MemberProfileNotes = (props) => {
    const providerType = useSelector((state) => state.auth.profile?.provider_type);
    const notes = props.data;
    const patientId = props.match.params.patientId;
    const isAuthorizedToCreateNotes =
        isAuthorized("provider:behavioral") ||
        isAuthorized("provider:prescribe") ||
        isAuthorized(admin_cse) ||
        isAuthorized("provider:peer") ||
        isAuthorized("provider:case_manager");

    const isAuthorizedToExportNotes = isAuthorized(admin_cse);
    const addNoteMenuCSS = isAuthorizedToCreateNotes
        ? "add-note-button mt-lg-0 mt-2 ml-0 ml-lg-2"
        : "add-note-button mt-lg-0 mt-2 ml-0 ml-lg-2 add-note-button-disabled";

    const [filterNoteType, setFilterNoteType] = useState("all");
    const [filterDateStart, setFilterDateStart] = useState(null);
    const [filterDateEnd, setFilterDateEnd] = useState(null);
    const [filterSearch, setFilterSearch] = useState(null);
    const [showStartDatePicker, setShowStartDatePicker] = useState(false);
    const [showEndDatePicker, setShowEndDatePicker] = useState(false);
    const [showAddNewMenu, setShowAddNewMenu] = useState(false);
    const [allNotesSelected, setAllNotesSelected] = useState(false);
    const [notesSelected, setNotesSelected] = useState({});

    useEffect(() => {
        const newNotesSelected = Array.from(Object.values(notesSelected))
            .filter(filterNotes)
            .reduce((obj, note) => {
                obj[note.id] = note;
                return obj;
            }, {});

        setNotesSelected(newNotesSelected);
    }, [filterNoteType, filterDateStart, filterDateEnd, filterSearch]);

    const getNoteTypeFilterOptions = () => {
        const noteTypeFilterOptions = [
            { value: "all", label: "All" },
            { value: "BHS", label: "Behavioral Health Specialist" },
            { value: "PRESCRIBE", label: "Prescriber" },
            { value: "CASE_MANAGER", label: "Case Manager" },
            { value: "PEER", label: "Peer" },
            { value: "Check-in", label: "Check-in" },
            { value: "Care Coordination", label: "Care Coordination" },
            { value: "Care Communication", label: "Care Communication" },
            { value: "Case Management", label: "Case Management" },
            { value: "Care Management", label: "Care Management" },
        ];
        if (hasAccessToViewAdvancedCareActivityNote()) {
            noteTypeFilterOptions.push({
                value: "Advanced Care Activity",
                label: "Advanced Care Activity",
            });
        }
        return noteTypeFilterOptions;
    };

    const filterNotes = (note) =>
        noteTypeFilter(note) && noteDateFilter(note) && noteSearchFilter(note);

    const noteTypeFilter = (note) =>
        filterNoteType === "all" ||
        (filterNoteType === "BHS" && note.provider_type?.includes("BEHAVIORAL")) ||
        (filterNoteType === "PRESCRIBE" && note.provider_type === "PRESCRIBE") ||
        (filterNoteType === "CASE_MANAGER" && note.provider_type === "CASE_MANAGER") ||
        (filterNoteType === "PEER" && note.provider_type === "PEER") ||
        (filterNoteType === "Check-in" && note.note_type === "Check-in") ||
        (filterNoteType === "Care Coordination" && note.note_type === "Care Coordination") ||
        (filterNoteType === "Care Communication" && note.note_type === "Care Communication") ||
        (filterNoteType === "Case Management" && note.note_type === "Case Management") ||
        (filterNoteType === "Care Management" && note.note_type === "Care Management") ||
        (filterNoteType === "Advanced Care Activity" &&
            note.note_type === "Advanced Care Activity");

    const noteDateFilter = (note) => {
        const formattedSessionDate = convertDateFormat(note.session_date);
        const formattedFilterDateStart = convertDateFormat(filterDateStart);
        const formattedFilterDateEnd = convertDateFormat(filterDateEnd);

        return (
            (formattedFilterDateStart ? formattedSessionDate >= formattedFilterDateStart : true) &&
            (formattedFilterDateEnd ? formattedSessionDate <= formattedFilterDateEnd : true)
        );
    };

    const convertDateFormat = (
        dateString,
        currentFormat = "MM/DD/YYYY",
        newFormat = "YYYY/MM/DD",
    ) => {
        if (dateString) {
            return moment(dateString, currentFormat).format(newFormat);
        }
        return dateString;
    };

    const noteSearchFilter = (note) =>
        filterSearch ? note.title && note.title.includes(filterSearch) : true;

    const selectAllNoteIds = () => {
        const newAllNotesSelected = !allNotesSelected;
        if (!newAllNotesSelected) {
            setNotesSelected({});
        } else {
            setNotesSelected(
                notes.filter(filterNotes).reduce((obj, note) => {
                    obj[note.id] = note;
                    return obj;
                }, {}),
            );
        }
        setAllNotesSelected(newAllNotesSelected);
    };

    const selectNote = (note) => {
        const notesSelectedCopy = _.cloneDeep(notesSelected);

        if (notesSelectedCopy[note.id] !== undefined) {
            delete notesSelectedCopy[note.id];
        } else {
            notesSelectedCopy[note.id] = note;
        }

        setNotesSelected(notesSelectedCopy);
    };

    const exportNotes = () => {
        const noteIds = Object.keys(notesSelected);

        if (noteIds.length > 50) {
            props.showAlertWithAction(
                "Too many notes selected for export. Please reduce the selection and try again (max: 50).",
            );
            return;
        }

        props.history.push(
            `/app/patient/${patientId}/export_notes?noteIds=${Object.keys(notesSelected).join(
                ",",
            )}`,
        );
    };

    const allowedRolesByNoteTitle = {
        "Guided Session Note":
            !isAuthorized(admin_cse) &&
            !isAuthorized(provider_peer) &&
            !isAuthorized(provider_case_manager),
        "Care Communication Note":
            !isAuthorized(admin_cse) &&
            !isAuthorized(provider_peer) &&
            !isAuthorized(provider_case_manager),
        "Case Management Note":
            !isAuthorized(admin_cse) &&
            !isAuthorized(provider_peer) &&
            !isAuthorized(provider_case_manager),
        "Care Management Assessment":
            (isAuthorized("provider:behavioral") && providerType === "BEHAVIORAL") ||
            isAuthorized(provider_case_manager),
        "Advanced Care Activity": hasAccessToCreateAdvancedCareActivityNote(providerType),
    };

    const noteTypeColumnSize = isAuthorizedToExportNotes ? "col-lg-3" : "col-lg-4";

    return (
        <>
            <CardSecondary style={{ maxWidth: "100%" }}>
                <div
                    className={"filter-notes-header mt-2 p-3 d-flex justify-content-between"}
                    data-public="true">
                    <div className="fs-18 mx-3 my-0">
                        <span>
                            <strong>Filter Notes</strong>
                        </span>
                    </div>
                    <div className={"d-flex justify-content-between"}>
                        {isAuthorizedToExportNotes && (
                            <Button
                                buttonType={"secondary"}
                                className={"ExportNotesButton"}
                                onClick={exportNotes}
                                disabled={Object.keys(notesSelected).length === 0}>
                                Export
                            </Button>
                        )}
                        <div
                            className={addNoteMenuCSS}
                            onClick={() =>
                                setShowAddNewMenu(!showAddNewMenu && isAuthorizedToCreateNotes)
                            }
                            role={"button"}>
                            <div
                                className={
                                    "add-note-button-content d-flex justify-content-center align-items-center"
                                }
                                data-e2e="MemberProfileNotes_addNewNote">
                                <img
                                    src={images("./icons/new-note-icon.svg")}
                                    alt="add note"
                                    className={"mr-2"}
                                    style={{ width: 24 }}
                                />
                                New Note
                            </div>
                        </div>
                        {showAddNewMenu && (
                            <div className={"GuidedVideoCall__launcher_modal"}>
                                <CloseOnOutsideClick setShowComponent={setShowAddNewMenu}>
                                    <div className={"GuidedVideoCall__launcher_modal-add-note"}>
                                        <div
                                            className={
                                                "d-flex justify-content-between create-note-header"
                                            }>
                                            <p className={"create-new-note-text fw-bold mb-0"}>
                                                Create New Note
                                            </p>
                                            <img
                                                src={images("./icons/new-note-x.svg")}
                                                alt="note x icon"
                                                onClick={() => setShowAddNewMenu(false)}
                                                className={"pointer note-x"}
                                            />
                                        </div>
                                        <SpaceNoteTypeOptions>
                                            {allowedRolesByNoteTitle["Guided Session Note"] && (
                                                <MemberProfileNoteTypeOption
                                                    onClick={() => {
                                                        let url = `/app/patient/${patientId}/note/add/v2?numSessions=${notes.length}`;
                                                        props.history.push(url);
                                                    }}
                                                    iconPath="./icons/note-icon.svg"
                                                    title="Guided Session Note"
                                                    subTitle="Create a guided session note manually."
                                                />
                                            )}
                                            {allowedRolesByNoteTitle["Care Communication Note"] && (
                                                <MemberProfileNoteTypeOption
                                                    onClick={() => {
                                                        props.history.push(
                                                            `/app/patient/${patientId}/care_communication`,
                                                        );
                                                    }}
                                                    iconPath="./icons/care-note-icon.svg"
                                                    title="Care Communication Note"
                                                    subTitle="Record communication regarding a patient’s care outside of a Guided Session."
                                                />
                                            )}
                                            {allowedRolesByNoteTitle["Case Management Note"] && (
                                                <MemberProfileNoteTypeOption
                                                    onClick={() => {
                                                        props.history.push(
                                                            `/app/patient/${patientId}/case_management`,
                                                        );
                                                    }}
                                                    iconPath="./icons/cm-note.jpg"
                                                    iconStyle={{ height: 36 }}
                                                    title="Case Management Note"
                                                    subTitle="For case management assessment and service plan."
                                                />
                                            )}
                                            {allowedRolesByNoteTitle[
                                                "Care Management Assessment"
                                            ] && (
                                                <MemberProfileNoteTypeOption
                                                    onClick={() => {
                                                        props.history.push(
                                                            `/app/patient/${patientId}/care_management`,
                                                            {
                                                                patientDiagnosis:
                                                                    props.patientDiagnosis,
                                                                patientDetails:
                                                                    props.patientDetails,
                                                            },
                                                        );
                                                    }}
                                                    iconPath="./icons/cm-note.jpg"
                                                    iconStyle={{ height: 36 }}
                                                    title="Care Management Assessment"
                                                    titleWrapperClassName={
                                                        "MemberProfileNoteTypeOption--no_sub_title"
                                                    }
                                                />
                                            )}
                                            {allowedRolesByNoteTitle["Advanced Care Activity"] && (
                                                <MemberProfileNoteTypeOption
                                                    onClick={() => {
                                                        props.history.push(
                                                            `/app/patient/${patientId}/advanced_care_activity`,
                                                        );
                                                    }}
                                                    iconPath="./icons/cm-note.jpg"
                                                    iconStyle={{ height: 36 }}
                                                    title="Advanced Care Activity"
                                                    subTitle="Complete for any care manager or peer activity regardless of eligibility for advanced care."
                                                />
                                            )}
                                        </SpaceNoteTypeOptions>
                                        {/* if is not peer and is not case manager hide note option */}
                                    </div>
                                </CloseOnOutsideClick>
                            </div>
                        )}
                    </div>
                </div>
                <div className={"p-3 m-1 row"} data-public={"true"}>
                    {isAuthorizedToExportNotes && (
                        <div className={"col-1"}>
                            <div className={"fw-bold"}>Select</div>
                            <CheckboxOld onClick={selectAllNoteIds} checked={allNotesSelected} />
                        </div>
                    )}
                    <div className={`${noteTypeColumnSize} col-12`}>
                        <Label>Note Type</Label>
                        <Select
                            className="mr-lg-2"
                            placeholder="All"
                            options={getNoteTypeFilterOptions()}
                            onChange={(option) => setFilterNoteType(option.value)}
                        />
                    </div>
                    <div className={"col-lg-4 col-12"}>
                        <Label>Date Range</Label>
                        <div className={"d-flex"}>
                            <DateInputPicker
                                displayValueClassName={"date-range-display"}
                                show={showStartDatePicker}
                                showCallback={() => {
                                    setShowEndDatePicker(false);
                                    setShowStartDatePicker(true);
                                }}
                                hideCallback={() => {
                                    setShowStartDatePicker(false);
                                }}
                                setInputValue={(date) => {
                                    const newDate = date.format("MM/DD/YYYY");
                                    setFilterDateStart(newDate);
                                }}
                                displayValue={filterDateStart ? filterDateStart : "Start"}
                                inputValue={
                                    filterDateStart
                                        ? moment(filterDateStart, "MM/DD/YYYY")
                                        : moment()
                                }
                            />

                            <DateInputPicker
                                wrapperClassName={"ml-3"}
                                displayValueClassName={"date-range-display"}
                                show={showEndDatePicker}
                                showCallback={() => {
                                    setShowStartDatePicker(false);
                                    setShowEndDatePicker(true);
                                }}
                                hideCallback={() => {
                                    setShowEndDatePicker(false);
                                }}
                                setInputValue={(date) => {
                                    const newDate = date.format("MM/DD/YYYY");
                                    setFilterDateEnd(newDate);
                                }}
                                displayValue={filterDateEnd ? filterDateEnd : "End"}
                                inputValue={
                                    filterDateEnd ? moment(filterDateEnd, "MM/DD/YYYY") : moment()
                                }
                            />
                        </div>
                    </div>
                    <div className={"col-lg-4 col-12"}>
                        <Label>Search</Label>
                        <SearchBar
                            className="note-search-bar"
                            value={filterSearch}
                            onchange={(e) => setFilterSearch(e.target.value)}
                        />
                    </div>
                </div>
            </CardSecondary>
            {notes &&
                notes
                    .filter(filterNotes)
                    .map((note) => {
                        return (
                            <MemberProfileExportableNote
                                note={note}
                                isSelectedForExport={notesSelected[note.id] !== undefined}
                                selectNoteToExport={() => selectNote(note)}
                                viewFullNote={() => {
                                    props.history.push(
                                        `/app/patient/${patientId}/note/${note.id}/v2`,
                                        {
                                            patientDiagnosis: props.patientDiagnosis,
                                            patientDetails: props.patientDetails,
                                        },
                                    );
                                }}
                            />
                        );
                    })
                    .flat()}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

export default connect(mapStateToProps, { showAlertWithAction })(withRouter(MemberProfileNotes));

/**
 * Spaces the selectable note type options by adding an hr element
 * above the first element and between each pair of elements
 * -
 * el
 * -
 * el
 */
const SpaceNoteTypeOptions = ({ children }) => {
    const nonNullChildren = (children ?? []).filter(Boolean);
    return (
        <>
            {/* there should always be a separator on the top */}
            <hr className={"add-note-modal-breaks"} />
            {nonNullChildren.map((child, index) => {
                // there should be no separator on the bottom
                const bottomHR = index !== nonNullChildren.length - 1 && (
                    <hr className={"add-note-modal-breaks"} />
                );
                return (
                    <>
                        {child}
                        {bottomHR}
                    </>
                );
            })}
        </>
    );
};

export const transformNoteTitleBasedOnType = (formData) => {
    if (formData["note_type"] === "Care Coordination") {
        return `Care Coordination: ${formData["session_date"]} - ${moment(
            formData["session_from"],
            "HH:mm",
        ).format("hh:mm a")}`;
    } else if (formData["note_type"] === "Check-in") {
        return `Check In: ${formData["session_date"]} - ${moment(
            formData["session_from"],
            "HH:mm",
        ).format("hh:mm a")}`;
    } else if (formData["note_type"] === "Care Communication") {
        return `Care Communication: ${formData["session_date"]} - ${moment(
            formData["session_from"],
            "HH:mm",
        ).format("hh:mm a")}`;
    }
    return `${formData["signature"]}: ${formData["session_date"]} - ${moment(
        formData["session_from"],
        "HH:mm",
    ).format("hh:mm a")}`;
};
export const noteV1Display = (note, patientName) => {
    return (
        <>
            {renderSessionMetaData(note.session_date, note, patientName)}
            {note.provider_type &&
                note.provider_type.startsWith("BEHAVIORAL") &&
                renderBehavioralData(note)}
            {note.provider_type && note.provider_type === "PRESCRIBE" && renderPrescribeData(note)}
        </>
    );
};

export const noteV2Display = (
    note,
    editable,
    editHandler,
    views = {},
    errors = {},
    patientAnswersEditable,
    isCallManuallyLinked = false,
    profile = {},
) => {
    return (
        <>
            {note.provider_type.startsWith("BEHAVIORAL") &&
                renderBehavioralDataV2(
                    note,
                    editable,
                    editHandler,
                    views,
                    errors,
                    patientAnswersEditable,
                    isCallManuallyLinked,
                    profile,
                )}
            {note.provider_type.startsWith("PRESCRIBE") &&
                renderPrescribeDataV2(
                    note,
                    editable,
                    editHandler,
                    views,
                    errors,
                    patientAnswersEditable,
                    isCallManuallyLinked,
                    profile,
                )}
        </>
    );
};

export const noteV3Display = (note) => {
    return (
        <>
            <div className={"text-left txt-sec fw-bold fs-16"}>Check-in</div>
            <div className={"row print-wrap"}>
                {CHECK_IN_SCHEMA.map((formEl) => {
                    let content = note[formEl.name];
                    if (formEl.name.startsWith("content.")) {
                        content = note["content"][formEl.name.split(".")[1]];
                    }
                    if (formEl.name === "content.discharges" && content) {
                        return content.map((discharge, index) =>
                            getReadonlyDischarge(discharge, index),
                        );
                    }
                    return (
                        <div key={formEl.name} className={formEl.elementClasses}>
                            <Label>{formEl.elementConfig.label}</Label>
                            {formEl.name === "content.call_focus" && (
                                <div className={"row print-wrap mx-2 mb-3"}>
                                    {getCallFocusCheckboxes(content, null, true)}
                                </div>
                            )}
                            {formEl.name === "content.patient_outcome_answer" && (
                                <div className={"mb-3 print-width"}>
                                    {getPatientOutcomeRatings(
                                        RATING_OPTION_LABEL_MAP,
                                        content,
                                        null,
                                        true,
                                    )}
                                </div>
                            )}
                            {formEl.name === "call_type" && (
                                <div className={"mb-3"}>{eventTypeDisplayNameFactory(content)}</div>
                            )}
                            {formEl.name === "signature" && (
                                <>
                                    <div
                                        className="w-100 fs-24 border-bottom"
                                        style={{ fontFamily: "Apple Chancery, cursive" }}>
                                        {"X " + content}
                                    </div>
                                    {note.provider_cp_credentials ? (
                                        <div className={"col-lg-2 mb-2"}>
                                            <div className={"fw-bold"}>Credential </div>
                                            <div>{note.provider_cp_credentials}</div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </>
                            )}

                            {formEl.name.includes("ICD_10_axis") && (
                                <div className={"mb-3"}>
                                    {content && <ul>{renderICD10(content)}</ul>}
                                </div>
                            )}

                            {typeof content === "string" &&
                                formEl.name !== "signature" &&
                                formEl.name !== "call_type" &&
                                !formEl.name.includes("ICD_10_axis") && (
                                    <div className={"mb-3"}>{content}</div>
                                )}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export function getReadonlyDischarge(discharge, index, classes) {
    const additional_discharge_information = get(discharge, "additional_discharge_information", "");
    const discharge_date = get(discharge, "discharge_date", "");
    const discharge_type = get(discharge, "discharge_type", "");
    return (
        <>
            <div className={classes && classes.colReadonly ? "row mx-1" : "w-100 d-flex"}>
                <div
                    key={"discharge_type" + index}
                    className={
                        classes && classes.colReadonly ? classes.colReadonly : "col-5 col-lg-2"
                    }>
                    <Label>Discharge Type</Label>
                    <div className={"mb-3"}>{discharge_type}</div>
                </div>
                <div
                    key={"discharge_date" + index}
                    className={
                        classes && classes.colReadonly ? classes.colReadonly : "col-5 col-lg-2"
                    }>
                    <Label>Discharge Date</Label>
                    <div className={"mb-3"}>{discharge_date}</div>
                </div>
            </div>
            <div className={classes && classes.col ? "row mx-1" : "w-100 d-flex"}>
                <div key={"discharge_type" + index} className={"col-12"}>
                    <Label>Additional Discharge Information</Label>
                    <div className={"mb-3"}>{additional_discharge_information}</div>
                </div>
            </div>
        </>
    );
}

export const noteV4Display = (note) => {
    return (
        <>
            <div className={"text-left txt-sec fw-bold fs-16"}>Care Coordination</div>
            <div className={"row print-wrap"}>
                {CARE_COORDINATION_SCHEMA.map((formEl) => {
                    let content = note[formEl.name];
                    if (formEl.name.startsWith("content.")) {
                        content = note["content"][formEl.name.split(".")[1]];
                    }
                    if (formEl.name === "content.discharges") {
                        return content.map((discharge, index) =>
                            getReadonlyDischarge(discharge, index),
                        );
                    }
                    return (
                        <div key={formEl.name} className={`${formEl.elementClasses}`}>
                            {formEl.elementConfig.label && (
                                <Label>{formEl.elementConfig.label}</Label>
                            )}
                            {!formEl.elementConfig.label && <br />}
                            {formEl.name === "content.call_focus" && (
                                <div className={"row print-wrap mx-2 mb-3"}>
                                    {getCallFocusCheckboxes(content, null, true)}
                                </div>
                            )}
                            {formEl.name === "content.patient_outcome_answer" && (
                                <div className={"mb-3 print-width"}>
                                    {getPatientOutcomeRatings(
                                        RATING_OPTION_LABEL_MAP,
                                        content,
                                        null,
                                        true,
                                    )}
                                </div>
                            )}
                            {formEl.name === "signature" && (
                                <>
                                    <div
                                        className="w-100 fs-24 border-bottom"
                                        style={{ fontFamily: "Apple Chancery, cursive" }}>
                                        {"X " + content}
                                    </div>
                                    {note.provider_cp_credentials ? (
                                        <div className={"col-lg-2 mb-2"}>
                                            <div className={"fw-bold"}>Credential </div>
                                            <div>{note.provider_cp_credentials}</div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </>
                            )}
                            {formEl.name === "call_type" && (
                                <div className={"mb-3"}>{eventTypeDisplayNameFactory(content)}</div>
                            )}
                            {formEl.name === "session_from" && (
                                <div className={"session-from-display mb-3"}>
                                    {moment(content, "HH:mm").format("hh:mm a")}
                                </div>
                            )}
                            {formEl.name.includes("ICD_10_axis") && (
                                <div className={"mb-3"}>
                                    {content && <ul>{renderICD10(content)}</ul>}
                                </div>
                            )}
                            {typeof content === "string" &&
                                formEl.name !== "signature" &&
                                formEl.name !== "call_type" &&
                                formEl.name !== "session_from" &&
                                !formEl.name.includes("ICD_10_axis") && (
                                    <div className={"mb-3"}>{content}</div>
                                )}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

const renderBehavioralDataV2 = (
    note,
    editable,
    editHandler,
    views,
    errors,
    patientAnswersEditable,
    isCallManuallyLinked,
    profile,
) => {
    let { profileView, createEditView, showSignature, overrideWidth } = views;
    if (!editable) {
        patientAnswersEditable = false;
    }
    const content = note.hasOwnProperty("content") && note.content;
    let behavioralGoals = content.goals_objectives_progress ? (
        <BehavioralGoals
            note={note}
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
        />
    ) : (
        <></>
    );
    let symptomTrendNotes = (
        <GenericSectionNotes
            note={note}
            sectionKey="symptom_trend_notes"
            sectionLabel="Symptom Trends/Review"
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
        />
    );
    let explorationNotes = (
        <GenericSectionNotes
            note={note}
            sectionKey="exploration_notes"
            sectionLabel="Exploration"
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
        />
    );
    let mentalStatus = content.mental_status ? (
        <BehavioralMentalStatus
            note={note}
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
            createEditView={createEditView}
            showTitle={true}
        />
    ) : (
        <></>
    );
    let symptomTrends =
        content.symptom_trends && content.symptom_trends_substances ? (
            <BehavioralSymptomTrend
                note={note}
                editable={editable}
                patientAnswersEditable={patientAnswersEditable}
                editHandler={editHandler}
                profileView={profileView}
            />
        ) : (
            <></>
        );
    let changes = content.changes ? (
        <GenericMultiselect
            note={note}
            sectionKey="changes"
            labelClass="clr-sec"
            sectionLabel="Changes/Updates"
            editable={patientAnswersEditable}
            className={!profileView ? "grey-container" : ""}
            editHandler={editHandler}
            profileView={profileView}
        />
    ) : (
        <></>
    );
    let healthCareUse = content.health_care_use ? (
        <GenericMultiselect
            note={note}
            sectionKey="health_care_use"
            labelClass="clr-sec"
            sectionLabel="Health Care Use"
            className={!profileView ? "grey-container" : ""}
            editable={patientAnswersEditable}
            editHandler={editHandler}
            profileView={profileView}
        />
    ) : (
        <></>
    );
    let discharges = content.hasOwnProperty("discharges") ? (
        <DischargeContent
            classes={{
                colEditable: "col-12",
                colReadonly: "col-5",
                card: editable ? "CardSeconday" : null,
            }}
            editable={editable}
            dischargeValues={note.content.discharges}
            setDischargeValues={(val, key, nested) => editHandler("discharges", val, nested)}
        />
    ) : null;
    let diagnosis = (
        <NotesDiagnosis
            note={note}
            profile={profile}
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
            errors={errors}
        />
    );
    let duration = !isCallManuallyLinked ? (
        <NotesDuration
            note={note}
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
            errors={errors}
        />
    ) : (
        <></>
    );
    let signature = (
        <div className="border-top-note">
            <div className={profileView ? "p-2" : ""}>
                <div className="clr-sec fw-bold float-left">Signature</div>
                <br />
                <div className="fs-24" style={{ fontFamily: "Apple Chancery, cursive" }}>
                    {note.signature ? note.signature : "Note was not signed"}
                </div>
                {note.provider_cp_credentials ? (
                    <div className="col-lg-6 p-0">
                        <div className={"fw-bold"}>Credentials</div>
                        <div>{note.provider_cp_credentials}</div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );

    let bottomArea;

    if (createEditView) {
        bottomArea = (
            <>
                {diagnosis}
                {duration}
                {signature}
            </>
        );
    } else if (profileView) {
        bottomArea = (
            <div className="row">
                <div className="col-lg-6 p-0">
                    {diagnosis}
                    {duration}
                    {signature}
                </div>
                <div className="col-lg-6 p-0">{signature}</div>
            </div>
        );
    }

    let bottomAreaMobile;
    if (createEditView) {
        bottomAreaMobile = (
            <>
                {diagnosis}
                {duration}
                {signature}
            </>
        );
    } else if (profileView) {
        bottomAreaMobile = (
            <>
                {diagnosis}
                {duration}
                {signature}
            </>
        );
    }

    let otherCss = profileView ? "px-0 mx-0 w-100" : "m-auto";
    let col1Css = profileView ? "col-3" : "col-5";
    let col2Css = profileView ? "col-9 p-0" : "col-7 pl-3 pr-0";
    let desktopContent = (
        <div className="d-none d-md-block d-lg-block">
            <div
                style={{ maxWidth: (!profileView && !createEditView) || overrideWidth ? "" : 978 }}>
                {behavioralGoals}
            </div>
            <div
                className={`row ${otherCss}`}
                style={{
                    maxWidth: (!profileView && !createEditView) || overrideWidth ? "100%" : 978,
                }}>
                <div className={`${col1Css} pr-0 pl-0`}>
                    <div className={profileView ? "p-3 border-right-note border-bottom-note" : ""}>
                        {symptomTrendNotes}
                    </div>
                    <div className={profileView ? "p-3 border-right-note border-bottom-note" : ""}>
                        {explorationNotes}
                    </div>
                    <div className={profileView ? "p-3 border-right-note" : ""}>{mentalStatus}</div>
                </div>

                <div className={`${col2Css}`}>
                    <div className={profileView ? "p-3" : ""}>{symptomTrends}</div>
                    <div className={profileView ? "p-3 border-top-note" : ""}>{changes}</div>
                    <div className={profileView ? "p-3 border-top-note" : ""}>{healthCareUse}</div>
                    {discharges && (
                        <div className={profileView ? "p-3 border-top-note" : ""}>{discharges}</div>
                    )}
                    <div className={profileView ? "p-3 " : ""}>{bottomArea}</div>
                </div>
            </div>
        </div>
    );

    let mobileContent = (
        <div className="d-block d-md-none d-lg-none">
            {behavioralGoals}
            {symptomTrends}
            {symptomTrendNotes}
            {explorationNotes}
            {changes}
            {healthCareUse}
            {discharges}
            {bottomAreaMobile}
        </div>
    );

    return (
        <>
            {desktopContent}
            {mobileContent}
        </>
    );
};

const renderPrescribeDataV2 = (
    note,
    editable,
    editHandler,
    views,
    errors,
    patientAnswersEditable,
    isCallManuallyLinked,
    profile,
) => {
    let { profileView, createEditView, showSignature, overrideWidth } = views;
    if (!editable) {
        patientAnswersEditable = false;
    }

    let medications = (
        <PrescriberMedicationsV2
            note={note}
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
            errors={errors}
        />
    );

    let diagnosisProgress = (
        <PrescribeDiagnosisProgress
            note={note}
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
        />
    );

    let symptomTrendNotes = (
        <GenericSectionNotes
            note={note}
            sectionKey="symptom_trend_notes"
            sectionLabel="Symptom Trends/Review"
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
        />
    );
    let medicationImpactNote = (
        <GenericSectionNotes
            note={note}
            sectionKey="medication_impact_notes"
            sectionLabel="Medication Impact"
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
        />
    );
    let medicalInformationNotes = (
        <GenericSectionNotes
            note={note}
            sectionKey="medical_information_notes"
            sectionLabel="Medical Information"
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
            renderExtra={() => {
                let prescriptionMonitoring = note.content.prescription_monitoring;
                return (
                    <div className={"ml-2"}>
                        <input
                            type="checkbox"
                            checked={prescriptionMonitoring.clicked ? true : null}
                        />
                        <span className={"ml-2"}>{prescriptionMonitoring.type}</span>
                    </div>
                );
            }}
        />
    );

    let mentalStatus = (
        <BehavioralMentalStatus
            note={note}
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
            createEditView={createEditView}
            showTitle={true}
        />
    );
    let symptomTrends;
    if (note.content.last_behavioral_note) {
        symptomTrends = (
            <BehavioralSymptomTrend
                note={note.content.last_behavioral_note}
                editable={false}
                patientAnswersEditable={false}
                editHandler={editHandler}
                profileView={profileView}
                showProvider={true}
                smallerDimensions={true}
            />
        );
    } else {
        symptomTrends = (
            <CardSecondary className="mx-0 px-3 py-3 my-3 w-100">
                Patient has not had previous behavioral guided session for trends.
            </CardSecondary>
        );
    }

    let adherenceInterventions = (
        <GenericMultiselect
            note={note}
            sectionKey="adherence_interventions"
            labelClass=""
            sectionLabel="Adherence Interventions"
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
            isCheckboxes={true}
        />
    );

    let medicalProblems = (
        <ExpandingSelect
            label={"Medical Problems"}
            labelClass="fw-bold clr-sec"
            className="m-3 grey-container"
            disabled
            options={note.content.medical_problems}
        />
    );

    let overallTrend = <IndividualSymptomTrend answer={note.content.symptom_trends.overall} />;

    let individualTrends = ["frequency", "effectiveness", "problems", "stop"]
        .filter((key) => note.content.medication_trends[key])
        .map((key) => <IndividualSymptomTrend answer={note.content.medication_trends[key]} />);

    let medicationProblems = (
        <ExpandingSelect
            label={"What makes it hard to take your medication?"}
            labelClass="fw-bold clr-sec fs-13"
            className="m-3 grey-container"
            disabled
            options={note.content.medication_difficulties}
        />
    );

    let sideEffects = (
        <GenericMultiselect
            note={note}
            sectionKey="side_effects"
            labelClass=""
            sectionLabel="Side Effect(s)"
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
            isCheckboxes={true}
            // noneSelectedMessage='No side effects observed or reported.'
        />
    );

    let labFindings = (
        <PrescribeLabFindings
            note={note}
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
        />
    );

    let diagnosis = (
        <NotesDiagnosis
            note={note}
            profile={profile}
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
            errors={errors}
        />
    );
    let duration = !isCallManuallyLinked ? (
        <NotesDuration
            note={note}
            editable={editable}
            editHandler={editHandler}
            profileView={profileView}
            errors={errors}
        />
    ) : (
        <></>
    );
    let discharges = note.content.hasOwnProperty("discharges") ? (
        <DischargeContent
            version="2"
            classes={{ colReadonly: "col-5", card: editable ? "CardSeconday py-2" : null }}
            editable={editable}
            dischargeValues={note.content.discharges}
            setDischargeValues={(val, key, nested) => editHandler("discharges", val, nested)}
        />
    ) : null;
    let signature = (
        <div className="border-top-note">
            <div className={profileView ? "p-2" : ""}>
                <div className="clr-sec fw-bold float-left">Signature</div>
                <br />
                <div className="fs-24" style={{ fontFamily: "Apple Chancery, cursive" }}>
                    {note.signature ? note.signature : "Note was not signed"}
                </div>
                {note.provider_cp_credentials ? (
                    <div className="col-lg-6 p-0">
                        <div className={"fw-bold"}>Credentials</div>
                        <div>{note.provider_cp_credentials}</div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );

    let bottomArea;
    if (createEditView) {
        bottomArea = (
            <div className={"p-3"}>
                {diagnosis}
                {duration}
                {discharges}
                {signature}
            </div>
        );
    } else if (profileView) {
        bottomArea = (
            <>
                {diagnosis}
                {duration}
                {discharges}
                {signature}
            </>
        );
    }

    let otherCss = profileView ? "px-0 mx-0 w-100" : "m-auto";
    let desktopContent = (
        <div className="d-none d-md-block d-lg-block">
            <div>{medications}</div>
            <div
                style={{ maxWidth: (!profileView && !createEditView) || overrideWidth ? "" : 978 }}>
                {diagnosisProgress}
            </div>
            <div
                className={`row ${otherCss}`}
                style={{
                    maxWidth: (!profileView && !createEditView) || overrideWidth ? "90%" : 978,
                }}>
                <div className={"col-8 px-0"}>
                    <div className="row px-0">
                        <div className={"col-6"}>
                            <div
                                className={
                                    profileView ? "p-3 border-right-note border-bottom-note" : ""
                                }>
                                {symptomTrendNotes}
                            </div>
                            <div
                                className={
                                    profileView ? "p-3 border-right-note border-bottom-note" : ""
                                }>
                                {medicationImpactNote}
                            </div>
                            <div className={profileView ? "p-3 border-right-note" : ""}>
                                {medicalInformationNotes}
                            </div>
                            <div className={profileView ? "p-3 border-right-note" : ""}>
                                {mentalStatus}
                            </div>
                        </div>
                        <div className={"col-6"}>
                            <div className={profileView ? "p-3 border-right-note" : ""}>
                                {symptomTrends}
                            </div>
                            <div
                                className={
                                    profileView
                                        ? "p-3 border-top-note border-right-note border-bottom-note"
                                        : ""
                                }>
                                {adherenceInterventions}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={profileView ? "p-3 " : ""}>{bottomArea}</div>
                    </div>
                </div>

                <div className={"col-4 px-0"}>
                    <div className={"row px-0"}>
                        <div className={"col-12"}>
                            <div
                                className={
                                    profileView ? "p-3 border-right-note border-bottom-note" : ""
                                }>
                                {medicalProblems}
                            </div>
                            <div
                                className={
                                    profileView ? "p-3 border-right-note border-bottom-note" : ""
                                }>
                                {overallTrend}
                            </div>
                            <div
                                className={
                                    profileView
                                        ? "p-3 border-right-note border-left-note  border-bottom-note"
                                        : ""
                                }>
                                {individualTrends}
                            </div>
                            <div
                                className={
                                    profileView
                                        ? "p-3 border-right-note border-left-note border-bottom-note"
                                        : ""
                                }>
                                {medicationProblems}
                            </div>
                            <div
                                className={
                                    profileView
                                        ? "p-3 border-right-note border-left-note border-bottom-note"
                                        : "px-3"
                                }>
                                {sideEffects}
                            </div>
                            <div
                                className={
                                    profileView
                                        ? "p-3 border-right-note border-left-note border-bottom-note"
                                        : ""
                                }>
                                {labFindings}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    let mobileContent = (
        <div className="d-block d-md-none d-lg-none">
            {medications}
            {diagnosisProgress}
            {symptomTrendNotes}
            {medicationImpactNote}
            {medicalInformationNotes}
            {mentalStatus}
            {symptomTrends}
            {adherenceInterventions}
            {medicalProblems}
            {overallTrend}
            {individualTrends}
            {medicalProblems}
            {sideEffects}
            {labFindings}
            {duration}
            {discharges}
            {bottomArea}
        </div>
    );

    return (
        <>
            {desktopContent}
            {mobileContent}
        </>
    );
};

export const RATING_OPTIONS_LABEL_MAP = {
    0: "Much Worse",
    1: "Slightly Worse",
    2: "Same",
    3: "Slightly Better",
    4: "Much Better",
};

export const POST_SESSION_SURVEY_LABEL_MAP = {
    0: "Strongly Disagree",
    1: "Disagree",
    2: "Neutral",
    3: "Agree",
    4: "Strongly Agree",
};

export const SCREENING_OPTIONS_LABEL_MAP = {
    0: "Not at all",
    1: "Several days",
    2: "More than half the days",
    3: "Nearly every day",
};
