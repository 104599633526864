export const TextTypes = {
    heading1: "heading-1",
    heading2: "heading-2",
    paragraph: "paragraph",
    subtitle: "subtitle",
    label: "label",
    small: "small",
    light: "light",
    link: "link",
    signature: "signature",
};

export const TextColors = {
    green: "green",
    greenV2: "greenV2",
    grey: "grey",
    navy: "navy",
    purple: "purple",
    red: "red",
    white: "white",
    black: "black",
    yellow: "yellow",
};

export const ButtonTypes = {
    primary: "primary",
    primaryV2: "primary-v2",
    primaryOutline: "primary-outline",
    primaryOutlineV2: "primary-v2-outline",
    secondary: "secondary",
    secondaryOutline: "secondary-outline",
    label: "label",
    underline: "underline",
};

export const ButtonSizes = {
    small: "small",
    normal: "normal",
};

export const PopoverPositions = {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
};
