/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const DownloadIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Download"
                {...props}>
                <path d="M16 4C16.5523 4 17 4.44772 17 5V20.5858L23.2929 14.2929C23.6834 13.9024 24.3166 13.9024 24.7071 14.2929C25.0976 14.6834 25.0976 15.3166 24.7071 15.7071L16 24.4142L7.29289 15.7071C6.90237 15.3166 6.90237 14.6834 7.29289 14.2929C7.68342 13.9024 8.31658 13.9024 8.70711 14.2929L15 20.5858V5C15 4.44772 15.4477 4 16 4Z" />
                <path d="M4 26V28H28V26H4Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Download"
                {...props}>
                <path d="M16 3C17.1046 3 18 3.89543 18 5V18.1716L22.5858 13.5858C23.3668 12.8047 24.6332 12.8047 25.4142 13.5858C26.1953 14.3668 26.1953 15.6332 25.4142 16.4142L17.8284 24H28V28H4V24H14.1716L6.58579 16.4142C5.80474 15.6332 5.80474 14.3668 6.58579 13.5858C7.36684 12.8047 8.63317 12.8047 9.41421 13.5858L14 18.1716V5C14 3.89543 14.8954 3 16 3Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Download"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM21.5 11.75C21.5 10.9216 20.8284 10.25 20 10.25C19.1716 10.25 18.5 10.9216 18.5 11.75V21.6287L15.0607 18.1893C14.4749 17.6036 13.5251 17.6036 12.9393 18.1893C12.3536 18.7751 12.3536 19.7249 12.9393 20.3107L18.6287 26H11V29H29V26H21.3713L27.0607 20.3107C27.6464 19.7249 27.6464 18.7751 27.0607 18.1893C26.4749 17.6036 25.5251 17.6036 24.9393 18.1893L21.5 21.6287V11.75Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

DownloadIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

DownloadIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
