import CardSecondary from "../../../../UI/Card/CardSecondary";
import { noteV2Display } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotes";
import React from "react";
import { PatientInfoWidget } from "./PatientInfoWidget";
import { PrescriberMedications } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotesMedications";
import AddendumDisplay from "../../../../../containers/Dashboard/common/ViewFullNote/AddendumDisplay";

const NO_PREVIOUS_NOTE_MESSAGE = (
    <CardSecondary className="pt-4 px-4 m-3 ml-auto mr-auto w-100 color-black text-center">
        No previous guided session with current patient. Please complete a guided session note to
        display.
    </CardSecondary>
);

const behavioralReviewLastNotes = (componentProps) => {
    const { state, jumpWorkflowStep } = componentProps;
    const { patientDetails, lastNote, lastNotesOther, patientDiagnosis, addendums } = state;

    let lastPrescriberNote = lastNotesOther["PRESCRIBE"];

    if (!lastPrescriberNote || !lastPrescriberNote.version || lastPrescriberNote.version === 1) {
        lastPrescriberNote = {
            content: {
                medications: [],
            },
        };
    }

    let currentMedicationDisplay = <PrescriberMedications note={lastPrescriberNote} />;

    let noteDisplay;
    if (!lastNote) {
        noteDisplay = NO_PREVIOUS_NOTE_MESSAGE;
    } else {
        noteDisplay = (
            <>
                <div className="mt-3 ml-auto mr-auto fw-bold" style={{ maxWidth: "90%" }}>
                    {lastNote.title}
                </div>
                {currentMedicationDisplay}
                <div className="m-auto">{noteV2Display(lastNote, false)}</div>
                {addendums.map((addendum) => (
                    <AddendumDisplay
                        style={{ minHeight: 0, maxWidth: "50%", margin: "10px auto" }}
                        addendum={addendum.addendum}
                        timestamp={addendum.timestamp}
                    />
                ))}
            </>
        );
    }

    return (
        <div className="GuidedVideoCall__notes_screen">
            <PatientInfoWidget
                patientDetails={patientDetails}
                patientDiagnosis={patientDiagnosis}
                isLarger={true}
            />
            {noteDisplay}
            <div className="my-3 position-relative w-100 text-center">
                <button
                    className="GuidedVideoCall__center-btn fs-14"
                    onClick={() => jumpWorkflowStep(state.currentStep + 1)}>
                    Begin Session
                </button>
            </div>
        </div>
    );
};

export const BehavioralReviewLastNotes = React.memo(behavioralReviewLastNotes);

const prescribeReviewLastNotes = (componentProps) => {
    const { state, jumpWorkflowStep } = componentProps;
    const { patientDetails, lastNote, patientDiagnosis, addendums } = state;

    let noteDisplay;
    if (!lastNote) {
        noteDisplay = NO_PREVIOUS_NOTE_MESSAGE;
    } else {
        noteDisplay = (
            <>
                <div className="mt-3 ml-auto mr-auto fw-bold" style={{ maxWidth: "90%" }}>
                    {lastNote.title}
                </div>
                <div className="m-auto">{noteV2Display(lastNote, false)}</div>
                {addendums.map((addendum) => (
                    <AddendumDisplay
                        style={{ minHeight: 0, maxWidth: "50%", margin: "10px auto" }}
                        addendum={addendum.addendum}
                        timestamp={addendum.timestamp}
                    />
                ))}
            </>
        );
    }

    return (
        <div className="GuidedVideoCall__notes_screen">
            <PatientInfoWidget
                patientDetails={patientDetails}
                patientDiagnosis={patientDiagnosis}
                isLarger={true}
            />
            {noteDisplay}
            <div className="my-3 position-relative w-100 text-center">
                <button
                    className="GuidedVideoCall__center-btn fs-14"
                    onClick={() => jumpWorkflowStep(state.currentStep + 1)}>
                    Begin Session
                </button>
            </div>
        </div>
    );
};

export const PrescribeReviewLastNotes = React.memo(prescribeReviewLastNotes);
