// Copyright aptihealth, inc. 2021 All Rights Reserved
import React, { useEffect, useState } from "react";
import { Form } from "formik";
import Button from "../../../UI/Button";
import * as yup from "yup";
import { api } from "../../../../APIRequests";
import images from "../../../../utils/images";
import { connect } from "react-redux";
import { SelfSignupInsuranceHOC } from "../../../../component-library/hocs/SelfSignUpInsuranceHOC";
import "../style.scss";
import _ from "lodash";
import moment from "moment";
import {
    adultDobValidationSchemaFactory,
    noInsuranceCarrierValidationSchemaFactory,
} from "../../../../utils/yupSchemaUtils";
import { trackEvent } from "../../../../utils/EventTrackingUtil";
import { CATCH_ALL_INSURANCE_OPTIONS } from "../../../../constants/insurnaceIconMap";
import {
    createInsurancePolicyAcknowledgement,
    trackInsurancePolicyAcknowledgement,
} from "../../../../utils/userAgreementUtil";
import InsuranceEligibilityWrapper from "../../../Common/InsuranceEligibility/InsuranceEligibilityWrapper";
import { carrierMemberIdValidationSchema } from "../../../Common/InsuranceEligibility/carrierMetadata";
import { CustomForm } from "../../../../component-library/CustomForm";
import {INITIAL_INSURANCE_VALUES} from "../index";

const AddInsurance = (props) => {
    const { nextStep, accountData, setAccountData, setInsuranceEligibilityResponse } = props;
    const [skipParentInsurance, setSkipParentInsurance] = useState(false);
    const [insuranceOptions, setInsuranceOptions] = useState([]);
    const [interestedInGuardianship, setInterestedInGuardianship] = useState(false);
    const [submissionAttempts, setSubmissionAttempts] = useState(0);
    const [showAcknowledgementPopup, setShowAcknowledgementPopup] = useState(false);
    const [insuranceDetails, setInsuranceDetails] = useState(null);
    const [requiredFields, setRequiredFields] = useState(null);
    const [skipSelected, setSkipSelected] = useState(false);

    useEffect(() => {
        setInterestedInGuardianship(accountData.interested_in_guardianship);
        try {
            (async () => {
                const options = { params: { showLoader: true } };
                const rawInsuranceList = await api.patient.fetch_insurance_list({ options });
                if (rawInsuranceList && rawInsuranceList.carriers) {
                    setInsuranceOptions(
                        rawInsuranceList.carriers.map((carrier) => {
                            return { value: carrier, label: carrier };
                        }),
                    );
                }
            })();
        } catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        if (props.configs.SKIP_PARENT_INSURANCE) {
            setSkipParentInsurance(true);
        }
    }, [props.configs.SKIP_PARENT_INSURANCE]);

    useEffect(() => {
        if (skipSelected) {
            nextStep(false);
        }
    }, [skipSelected]);

    const supplementInsuranceInfo = (data) => {
        const policyHolderQuestion = _.get(data, "primary_insurance.policy_holder_question");
        const carrier = _.get(data, "primary_insurance.carrier");
        if (!policyHolderQuestion && !CATCH_ALL_INSURANCE_OPTIONS.includes(carrier)) {
            data["primary_insurance"]["policy_holder_question"] = "yes";
            data["primary_insurance"][
                "policy_holder_name"
            ] = `${data.first_name} ${data.last_name}`;
            data["primary_insurance"]["policy_relationship"] = "self";
        }
        return data;
    };

    const isAcknowledgementRequired = (carrier) => {
        return CATCH_ALL_INSURANCE_OPTIONS.includes(carrier);
    };

    useEffect(() => {
        if (accountData && !_.isEmpty(accountData.acknowledgements)) {
            setShowAcknowledgementPopup(false);
            nextStep();
        }
    }, [accountData]);

    const submitAcknowledgement = async (formData, actions) => {
        const mergedAccountData = await transformAccountData(formData);
        const acknowledgement = await createInsurancePolicyAcknowledgement(
            formData.primary_insurance.carrier,
        );
        const mergedAcknowledgements = {
            ...mergedAccountData.acknowledgements,
            ...acknowledgement,
        };
        setAccountData({ ...mergedAccountData, acknowledgements: mergedAcknowledgements });
    };

    const transformAccountData = async (formData) => {
        return {
            ...accountData,
            ...formData,
        };
    };

    const transformEligibilityDetails = (formData, mergedAccountData) => {
        const primaryInsurance = _.cloneDeep(accountData.primary_insurance);
        delete primaryInsurance["card_front_preview"];
        delete primaryInsurance["card_back_preview"];
        const finalAccountData = supplementInsuranceInfo(mergedAccountData);
        const insuranceInfo = {
            ...primaryInsurance,
            policy_dob: moment(finalAccountData.dob, "MMDDYYYY").format("MM/DD/YYYY"),
            first_name: finalAccountData.first_name,
            last_name: finalAccountData.last_name,
            email: finalAccountData.email,
            dob: moment(finalAccountData.dob, "MMDDYYYY").format("MM/DD/YYYY"),
            area: "patient_self_signup",
            carrier: formData.primary_insurance.carrier,
            member_id: formData.primary_insurance.member_id,
            group_id: formData?.primary_insurance?.group_id,
        };

        setInsuranceDetails(insuranceInfo);
        setAccountData(finalAccountData);
    };

    const submitOrOpenAcknowledgement = async (formData, actions) => {
        if (isAcknowledgementRequired(formData.primary_insurance.carrier)) {
            setShowAcknowledgementPopup(true);
            return;
        }

        setShowAcknowledgementPopup(false);
        const mergedAccountData = await transformAccountData(formData);
        transformEligibilityDetails(formData, mergedAccountData);
        setSubmissionAttempts(submissionAttempts + 1);
    };

    const skipInsurance = () => {
        setAccountData({...accountData, primary_insurance: _.cloneDeep(INITIAL_INSURANCE_VALUES)});
        setSkipSelected(true);
    };

    const eligibilityCallback = (eligibilityMessage = "", submissionAttempts = 0, response) => {
        setInsuranceEligibilityResponse(response);
        const isCarrierSelected = !!_.get(insuranceDetails, "carrier", false);
        if (isCarrierSelected || (interestedInGuardianship && !insuranceDetails)) {
            if (submissionAttempts < 5 && eligibilityMessage === "") {
                trackInsurancePolicyAcknowledgement(insuranceDetails.carrier);
                trackEvent("Self Sign-up Insurance Information V2");
            }
            nextStep(isCarrierSelected || (interestedInGuardianship && !insuranceDetails));
        }
    };

    const insuranceCarrierValidation = (FIVE_TO_ELEVEN_CHILD_FLAG) => {
        let validation = yup.object().shape({
            primary_insurance: yup.object().shape({
                carrier: yup.string().required("Carrier is required").nullable(),
                member_id: noInsuranceCarrierValidationSchemaFactory(
                    "carrier",
                    carrierMemberIdValidationSchema,
                ),
                group_id: yup
                    .string()
                    .matches(/^[a-zA-Z0-9]*$/, "Enter a valid Group ID")
                    .nullable(),
            }),
        });

        if (!requiredFields) {
            return validation;
        }

        if (requiredFields.includes("dob")) {
            validation = validation.concat(
                yup.object().shape({
                    dob: adultDobValidationSchemaFactory(
                        "MMDDYYYY",
                        "Must be a valid date",
                        `You must be 18 or older to register yourself for services. 
                Children aged ${
                    FIVE_TO_ELEVEN_CHILD_FLAG ? "5" : "12"
                }-17 must have a parent register first.`,
                    ).required("Date of birth is required"),
                }),
            );
        }

        if (requiredFields.includes("first_name")) {
            validation = validation.concat(
                yup.object().shape({
                    first_name: yup
                        .string()
                        .required("First name is required")
                        .matches(
                            /^[a-zA-Z-]+$/,
                            "Numerals, spaces & special characters are not allowed",
                        ),
                }),
            );
        }

        if (requiredFields.includes("last_name")) {
            validation = validation.concat(
                yup.object().shape({
                    last_name: yup
                        .string()
                        .required("Last name is required")
                        .matches(
                            /^[a-zA-Z-]+$/,
                            "Numerals, spaces & special characters are not allowed",
                        ),
                }),
            );
        }

        return validation;
    };

    const renderForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 mb-3">
                    <div className="container px-0">
                        <SelfSignupInsuranceHOC
                            className="AddInsurance--self-signup"
                            insuranceCompanyOptions={insuranceOptions}
                            isPrimaryInsurance={true}
                            formikProps={formikProps}
                            hideFileUpload={true}
                            submitAcknowledgementHandler={() =>
                                submitAcknowledgement(formikProps.values)
                            }
                            closeAcknowledgementHandler={() => setShowAcknowledgementPopup(false)}
                            showAcknowledgementPopup={showAcknowledgementPopup}
                            requiredFields={requiredFields}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    {interestedInGuardianship && skipParentInsurance && (
                        <Button
                            type={"button"}
                            onClick={skipInsurance}
                            className="Btn Btn--otl-pri self-signup-btn mr-5">
                            Skip
                        </Button>
                    )}
                    <Button
                        disabled={submissionAttempts > 4}
                        type={"submit"}
                        className="Btn Btn--pri self-signup-btn">
                        Continue
                    </Button>
                </div>
            </Form>
        );
    };
    return (
        <>
            <div className={"p-5"}>
                <div className={"fs-22 txt-pri fw-bold mb-5 text-center"}>
                    Almost there! Enter your insurance.
                </div>
                <div className={"text-center my-5"}>
                    <img
                        src={images("./signup/progress-3.png")}
                        alt={"Step 3/3"}
                        className={"signup-progress-bar"}
                    />
                </div>
                {insuranceDetails && (
                    <InsuranceEligibilityWrapper
                        submissionAttempts={submissionAttempts}
                        insuranceDetails={insuranceDetails}
                        eligibilityCallback={eligibilityCallback}
                        entryPoint={"self_signup"}
                        setRequiredFields={setRequiredFields}
                    />
                )}
                <CustomForm
                    initialValues={accountData}
                    validationSchema={insuranceCarrierValidation(
                        props.configs.FIVE_TO_ELEVEN_CHILD_FLAG,
                    )}
                    onSubmit={submitOrOpenAcknowledgement}
                    render={renderForm}
                />
                <div className={"my-5 py-5"} />
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        configs: state.configs,
    };
};

export default connect(mapStateToProps, {})(AddInsurance);
