// Copyright aptihealth, inc. 2020 All Rights Reserved
import { Form, getIn } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { api } from "../../../APIRequests";
import { ProviderDetails } from "../../../components/Admin/Practice";
import { ProviderPracticeDetails } from "../../../components/Admin/Provider/ProviderPracticeDetails";
import Button from "../../../components/UI/Button";
import CardSecondary from "../../../components/UI/Card/CardSecondary";
import FormikInput from "../../../components/UI/FormikInput";
import CardHeading from "../../../components/UI/Headings/CardHeading";
import InputError from "../../../components/UI/InputError";
import Label from "../../../components/UI/Label";
import FormikMobileInput from "../../../components/UI/formikMobileInput";
import { adminRoute, admin_cse } from "../../../config/roles";
import { isAuthorized } from "../../../redux/actions/auth";
import { getQueryParams } from "../../../utils/filters";
import PatientsList from "../Providers/PatientsList";
import { VALIDATION_SCHEMA } from "../Providers/schema/providerProfileBasicValidation";
import { PROVIDER_DETAILS_SCHEMA } from "./schema/providerDetailsSchema";

import { connect } from "react-redux";
import { CustomForm } from "../../../component-library/CustomForm";
import { ProviderStatusDetails } from "../../../components/Admin/Provider/ProviderStatusDetails";

class Provider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            practiceId: this.props.match.params.practiceId,
            providerId: this.props.match.params.providerId,
            hideBreadCrumb: getQueryParams("hideBreadCrumb", this.props.location.search),
            providerDetails: null,
            editProviderDetails: false,
            intakePracticeOptions: [],
            existingIntakePractices: [],
            status: null,
        };
    }

    async componentDidMount() {
        await this.getProvider();
        this.getPatientList();
        this.getPractices();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.providerId !== this.props.match.params.providerId) {
            this.setState(
                {
                    practiceId: this.props.match.params.practiceId,
                    providerId: this.props.match.params.providerId,
                },
                () => this.componentDidMount(),
            );
        }
    }

    async getProvider() {
        let urlParams = { practiceId: this.state.practiceId, providerId: this.state.providerId };
        let cleanResponse = await api.shared.fetch_practice_provider({ urlParams });
        this.setState({
            providerDetails: cleanResponse,
        });
    }

    getPractices() {
        if (
            this.state.providerDetails &&
            this.state.providerDetails.provider_type === "BEHAVIORAL_INTAKE"
        ) {
            (async () => {
                const options = { params: { showLoader: false } };
                const response = await api.admin.fetch_all_practices({ options });
                const intakePracticeOptions = response
                    .filter((practice) => practice["practice_type"] === "Primary Care")
                    .map((practice) => {
                        return {
                            value: practice["id"],
                            label: practice["name"],
                        };
                    });
                const existingIntakePractices = intakePracticeOptions.filter(
                    (option) =>
                        this.state.providerDetails.hasOwnProperty("intake_practice_ids") &&
                        this.state.providerDetails["intake_practice_ids"].includes(option.value),
                );

                this.setState({
                    intakePracticeOptions,
                    existingIntakePractices,
                });
            })();
        }
    }

    getPatientList() {
        (async () => {
            let urlParams = { providerId: this.state.providerId };
            let cleanResponse = await api.admin.fetch_patient_list({ urlParams });
            this.setState({
                patientList: cleanResponse.user,
            });
        })();
    }

    editButtonHandler = () => {
        this.setState((ps) => {
            return { editProviderDetails: !ps.editProviderDetails };
        });
    };

    submitHandler = (formData, actions) => {
        actions.setSubmitting(false);
        const data = { ...formData };
        data["intake_practice_ids"] = data["intake_practice_ids"]
            ? data["intake_practice_ids"].map((option) => (option.value ? option.value : option))
            : [];
        const queryParams = { providerId: this.state.providerId };
        api.provider
            .update_profile({ data, queryParams })
            .then((response) => {
                const existingIntakePractices = this.state.intakePracticeOptions.filter(
                    (option) =>
                        data.hasOwnProperty("intake_practice_ids") &&
                        data["intake_practice_ids"].includes(option.value),
                );
                this.setState({ practiceDetails: data, existingIntakePractices });
                this.editButtonHandler();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    submitHandlerV2 = (formData, actions) => {
        actions.setSubmitting(false);
        const data = { ...formData };
        data["intake_practice_ids"] = data["intake_practice_ids"]
            ? data["intake_practice_ids"].map((option) => (option.value ? option.value : option))
            : [];
        const queryParams = { providerId: this.state.providerId };
        api.provider
            .update_profile({ data, queryParams })
            .then((response) => {
                const existingIntakePractices = this.state.intakePracticeOptions.filter(
                    (option) =>
                        data.hasOwnProperty("intake_practice_ids") &&
                        data["intake_practice_ids"].includes(option.value),
                );
                this.setState({ practiceDetails: data, existingIntakePractices });
                this.editButtonHandler();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    getProviderDetailsInitialValues = (providerDetails) => {
        return {
            name: providerDetails?.name,
            email: providerDetails?.email,
            cp_credentials: providerDetails?.cp_credentials,
            npi_number: providerDetails?.npi_number,
            mobile: providerDetails?.mobile,
            provider_type: providerDetails?.provider_type,
            intake_practice_ids: providerDetails?.intake_practice_ids,
            practice: providerDetails?.practice,
        };
    };

    renderForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 mb-3">
                    <div className="container px-0">
                        <div className="row">
                            {PROVIDER_DETAILS_SCHEMA.map((formEl) => {
                                const error = getIn(formikProps.errors, formEl.name);
                                const touched = getIn(formikProps.touched, formEl.name);
                                return (
                                    <div key={formEl.name} className={"col-12 col-lg-6"}>
                                        {formEl.name === "provider_type" ||
                                        formEl.name === "email" ? (
                                            <FormikInput
                                                disabled
                                                inputBorderDisabled="border-0 px-0"
                                                formEl={formEl}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                            />
                                        ) : formEl.name === "mobile" ? (
                                            <FormikMobileInput
                                                value={formikProps && formikProps.values.mobile}
                                                onChange={async (val) => {
                                                    await formikProps.setFieldValue(
                                                        "mobile",
                                                        val.value,
                                                    );
                                                    formikProps.setFieldTouched("mobile");
                                                }}
                                                formEl={formEl}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                            />
                                        ) : formEl.name === "intake_practice_ids" ? (
                                            <>
                                                {formikProps.values["provider_type"] ===
                                                    "BEHAVIORAL_INTAKE" && (
                                                    <div
                                                        key={formEl.name}
                                                        className={"col-12 col-lg-6"}>
                                                        <Label>{formEl.elementConfig.label}</Label>
                                                        <Select
                                                            components={{ ClearIndicator: null }}
                                                            isMulti
                                                            options={
                                                                this.state.intakePracticeOptions
                                                            }
                                                            defaultValue={
                                                                this.state.existingIntakePractices
                                                            }
                                                            className={"basic-multi-select"}
                                                            classNamePrefix="select"
                                                            onChange={async (selectedOptions) =>
                                                                await formikProps.setFieldValue(
                                                                    formEl.name,
                                                                    selectedOptions,
                                                                )
                                                            }
                                                        />
                                                        {error && touched ? (
                                                            <InputError classes={"custom-error"}>
                                                                {error}
                                                            </InputError>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <FormikInput
                                                formEl={formEl}
                                                errors={formikProps.errors}
                                                touched={formikProps.touched}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="d-lg-flex d-block justify-content-center text-center justify-content-lg-end">
                    <Button
                        type="button"
                        onClick={this.editButtonHandler}
                        className="Btn Btn--otl-pri Btn-sm mr-lg-3 mb-3 mb-lg-0">
                        Cancel
                    </Button>
                    <Button type="submit" className="Btn Btn--pri Btn-sm">
                        Save
                    </Button>
                </div>
            </Form>
        );
    };

    render() {
        if (!this.state.providerDetails || !this.state.patientList) {
            return <div />;
        }

        return (
            <div className="container mt-lg-5 mt-3">
                <div>
                    {!this.state.hideBreadCrumb && (
                        <div className="mx-auto fs-14 my-3 py-2">
                            <Link to={adminRoute + "/practice"}>
                                <p className="d-inline ml-0">Practices</p>
                            </Link>
                            <Link to={adminRoute + "/practice/" + this.state.practiceId}>
                                <p className="d-inline ml-0"> {">"} Practice Details</p>
                            </Link>
                            <span className="fs-14"> {">"} Provider Details</span>
                        </div>
                    )}
                    <h3 className="fs-25 fw-bold mb-3 mb-lg-4">
                        {this.state.providerDetails.name}
                    </h3>
                </div>
                {!this.state.editProviderDetails && (
                    <ProviderDetails
                        existingIntakePractices={this.state.existingIntakePractices}
                        providerDetails={this.state.providerDetails}
                        onclick={this.editButtonHandler}
                    />
                )}
                {this.state.editProviderDetails && (
                    <CardSecondary className="mx-auto container Practice__Details">
                        <div className="d-flex justify-content-between py-4">
                            <CardHeading
                                className="fs-18 m-0 px-3 px-lg-4"
                                text="Provider Details"
                            />
                        </div>
                        <CustomForm
                            initialValues={this.getProviderDetailsInitialValues(this.state.providerDetails)}
                            validationSchema={VALIDATION_SCHEMA}
                            onSubmit={this.submitHandlerV2}
                            render={this.renderForm}
                        />
                    </CardSecondary>
                )}
                {isAuthorized(admin_cse) && (
                    <ProviderPracticeDetails providerDetails={this.state.providerDetails} />
                )}
                <ProviderStatusDetails providerDetails={this.state.providerDetails} />
                <PatientsList
                    {...this.props}
                    group_auth_type="admin"
                    patientList={this.state.patientList}
                    practiceId={this.props.match.params.practiceId}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        flags: state?.flags || {},
    };
};

export default connect(mapStateToProps, {})(Provider);
