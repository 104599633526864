import React, { useState } from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";
import { TextTypes, TextColors, ButtonTypes } from "../constants";

import { Button } from "../Button";
import { Image } from "../Image";
import { Modal } from "../Modal";
import { PageBreak } from "../PageBreak";
import { Text } from "../Text";
import { UploadFile } from "../UploadFile";

import "./styles.scss";

export const UploadFileModal = ({
    className,
    show,
    onFrontFileInputChange,
    onBackFileInputChange,
    onSubmit,
    onCancel,
    ...props
}) => {
    const [showState, setShowState] = useState(show);

    const [frontCardImgName, setFrontCardImgName] = useState(undefined);
    const [backCardImgName, setBackCardImgName] = useState(undefined);

    const hiddenFrontFileInput = React.useRef(null);
    const hiddenBackFileInput = React.useRef(null);

    const handleFrontFileBrowseButtonClick = (event) => {
        hiddenFrontFileInput.current.click();
    };
    const handleBackFileBrowseButtonClick = (event) => {
        hiddenBackFileInput.current.click();
    };

    const handleFrontFileInputChange = (e) => {
        setFrontCardImgName(e.target.files[0].name);
        onFrontFileInputChange(e);
    };

    const handleBackFileInputChange = (e) => {
        setBackCardImgName(e.target.files[0].name);
        onBackFileInputChange(e);
    };

    const onFrontFileDelete = () => setFrontCardImgName(undefined);
    const onBackFileDelete = () => setBackCardImgName(undefined);

    return (
        <Modal
            className={createClassNameString(["apti-UploadFileModal", className])}
            show={true}
            {...props}>
            <div className="apti-UploadFileModal__header">
                <Text type={TextTypes.heading2}>Upload Insurance Card</Text>
                <Image
                    src="icons/delete_button.svg"
                    onClick={() => {
                        setShowState(!showState);
                        onCancel();
                    }}
                    alt="close"
                />
            </div>
            <PageBreak />
            <div className="apti-UploadFileModal__main">
                <Text type={TextTypes.paragraph} color={TextColors.grey}>
                    Upload images of the front and back of your insurance card.
                </Text>
                <UploadFile
                    label="Upload Front of Card"
                    placeholder="Browse files"
                    required
                    files={frontCardImgName ? [frontCardImgName] : []}
                    onFileDelete={onFrontFileDelete}
                    onBrowseButtonClicked={handleFrontFileBrowseButtonClick}
                />
                <input
                    type="file"
                    ref={hiddenFrontFileInput}
                    onChange={handleFrontFileInputChange}
                    style={{ display: "none" }}
                />
                <UploadFile
                    label="Upload Back of Card"
                    placeholder="Browse files"
                    required
                    files={backCardImgName ? [backCardImgName] : []}
                    onFileDelete={onBackFileDelete}
                    onBrowseButtonClicked={handleBackFileBrowseButtonClick}
                />
                <input
                    type="file"
                    ref={hiddenBackFileInput}
                    onChange={handleBackFileInputChange}
                    style={{ display: "none" }}
                />
                <Text type={TextTypes.paragraph} color={TextColors.grey}>
                    <span className="bold">Acceptable file formats:</span> .jpg, .jpeg, .heic, .pdf,
                    .png, .tiff, .tif, .heif.
                    <br />
                    <span className="bold">Max file size:</span> 20MB.
                </Text>
            </div>
            <Button
                className="apti-UploadFileModal__button"
                buttonType={ButtonTypes.primary}
                onClick={onSubmit}>
                Done
            </Button>
        </Modal>
    );
};

UploadFileModal.propTypes = {
    className: PropTypes.string,
    show: PropTypes.bool,
    onFrontFileInputChange: PropTypes.func,
    onBackFileInputChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
};

UploadFileModal.defaultProps = {
    className: undefined,
    show: false,
    onFrontFileInputChange: () => {},
    onBackFileInputChange: () => {},
    onSubmit: () => {},
    onCancel: () => {},
};
