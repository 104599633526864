import moment from "moment-timezone";
import PropTypes from "prop-types";
import * as eventConstants from "../../constants/event";
import { isAuthorized } from "../../redux/actions/auth";
import { convertToMultiDaySlots } from "./timezoneConversion";

const PATIENT_CANCELLATION_TYPES = {
    patientNoShow: {
        isCancelled: true,
        text: "Patient No-Show",
    },
    providerCancelled: {
        isCancelled: true,
        text: "Provider Cancelled",
    },
    patientCancelled: {
        isCancelled: true,
        text: "Patient Cancelled",
    },
    cancelled: {
        isCancelled: true,
        text: "Cancelled",
    },
    reschedulePatient: {
        isCancelled: true,
        text: "Patient Rescheduled",
    },
    rescheduleProvider: {
        isCancelled: true,
        text: "Provider Rescheduled",
    },
    reschedule: {
        isCancelled: true,
        text: "Rescheduled",
    },
};

const PATIENT_CANCELLATION_TYPES_V2 = {
    patientNoShow: {
        isCancelled: true,
        text: "Patient No-Show",
    },
    providerCancelled: {
        isCancelled: true,
        text: "Provider Cancelled",
    },
    patientCancelled: {
        isCancelled: true,
        text: "Patient Cancelled",
    },
    adminCancelled: {
        isCancelled: true,
        text: "aptihealth Cancelled",
    },
    cancelled: {
        isCancelled: true,
        text: "System Cancelled",
    },
    reschedulePatient: {
        isCancelled: true,
        text: "Patient Rescheduled",
    },
    rescheduleProvider: {
        isCancelled: true,
        text: "Provider Rescheduled",
    },
    reschedule: {
        isCancelled: true,
        text: "aptihealth Rescheduled",
    },
};

const CANCELLATION_REASONS = {
    user: {
        conflict_with_time: "Conflict with time",
        illness_medical: "Illness/Medical",
        need_sooner_appointment: "Need sooner appointment",
        other: "Other",
    },
    provider: {
        conflict_self: "The provider has a conflict with this time",
        conflict_member: "The patient has a conflict with this time",
        other: "Other",
    },
    admin: {
        conflict_self: "The provider has a conflict with this time",
        conflict_member: "The patient has a conflict with this time",
        other: "Other",
    },
};

const patientStatusType = PropTypes.shape({
    text: PropTypes.string.isRequired,
    isCancelled: PropTypes.bool,
});

const AWAY_TIME_TYPES = {
    personal: {
        text: "Personal",
    },
    sick: {
        text: "Sick",
    },
};

const awayTimeType = PropTypes.shape({ text: PropTypes.string.isRequired });

/**
 *
 * @param {object[]} schedule
 * @param {string} currentTimeZone
 * @param {import("moment").Moment[]} daysToDisplay
 */
const scheduleToEvents = (schedule, currentTimeZone, daysToDisplay) => {
    const allEvents = schedule
        .map((event) => {
            const oldStartTime = moment.utc(event.timestamp, "YYYY-MM-DD HH:mm");
            const oldEndTime = oldStartTime.clone().add(event.allotted_time, "minutes");

            const startTime = oldStartTime.clone().tz(currentTimeZone);
            const endTime = oldEndTime.clone().tz(currentTimeZone);

            // accounts for times shifting over multiple days due to TZ conversion
            const slots = convertToMultiDaySlots(startTime, endTime);

            // create a object for each event
            const newEventObjects = slots.map((slot) => {
                return {
                    callId: event.callId,
                    startTime: slot.startTime,
                    endTime: slot.endTime,
                    details: event,
                };
            });

            return newEventObjects;
        })
        .flat();

    // filter events to only those that exist on a displayed day
    const filteredEvents = allEvents.filter((event) => {
        const shouldDisplay = daysToDisplay.some((dayToDisplay) => {
            return event.startTime.isSame(dayToDisplay, "day");
        });

        return shouldDisplay;
    });

    return filteredEvents;
};

const getPatientSessionStatus = (eventDetails) => {
    // by default status is undefined
    let status;
    const cancellationReason = eventDetails?.cancellation_reason;
    const cancelledByUserType = cancellationReason?.cancelled_by_user_type;

    if (!cancellationReason && eventDetails?.deleted && !eventDetails.rescheduled_as) {
        status = PATIENT_CANCELLATION_TYPES.cancelled;
    } else if (cancellationReason?.reason_type === "no_show") {
        status = PATIENT_CANCELLATION_TYPES.patientNoShow;
    } else if (eventDetails.rescheduled_as) {
        if (cancelledByUserType?.includes("patient")) {
            status = PATIENT_CANCELLATION_TYPES.reschedulePatient;
        } else if (cancelledByUserType?.includes("provider")) {
            status = PATIENT_CANCELLATION_TYPES.rescheduleProvider;
        } else {
            status = PATIENT_CANCELLATION_TYPES.reschedule;
        }
    } else if (eventDetails?.deleted) {
        if (cancelledByUserType?.includes("patient")) {
            status = PATIENT_CANCELLATION_TYPES.patientCancelled;
        } else if (cancelledByUserType?.includes("provider")) {
            status = PATIENT_CANCELLATION_TYPES.providerCancelled;
        } else {
            status = PATIENT_CANCELLATION_TYPES.cancelled;
        }
    }
    return status;
};

const getPatientSessionStatusV2 = (eventDetails) => {
    // by default status is undefined
    let status;
    const cancellationReason = eventDetails?.cancellation_reason;
    const cancelledByUserType = cancellationReason?.cancelled_by_user_type;

    if (
        cancellationReason &&
        cancellationReason.reason_type !== "no_show" &&
        cancellationReason.reason_type !== "auto" &&
        eventDetails?.deleted
    ) {
        // Cancellations
        if (cancelledByUserType?.includes("patient")) {
            status = PATIENT_CANCELLATION_TYPES_V2.patientCancelled;
        } else if (cancelledByUserType?.includes("provider")) {
            status = PATIENT_CANCELLATION_TYPES_V2.providerCancelled;
        } else if (cancelledByUserType?.includes("admin") || eventDetails.admin_name) {
            status = PATIENT_CANCELLATION_TYPES_V2.adminCancelled;
        }
    } else if (cancellationReason && cancellationReason.reason_type === "auto") {
        // Auto-cancellation
        status = PATIENT_CANCELLATION_TYPES_V2.cancelled;
    } else if (eventDetails.rescheduled_as) {
        // Reschedules
        if (eventDetails.updated_by === eventDetails.patient_username) {
            status = PATIENT_CANCELLATION_TYPES_V2.reschedulePatient;
        } else if (eventDetails.updated_by === eventDetails.provider_username) {
            status = PATIENT_CANCELLATION_TYPES_V2.rescheduleProvider;
        } else {
            status = PATIENT_CANCELLATION_TYPES_V2.reschedule;
        }
    } else if (cancellationReason?.reason_type === "no_show") {
        // No show
        status = PATIENT_CANCELLATION_TYPES_V2.patientNoShow;
    }

    return status ? status : "";
};

const getCancellationReason = (user, reason) => {
    return CANCELLATION_REASONS[user][reason];
};

export const allottedTimeFactory = (type) => {
    switch (type) {
        case eventConstants.AWAY:
            return 30;
        case eventConstants.VIDEO_CONSULTATION:
            return 30;
        case eventConstants.DIAGNOSTIC_INTERVIEW_90791:
            return 45;
        case eventConstants.DIAGNOSTIC_INTERVIEW_90792:
            return 60;
        case eventConstants._30_MIN_INDIVIDUAL_90832:
            return 30;
        case eventConstants._45_MIN_INDIVIDUAL_90834:
            return 45;
        case eventConstants._60_MIN_INDIVIDUAL_90837:
            return 60;
        case eventConstants.FAMILY_THERAPY_WO_PATIENT_90846:
            return 45;
        case eventConstants.CRISIS_EVAL_90839:
            return isAuthorized("provider:prescribe") ? 15 : 45;
        case eventConstants.CRISIS_EVAL_EXTENDED_90840:
            return 75;
        case eventConstants.FAMILY_THERAPY_90847:
            return 45;
        case eventConstants.MEDICATION_MGMT_99212:
            return 15;
        case eventConstants.MEDICATION_MGMT_99213:
            return 15;
        case eventConstants.MEDICATION_MGMT_99214:
            return 30;
        case eventConstants._5_10_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98966:
            return 10;
        case eventConstants._11_20_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98967:
            return 20;
        case eventConstants._21_30_PHONE_ASSESSMENT_AND_MGMT_VS_MED_DISCUSSION_98968:
            return 30;
        case eventConstants.CM_SERVICES_20_MINS_MINIMUM_MONTH:
            return 20;
        case eventConstants.OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204:
            return 60;
        case eventConstants.OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205:
            return 75;
        case eventConstants.ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215:
            return 45;
        default:
            return 30;
    }
};

export const DEFAULT_PRESCRIBER_SCHEDULE_OPTIONS = [
    // NOTE: these are label not display. won't work in ShedulePage
    { value: eventConstants.AWAY, label: "Away" },
    { value: eventConstants.AVAILABLE, label: "Available" },
    {
        value: eventConstants.DIAGNOSTIC_INTERVIEW_90792,
        label: `Diagnostic Interview (90792) (${allottedTimeFactory(
            eventConstants.DIAGNOSTIC_INTERVIEW_90792,
        )} minutes)`,
    },
    {
        value: eventConstants.MEDICATION_MGMT_99212,
        label: `Medication Management (99212) (${allottedTimeFactory(
            eventConstants.MEDICATION_MGMT_99212,
        )} minutes)`,
    },
    {
        value: eventConstants.MEDICATION_MGMT_99213,
        label: `Medication Management (99213) (${allottedTimeFactory(
            eventConstants.MEDICATION_MGMT_99213,
        )} minutes)`,
    },
    {
        value: eventConstants.MEDICATION_MGMT_99214,
        label: `Medication Management (99214) (${allottedTimeFactory(
            eventConstants.MEDICATION_MGMT_99214,
        )} minutes)`,
    },
    {
        value: eventConstants.CRISIS_EVAL_90839,
        label: `Crisis Evaluation (90839) (${allottedTimeFactory(
            eventConstants.CRISIS_EVAL_90839,
        )} minutes)`,
    },
    {
        value: eventConstants.ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
        label: `Established Patient Office or Other Outpatient Services (99215) (${allottedTimeFactory(
            eventConstants.ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
        )} minutes)`,
    },
];

export const DEFAULT_BEHAVIORAL_SCHEDULE_OPTIONS = [
    { value: eventConstants.AWAY, label: "Away" },
    { value: eventConstants.AVAILABLE, label: "Available" },
    {
        value: eventConstants.DIAGNOSTIC_INTERVIEW_90791,
        label: `Diagnostic Interview (90791) (${allottedTimeFactory(
            eventConstants.DIAGNOSTIC_INTERVIEW_90791,
        )} minutes)`,
    },
    {
        value: eventConstants._30_MIN_INDIVIDUAL_90832,
        label: `30 Minute Individual (90832) (${allottedTimeFactory(
            eventConstants._30_MIN_INDIVIDUAL_90832,
        )} minutes)`,
    },
    {
        value: eventConstants._45_MIN_INDIVIDUAL_90834,
        label: `45 Minute Individual (90834) (${allottedTimeFactory(
            eventConstants._45_MIN_INDIVIDUAL_90834,
        )} minutes)`,
    },
    {
        value: eventConstants._60_MIN_INDIVIDUAL_90837,
        label: `60 Minute Individual (90837) (${allottedTimeFactory(
            eventConstants._60_MIN_INDIVIDUAL_90837,
        )} minutes)`,
    },
    {
        value: eventConstants.FAMILY_THERAPY_90847,
        label: `Family Therapy w/ Patient (90847) (${allottedTimeFactory(
            eventConstants.FAMILY_THERAPY_90847,
        )} minutes)`,
    },
    {
        value: eventConstants.FAMILY_THERAPY_WO_PATIENT_90846,
        label: `Family Therapy w/o Patient (90846) (${allottedTimeFactory(
            eventConstants.FAMILY_THERAPY_WO_PATIENT_90846,
        )} minutes)`,
    },
    {
        value: eventConstants.CRISIS_EVAL_90839,
        label: `Crisis Evaluation (90839) (${allottedTimeFactory(
            eventConstants.CRISIS_EVAL_90839,
        )} minutes)`,
    },
    {
        value: eventConstants.CRISIS_EVAL_EXTENDED_90840,
        label: `Crisis Evaluation Extended (90840) (${allottedTimeFactory(
            eventConstants.CRISIS_EVAL_EXTENDED_90840,
        )} minutes)`,
    },
    {
        value: eventConstants.OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
        label: `Office or Other Outpatient Visit w/ New Patient (99204) (${allottedTimeFactory(
            eventConstants.OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
        )} minutes)`,
    },
    {
        value: eventConstants.OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
        label: `Office or Other Outpatient Visit w/ New Patient (99205) (${allottedTimeFactory(
            eventConstants.OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
        )} minutes)`,
    },
];

export {
    AWAY_TIME_TYPES,
    PATIENT_CANCELLATION_TYPES,
    PATIENT_CANCELLATION_TYPES_V2,
    awayTimeType,
    getPatientSessionStatus,
    getPatientSessionStatusV2,
    getCancellationReason,
    patientStatusType,
    scheduleToEvents,
};
