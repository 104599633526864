import moment from "moment-timezone";
import { eventTypeDisplayNameFactory } from "../../../../Provider/ScheduleAvailability/ActiveScheduleDetails";
import _ from "lodash";
import { MENTAL_STATUS_QUESTIONS } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotesMentalStatus";
import { LAB_FINDING_QUESTIONS } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotesLabFindings";
import { SUBSTANCE_ABUSE_QUESTIONS } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotesSymptomTrend";
import { copyFields } from "../../../../../utils/objectUtils";
import { SIDE_EFFECT_QUESTIONS } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotesSideEffects";
import { ADHERENCE_INTERVENTIONS_QUESTIONS } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotesAdherenceInterventions";

export const transformPatientQuestionsToSection = (
    questionSet,
    relevantSections,
    addNone = false,
    answerMapper = null,
) => {
    let reducedEntries = {};
    relevantSections.forEach((sectionId) => {
        let section = questionSet[sectionId];

        if (section == null) {
            return;
        }

        let questions = ["rating", "gad", "phq"].includes(section.type)
            ? section.questions
            : section.options;
        Object.entries(questions).forEach(([key, value]) => {
            reducedEntries[key] = value;

            if (answerMapper) {
                reducedEntries[key].answer = answerMapper(value.answer);
            }

            reducedEntries[key].label = reducedEntries[key].label
                ? reducedEntries[key].label
                : section.header;
            reducedEntries[key].type = section.type;
            if (section.screening_type) {
                reducedEntries[key].screening_type = section.screening_type;
            }
            if (section.rating_labels) {
                reducedEntries[key].rating_labels = section.rating_labels;
            }
        });
    });

    if (addNone) {
        reducedEntries = {
            none: {
                label: "None",
                answer: false,
            },
            ...reducedEntries,
        };
    }

    return reducedEntries;
};

export const carePlanToObjectives = (carePlan) => {
    let objectives = carePlan.objectives ? carePlan.objectives : [];

    if (!Array.isArray(objectives)) {
        objectives = ["objective_one", "objective_two", "objective_three"].map((objectiveKey) =>
            objectives[objectiveKey] ? objectives[objectiveKey] : "",
        );
    }

    return objectives.map((objective) => {
        return {
            label: objective,
            answer: null,
            actions: "",
            plan: "",
        };
    });
};

export const joinDiagnosis = (patientDiagnosis) => {
    const axis1 = patientDiagnosis.ICD_10_axis_1_diagnosis
        ? patientDiagnosis.ICD_10_axis_1_diagnosis
        : [];
    const axis2 = patientDiagnosis.ICD_10_axis_2_diagnosis;
    let allDiagnosis = _.cloneDeep(axis1);

    if (axis2) {
        allDiagnosis = allDiagnosis.concat(_.cloneDeep(axis2));
    }

    return allDiagnosis;
};

export const transformDiagnosisToProgress = (patientDiagnosis) => {
    const allDiagnosis = joinDiagnosis(patientDiagnosis);

    return allDiagnosis.map((diagnosis) => {
        return {
            label: diagnosis.label,
            answer: null,
            assessment: "",
            plan: "",
        };
    });
};

export const createEmptyNote = (callDetails, version, patientDiagnosis) => {
    let timestamp = moment.utc(callDetails.timestamp);
    let sessionDate = timestamp.local().format("MM/DD/YYYY");
    let sessionFrom = timestamp.local();
    let sessionTo = moment
        .utc(callDetails.timestamp)
        .add(moment.duration(callDetails.allotted_time, "minutes"))
        .local();

    const providerType = callDetails.provider_role;

    let title;
    if (providerType.startsWith("BEHAVIORAL")) {
        title = `Therapy Session ${sessionDate} - (${sessionFrom.format(
            "hh:mm A",
        )}-${sessionTo.format("hh:mm A")})`;
    } else {
        title = `Prescriber Guided Session ${sessionDate} - (${sessionFrom.format(
            "hh:mm A",
        )}-${sessionTo.format("hh:mm A")})`;
    }

    return {
        title: title,
        call_id: callDetails.callId,
        call_name: eventTypeDisplayNameFactory(callDetails.event_type),
        provider_type: providerType,
        session_date: sessionDate,
        session_from: sessionFrom.format("HH:mm"),
        session_to: sessionTo.format("HH:mm"),
        note_state: "draft",
        content: {},
        ICD_10_axis_1_diagnosis:
            patientDiagnosis && patientDiagnosis.ICD_10_axis_1_diagnosis
                ? patientDiagnosis.ICD_10_axis_1_diagnosis
                : [],
        ICD_10_axis_2_diagnosis:
            patientDiagnosis && patientDiagnosis.ICD_10_axis_2_diagnosis
                ? patientDiagnosis.ICD_10_axis_2_diagnosis
                : [],
        version,
    };
};

const createSkinnyNote = (note, mainFields, contentFields) => {
    let transformedNote = {
        content: {},
    };

    copyFields(note, transformedNote, mainFields);

    if (note.content) {
        copyFields(note.content, transformedNote.content, contentFields);
    }

    return transformedNote;
};

export const enrichBehavioralNoteContent = (
    patientDetails,
    questionSet,
    relevantSections,
    notesMap,
    patientDiagnosis,
) => {
    let initializeValues = true;
    let enrichedContent = {
        goals_objectives_progress: null,
        symptom_trends_substances: null,
        symptom_trend_notes: "",
        exploration_notes: "",
        mental_status: _.cloneDeep(MENTAL_STATUS_QUESTIONS),
        call_timer: "",
        patient_no_show_reason: "",
        patient_no_show: false,
    };

    if (notesMap.existingNote && notesMap.existingNote.content) {
        initializeValues = false;
        copyFields(notesMap.existingNote.content, enrichedContent, Object.keys(enrichedContent));
        // don't want to add to initial def of enrichedContent to avoid always having it be set
        if (notesMap.existingNote.content.hasOwnProperty("discharges")) {
            enrichedContent["discharges"] = _.cloneDeep(notesMap.existingNote.content.discharges);
        }
    } else if (notesMap.lastNote && notesMap.lastNote.content) {
        copyFields(notesMap.lastNote.content, enrichedContent, ["mental_status"]);
    }

    if (initializeValues) {
        let carePlan = patientDetails.care_plan ? patientDetails.care_plan : {};
        enrichedContent.goals_objectives_progress = carePlanToObjectives(carePlan);
        enrichedContent.symptom_trends_substances = _.cloneDeep(SUBSTANCE_ABUSE_QUESTIONS);
    }

    enrichedContent.symptom_trends = transformPatientQuestionsToSection(
        questionSet,
        relevantSections.symptomTrends,
    );
    enrichedContent.changes = transformPatientQuestionsToSection(
        questionSet,
        relevantSections.changes,
        true,
        (answer) => {
            if (typeof answer === "boolean") {
                return answer;
            } else {
                return answer !== 2 && answer != null;
            }
        },
    );
    enrichedContent.health_care_use = transformPatientQuestionsToSection(
        questionSet,
        relevantSections.healthCareUse,
    );

    let lastPrescriberNote = _.get(notesMap, "lastNotesOther.PRESCRIBE");
    let skinnyPrescriberNote = null;
    if (lastPrescriberNote) {
        skinnyPrescriberNote = createSkinnyNote(
            lastPrescriberNote,
            ["id", "signature", "session_date", "version"],
            ["medications"],
        );
    }
    enrichedContent.last_prescribe_note = skinnyPrescriberNote;

    return enrichedContent;
};

const DEFAULT_PRESCRIPTION_MONITORING = {
    type: "I-STOP",
    last_viewed: null,
    clicked: false,
};

export const enrichPrescribeNoteContent = (
    patientDetails,
    questionSet,
    relevantSections,
    notesMap,
    patientDiagnosis,
) => {
    let initializeValues = true;
    let enrichedContent = {
        diagnosis_progress: null,
        discharges: [],
        symptom_trend_notes: "",
        medication_impact_notes: "",
        prescription_monitoring: _.cloneDeep(DEFAULT_PRESCRIPTION_MONITORING),
        medical_information_notes: "",
        medications: [],
        side_effects: null,
        mental_status: _.cloneDeep(MENTAL_STATUS_QUESTIONS),
        lab_findings: null,
        adherence_interventions: null,
        call_timer: "",
        patient_no_show_reason: "",
        patient_no_show: false,
    };

    if (notesMap.existingNote && notesMap.existingNote.content) {
        initializeValues = false;
        copyFields(notesMap.existingNote.content, enrichedContent, Object.keys(enrichedContent));
    } else if (notesMap.lastNote && notesMap.lastNote.content) {
        copyFields(notesMap.lastNote.content, enrichedContent, [
            "mental_status",
            "medications",
            "prescription_monitoring",
        ]);
        enrichedContent.medications = enrichedContent.medications.filter(
            (medication) => medication.med_changes !== "DISCONTINUE",
        );
        enrichedContent.medications = enrichedContent.medications.map((medication) => {
            if (medication.med_changes === "ADD") {
                medication.med_changes = "MAINTAIN";
            }
            return medication;
        });
    }
    if (initializeValues) {
        enrichedContent.diagnosis_progress = patientDiagnosis
            ? transformDiagnosisToProgress(patientDiagnosis)
            : [];
        enrichedContent.side_effects = _.cloneDeep(SIDE_EFFECT_QUESTIONS);
        enrichedContent.lab_findings = _.cloneDeep(LAB_FINDING_QUESTIONS);
        enrichedContent.adherence_interventions = _.cloneDeep(ADHERENCE_INTERVENTIONS_QUESTIONS);
    }

    enrichedContent.symptom_trends = transformPatientQuestionsToSection(
        questionSet,
        relevantSections.symptomTrends,
    );
    enrichedContent.medication_trends = transformPatientQuestionsToSection(
        questionSet,
        relevantSections.medicationTrends,
    );
    enrichedContent.medication_difficulties = transformPatientQuestionsToSection(
        questionSet,
        relevantSections.medicationDifficulties,
    );
    enrichedContent.medical_problems = transformPatientQuestionsToSection(
        questionSet,
        relevantSections.medicalProblems,
    );
    let lastBehavioralNote = _.get(notesMap, "lastNotesOther.BEHAVIORAL");
    let skinnyBehavioralNote = null;
    if (lastBehavioralNote) {
        skinnyBehavioralNote = createSkinnyNote(
            lastBehavioralNote,
            ["id", "signature", "session_date", "version"],
            ["symptom_trends", "symptom_trends_substances", "changes", "health_care_use"],
        );
    }
    enrichedContent.last_behavioral_note = skinnyBehavioralNote;
    return enrichedContent;
};
