/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const TrashCanIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="TrashCan"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 4C13.4477 4 13 4.44772 13 5H19C19 4.44772 18.5523 4 18 4H14ZM8 7C7.44772 7 7 7.44772 7 8V9H7.99343C7.99841 8.99996 8.00338 8.99996 8.00834 9H23.9917C23.9966 8.99996 24.0016 8.99996 24.0066 9H25V8C25 7.44772 24.5523 7 24 7H8ZM24.946 11H27V8C27 6.34315 25.6569 5 24 5H21C21 3.34315 19.6569 2 18 2H14C12.3431 2 11 3.34315 11 5H8C6.34315 5 5 6.34315 5 8V11H7.05401L7.94907 27.1109C8.00795 28.1708 8.88452 29 9.94599 29H22.054C23.1155 29 23.9921 28.1708 24.0509 27.1109L24.946 11ZM22.9429 11H9.0571L9.94599 27H22.054L22.9429 11ZM14 14C14.5523 14 15 14.4477 15 15V23C15 23.5523 14.5523 24 14 24C13.4477 24 13 23.5523 13 23V15C13 14.4477 13.4477 14 14 14ZM18 14C18.5523 14 19 14.4477 19 15V23C19 23.5523 18.5523 24 18 24C17.4477 24 17 23.5523 17 23V15C17 14.4477 17.4477 14 18 14Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="TrashCan"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 2C12.3431 2 11 3.34315 11 5H8C6.34315 5 5 6.34315 5 8V9H27V8C27 6.34315 25.6569 5 24 5H21C21 3.34315 19.6569 2 18 2H14ZM14 4C13.4477 4 13 4.44772 13 5H19C19 4.44772 18.5523 4 18 4H14Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.0606 10H6.93945L7.94636 27.1174C8.00855 28.1746 8.88396 29 9.94291 29H22.0571C23.1161 29 23.9915 28.1746 24.0536 27.1174L25.0606 10ZM14 14C14.5523 14 15 14.4477 15 15V23C15 23.5523 14.5523 24 14 24C13.4477 24 13 23.5523 13 23V15C13 14.4477 13.4477 14 14 14ZM18 14C18.5523 14 19 14.4477 19 15V23C19 23.5523 18.5523 24 18 24C17.4477 24 17 23.5523 17 23V15C17 14.4477 17.4477 14 18 14Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="TrashCan"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM16.25 11.75C16.25 10.5074 17.2574 9.5 18.5 9.5H21.5C22.7426 9.5 23.75 10.5074 23.75 11.75H26C27.2426 11.75 28.25 12.7574 28.25 14V14.75H11.75V14C11.75 12.7574 12.7574 11.75 14 11.75H16.25ZM17.75 11.75C17.75 11.3358 18.0858 11 18.5 11H21.5C21.9142 11 22.25 11.3358 22.25 11.75H17.75ZM13.9598 28.3381L13.2046 15.5H26.7954L26.0402 28.3381C25.9936 29.1309 25.337 29.75 24.5428 29.75H15.4572C14.663 29.75 14.0064 29.1309 13.9598 28.3381ZM19.25 19.25C19.25 18.8358 18.9142 18.5 18.5 18.5C18.0858 18.5 17.75 18.8358 17.75 19.25V25.25C17.75 25.6642 18.0858 26 18.5 26C18.9142 26 19.25 25.6642 19.25 25.25V19.25ZM22.25 19.25C22.25 18.8358 21.9142 18.5 21.5 18.5C21.0858 18.5 20.75 18.8358 20.75 19.25V25.25C20.75 25.6642 21.0858 26 21.5 26C21.9142 26 22.25 25.6642 22.25 25.25V19.25Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

TrashCanIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

TrashCanIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
