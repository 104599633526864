// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Fragment } from "react";

//components
import Badge from "../../../../components/UI/Badge";
import Input from "../../../../components/UI/Input";

//utils
import { formatMobileNumber, toTitleCase } from "../../../../utils/filters";
import moment from "moment-timezone";
import { ACUITY_INDEX } from "../../../../constants/acuity";
//constants

const ActivePatientListItem = (props) => {
    const { index, userType, patient, setPatientDetails, badgeStyle, flags } = props;

    let {
        patient_id,
        first_name,
        last_name,
        patient_status,
        initial_video_consult,
        referred_date,
        dob,
        acuity_score,
    } = patient;

    const patientName = first_name + " " + last_name;
    const createdDate = referred_date
        ? moment(parseFloat(referred_date) * 1000).format("MM/DD/YYYY")
        : null;

    if (patient_status === "EXTENDED CARE") {
        patient_status = "Ext. Care";
    }

    const formatDob = (dob) => {
        let [month, day, year] = dob.split("/");
        day = day.replace(/^0+/, "");
        month = month.replace(/^0+/, "");
        return `${month}/${day}/${year}`;
    };

    let desktopListItem = (
        <div className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
            <span className="col-2">
                <span>{`${toTitleCase(patientName)}, `}</span>
                <span className={"DateOfBirth"}>{formatDob(dob)}</span>
            </span>
            <span className="col-1">
                <span>{acuity_score ?? ""}</span>
                <span className={"AcuityDescription"}>
                    {acuity_score ? ` ${ACUITY_INDEX[acuity_score].label}` : null}
                </span>
            </span>
            <span
                className="col-3"
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                data-toggle="tooltip"
                data-placement="top"
                title={patient.email}>
                {patient.email}
            </span>
            <div className="col-1 d-flex justify-content-center">
                {formatMobileNumber(patient.mobile)}
            </div>
            <div className="col-1 d-flex justify-content-center">{createdDate}</div>
            <span className="col-1 d-flex justify-content-center">
                {initial_video_consult && (
                    <Input
                        type="checkbox"
                        name="initial_video_consult"
                        checked={initial_video_consult ? "checked" : null}
                        disabled
                        className="AddPatientInput"
                    />
                )}
            </span>
            <div className="col-2 d-flex justify-content-center">
                <Badge type={badgeStyle} text={patient_status} />
            </div>
            <div className="col-1 d-flex justify-content-center">
                <div
                    className={"ViewChartButton d-flex justify-content-center align-items-center"}
                    onClick={() => setPatientDetails(patient_id, patientName)}>
                    View Chart
                </div>
            </div>
        </div>
    );

    let mobileListItem = (
        <div className="d-flex d-lg-none justify-content-between">
            <div
                className="d-flex fs-14 flex-column"
                style={{ width: "65%", wordWrap: "break-word" }}>
                <span className="fs-16 fw-bold">{toTitleCase(first_name + " " + last_name)}</span>
                <span>{patient.email}</span>
                <span>{formatMobileNumber(patient.mobile)}</span>
            </div>
            <div className="d-flex fs-14 flex-column">
                <div>
                    <Badge type={badgeStyle} text={patient_status} />
                </div>
                <div>
                    <span>{createdDate}</span>
                </div>
                <div>{patient.is_spmi ? "SPMI ✓" : null}</div>
                <div>{initial_video_consult ? "First Session ✓" : null}</div>
            </div>
        </div>
    );

    return (
        <Fragment>
            {mobileListItem}
            {desktopListItem}
        </Fragment>
    );
};

export default ActivePatientListItem;
