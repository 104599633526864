import React from "react";
import { BehavioralReviewLastNotes, PrescribeReviewLastNotes } from "./ReviewLastNotes";
import { DisplayWorkflowStepWithVideoClient } from "./DisplayWorkflowStepWithVideoClient";
import {
    BehavioralActions,
    BehavioralExploration,
    BehavioralInterventions,
    BehavioralSymptomTrends,
} from "./BehavioralWorkflowSteps";
import { ScheduleNextCall } from "./ScheduleNextCall";
import { Signoff } from "./Signoff";
import { enrichBehavioralNoteContent, enrichPrescribeNoteContent } from "./WorkflowFunctions";
import { withRouter } from "react-router-dom";
import images from "../../../../../utils/images";
import { providerTypeToDefaultEvent } from "../../../../Provider/PatientView/MemberProfile/MemberProfileHeader";
import {
    _30_MIN_INDIVIDUAL_90832,
    _45_MIN_INDIVIDUAL_90834,
    _60_MIN_INDIVIDUAL_90837,
    DIAGNOSTIC_INTERVIEW_90791,
    DIAGNOSTIC_INTERVIEW_90792,
    ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215,
    FAMILY_THERAPY_90847,
    MEDICATION_MGMT_99212,
    MEDICATION_MGMT_99213,
    MEDICATION_MGMT_99214,
    FAMILY_THERAPY_WO_PATIENT_90846,
    OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205,
    OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204,
} from "../../../../Provider/ScheduleAvailability/constants";
import {
    PrescribeMedicalInformation,
    PrescribeMedicationImpact,
    PrescribeAssessmentPlan,
    PrescribeSymptomTrends,
} from "./PrescribeWorkflowSteps";

export const BEHAVIORAL_NOTE_V2_WORKFLOW_STEPS = () => [
    {
        header: "Review Previous Session Summary",
        short: "Review",
        component: BehavioralReviewLastNotes,
    },
    {
        caption: "Symptom Trends",
        short: "Symptoms",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: BehavioralSymptomTrends,
        startCall: true,
        carePlanTips: [
            "Explore overall progress",
            "Note symptoms/frequency/duration/intensity",
            "Identify triggers/precipitants/situations when symptoms occur",
            "Explore self-ratings of symptom progress",
            "Explore/reconcile patient conversation of symptom progress self-ratings",
            "Complete substance use ratings",
        ],
    },
    {
        caption: "Exploration",
        short: "Exploration",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: BehavioralExploration,
        saveNote: true,
        carePlanTips: [
            "Explore emotional reactions to symptoms and situations",
            "Explore coping strategies/change efforts and results",
            "Explore impact on motivation or confidence",
            "Explore/reconcile situational changes and healthcare use change/updates and healthcare use",
            "Complete mental status",
        ],
    },
    {
        caption: "Interventions",
        short: "Interventions",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: BehavioralInterventions,
        saveNote: true,
        carePlanTips: [
            "Explore ways of changing/feeling better/improve coping/changing triggers",
            "Use any modalities (i.e. CBT, DBT, BA, etc.)",
            "Target actions aligned with goals",
            "Consider action purpose: support, acknowledge, validate, consider, re-examine, re-experience, interpret, instruct, etc.",
            "Rate progress towards goal",
        ],
    },
    {
        caption: "Plan Between Sessions",
        short: "Plans",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: BehavioralActions,
        saveNote: true,
        carePlanTips: [
            "Build from therapeutic actions (e.g. ways of changing/feeling better, practice re-examination, etc.)",
            "Provider rationale/be encouraging",
            "Practice in session/illustrate impact",
            "Help allocate time/define situation",
            "Troubleshoot obstacles",
            "Plan check in",
        ],
    },
    {
        caption: "Schedule Next Appointment",
        short: "Schedule",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: ScheduleNextCall,
        saveNote: true,
        customTransition: true,
        carePlanTips: [
            "Schedule same time every week",
            "Schedule time even if it needs to be rescheduled",
            "Provide positive feedback",
            "Remind check in",
        ],
    },
    {
        header: "Review Session Summary",
        short: "Sign-Off",
        component: Signoff,
        endCall: true,
        saveNote: true,
    },
];

export const BEHAVIORAL_NOTE_V2_WORKFLOW_DETAILS = () => {
    return {
        viewLastNoteSelf: true,
        viewLastNoteOther: "PRESCRIBE",
        createNote: true,
        noteVersion: 2,
        noteEnricher: enrichBehavioralNoteContent,
        relevantPatientQuestionSections: {
            symptomTrends: [0, 3, 4],
            changes: [1],
            healthCareUse: [2],
        },
        iconComponents: [],
        startingStep: -1,
        endCallShouldRoute: false,
    };
};

export const BEHAVIORAL_CLINICAL_ASSESSMENT_WORKFLOW_DETAILS = () => {
    return {
        viewLastNoteSelf: false,
        viewLastNoteOther: null,
        createNote: false,
        createClinicalAssessmentNote: true,
        iconComponents: [],
        startingStep: 0,
        endCallShouldRoute: true,
        skipEndCallModal: false,
        showNoShowModalOnly: true,
    };
};

export const PRESCRIBE_NOTE_V2_WORKFLOW_STEPS = () => [
    {
        header: "Review Previous Session Summary",
        short: "Review",
        component: PrescribeReviewLastNotes,
    },
    {
        caption: "Symptom Trends",
        short: "Symptoms",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: PrescribeSymptomTrends,
        startCall: true,
        carePlanTips: [
            "Explore overall progress",
            "Note symptoms/frequency/duration/intensity",
            "Identify triggers or precipitants impacting symptoms",
            "Explore patient feedback",
            " Complete prescriber rating of symptom duration",
        ],
    },
    {
        caption: "Medication Impact",
        short: "Medication",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: PrescribeMedicationImpact,
        saveNote: true,
        carePlanTips: [
            "Review current impact of medications",
            "Review patient report re: medication experiences",
            "Utilize I-STOP if clinically warranted",
            "Complete prescriber rating regarding benefit and side effects",
        ],
    },
    {
        caption: "Medical Information",
        short: "Medical",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: PrescribeMedicalInformation,
        saveNote: true,
        carePlanTips: [
            "Note Psychosocial changes",
            "Explore healthcare use",
            "Review labs as indicated",
            "Complete mental status ",
        ],
    },
    {
        caption: "Assessment & Plan",
        short: "Assessment",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: PrescribeAssessmentPlan,
        saveNote: true,
        carePlanTips: [
            "Rate patient progress across each diagnosis",
            "Complete assessment and plan",
            "Enter medication ( medication initiated or adjusted, dosage & change)",
            "Address medication adherence and check adherence interventions",
        ],
    },
    {
        caption: "Schedule Next Appointment",
        short: "Schedule",
        component: DisplayWorkflowStepWithVideoClient,
        subComponent: ScheduleNextCall,
        saveNote: true,
        customTransition: true,
        carePlanTips: ["Try to maintain regular appointment times ", "Confirm next appointment"],
    },
    {
        header: "Review Previous Session Summary",
        short: "Review",
        component: Signoff,
    },
];

export const PRESCRIBE_NOTE_V2_WORKFLOW_DETAILS = () => {
    return {
        viewLastNoteSelf: true,
        viewLastNoteOther: "BEHAVIORAL",
        createNote: true,
        noteVersion: 2,
        noteEnricher: enrichPrescribeNoteContent,
        relevantPatientQuestionSections: {
            symptomTrends: [0],
            medicationTrends: [1, 2, 3, 4],
            medicationDifficulties: [5],
            medicalProblems: [6],
        },
        iconComponents: [],
        startingStep: -1,
        endCallShouldRoute: false,
    };
};

export const DEFAULT_WORKFLOW_STEPS = () => [
    {
        caption: "Video Consultation",
        short: "Consultation",
        component: DisplayWorkflowStepWithVideoClient,
        fullScreen: true,
        startCall: true,
    },
];

export const DEFAULT_WORKFLOW_DETAILS = () => {
    return {
        viewLastNoteSelf: false,
        viewLastNoteOther: null,
        createNote: true,
        noteVersion: 1,
        iconComponents: [],
        startingStep: 0,
        endCallShouldRoute: true,
        skipEndCallModal: true,
    };
};

export const getCPTWorkflow = (eventType, provider) => {
    let processedEventType = eventType;

    if (!processedEventType) {
        processedEventType = providerTypeToDefaultEvent(provider);
    }

    switch (processedEventType) {
        case _30_MIN_INDIVIDUAL_90832:
        case _45_MIN_INDIVIDUAL_90834:
        case _60_MIN_INDIVIDUAL_90837:
        case OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99204:
        case OFFICE_OR_OTHER_OUTPATIENT_VISIT_W_NEW_PATIENT_99205:
        case FAMILY_THERAPY_WO_PATIENT_90846:
        case FAMILY_THERAPY_90847: {
            return {
                steps: BEHAVIORAL_NOTE_V2_WORKFLOW_STEPS(),
                details: BEHAVIORAL_NOTE_V2_WORKFLOW_DETAILS(),
            };
        }

        case DIAGNOSTIC_INTERVIEW_90791: {
            return {
                steps: DEFAULT_WORKFLOW_STEPS(),
                details: BEHAVIORAL_CLINICAL_ASSESSMENT_WORKFLOW_DETAILS(),
            };
        }

        case DIAGNOSTIC_INTERVIEW_90792:
        case MEDICATION_MGMT_99212:
        case MEDICATION_MGMT_99213:
        case MEDICATION_MGMT_99214:
        case ESTABLISHED_PATIENT_OFFICE_OR_OTHER_OUTPATIENT_SERVICES_99215: {
            return {
                steps: PRESCRIBE_NOTE_V2_WORKFLOW_STEPS(),
                details: PRESCRIBE_NOTE_V2_WORKFLOW_DETAILS(),
            };
        }

        default: {
            return {
                steps: DEFAULT_WORKFLOW_STEPS(),
                details: DEFAULT_WORKFLOW_DETAILS(),
            };
        }
    }
};
