/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const EyeCrossedIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="EyeCrossed"
                {...props}>
                <path d="M3.29285 4.70718L27.2928 28.7072L28.7071 27.293L4.70706 3.29297L3.29285 4.70718Z" />
                <path d="M30 16.0006C28.731 18.176 27.2319 19.957 25.5862 21.3436L24.1668 19.9242C25.4227 18.8901 26.5992 17.5866 27.6468 16.0006C24.4853 11.2138 20.1502 9.00025 16 9.00025C15.1735 9.00024 14.3396 9.08804 13.5091 9.26647L11.8711 7.62848C18.3537 5.62439 25.5753 8.41513 30 16.0006Z" />
                <path d="M22 16C22 16.5375 21.9293 17.0584 21.7968 17.5541L19.9923 15.7497C19.8682 13.7399 18.2601 12.1318 16.2503 12.0077L14.4459 10.2032C14.9416 10.0707 15.4625 10 16 10C19.3137 10 22 12.6863 22 16Z" />
                <path d="M17.5541 21.7968L15.7497 19.9923C13.7399 19.8682 12.1318 18.2601 12.0077 16.2503L10.2032 14.4459C10.0707 14.9416 10 15.4625 10 16C10 19.3137 12.6863 22 16 22C16.5375 22 17.0584 21.9293 17.5541 21.7968Z" />
                <path d="M4.35316 16.0006C5.4009 14.4143 6.57754 13.1105 7.83363 12.0763L6.41421 10.6569C4.76834 12.0437 3.26912 13.8249 2 16.0007C6.42479 23.5856 13.6465 26.376 20.1293 24.3719L18.4913 22.7339C17.6607 22.9124 16.8267 23.0002 16 23.0002C11.8497 23.0002 7.51463 20.7868 4.35316 16.0006Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="EyeCrossed"
                {...props}>
                <path d="M3.29285 4.70718L27.2928 28.7072L28.7071 27.293L4.70706 3.29297L3.29285 4.70718Z" />
                <path d="M30 16.0006C28.731 18.176 27.2319 19.957 25.5862 21.3436L24.1668 19.9242C25.4227 18.8901 26.5992 17.5866 27.6468 16.0006C24.4853 11.2138 20.1502 9.00025 16 9.00025C15.1735 9.00024 14.3396 9.08804 13.5091 9.26647L11.8711 7.62848C18.3537 5.62439 25.5753 8.41513 30 16.0006Z" />
                <path d="M22 16C22 16.5375 21.9293 17.0584 21.7968 17.5541L14.4459 10.2032C14.9416 10.0707 15.4625 10 16 10C19.3137 10 22 12.6863 22 16Z" />
                <path d="M17.5541 21.7968L10.2032 14.4459C10.0707 14.9416 10 15.4625 10 16C10 19.3137 12.6863 22 16 22C16.5375 22 17.0584 21.9293 17.5541 21.7968Z" />
                <path d="M4.35316 16.0006C5.4009 14.4143 6.57754 13.1105 7.83363 12.0763L6.41421 10.6569C4.76834 12.0437 3.26912 13.8249 2 16.0007C6.42479 23.5856 13.6465 26.376 20.1293 24.3719L18.4913 22.7339C17.6607 22.9124 16.8267 23.0002 16 23.0002C11.8497 23.0002 7.51463 20.7868 4.35316 16.0006Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="EyeCrossed"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM10.4696 11.5304L28.4696 29.5304L29.5303 28.4697L11.5303 10.4697L10.4696 11.5304ZM30.5 20.0005C29.5482 21.632 28.4239 22.9677 27.1897 24.0077L26.1251 22.9431C27.0671 22.1676 27.9494 21.19 28.7351 20.0004C26.364 16.4104 23.1127 14.7502 20 14.7502C19.3801 14.7502 18.7547 14.816 18.1318 14.9499L16.9033 13.7214C21.7653 12.2183 27.1815 14.3113 30.5 20.0005ZM24.3476 21.1656C24.447 20.7938 24.5 20.4031 24.5 20C24.5 17.5147 22.4853 15.5 20 15.5C19.5969 15.5 19.2062 15.553 18.8344 15.6524L24.3476 21.1656ZM21.1656 24.3476L15.6524 18.8344C15.553 19.2062 15.5 19.5969 15.5 20C15.5 22.4853 17.5147 24.5 20 24.5C20.4031 24.5 20.7938 24.447 21.1656 24.3476ZM13.8752 17.0572C12.9332 17.8329 12.0507 18.8107 11.2649 20.0005C13.636 23.5901 16.8873 25.2502 20 25.2502C20.62 25.2502 21.2455 25.1843 21.8685 25.0504L23.0969 26.2789C18.2349 27.782 12.8186 25.6892 9.5 20.0005C10.4518 18.3687 11.5763 17.0327 12.8107 15.9926L13.8752 17.0572Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

EyeCrossedIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

EyeCrossedIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
