// Copyright aptihealth, inc. 2019 All Rights Reserved
import { ALL_STATES, STATES } from "../../../constants/states";

// elementType = segment/actionSegment are just a fancy names given to the form section divider mentioned in formSchema config
// this is just to allow array iterator to differentiate b/w a form field and some other stuff
// like sub form heading or a subform heading along with some action control btn.
import { RELATIONSHIP_TO_INSURED_OPTIONS } from "../../Dashboard/Providers/schema/newPatientFormSchema";

export const INITIAL_VALUES = {
    Username: "",
    Password: "",
    ConfirmPassword: "",
    first_name: "",
    last_name: "",
    dob: "",
    gender: "",
    mobile: "",
    address: {
        street: "",
        city: "",
        state: "",
        zip_code: "",
    },
    emergency_contact_person: "",
    emergency_contact_number: "",
    referralCode: "",
    pcp_id: "",
    pcp_name: "",
    preferences: { is_sms_enabled: false },
    insurance: {
        state: "",
        carrier: "",
        card_front: "",
        card_back: "",
        member_id: "",
        policy_dob: "",
        policy_holder_name: "",
        policy_holder_question: "yes",
        policy_relationship: "self",
    },
    consent: {
        eula: null,
        privacy: null,
        hipaa: null,
    },
};

export const PRIMARY_PATIENT_INFORMATION_SCHEMA = [
    {
        name: "first_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "First Name",
            placeholder: "First name",
        },
    },
    {
        name: "last_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Last Name",
            placeholder: "Last name",
        },
    },
    {
        name: "dob",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Date of Birth",
            placeholder: "MM/DD/YYYY",
        },
        formatted: true,
    },
    {
        name: "gender",
        elementType: "select",
        elementConfig: {
            options: [
                { value: "", display: "Gender", selected: true },
                { value: "male", display: "Male" },
                { value: "female", display: "Female" },
            ],
            label: "Gender",
            placeholder: "Gender",
        },
    },
    {
        name: "mobile",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Mobile",
            placeholder: "Mobile",
        },
        formatted: true,
    },
    {
        name: "Username",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "Email",
            placeholder: "Email",
        },
    },
    {
        name: "Password",
        elementType: "input",
        elementConfig: {
            type: "password",
            label: "Create Password",
            placeholder: "Password",
        },
    },
    {
        name: "ConfirmPassword",
        elementType: "input",
        elementConfig: {
            type: "password",
            label: "Confirm Password",
            placeholder: "Confirm Password",
        },
    },
];

export const PATIENT_ADDRESS_SCHEMA = (interestedInGuardianship) => [
    // below object does not render form element
    // only marks the hook for nested form and displays heading
    {
        elementType: "segment",
        displayName: "Address",
    },
    {
        name: "address.street",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Street",
            placeholder: "Enter Street",
        },
    },
    {
        name: "address.city",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "City",
            placeholder: "Enter City",
        },
    },
    {
        name: "address.state",
        elementType: "select",
        elementConfig: {
            options: interestedInGuardianship ? ALL_STATES : STATES,
            label: "State",
            placeholder: "Enter State",
        },
    },
    {
        name: "address.zip_code",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Zip",
            placeholder: "Enter zip",
        },
    },
];

export const EMERGENCY_CONTACT_SCHEMA = [
    {
        name: "emergency_contact_person",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Emergency Contact Person",
            placeholder: "Emergency Contact Person",
        },
    },
    {
        name: "emergency_contact_number",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Emergency Contact Number",
            placeholder: "Emergency Contact Number",
        },
        formatted: true,
    },
];

export const PRI_INSURANCE_SCHEMA = [
    // below object does not render form element
    // only marks the hook for nested form and displays heading
    {
        elementType: "segment",
        displayName: "Primary Insurance Information",
    },
    {
        name: "insurance.state",
        elementType: "select",
        elementConfig: {
            options: [],
            label: "State",
            placeholder: "Select State",
        },
    },
    {
        name: "insurance.carrier",
        elementType: "select",
        elementConfig: {
            sentenceCase: false,
            options: [],
            label: "Carrier",
            placeholder: "Select Carrier",
        },
    },
    {
        elementType: "InsuranceCardBtn",
        type: "pri",
    },
    // {
    //     name: 'insurance[0].form.card_front',
    //     elementType: 'select',
    //     elementConfig: {
    //         options: [{ value: 'one', display: 'One' },
    //         { value: 'two', display: 'Two' },
    //         ],
    //         label: 'Plan type',
    //         placeholder: 'Select Plan type'
    //     }
    // },
    {
        name: "insurance.member_id",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Member ID",
            placeholder: "Enter Member ID",
        },
    },
    {
        name: "insurance.policy_holder_name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Policy Holder Name",
            placeholder: "Enter Policy Holder Name",
        },
    },
    {
        name: "insurance.policy_relationship",
        elementType: "select",
        elementConfig: {
            options: RELATIONSHIP_TO_INSURED_OPTIONS,
            label: "Relationship to Insured",
            placeholder: "Select Relationship",
        },
    },
    {
        name: "insurance.policy_dob",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Policy Holder Date of Birth",
            placeholder: "MM/DD/YYYY",
        },
    },
];
