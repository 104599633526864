/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const DashboardIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Dashboard"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 15H6C4.89543 15 4 14.1046 4 13V6C4 4.89543 4.89543 4 6 4H13C14.1046 4 15 4.89543 15 6V13C15 14.1046 14.1046 15 13 15ZM13 13H6V6H13V13Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 28H6C4.89543 28 4 27.1046 4 26V19C4 17.8954 4.89543 17 6 17H13C14.1046 17 15 17.8954 15 19V26C15 27.1046 14.1046 28 13 28ZM13 26H6V19H13V26Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19 15H26C27.1046 15 28 14.1046 28 13V6C28 4.89543 27.1046 4 26 4H19C17.8954 4 17 4.89543 17 6V13C17 14.1046 17.8954 15 19 15ZM19 13H26V6H19V13Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26 28H19C17.8954 28 17 27.1046 17 26V19C17 17.8954 17.8954 17 19 17H26C27.1046 17 28 17.8954 28 19V26C28 27.1046 27.1046 28 26 28ZM26 26H19V19H26V26Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Dashboard"
                {...props}>
                <path d="M6 15H13C14.1046 15 15 14.1046 15 13V6C15 4.89543 14.1046 4 13 4H6C4.89543 4 4 4.89543 4 6V13C4 14.1046 4.89543 15 6 15Z" />
                <path d="M6 28H13C14.1046 28 15 27.1046 15 26V19C15 17.8954 14.1046 17 13 17H6C4.89543 17 4 17.8954 4 19V26C4 27.1046 4.89543 28 6 28Z" />
                <path d="M26 15H19C17.8954 15 17 14.1046 17 13V6C17 4.89543 17.8954 4 19 4H26C27.1046 4 28 4.89543 28 6V13C28 14.1046 27.1046 15 26 15Z" />
                <path d="M19 28H26C27.1046 28 28 27.1046 28 26V19C28 17.8954 27.1046 17 26 17H19C17.8954 17 17 17.8954 17 19V26C17 27.1046 17.8954 28 19 28Z" />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Dashboard"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM17.75 19.25H12.5C11.6716 19.25 11 18.5784 11 17.75V12.5C11 11.6716 11.6716 11 12.5 11H17.75C18.5784 11 19.25 11.6716 19.25 12.5V17.75C19.25 18.5784 18.5784 19.25 17.75 19.25ZM17.75 29H12.5C11.6716 29 11 28.3284 11 27.5V22.25C11 21.4216 11.6716 20.75 12.5 20.75H17.75C18.5784 20.75 19.25 21.4216 19.25 22.25V27.5C19.25 28.3284 18.5784 29 17.75 29ZM27.5 19.25H22.25C21.4216 19.25 20.75 18.5784 20.75 17.75V12.5C20.75 11.6716 21.4216 11 22.25 11H27.5C28.3284 11 29 11.6716 29 12.5V17.75C29 18.5784 28.3284 19.25 27.5 19.25ZM27.5 29H22.25C21.4216 29 20.75 28.3284 20.75 27.5V22.25C20.75 21.4216 21.4216 20.75 22.25 20.75H27.5C28.3284 20.75 29 21.4216 29 22.25V27.5C29 28.3284 28.3284 29 27.5 29Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

DashboardIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

DashboardIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
