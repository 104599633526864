// Copyright aptihealth, inc. 2019 All Rights Reserved

import { MemberProfileActivityEntry } from "../../../component-library";
import React from "react";

export const MemberActivatedActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { provider_name } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Dark"
            title="Patient Activated"
            subTitle={`Activated by ${provider_name}`}
            localDateTime={localCreatedTime}
            iconSrc="activate_timeline.svg"
            iconAlt="PatientActivatedIcon"
        />
    );
};

export const MemberInactivatedActivity = (props) => {
    const { localCreatedTime, keyContent } = props;
    const { provider_name, reason_for_inactivation } = keyContent;

    return (
        <MemberProfileActivityEntry
            headerClassName="apti-MemberProfileActivityEntry__Header__Dark"
            title="Patient Inactivated"
            subTitle={`Inactivated by ${provider_name}`}
            localDateTime={localCreatedTime}
            iconSrc="inactivated_timeline.svg"
            iconAlt="PatientInactivatedIcon">
            <div className={"fs-14"}>{`Reason: ${reason_for_inactivation}`}</div>
        </MemberProfileActivityEntry>
    );
};
