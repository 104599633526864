import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Button from "../../UI/Button";

import "./styles.scss";
import images from "../../../utils/images";

// For those not able to spot it, you can trigger this modal be closed using
// const [closeHandler, setCloseHandler] = useState(null);
// const closeModal = () => {
//     setCloseHandler(!closeHandler);
//   }
// You can pass your closeModal into the body of the Modal \
// Then
// <Portal
// isBottomCloseActive={false}
// openButtonRender={<span>Complete aptihealth Profile</span>}
// headerRender={
//   <CompleteProfileHeaderWidget
//     currentCompleteProfileStep={currentCompleteProfileStep}
//     completeProfileSteps={completeProfileSteps}
//   />
// }
// triggerModalStateChange={closeHandler}
// >
/**
 * Represents a general purpose Modal
 * A note on common use case: display modal on an event, in such a case
 * the initial triggerModalStateChange must start as NULL
 * you should provide flipping function to triggerModalStateChange similar to:
 *               <Portal
                isBottomCloseActive={false}
                headerRender={
                  <div className={"fs-22 fw-bold mb-5 text-center"}>
                    Authorized States to Practice
                  </div>
                }
                isOpenOnRender={false}
                triggerModalStateChange={this.state.stateModal}

    toggleStateModal = () => {
        console.log("stateModal", this.state.stateModal);
        this.setState({ stateModal: !this.state.stateModal });
    };
 */
export const Portal = React.memo((props) => {
    const {
        children,
        className,
        openButtonRender,
        footerRender,
        headerRender,
        isTopCloseActive = true,
        isBottomCloseActive = true,
        isOpenOnRender = false,
        onModalOpen,
        onModalClose,
    } = props;

    const [modalStateIsOpen, setModalStateIsOpen] = useState(isOpenOnRender);
    const [container, setContainer] = useState(document.createElement("div"));

    const isModalHeaderActive = isTopCloseActive || headerRender;
    const isModalFooterActive = isBottomCloseActive || footerRender;

    let setModalOpen = () => {
        if (onModalOpen != null) {
            onModalOpen();
        }
        setModalStateIsOpen(true);
    };

    let setModalClose = () => {
        if (onModalClose != null) {
            onModalClose();
        }
        setModalStateIsOpen(false);
    };

    useEffect(() => {
        if (props.triggerModalStateChange == null) {
            return;
        }

        if (modalStateIsOpen) {
            setModalClose();
        } else {
            setModalOpen();
        }
    }, [props.triggerModalStateChange]);

    const renderOpenButton = (
        <span className="cursor" onClick={() => setModalOpen()}>
            {openButtonRender}
        </span>
    );
    const borders = {
        header: headerRender ? "" : "border-bottom-0",
        footer: isModalFooterActive ? "" : "border-top-0",
    };
    const headerClass = props.headerClass || "";

    const modalHTML = (
        <div className="modal-dialog">
            <div className="modal-content">
                {isModalHeaderActive && (
                    <div className={`modal-header ${borders["header"]} ${headerClass}`}>
                        {headerRender}
                        {isTopCloseActive && (
                            <Button
                                type="button"
                                className="close modal-close"
                                onClick={() => {
                                    setModalClose();
                                }}>
                                <span aria-hidden="true">
                                    <img src={images("./icons/grey-x-circle.svg")} />
                                </span>
                            </Button>
                        )}
                    </div>
                )}
                <div className="modal-body">{children}</div>
                {isModalFooterActive && (
                    <div className={`modal-footer ${borders["footer"]}`}>
                        {footerRender}
                        {isBottomCloseActive && (
                            <Button
                                type="button"
                                className="Btn Btn--sec modal-button-close"
                                onClick={() => setModalClose()}>
                                Close
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );

    useEffect(() => {
        container.classList.add("modal");
        if (className) {
            container.classList.add(...className.split(" "));
        }
        document.body.appendChild(container);
        return () => {
            document.body.removeChild(container);
        };
    }, []);

    if (modalStateIsOpen) {
        const modalBackdrop = (
            <div onClick={() => setModalClose()} className="modal-backdrop show"></div>
        );
        container.classList.add("d-block");
        container.classList.remove("d-none");
        return (
            <>
                {renderOpenButton}
                {ReactDOM.createPortal(
                    <>
                        {modalHTML}
                        {modalBackdrop}
                    </>,
                    container,
                )}
            </>
        );
    } else {
        container.classList.add("d-none");
        container.classList.remove("d-block");
        return renderOpenButton;
    }
});
