// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import ListViewNav from "../../../UI/ListView/ListViewNav";
import CardSecondary from "../../../UI/Card/CardSecondary";
import moment from "moment";
import { api } from "../../../../APIRequests";
import withRouter from "react-router-dom/es/withRouter";
import { connect } from "react-redux";
import Button from "../../../UI/Button";
import { ChangePatientStatusPopUp } from "../ChangePatientStatusPopUp";
import {
    _30_MIN_INDIVIDUAL_90832,
    allottedTimeFactory,
    DIAGNOSTIC_INTERVIEW_90791,
    DIAGNOSTIC_INTERVIEW_90792,
} from "../../ScheduleAvailability/constants";
import CallPatientPopUp from "../CallPatientPopUp";
import { getMemberProgress } from "../PatientProfile90DayProgress";
import { Portal } from "../../../Common/Modal/Portal";
import "./style.scss";
import { ExistingPatientIntake } from "./ExistingPatientIntake";
import { calculateAge } from "../../../../utils/momentUtils";
import { formatMobileNumber } from "../../../../utils/formInputUtils";
import { getRole, isAuthorized, isUserAdmin, isUserPCP } from "../../../../redux/actions/auth";
import { OnDemandWelcomeEmail } from "../../../../component-library/CardVariations/OnDemandWelcomeEmail";
import { OnDemandWelcomeEmailSuccess } from "../../../../component-library/CardVariations/OnDemandWelcomeEmailSuccess";
import {
    CalendarIcon,
    CheckmarkIcon,
    Dropdown,
    ErrorCard,
    LockIcon,
    MessagesIcon,
    PhoneUpIcon,
    Text
} from "../../../../component-library";

import _ from "lodash";
import {
    admin_cse,
    provider_behavioral,
    provider_case_manager,
    provider_peer,
} from "../../../../config/roles";
import MemberProfileStatusPopUp from "./MemberProfileStatusPopUp";
import { memberProfileBadgeColorTypes, MemberProfileHeaderBadge } from "./MemberProfileHeaderBadge";
import { addToast, toastMessageTypes } from "../../../../redux/actions/toaster";
import { PAYMENT_TAB } from "../../../../constants/MemberProfile";
import { hasAccessToPaymentTab } from "../../../../utils/authUtils";
import {ICON_SIZES, ICON_TYPES} from "../../../../constants/icons";
import {EnvelopeIcon} from "../../../Icons/EnvelopeIcon";
import LinkIcon, {LinkIconColor} from "../../../UI/LinkIcon";
import { copyToClipboard } from "../../../../utils/webAPIUtils";

const tabs = ["Care Plan", "Reports", "Notes", "Medical", "Timeline"];

class MemberProfileHeader extends Component {
    constructor(props) {
        super(props);
        this.scrollRef = React.createRef();
        let newPatientStatus =
            this.props.patientDetails.status === "INACTIVE"
                ? this.props.patientDetails.previous_status || "ACTIVE 90-DAY"
                : "INACTIVE";

        const patientName = `${this.props.patientDetails.first_name} ${this.props.patientDetails.last_name}`;

        const eligibilityStatus = this.props.eligibilityDetails?.status;

        if (isAuthorized(admin_cse) && !tabs.includes("Admin")) {
            tabs.push("Admin");
        }

        this.state = {
            patientId: this.props.match.params.patientId,
            patientName: patientName,
            pronouns: this.props.patientDetails?.demographics?.pronouns,
            showChangeStatusPopUp: false,
            showCallPatientPopUp: false,
            newPatientStatus: newPatientStatus,
            showPatientMenu: false,
            currentPatientStatus: this.props.patientDetails.status,
            gender: this.props.patientDetails.gender,
            showOTCPopup: false,
            otc: null,
            otcExpiration: null,
            showOnDemandWelcomeEmailModal: false,
            showOnDemandWelcomeEmailSuccessModal: false,
            eligibilityStatus: eligibilityStatus,
            tabs: tabs,
            useStickyHeader: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.patientDetails.first_name !== this.props.patientDetails.first_name ||
            prevProps.patientDetails.last_name !== this.props.patientDetails.last_name
        ) {
            const patientName = `${this.props.patientDetails.first_name} ${this.props.patientDetails.last_name}`;
            this.setState({ patientName });
        }

        if (this.props.eligibilityDetails?.status !== this.state.eligibilityStatus) {
            this.setState({ eligibilityStatus: this.props.eligibilityDetails?.status });
        }

        if (prevProps.patientDetails.status !== this.props.patientDetails.status) {
            let newPatientStatus =
                this.props.patientDetails.status === "INACTIVE"
                    ? this.props.patientDetails.previous_status || "ACTIVE 90-DAY"
                    : "INACTIVE";
            this.setState({
                newPatientStatus,
            });
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll, { passive: true });
    }

    componentWillUnmount() {
        // Removing the scroll listener and observers for sticky headers
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        const scrollRefOffsetTop = this.scrollRef?.current?.offsetTop;

        if (scrollRefOffsetTop && window.scrollY > scrollRefOffsetTop) {
            this.setState({useStickyHeader: true});
        } else if (window.scrollY === 0) {
            this.setState({useStickyHeader: false});
        }
    };

    getDaysOnWaitList = () => {
        const creationDate = moment
            .unix(Number(this.props.patientDetails.wait_listed_date))
            .local();
        const currentDate = moment();
        let daysOnWaitList = currentDate.diff(creationDate, "days");
        if (isNaN(daysOnWaitList)) {
            daysOnWaitList = 0;
        }
        if (daysOnWaitList === 1) {
            return `${daysOnWaitList} day on a waitlist.`;
        }
        return `${daysOnWaitList} days on a waitlist.`;
    };

    getTabsState = () => {
        if (hasAccessToPaymentTab()) {
            return [...this.state.tabs, PAYMENT_TAB];
        }
        return this.state.tabs;
    };

    confirmChangePatientStatus = async (status, reason) => {
        const urlParams = { patientId: this.state.patientId };
        const providerName = this.props.profile.name;
        const data = { username: this.state.patientId, status: status, reason, providerName };
        const patientDetails = await api.provider.update_patient_details({ data, urlParams });
        await this.props.refetchDetails();
        let newPatientStatus =
            status === "INACTIVE" ? patientDetails.previous_status || "ACTIVE 90-DAY" : "INACTIVE";
        this.setState({
            showChangeStatusPopUp: false,
            currentPatientStatus: status,
            newPatientStatus,
        });
        this.props.updateState({
            patientDetails: {
                ...this.props.patientDetails,
                status: status,
                previous_status: patientDetails.previous_status,
            },
        });
    };

    callPatient = async () => {
        let UTCDate = moment().utc().format("YYYY-MM-DD HH:mm");
        let eventType = providerTypeToDefaultEvent(this.props.profile);
        let allottedTime = allottedTimeFactory(eventType);
        let data = {
            timestamp: UTCDate,
            is_instant: true,
            event_type: eventType,
            allotted_time: allottedTime,
        };
        let urlParams = { patientId: this.state.patientId, timezone: this.props.timezone };
        try {
            const response = await api.provider.set_video_consultation_schedule({
                data,
                urlParams,
            });
            this.props.history.push("/app/video-call?" + "callId=" + response["callId"]);
        } catch (err) {
            console.log(err);
        }
    };

    generateOTC = async () => {
        let queryParams = { patientId: this.state.patientId };
        let otcExpiration = this.state.otcExpiration;

        try {
            if (!otcExpiration || moment.utc() > moment.utc(otcExpiration)) {
                const response = await api.shared.generate_otc({ queryParams });

                otcExpiration = response.expiration;

                let expirationTimer = this.getFormattedTimeRemaining(otcExpiration);

                this.setState({
                    showOTCPopup: true,
                    otc: response.code,
                    otcExpiration,
                    expirationTimer,
                });

                const endTimerMillis = moment.utc(otcExpiration) - moment.utc();

                const timerId = setInterval(() => {
                    let expirationTimer = this.getFormattedTimeRemaining(otcExpiration);
                    this.setState({ expirationTimer: expirationTimer });
                }, 1000);

                setTimeout(() => {
                    clearInterval(timerId);
                }, endTimerMillis + 1000);
            } else {
                this.setState({ showOTCPopup: true });
            }
        } catch (err) {
            console.log(err);
        }
    };

    getFormattedTimeRemaining(otcExpiration) {
        let expirationTimer = "0:00";
        const totalSeconds = Math.floor((moment.utc(otcExpiration) - moment.utc()) / 1000);
        const mins = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        if (mins >= 0 && seconds >= 0) {
            expirationTimer = `${mins}:${seconds < 10 ? "0" + seconds : seconds}`;
        }
        return expirationTimer;
    }

    otcPopUp = () => {
        const isExpired =
            this.state.otcExpiration && moment.utc() > moment.utc(this.state.otcExpiration);
        return (
            <>
                <Portal
                    isBottomCloseActive={false}
                    isOpenOnRender={true}
                    onModalClose={() => this.setState({ showOTCPopup: false })}
                    className={"otc-modal"}>
                    <div className={"text-center"}>
                        <div className={"fs-22 mb-4 fw-bold"}>One-time code</div>
                        {!isExpired && (
                            <>
                                <div className={"my-3"}>
                                    The code below helps a patient sign in to their aptihealth
                                    account from a patient tablet. Securely provide the 5 character
                                    code to the patient, and assist them in entering the code on the
                                    tablet.
                                </div>
                                <div className={"my-4 fw-bold fs-32"} style={{ letterSpacing: 5 }}>
                                    {this.state.otc}
                                </div>
                                <div className={"my-3"}>
                                    The one-time code expires in{" "}
                                    <span className={"fw-bold"}>
                                        {this.state.expirationTimer} minutes
                                    </span>
                                </div>
                            </>
                        )}
                        {isExpired && (
                            <>
                                <div className={"my-5 txt-error"}>
                                    <div>One-time code has expired.</div>
                                    <div>To generate a new code, click the button below.</div>
                                </div>
                                <div className={"my-5"}>
                                    <Button
                                        className={"Btn Btn--pri"}
                                        type="button"
                                        onClick={this.generateOTC}>
                                        Generate new code
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </Portal>
            </>
        );
    };

    sendOnDemandWelcomeEmail = async () => {
        try {
            let urlParams = { patientId: this.state.patientId };
            await api.shared.send_on_demand_welcome_email({ urlParams });
            this.setState({
                showOnDemandWelcomeEmailModal: false,
                showOnDemandWelcomeEmailSuccessModal: true,
            });
        } catch (err) {
            console.log(err);
        }
    };

    renderStatusBadge = () => {
        return (
            <div className={"MemberProfileHeader--status-badge"}>
                {this.props.patientDetails.status === "INACTIVE" && (
                    <MemberProfileHeaderBadge
                        text={this.props.patientDetails.status}
                        type={memberProfileBadgeColorTypes.inactive}
                    />
                )}
                {this.props.patientDetails.status !== "INACTIVE" && (
                    <MemberProfileHeaderBadge
                        text={this.props.patientDetails.status}
                        type={memberProfileBadgeColorTypes.active}
                    />
                )}
                {this.props.patientDetails.is_wait_listed && (
                    <MemberProfileHeaderBadge
                        text={"WaitListed"}
                        type={memberProfileBadgeColorTypes.waitListed}
                    />
                )}
                <MemberProfileStatusPopUp
                    openPopup={() => {
                        this.setState({
                            showPatientMenu: true,
                        });
                    }}
                    closePopup={() => {
                        this.setState({
                            showPatientMenu: false,
                        });
                    }}
                    isOpen={this.state.showPatientMenu}
                    popUpOptions={[
                        {
                            title:
                                this.props.patientDetails.status ===
                                "INACTIVE"
                                    ? "Reactivate"
                                    : "Inactivate",
                            action: () => {
                                this.setState({
                                    showChangeStatusPopUp: true,
                                });
                            },
                            shouldShow: () => {
                                return (
                                    !isUserPCP() &&
                                    !isAuthorized(provider_peer) &&
                                    !isAuthorized(provider_case_manager)
                                );
                            },
                        },
                        {
                            title: "Remove from waitlist",
                            action: async () => {
                                try {
                                    await api.patient.remove_from_wait_list(
                                        {
                                            queryParams: {
                                                patient_username:
                                                this.state.patientId,
                                            },
                                        },
                                    );
                                    await this.props.refetchDetails();
                                    this.props.addToast({
                                        message: `${this.state.patientName} is no longer waitlisted.`,
                                        messageType:
                                        toastMessageTypes.success,
                                    });
                                } catch (error) {
                                    console.log(error);
                                }
                            },
                            shouldShow: () => {
                                const isWaitListed =
                                    this.props.patientDetails
                                        ?.is_wait_listed;
                                return (
                                    this.showWaitListOption() &&
                                    isWaitListed
                                );
                            },
                        },
                        {
                            title: "Waitlist",
                            action: () => {
                                this.props.history.push(
                                    `/app/patient/${this.state.patientId}/waitlist_form`,
                                );
                            },
                            shouldShow: () => {
                                const isNotWaitListed =
                                    !this.props.patientDetails
                                        ?.is_wait_listed;
                                return (
                                    this.showWaitListOption() &&
                                    isNotWaitListed
                                );
                            },
                        },
                    ]}
                />
            </div>
        );
    };

    renderVerifiedIcon = () => {
        return <div className={"MemberProfileHeader--verified-container"}>
            <p className="MemberProfileHeader--verified-text">Verified</p>
        </div>;
    };

    renderDropdownNavigation = () => {
        const transformToOption = (tab) => {
            return {value: tab, label: tab};
        };
        const options = this.getTabsState().map(tab => transformToOption(tab));

        return <div className={"MemberProfileHeader--nav-mobile"}>
            <div className={"MemberProfileHeader--nav-mobile-label"}>
                Navigate to:
            </div>
            <Dropdown
                onChange={e => this.props.selectedTabChangeHandler(e.target.value)}
                value={transformToOption(this.props.selectedTab)}
                options={options}
            />
        </div>;
    };

    internalCopyToClipBoard = (text) => {
        copyToClipboard(text, this.props.addToast);
    };

    renderContactGroupPrimaryActions = () => {
        return <>
            <button className={"MemberProfileHeader--mobile"} onClick={() => {
                this.internalCopyToClipBoard(this.props.patientDetails.mobile);
            }}>
                <PhoneUpIcon
                    className={"MemberProfileHeader--icon"}
                    iconSize={ICON_SIZES.SMALL}
                    iconType={ICON_TYPES.OUTLINE}
                />
                <p>{formatMobileNumber(this.props.patientDetails.mobile)}</p>
                {this.props.patientDetails.preferences?.is_sms_verified && this.renderVerifiedIcon()}
            </button>
            <button className={"MemberProfileHeader--email"} onClick={() => {
                this.internalCopyToClipBoard(this.props.patientDetails.email);
            }}>
                <EnvelopeIcon
                    className={"MemberProfileHeader--icon"}
                    iconSize={ICON_SIZES.SMALL}
                    iconType={ICON_TYPES.OUTLINE}
                />
                <p className={"MemberProfileHeader--email-text"}>
                    {this.props.patientDetails.email}
                </p>
                {this.props.patientDetails.email_verified && this.renderVerifiedIcon()}
            </button>
        </>;
    };

    renderScheduleIntakeButton = () => {
        const openButtonRender = (
            <button className={"MemberProfileHeader--schedule-intake"}>
                <CalendarIcon
                    className={"MemberProfileHeader--icon"}
                    iconSize={ICON_SIZES.SMALL}
                    iconType={ICON_TYPES.OUTLINE}
                />
                <p>{"Schedule DI"}</p>
            </button>
        );

        return <ExistingPatientIntake
            patientDetails={this.props.patientDetails}
            openModalOnRender={this.props.openScheduleIntake}
            openButtonRender={openButtonRender}
        />;
    };

    renderContactGroupConditionalActions = () => {
        return <>
            { !this.props.patientDetails?.registration_date &&
                <button className={"MemberProfileHeader--resend-reg-email"} onClick={() => {
                    this.setState({showOnDemandWelcomeEmailModal: true});
                }}>
                    <EnvelopeIcon
                        className={"MemberProfileHeader--icon"}
                        iconSize={ICON_SIZES.SMALL}
                        iconType={ICON_TYPES.OUTLINE}
                    />
                    <p>{"Resend Registration Link"}</p>
                </button>
          }
          { this.props.profile && this.props.profile.otc_enabled &&
            <button className={"MemberProfileHeader--send-otc"} onClick={this.generateOTC}>
              <LockIcon
                  className={"MemberProfileHeader--icon"}
                  iconSize={ICON_SIZES.SMALL}
                  iconType={ICON_TYPES.OUTLINE}
              />
              <p>{"Generate Code"}</p>
            </button>
          }
          { this.props.profile && this.props.profile.intake_scheduling_enabled &&
              this.renderScheduleIntakeButton()
          }
        </>;
    };

    renderDemographicInfo = () => {
        const age = calculateAge(this.props.patientDetails.dob);
        return (
            <div className="MemberProfileHeader--demographics">
                <div className="MemberProfileHeader--demographics-header">
                    <h1 className="MemberProfileHeader--demographics-name">
                        {this.state.patientName}
                    </h1>
                    { this.state.pronouns && this.state.pronouns.length !== 0 &&
                        <h2 className="MemberProfileHeader--demographics-pronouns">
                            {this.state.pronouns.join(", ")}
                        </h2>
                    }
                </div> 
                <div className="MemberProfileHeader--demographics-sub-header">
                    {this.props.patientDetails.dob} ({age}) • {this.state.gender}
                </div>
            </div>
        );
    };

    renderContactGroup = () => {
      return (
          <div className="MemberProfileHeader--contact">
              { !isUserAdmin() &&
                  <LinkIcon
                      label={"Message"}
                      icon={
                          <MessagesIcon
                              iconSize={ICON_SIZES.SMALL}
                              iconType={ICON_TYPES.OUTLINE}
                          />
                      }
                      onClick={() => this.props.history.push(
                        `/app/messages?memberId=${this.state.patientId}`,
                      )}
                      color={LinkIconColor.PURPLE}
                  />
              }
              <div className="MemberProfileHeader--contact-secondary">
                  {this.renderContactGroupPrimaryActions()}
                  {this.renderContactGroupConditionalActions()}
              </div>
          </div>
      );
    };

    renderDaysOfCare = () => {
        const shouldShow = !["REFERRED", "REGISTERED", "ASSESSMENT"].includes(
            this.state.currentPatientStatus
        );

        if (shouldShow) {
            return <div className="MemberProfileHeader--status-group-second">
                <span>Day {this.props.patientDetails.number_of_days_in_care} of care</span>
            </div>;
        }
        return <></>;
    };

    renderWaitListDetails = () => {
        return this.props.patientDetails?.is_wait_listed && (
            <div className={"MemberProfileHeader__wait_list"}>
                <span className={"fs-14 fw-normal grey-text"}>
                    {this.getDaysOnWaitList()} Reasons:{" "}
                    {this.getWaitListReasons()}.
                </span>
                <br />
                {this.renderWaitListComment()}
            </div>
        );
    };

    renderDiagnoses = () => {
        return <>
            <div className="MemberProfileHeader--diagnosis">
                <p>
                    <strong>Primary Diagnosis:</strong>{" "}
                    {this.getFormattedDiagnosis(this.props.patientDiagnosis?.ICD_10_axis_1_diagnosis)}
                </p>
            </div>
            <div className="MemberProfileHeader--diagnosis">
                <p>
                    <strong>Secondary Diagnosis:</strong>{" "}
                    {this.getFormattedDiagnosis(this.props.patientDiagnosis?.ICD_10_axis_2_diagnosis)}
                </p>
                <p>
                    {this.renderPatientAcuity(
                        this.props.patientDetails.acuity_score,
                        this.props.patientDetails.acuity_timestamp,
                    )}
                </p>
            </div>
        </>;
    };

    renderHeader = () => {
        let className = "MemberProfileHeader";

        // Add a smooth transition when swapping from sticky -> static header
        if (!this.state.useStickyHeader) {
            className += " scroll-transition active";
        }

        return (
            <div className={className}>
                <CardSecondary className={"MemberProfileHeader--card"}>
                    <div className="MemberProfileHeader--main">
                        {this.renderDemographicInfo()}
                        {this.renderContactGroup()}
                    </div>
                    <hr/>
                    { !this.state.useStickyHeader && <>
                        <div className="MemberProfileHeader--collapsible">
                            <div className="MemberProfileHeader--status-group">
                                {this.renderStatusBadge()}
                                {this.renderDaysOfCare()}
                                {this.renderWaitListDetails()}
                            </div>
                            {this.renderDiagnoses()}
                        </div>
                        <hr ref={this.scrollRef}/>
                    </>}
                    <div className={"MemberProfileHeader--nav"}>
                        <ListViewNav
                            ListNavHandler={this.props.selectedTabChangeHandler}
                            status={this.props.selectedTab}
                            tabs={this.getTabsState()}
                            centered={true}
                        />
                    </div>
                    {this.renderDropdownNavigation()}
                </CardSecondary>
            </div>
        );
    };

    render() {
        const omnisearchShowingClass = this.props.omnisearchDisplayStatus ? "omnisearch-showing" : "";
        return (
            <>
                {this.state.showOnDemandWelcomeEmailModal && (
                    <OnDemandWelcomeEmail
                        className={"on-demand-email-modal"}
                        buttonLeftClick={() =>
                            this.setState({showOnDemandWelcomeEmailModal: false})
                        }
                        buttonRightClick={this.sendOnDemandWelcomeEmail}
                        email={_.get(this.props, "patientDetails.email", "")}
                    />
                )}
                {this.state.showOnDemandWelcomeEmailSuccessModal && (
                    <OnDemandWelcomeEmailSuccess
                        className={"on-demand-email-modal"}
                        buttonClick={() =>
                            this.setState({showOnDemandWelcomeEmailSuccessModal: false})
                        }
                    />
                )}
                {this.state.showCallPatientPopUp && (
                    <CallPatientPopUp
                        cancelHandler={() => this.setState({showCallPatientPopUp: false})}
                        callHandler={this.callPatient}
                    />
                )}
                {this.state.showChangeStatusPopUp && (
                    <ChangePatientStatusPopUp
                        patientName={this.state.patientName}
                        allowExtendedCare={
                            getMemberProgress(this.props.patientDetails).remainingDays <= 0
                        }
                        cancelHandler={() => this.setState({showChangeStatusPopUp: false})}
                        confirmHandler={(reason) =>
                            this.confirmChangePatientStatus(this.state.newPatientStatus, reason)
                        }
                        newPatientStatus={this.state.newPatientStatus}
                        oldPatientStatus={this.props.patientDetails.previous_status}
                        statusChangeHandler={(e) =>
                            this.setState({newPatientStatus: e.target.value})
                        }
                    />
                )}
                {this.state.showOTCPopup && this.otcPopUp()}
                {["declined", "no_match"].includes(this.state.eligibilityStatus) &&
                    this.insuranceAlertBanner()}
                <div className={this.state.useStickyHeader ? `sticky-header scroll-transition active ${omnisearchShowingClass}` : ""}>
                    {this.renderHeader()}
                </div>
            </>
        );
    }

    showWaitListOption() {
        const role = getRole();
        const isIntake =
            role === provider_behavioral &&
            this.props.profile?.provider_type === "BEHAVIORAL_INTAKE";

        const isCSE = role === admin_cse && this.props.profile?.user_type === "CSE";

        return isIntake || isCSE;
    }

    renderWaitListComment() {
        const waitListComment = this.props?.patientDetails?.wait_list_comment;
        if (!waitListComment) {
            return null;
        }

        const waitListCommentClass = "MemberProfileHeader__wait_list--comment";

        return (
            <div className={waitListCommentClass}>
                <div className={`${waitListCommentClass}-content`}>
                    <span className={`${waitListCommentClass}-header`}>Comment</span>
                    <br />
                    <p className={`${waitListCommentClass}-text`}>
                        {this.props?.patientDetails?.wait_list_comment}
                    </p>
                </div>
            </div>
        );
    }

    getWaitListReasons() {
        const waitListReasons = this.props.patientDetails.wait_list_reason;
        if (Array.isArray(waitListReasons)) {
            return waitListReasons.join(", ");
        }
        return waitListReasons;
    }

    getFormattedDiagnosis(diagnosis) {
        let formattedDiagnosis = "None";
        if (diagnosis && diagnosis.length) {
            formattedDiagnosis = diagnosis.map((icd_10) => icd_10["value"]).join(", ");
        }
        return formattedDiagnosis;
    }

    renderPatientAcuity(acuityIndex, acuityTimestamp) {
        const indexDescription = {
            1: "Mild",
            2: "Moderate",
            3: "Intense",
            4: "Severe",
        };

        if (!acuityIndex) {
            return <></>;
        }

        let formattedAcuityDate;
        if (acuityTimestamp) {
            formattedAcuityDate = moment(acuityTimestamp, "YYYY-MM-DD").format("M/D/YY");
        }

        return <>
            <div className={"fs-14 CareResults-Acuity"}>
                <span className={"fw-bold"}>Acuity Index: </span>
                <span className={"fw-bold"}>{acuityIndex} </span>
                <span className={"CareResults-Acuity__description"}>
                    {indexDescription[acuityIndex]}{" "}
                </span>
                <span>updated {formattedAcuityDate}</span>
            </div>
        </>;
    }

    insuranceAlertBanner = () => {
        return (
            <ErrorCard className={"mt-4"}>
                <Text color="red" type="subtitle">
                    Before scheduling {this.props.patientDetails.first_name}'s next appointment,
                    advise them to update insurance or contact Support.
                </Text>
            </ErrorCard>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        timezone: state.auth.timezone,
        profile: state.auth.profile,
        omnisearchDisplayStatus: state.dynamicRouteConfig.omnisearchDisplayStatus,
    };
};
const actions = {
    addToast,
};

export default connect(mapStateToProps, actions)(withRouter(MemberProfileHeader));

export const providerTypeToDefaultEvent = (provider) => {
    switch (provider.provider_type) {
        case "BEHAVIORAL":
            return _30_MIN_INDIVIDUAL_90832;
        case "BEHAVIORAL_INTAKE":
            return DIAGNOSTIC_INTERVIEW_90791;
        case "PRESCRIBE":
            return DIAGNOSTIC_INTERVIEW_90792;
        default:
            return _30_MIN_INDIVIDUAL_90832;
    }
};

export const providerTypeToDefaultNoteEventType = (provider) => {
    switch (provider.provider_type) {
        case "BEHAVIORAL":
            return _30_MIN_INDIVIDUAL_90832;
        case "BEHAVIORAL_INTAKE":
            return _30_MIN_INDIVIDUAL_90832;
        case "PRESCRIBE":
            return DIAGNOSTIC_INTERVIEW_90792;
        default:
            return _30_MIN_INDIVIDUAL_90832;
    }
};
