import React from "react";
import CardSecondary from "../../../../UI/Card/CardSecondary";
import _ from "lodash";
import { getRole, isAuthorized } from "../../../../../redux/actions/auth";
import { admin_cse } from "../../../../../config/roles";
import moment from "moment/moment";
import { Button, CheckboxOld } from "../../../../../component-library";
import images from "../../../../../utils/images";

const getNoteIcon = (note) => {
    let icon = "bhs-note-icon.svg";
    if (note.provider_type && note.provider_type.startsWith("PRESCRIBE")) {
        icon = "prescriber-note-icon.svg";
    }
    if (note.version === 3) {
        return "care-note-icon.svg";
    } else if (note.version === 4) {
        return "care-note-icon.svg";
    } else if (note.version === 5) {
        return "care-note-icon.svg";
    } else if (note.version === 6 || note.version === 7) {
        return "cm-note.jpg";
    }

    return icon;
};

const CommonMetaData = ({ signature, date, time }) => {
    return (
        <div>
            <div className={"grey-text"}>{signature}</div>
            <div className={"grey-text"}>
                {date} <br />
                {time}
            </div>
        </div>
    );
};

const getMetaData = (note) => {
    let metaData =
        note.note_state === "draft" ? (
            <div className={"txt-sec"}>
                <b>DRAFT</b>
            </div>
        ) : null;

    if (note.note_type === "Care Management" && note.note_state === "draft") {
        return metaData;
    } else if (
        note.note_type === "Care Management" ||
        note.note_type === "Advanced Care Activity"
    ) {
        const submission_date = note.completed_date_time
            ? moment(note.completed_date_time).local().format("MM/DD/YYYY")
            : null;
        const submission_time = note.completed_date_time
            ? moment(note.completed_date_time).local().format("hh:mm A")
            : null;
        return (
            <CommonMetaData
                date={submission_date}
                time={submission_time}
                signature={note.signature}
            />
        );
    }

    if (note.version === 5 || note.version === 6) {
        metaData = (
            <div>
                <div className={"grey-text"}>{note.signature}</div>
                <div className={"grey-text"}>
                    {moment(note.session_date, "MM/DD/YYYY").format("MM/DD/YYYY")} <br />
                    {moment(note.session_from, "hh:mm").format("h:mm a")}
                </div>
            </div>
        );
    }

    if (note.note_type === "Initial Clinical Assessment") {
        let submission_date = note.completed_date_time
            ? moment.utc(note.completed_date_time).local().format("MM/DD/YYYY")
            : null;
        let submission_time = note.completed_date_time
            ? moment.utc(note.completed_date_time).local().format("hh:mm A")
            : null;

        if (note.content?.patient_no_show_array && !note.completed_date_time) {
            submission_date = moment(note.content.patient_no_show_array[0].patient_no_show_time)
                .local()
                .format("MM/DD/YYYY");
            submission_time = moment(note.content.patient_no_show_array[0].patient_no_show_time)
                .local()
                .format("hh:mm A");
        }

        metaData = (
            <div>
                <div className={"grey-text"}>{note.signature}</div>
                <div className={"grey-text"}>
                    {submission_date} <br />
                    {submission_time}
                </div>
            </div>
        );
    }

    return metaData;
};

const getBadges = (note) => {
    let badges = [];
    if (!note.is_billable) {
        badges.push(<div className={"note-badge non-billable"}>NON-BILLABLE</div>);
    }

    if (_.get(note, "content.patient_no_show")) {
        badges.push(<div className={"note-badge patient-no-show"}>PATIENT-NO-SHOW</div>);
    }

    if (
        note.note_type === "Initial Clinical Assessment" &&
        note.content?.patient_no_show_array &&
        !note.completed_date_time
    ) {
        badges.push(<div className={"note-badge patient-no-show"}>PATIENT-NO-SHOW</div>);
    }

    return badges;
};

const getSubTitle = (note) => {
    let subTitle = null;

    if (note.version === 5 || note.version === 6) {
        const communicationReason = note.content.reason_for_communication
            .map((reason) => {
                if (reason === "Other (open text)" && note.content.reason_for_communication_other) {
                    reason = "Other: " + note.content.reason_for_communication_other;
                }
                return reason;
            })
            .join(", ");

        subTitle = `${note.content.communication_type} - ${communicationReason}`;
    }

    if (
        note.note_type === "Initial Clinical Assessment" &&
        note.content?.patient_no_show_array &&
        !note.completed_date_time
    ) {
        subTitle = null;
    }

    return subTitle;
};

const getTitle = (note) => {
    let title = note.title;

    if (note.note_type === "Initial Clinical Assessment") {
        title = "Diagnostic Interview";
    }

    return title;
};

export const MemberProfileExportableNote = ({
    note,
    selectNoteToExport,
    isSelectedForExport,
    viewFullNote,
}) => {
    const isAuthorizedToExportNotes = isAuthorized(admin_cse);

    const isAuthorizedToViewFullNote = (note) => {
        const isBhsTherapySessionNote =
            (note.provider_type === "BEHAVIORAL" || note.provider_type === "BEHAVIORAL_INTAKE") &&
            (note.note_type === "Video Consultation" || note.version === 2);

        switch (getRole()) {
            case "provider:pcp":
                return !isBhsTherapySessionNote;
            default:
                return true;
        }
    };
    const title = getTitle(note);
    const subTitle = getSubTitle(note);
    const metaData = getMetaData(note);
    const cpt_code = note.cpt_code;
    const icon = getNoteIcon(note);
    const badges = getBadges(note);

    const cardColumns = isAuthorizedToExportNotes
        ? ["col-lg-6 col-12", "col-lg-2 col-12", "col-lg-1 col-12"]
        : ["col-lg-6 col-12", "col-lg-2 col-12", "col-lg-2 col-12"];
    return (
        <div key={note.id} className="py-3">
            <CardSecondary style={{ maxWidth: "unset" }}>
                <div className="py-3">
                    <div className={"row text-left mx-3 py-2  txt-black"}>
                        {isAuthorizedToExportNotes && (
                            <div className={"col-1 d-flex align-items-center"}>
                                <CheckboxOld
                                    onClick={() => selectNoteToExport(note)}
                                    checked={isSelectedForExport}
                                />
                            </div>
                        )}
                        <div className={`${cardColumns[0]} d-flex align-items-center`}>
                            {icon && (
                                <img
                                    alt="note-icon"
                                    style={{ height: "36px", marginRight: "15px" }}
                                    src={images(`./icons/${icon}`)}
                                />
                            )}
                            <div>
                                <div className={"fw-bold"}>{title}</div>
                                <div>{subTitle}</div>
                                <div className={"grey-text"}>{cpt_code}</div>
                            </div>
                        </div>
                        <div
                            className={`${cardColumns[1]} d-flex flex-column align-items-center justify-content-center`}>
                            {badges}
                        </div>
                        <div className={`${cardColumns[2]} d-flex align-items-center`}>
                            {metaData}
                        </div>
                        <div className={`${cardColumns[1]} d-flex align-items-center`}>
                            {isAuthorizedToViewFullNote(note) && (
                                <Button
                                    className={"Btn-link Btn-override txt-sec fw-bold"}
                                    onClick={viewFullNote}>
                                    View Full Note
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </CardSecondary>
        </div>
    );
};
