// Copyright aptihealth, inc. 2019 All Rights Reserved
import { httpAuth, httpBackground } from "../utils/axios";
import * as SendBird from "sendbird";

// Wrapper method to help promisify all of SendBird's callback methods
const promisify = (func, ...args) => {
    return new Promise((resolve, reject) => {
        //Generic Handler
        return func(
            ...args.concat((response, error) => {
                if (error) {
                    reject(error);
                }
                resolve(response);
            }),
        );
    });
};

const DEFAULT_CHANNEL_PAGE_SIZE_LIMIT = 30;

const messagingRequests = {
    fetch_user: ({ options } = {}) => {
        let conf = {
            method: "post",
            url: "/v1/messaging/user",
            ...options,
        };

        return httpAuth(conf);
    },

    fetch_contacts: ({ options } = {}) => {
        let conf = {
            method: "post",
            url: "/v1/messaging/contacts",
            ...options,
        };

        return httpAuth(conf);
    },

    fetch_unread_message_count: ({ options } = {}) => {
        let conf = {
            method: "post",
            url: "/v1/messaging/unread_message_count",
            ...options,
        };

        return httpBackground(conf);
    },

    create_client: () => {
        return new SendBird({ appId: process.env.REACT_APP_SENDBIRD_APPLICATION_ID });
    },

    connect_to_sendbird: (client, userId, accessToken) => {
        return promisify(client.connect.bind(client), userId, accessToken);
    },

    upsert_distinct_channel: (client, userIds) => {
        let groupChannelParams = new client.GroupChannelParams();

        groupChannelParams.isDistinct = true;
        groupChannelParams.isPublic = false;
        groupChannelParams.isDiscoverable = false;
        groupChannelParams.isEphemeral = false;
        groupChannelParams.name = userIds.join(" | ");
        groupChannelParams.addUserIds(userIds);

        return promisify(
            client.GroupChannel.createDistinctChannelIfNotExist.bind(client),
            groupChannelParams,
        );
    },

    get_paginated_channels: async (client, pageSizeLimit = DEFAULT_CHANNEL_PAGE_SIZE_LIMIT) => {
        const query = client.GroupChannel.createMyGroupChannelListQuery();
        query.includeEmpty = true;
        query.limit = pageSizeLimit;

        return await messagingRequests.get_next_channel_page(query);
    },

    get_next_channel_page: async (nextChannelPageQuery) => {
        return {
            channels: await nextChannelPageQuery.next(),
            nextChannelPageQuery: nextChannelPageQuery.hasNext ? nextChannelPageQuery : null,
        };
    },

    exit_channel: (channel) => {
        return promisify(channel.exit);
    },

    get_messages: (query) => {
        return promisify(query.load.bind(query));
    },

    send_message: (channel, message) => {
        return promisify(channel.sendUserMessage.bind(channel), message);
    },
};

export default messagingRequests;
