import React from "react";
import "./styles.scss";

const Toggle = (props) => {
    const enabledText = props.enabledText ? props.enabledText : "On";
    const disabledText = props.disabledText ? props.disabledText : "Off";

    return (
        <div className={`toggle-container mb-2 ${props.className}`} onClick={props.toggleSelected}>
            <div
                className={`dialog-button dialog-button-on ${
                    props.selected ? "toggle-button-enabled-on" : "toggle-button-disabled"
                }`}>
                {enabledText}
            </div>
            <div
                className={`dialog-button ${
                    props.selected ? "toggle-button-disabled" : "toggle-button-enabled-off"
                }`}>
                {disabledText}
            </div>
        </div>
    );
};

export default Toggle;
