// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { useEffect, useState } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import DesktopToolbar from "./Views/DesktopToolbar";
import Omnisearch from "../../Common/Omnisearch";
import { isAuthorized, isUserAdmin } from "../../../redux/actions/auth";
import { setOmnisearchDisplayStatus } from "../../../redux/actions/navbar"
import { admin_bp } from "../../../config/roles";
import OmnisearchQuestions from "../../../APIRequests/omnisearch";

const Toolbar = (props) => {
    const [profile, setProfile] = useState(props.profile);
    const [navbarConfiguration, setNavbarConfiguration] = useState(props.navbarConfiguration);
    const [dynamicRouteConfig, setDynamicRouteConfig] = useState(props.dynamicRouteConfig);
    const [displaySearchBar, setDisplaySearchBar] = useState(false);
    const [omnisearchQuestions, setOmnisearchQuestions] = useState([]);

    useEffect(() => {
        setNavbarConfiguration(props.navbarConfiguration);
        setProfile(props.profile);
    }, [props.navbarConfiguration, props.profile]);

    useEffect(() => {
        setDynamicRouteConfig(props.dynamicRouteConfig);
    }, [props.dynamicRouteConfig]);

    useEffect(() => {
        if (!isAuthorized(admin_bp) && isUserAdmin()) {
            OmnisearchQuestions.fetch_provider_schedule_call_list().then(
                (omnisearchQuestionBank) => {
                    setOmnisearchQuestions(omnisearchQuestionBank);
                },
            );
        }
    }, []);

    const handleClickSearch = () => {
        setDisplaySearchBar(!displaySearchBar);
        props.setOmnisearchDisplayStatus(!displaySearchBar);

        if (!displaySearchBar) {
            setTimeout(() => {
                const inputElement = document.getElementById("omnisearch-input");
                if (inputElement) {
                    inputElement.focus();
                }
            }, 0);
        }
    };

    return (
        <>
            <header className="Toolbar">
                <div>
                    {
                        <DesktopToolbar
                            {...props}
                            profile={profile}
                            {...navbarConfiguration}
                            handleClickSearch={handleClickSearch}
                            totalUnreadMessages={props.totalUnreadMessages}></DesktopToolbar>
                    }
                    {/* only visible on desktop */}
                    <div className="Toolbar__margin-fix" />
                </div>
            </header>
            {displaySearchBar && (
                <div className="omnisearch-dropdown">
                    <Omnisearch
                        startFocused
                        id="omnisearch-input"
                        questions={omnisearchQuestions}
                    />
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
        dynamicRouteConfig: state.dynamicRouteConfig,
    };
};
export default connect(mapStateToProps, { setOmnisearchDisplayStatus })(Toolbar);
