import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../utils";
import { Button } from "../Button";
import { Modal } from "../Modal";
import { Text } from "../Text";

import "./styles.scss";
import { ICON_SIZES, ICON_TYPES } from "../../constants/icons";
import { CloseIcon } from "../../components/Icons/CloseIcon";
import { ButtonTypes } from "../constants";

export const preventModalPageScroll = (isOpen) => {
    if (isOpen) {
        document.body.classList.add("modal-open");
    } else {
        document.body.classList.remove("modal-open");
    }
};

export const ScrollableTextModal = ({
    className,
    title,
    bodyContent,
    show,
    submitButtonText,
    onSubmit,
    onCancel,
    captchaComponent,
    submitDisabled,
    cardClassName,
    ...props
}) => {
    const SMALL_SCREEN_SIZE = 421;

    const isSmallScreen = window.innerWidth < SMALL_SCREEN_SIZE;
    return (
        <Modal
            className={createClassNameString([className, "apti-ScrollableTextModal"])}
            cardClassName={cardClassName}
            show={show}
            {...props}>
            <div className="apti-ScrollableTextModal__header">
                <div className="apti-ScrollableTextModal__header-text">
                    <Text className={"apti-ScrollableTextModal__header-text-heading"}>{title}</Text>
                </div>
                <CloseIcon
                    className={"apti-ScrollableTextModal__cancel"}
                    iconType={ICON_TYPES.OUTLINE}
                    iconSize={ICON_SIZES.SMALL}
                    onClick={onCancel}
                />
            </div>

            <div className={"apti-ScrollableTextModal__scroller"}>
                <div className={"apti-ScrollableTextModal__scroller-body"}>{bodyContent}</div>
            </div>
            {captchaComponent && captchaComponent}
            <div
                className={createClassNameString([className, "apti-ScrollableTextModal__buttons"])}>
                <Button
                    className={`apti-ScrollableTextModal__button ${
                        isSmallScreen ? "full-width" : "wide-width"
                    }`}
                    buttonType={ButtonTypes.primaryV2}
                    onClick={onSubmit}
                    disabled={submitDisabled}>
                    {submitButtonText}
                </Button>
            </div>
        </Modal>
    );
};

ScrollableTextModal.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    bodyContent: PropTypes.element,
    show: PropTypes.bool,
    submitButtonText: PropTypes.string,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
};

ScrollableTextModal.defaultProps = {
    className: undefined,
    title: "",
    show: false,
    submitButtonText: "Agree",
    onSubmit: () => {},
};
