// Copyright aptihealth, inc. 2019 All Rights Reserved

export const PROVIDER_DETAILS_SCHEMA = [
    {
        name: "name",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Full Name",
            placeholder: "Full Name",
        },
    },
    {
        name: "email",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "Email",
            placeholder: "Email",
        },
    },
    {
        name: "cp_credentials",
        elementType: "input",
        elementConfig: {
            type: "email",
            label: "CP Credential",
            placeholder: "CP Credential",
        },
    },
    {
        name: "npi_number",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "NPI Number",
            placeholder: "NPI Number",
        },
    },
    {
        name: "mobile",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Mobile",
            placeholder: "Mobile",
        },
    },
    {
        name: "provider_type",
        elementType: "input",
        elementConfig: {
            type: "text",
            label: "Provider Type",
            placeholder: "Provider Type",
        },
    },
    {
        name: "intake_practice_ids",
        elementType: "react-select",
        elementConfig: {
            options: [],
            label: "Intake Coordinator For Practice(s)",
            placeholder: "Intake Coordinator For Practice(s)",
        },
    },
];

export const PROVIDER_STATUS_DETAILS_SCHEMA = [
    {
        name: "status",
        elementType: "react-select",
        elementConfig: {
            options: [
                { label: "Active", value: "ACTIVE" },
                { label: "Inactive", value: "INACTIVE" },
            ],
            label: "Provider Status",
        },
    },
];

export const PROVIDER_PRACTICE_DETAILS_SCHEMA = [
    {
        name: "default_practice_id",
        elementType: "react-select",
        elementConfig: {
            options: [],
            label: "Default Practice",
        },
    },
];
