// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { useEffect } from "react";
import "../styles.scss";
import { VideoViewBase } from "../index";
import { isAuthorized } from "../../../../redux/actions/auth";

export const PreviewStep = (props) => {
    const {
        localMedia,
        toggleAudioBtn,
        toggleVideoBtn,
        startWorkflow,
        setAudioOff,
        setVideoOff,
        isPartient,
    } = props;

    let header = (
        <div className="fw-bold text-white fs-14 fs-lg-18">
            Audio and Video Settings for Your Call
        </div>
    );

    useEffect(() => {
        // fix for: Mute and video buttons show disabled but are actually enabled during preview
        if (isPartient) {
            setAudioOff(false);
            setVideoOff(false);
        }
    }, [isPartient]);

    let content = (
        <div className="GuidedVideoCall__wrapper_2">
            <div className="GuidedVideoCall__settings_message fw-bold text-white fs-18">
                <div className="GuidedVideoCall__preview">
                    {localMedia}

                    <div className="GuidedVideoCall__controls_overlap d-flex align-items-center justify-content-center">
                        <div className="GuidedVideoCall__controls-inner d-flex">
                            {toggleAudioBtn}
                            {toggleVideoBtn}
                        </div>
                    </div>
                </div>

                <span
                    className="GuidedVideoCall__center-btn mt-3 mb-5 text-center cursor"
                    onClick={startWorkflow}>
                    Join Now
                </span>
                <p className="mb-2">Important Information for Your Call</p>
                <p className="fs-lg-14 fs-12 w-50">
                    Before joining the call, please use the preview box above to get set-up. You
                    will need your audio and video turned on. Please note, there is a dedicated
                    amount of time for your call. This time is displayed in the top navigation bar
                    for easy access. Thank you!
                </p>
            </div>
        </div>
    );

    return <VideoViewBase header={header} content={content} {...props} />;
};
