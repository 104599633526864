// Copyright aptihealth, inc. 2019 All Rights Reserved

import React from "react";
import images from "../../../utils/images";
import { ICON_SIZES, ICON_TYPES } from "../../../constants/icons";
import PropTypes from "prop-types";

const SettingsList = (props) => {
    const IconComponent = props.iconComponent;

    console.log("IconComponent: ", IconComponent);

    return (
        <div
            className="Settings__list fs-16 fs-md-14 px-0 px-md-3 d-flex cursor justify-content-between align-items-center"
            data-public={"true"}>
            <div className="my-3 py-2">
                {IconComponent && (
                    <IconComponent
                        iconType={ICON_TYPES.OUTLINE}
                        iconSize={ICON_SIZES.SMALL}
                        className="Settings__iconComponent"
                    />
                )}

                <p className="d-inline-block m-0 Settings__icon-text">{props.text}</p>
            </div>
            <div>
                <img src={images("./common/settings_right.svg")} alt="Right Arrow" />
            </div>
        </div>
    );
};

SettingsList.propTypes = {
    text: PropTypes.string,
    iconComponent: PropTypes.func,
};
export default SettingsList;
