import { api } from "../APIRequests";
import { http } from "./axios";

const ASSOCIATED_FILES_FILE_TYPE = "ASSOCIATED_FILES";
const PATIENT_ASSOCIATED_FILES_FILE_SUB_TYPE = "ASSOCIATED_FILE_PATIENT";
const PATIENT_ASSOCIATED_FILES_ENTITY_TYPE = PATIENT_ASSOCIATED_FILES_FILE_SUB_TYPE.toLowerCase();
const REFERRAL_ASSOCIATED_FILES_FILE_SUB_TYPE = "ASSOCIATED_FILE_REFERRAL";
const REFERRAL_ASSOCIATED_FILES_ENTITY_TYPE = REFERRAL_ASSOCIATED_FILES_FILE_SUB_TYPE.toLowerCase();
const SCREENING_ASSOCIATED_FILES_FILE_SUB_TYPE = "ASSOCIATED_FILE_SCREENING";
const SCREENING_ASSOCIATED_FILES_ENTITY_TYPE =
    SCREENING_ASSOCIATED_FILES_FILE_SUB_TYPE.toLowerCase();

export const PATIENT_INSURANCE_IMAGE_FILE_TYPE = "PATIENT_INSURANCE_IMAGE";
export const REFERRAL_INSURANCE_IMAGE_FILE_TYPE = "REFERRAL_INSURANCE_IMAGE";
export const INSURANCE_IMAGE_FILE_PRIMARY_FRONT_SUB_TYPE = "PRIMARY_INSURANCE_FRONT";
export const INSURANCE_IMAGE_FILE_PRIMARY_BACK_SUB_TYPE = "PRIMARY_INSURANCE_BACK";
export const INSURANCE_IMAGE_FILE_SECONDARY_FRONT_SUB_TYPE = "SECONDARY_INSURANCE_FRONT";
export const INSURANCE_IMAGE_FILE_SECONDARY_BACK_SUB_TYPE = "SECONDARY_INSURANCE_BACK";

/**
 *
 * @param selectedFile: input from selecting a file (e.target.files[0])
 * @param data: {
 *     key: s3 key
 *     method: get_object | put_object
 *     patientId, referralCode, screeningId: id of the object is related to (auth purposes)
 * }
 * @returns {Promise<void>}
 */
export const uploadFileV2 = async (selectedFile, id, entity_type, key) => {
    const version = "v2";
    let fileType = selectedFile.type;
    let data = {
        method: "put_object",
        entity_id: id,
        entity_type: entity_type,
        file_key: key,
        content_type: fileType,
    };
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    try {
        const response = await api.shared.get_presigned_url({ version, data });
        await http.put(response.message, selectedFile, {
            headers: {
                "Content-Type": fileType,
            },
        });
    } catch (err) {
        console.log(err);
    }
};

export async function getReportPresignedUrl(report) {
    const version = "v2";
    const data = {
        method: "get_object",
        entity_id: report["entity_id"],
        entity_type: "patient",
        file_type: report["file_type"],
        file_sub_type: report["file_sub_type"],
        file_key: report["file_key"],
        content_type: "application/pdf",
    };
    const response = await api.shared.get_presigned_url({ version, data });
    return response.message;
}

export async function getInsuranceImagePresignedUrl(entityId, fileType, fileSubType) {
    const version = "v2";
    const data = {
        method: "get_object",
        entity_id: entityId,
        entity_type: fileType.toLowerCase(),
        file_type: fileType,
        file_sub_type: fileSubType,
        content_type: "json",
    };
    return await api.shared.get_presigned_url({ version, data });
}

export async function viewReport(report) {
    const reportUrl = await getReportPresignedUrl(report);
    window.open(reportUrl);
}

export async function getScreeningAssociatedFilePresignedUrl(id, key) {
    const version = "v2";
    const data = {
        method: "get_object",
        entity_id: id,
        entity_type: SCREENING_ASSOCIATED_FILES_ENTITY_TYPE,
        file_type: ASSOCIATED_FILES_FILE_TYPE,
        file_sub_type: SCREENING_ASSOCIATED_FILES_FILE_SUB_TYPE,
        file_key: key,
        content_type: "json",
    };
    const response = await api.shared.get_presigned_url({ version, data });
    if (response && response.message) {
        return response.message;
    }
}

export async function viewScreeningAssociatedFile(id, key) {
    const reportUrl = await getScreeningAssociatedFilePresignedUrl(id, key);
    window.open(reportUrl);
}

export async function getScreeningAssociatedFiles(entity_id) {
    return await api.shared.get_files({
        entity_id: entity_id,
        entity_type: ASSOCIATED_FILES_FILE_TYPE,
        entity_sub_type: SCREENING_ASSOCIATED_FILES_ENTITY_TYPE,
    });
}

export async function getPatientAssociatedFilePresignedUrl(id, key) {
    const version = "v2";
    const data = {
        method: "get_object",
        entity_id: id,
        entity_type: PATIENT_ASSOCIATED_FILES_ENTITY_TYPE,
        file_type: ASSOCIATED_FILES_FILE_TYPE,
        file_sub_type: PATIENT_ASSOCIATED_FILES_FILE_SUB_TYPE,
        file_key: key,
        content_type: "json",
    };
    const response = await api.shared.get_presigned_url({ version, data });
    if (response && response.message) {
        return response.message;
    }
}

export async function viewPatientAssociatedFile(id, key) {
    const reportUrl = await getPatientAssociatedFilePresignedUrl(id, key);
    window.open(reportUrl);
}

export async function getPatientAssociatedFiles(entity_id) {
    return await api.shared.get_files({
        entity_id: entity_id,
        entity_type: ASSOCIATED_FILES_FILE_TYPE,
        entity_sub_type: PATIENT_ASSOCIATED_FILES_ENTITY_TYPE,
    });
}

export async function getReferralAssociatedFilePresignedUrl(id, key) {
    const version = "v2";
    const data = {
        method: "get_object",
        entity_id: id,
        entity_type: REFERRAL_ASSOCIATED_FILES_ENTITY_TYPE,
        file_type: ASSOCIATED_FILES_FILE_TYPE,
        file_sub_type: REFERRAL_ASSOCIATED_FILES_FILE_SUB_TYPE,
        file_key: key,
        content_type: "json",
    };
    const response = await api.shared.get_presigned_url({ version, data });
    if (response && response.message) {
        return response.message;
    }
}

export async function viewReferralAssociatedFile(id, key) {
    const reportUrl = await getReferralAssociatedFilePresignedUrl(id, key);
    window.open(reportUrl);
}

export async function getReferralAssociatedFiles(entity_id) {
    return await api.shared.get_files({
        entity_id: entity_id,
        entity_type: ASSOCIATED_FILES_FILE_TYPE,
        entity_sub_type: REFERRAL_ASSOCIATED_FILES_ENTITY_TYPE,
    });
}

export async function writeToUserFileTable(entityId, entityType, fileInfo) {
    const data = {
        entity_id: entityId,
        entity_type: entityType,
        file_info: fileInfo,
    };
    return await api.shared.write_file(data);
}
