import React, { Component, Fragment } from "react";

import SearchBar from "../../UI/SearchBar";
import {
    List,
    ListBody,
    ListHeader,
    ListItem,
    ListTitleBar,
    TitleBarItem,
} from "../../UI/ListView";
import Hr from "../../UI/Hr";
import { toTitleCase } from "../../../utils/filters";
import { Link } from "react-router-dom";
import images from "../../../utils/images";
import { adminRoute } from "../../../config/roles";
import { Button, ButtonTypes } from "../../../component-library";

export const PROVIDER_LIST_CONF = [
    { colValue: "col-2", listTitleText: "Name" },
    { colValue: "col-2", listTitleText: "Email Address" },
    { colValue: "col-2", listTitleText: "Provider Types" },
    { colValue: "col-1", listTitleText: "Referral Count" },
    { colValue: "col-1", listTitleText: "Working Hours" },
    { colValue: "col-1", listTitleText: "Total Patients" },
    { colValue: "col-1", listTitleText: "Profile" },
    { colValue: "col-1", listTitleText: "Schedule" },
    { colValue: "col-1", listTitleText: "" },
];

export default class ProviderList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            providerList: props.providerList,
            search: "",
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.providerList !== this.state.providerList) {
            this.setState({
                providerList: this.props.providerList,
            });
        }
    }

    updateSearch = (e) => {
        this.setState({ search: e.target.value });
    };

    addButtonClickHandler = () => {
        this.props.history.push(`${this.props.match.url}/provider/add`);
    };

    renderControls = () => {
        return (
            <div className="d-flex render-controls">
                <SearchBar
                    className="mr-lg-2"
                    value={this.state.search}
                    onchange={this.updateSearch}
                />
                <Button buttonType={ButtonTypes.primaryV2} onClick={this.addButtonClickHandler}>
                    New Provider
                </Button>
            </div>
        );
    };

    getFilteredProviders = (list) => {
        return list.filter((provider) => {
            return provider.name
                .toLowerCase()
                .match(new RegExp("^" + this.state.search.toLowerCase()));
        });
    };

    setProviderDetails = (providerId) => {
        this.props.history.push(`${this.props.match.url}/provider/${providerId}`);
    };

    viewProviderSchedule = (providerId) => {
        this.props.history.push(`${adminRoute}/schedule/${providerId}/view?view=list`);
    };

    viewProviderProfile = (providerId) => {
        this.props.history.push(`${adminRoute}/profile/${providerId}`);
    };

    render() {
        return (
            <div data-public={"true"}>
                <ListBody classes="List__body_lg">
                    <ListHeader title={"Providers"} render={this.renderControls} />
                    <div className="d-none d-lg-block mb-2">
                        <Hr />
                    </div>
                    <ListTitleBar>
                        {PROVIDER_LIST_CONF.map((titleConf) => {
                            return (
                                <TitleBarItem
                                    key={titleConf.listTitleText.trim()}
                                    classes={titleConf.colValue}
                                    text={titleConf.listTitleText}
                                />
                            );
                        })}
                    </ListTitleBar>
                    <List>
                        <div>
                            <Fragment>
                                {this.getFilteredProviders(this.state.providerList).map(
                                    (provider, i) => {
                                        return (
                                            <ListItem
                                                key={provider.username}
                                                clickOnMobile
                                                clickHandler={() =>
                                                    this.setProviderDetails(provider.username)
                                                }>
                                                <ProviderListItem
                                                    provider={provider}
                                                    setProviderDetails={this.setProviderDetails}
                                                    viewProviderSchedule={this.viewProviderSchedule}
                                                    viewProviderProfile={this.viewProviderProfile}
                                                />
                                            </ListItem>
                                        );
                                    },
                                )}
                            </Fragment>
                        </div>
                    </List>
                </ListBody>
            </div>
        );
    }
}

const ProviderListItem = (props) => {
    const { provider, setProviderDetails, viewProviderSchedule, viewProviderProfile } = props;
    const groupTypesWithSchedules = ["PRESCRIBE", "BEHAVIORAL", "BEHAVIORAL_INTAKE"];
    const groupTypesWithProfilesToUpdate = groupTypesWithSchedules + ["PEER", "CASE_MANAGER"];
    const totalPatients = provider.total_patients || 0;

    let desktopListItem = (
        <div className="row no-gutters d-none d-lg-flex fs-14 align-items-center justify-content-between">
            <span className="col-2">{toTitleCase(provider.name)}</span>
            <span
                className="col-2"
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                data-toggle="tooltip"
                data-placement="top"
                title={provider.email}>
                {provider.email}
            </span>
            <span className="col-2">{provider.provider_type}</span>
            <span className="col-1">{provider.referral_count}</span>
            <span className="col-1">{provider.number_of_weekly_working_hours}</span>
            <span className="col-1">{totalPatients}</span>
            <span className="col-1">
                {groupTypesWithProfilesToUpdate.includes(provider.provider_type) && (
                    <div className="cursor">
                        <img
                            onClick={() => viewProviderProfile(provider.username)}
                            src={images("./common/profile_icon.svg")}
                            alt="view icon"
                        />
                    </div>
                )}
            </span>
            <span className="col-1">
                {groupTypesWithSchedules.includes(provider.provider_type) && (
                    <div className="cursor">
                        <img
                            onClick={() => viewProviderSchedule(provider.username)}
                            src={images("./icons/calender-icon.svg")}
                            alt="view icon"
                        />
                    </div>
                )}
            </span>
            <div className="col-1">
                <div className="cursor ml-2">
                    <img
                        onClick={() => setProviderDetails(provider.username)}
                        src={images("./common/view.png")}
                        alt="view icon"
                    />
                </div>
            </div>
        </div>
    );

    let mobileListItem = (
        <div className="d-flex d-lg-none justify-content-between">
            <div
                className="d-flex fs-14 flex-column"
                style={{ width: "65%", wordWrap: "break-word" }}>
                <span className="fs-16 fw-bold">{toTitleCase(provider.name)}</span>
                <span>{provider.email}</span>
            </div>
            <div className="d-flex fs-14 flex-column">
                <div>
                    <span>{provider.provider_type}</span>
                </div>
            </div>
        </div>
    );

    return (
        <Fragment>
            {mobileListItem}
            {desktopListItem}
        </Fragment>
    );
};
