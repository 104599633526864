import React from "react";
import CardSecondary from "../../UI/Card/CardSecondary";
import images from "../../../utils/images";
import { CardHeading } from "../../UI/Headings";

const CarePlanCard__EXPERIMENTAL_GOALS_AND_OBJECTIVES__ENABLED = (props) => {
    return (
        <CardSecondary
            style={{ maxWidth: "100%" }}
            className="PatientProfile__CreatePlan-wpr py-4 d-xl-flex d-block justify-content-between align-items-center px-3 px-xl-4">
            <div 
                className="d-flex align-items-center"
                style={{ maxWidth: "65%" }}>
                <img
                    src={images("./common/90DaysPlan.svg")}
                    alt="90 Days Plan"
                    className="PatientProfile__90DaysPlan img-fluid"
                    width="39px"
                />
                <div className="ml-2 pl-2 mr-0 mr-lg-5">
                    <CardHeading
                        text="Legacy Care Plan"
                        className="text-white d-lg-inline-block fs-16"
                    />
                    <p className="text-white fs-14">
                        To support our transition to the new Care Plan, goals and objectives previously entered here will be temporarily retained in view-only mode.
                    </p>
                </div>
            </div>
            <div className="ml-auto mt-3 mt-lg-0">
                <div className="d-block text-right">
                    <span
                        style={{ color: "white", cursor: "pointer" }}
                        className="ClinicalAssessment_Btn Btn Btn--pri Btn-sm"
                        onClick={props.showCard}>
                        Care Plan Details
                    </span>
                </div>
            </div>
        </CardSecondary>
    );
};

export default CarePlanCard__EXPERIMENTAL_GOALS_AND_OBJECTIVES__ENABLED;
