import "./styles.scss";
import React, { Component } from "react";

class DemographicsCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const labelMap = {
            "race_ethnicity": "Race, Ethnicity",
            "marital_status": "Marital Status",
            "current_housing": "Current Housing",
            "current_living": "Current Living",
            "employment_status": "Employed",
            "sexual_orientation": "Sexual Orientation",
            "education": "Education Completed",
            "military_background": "Military Background",
            "pcp_name": "PCP",
            "pcp_location": "PCP Location",
        }

        return (
            <div className="DemographicsCard--container">
                {this.props.demographics &&
                    Object.keys(this.props.demographics).map((key) => {
                        // If erroneous key sent, skip
                        if (!(key in labelMap)) return

                        // If no value, skip
                        const value = this.props.demographics[key]
                        if (value === null || value === undefined || value === "") return
                        
                        const label = labelMap[key]
                        const text = Array.isArray(value) ? value.join(', ') : value

                        return (
                            <div className="DemographicsCard--entry">
                                <p className="DemographicsCard--label">{label}</p>
                                <p className="DemographicsCard--text">{text}</p>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
};

export default DemographicsCard;
