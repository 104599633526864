// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Spinner from "../components/UI/Spinner";
import Alert from "../components/UI/Alert";
import { connect } from "react-redux";
import { loadToken } from "../redux/actions/auth";
import "./App.scss";
import ExceptionReport from "../components/Common/ExceptionReport";
import { api } from "../APIRequests";
import { loadConfigs } from "../redux/actions/configs";
import RouterFactory from "./RouterFactory/RouterFactory";
import { isConfigsLoaded } from "../redux/reducers/configsReducer";
import { Toaster } from "../component-library";
import { removeToast } from "../redux/actions/toaster";
import { loadFlags } from "../redux/actions/featureFlag";
import { privateFlagsLoaded, publicFlagsLoaded } from "../redux/reducers/featureFlagReducer";

class App extends Component {
    constructor(props) {
        super(props);
        if (!window.location.pathname.startsWith("/auth") && window.location.pathname !== "/") {
            window.localStorage.setItem(
                "redirectAfterAuth",
                window.location.pathname + window.location.search,
            );
        }
        this.state = {
            configsLoaded: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps !== this.props &&
            isConfigsLoaded(this.props.configs) &&
            publicFlagsLoaded(this.props.flags) &&
            (!this.props.auth.accessToken ||
                (this.props.auth.accessToken && privateFlagsLoaded(this.props.flags)))
        ) {
            this.setState({ configsLoaded: true });
        }
    }

    async componentDidMount() {
        this.props.loadToken();
        this.emailTracking();
        this.props.loadConfigs();
        this.props.loadFlags();
    }

    emailTracking() {
        const params = new URLSearchParams(window.location.search.substring(1));
        let campaignId = params.get("campaign_id");
        let entityId = params.get("entity_id");
        let cohort = params.get("cohort");
        if (campaignId != null && entityId != null && cohort != null) {
            let data = {
                campaign_id: campaignId,
                entity_id: entityId,
                cohort: cohort,
                action: "CLICKED",
            };
            try {
                api.tracking.email({ data });
            } catch (error) {
                console.log(error);
            }
        }
    }

    render() {
        return (
            <Router>
                <div className="container-fluid p-0 position-relative">
                    {this.props.spinner.isLoading && <Spinner />}
                    {this.props.alert && (
                        <Alert
                            type={this.props.alert.type}
                            message={this.props.alert.message}
                            dismiss={this.props.alert.dismiss}
                            requestId={this.props.alert.requestId}
                            show_exception_handling={this.props.alert.showExceptionReporting}
                            customHTML={this.props.alert.customHTML}
                            buttonText={this.props.alert.buttonText}
                            buttonAction={this.props.alert.buttonAction}
                        />
                    )}

                    {this.props.exceptionReport && (
                        <ExceptionReport
                            requestId={this.props.exceptionReport.requestId}
                            dismiss={this.props.exceptionReport.dismiss}
                        />
                    )}

                    <Toaster
                        toasts={this.props.toaster.toasts}
                        onDismissButtonClick={this.props.removeToast}
                    />

                    {this.state.configsLoaded && <RouterFactory {...this.props} />}
                </div>
            </Router>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        spinner: state.loader,
        alert: state.alert.alertConf,
        exceptionReport: state.exceptionReport.exceptionReportConf,
        configs: state.configs,
        toaster: state.toaster,
        flags: state.flags,
    };
};

export default connect(mapStateToProps, { loadToken, loadConfigs, removeToast, loadFlags })(App);
