// Copyright aptihealth, inc. 2019 All Rights Reserved
import {httpAuth} from '../utils/axios';
import { GROUP_AUTH_HEADER_NAME } from '../constants/axios';

const headers = {
    [GROUP_AUTH_HEADER_NAME]: 'admin'
};

const adminRequests = {
    change_provider_status: function ({ data } = {}) {
        let conf = {
            method: "post",
            url: "/v1/admin/change_provider_status",
            headers,
            data,
        };
        return httpAuth(conf);
    },

    change_provider_practice: function ({ data } = {}) {
        let conf = {
            method: "post",
            url: "/v1/admin/change_provider_practice",
            headers,
            data,
        };
        return httpAuth(conf);
    },

    change_automatic_payment_enabled: function ({ data } = {}) {
        let conf = {
            method: "post",
            url: "/v1/admin/change_automatic_payment_enabled",
            headers,
            data,
        };
        return httpAuth(conf);
    },

    fetch_all_practices: function ({ options, queryParams } = {}) {
        let url = "/v1/practice/all_practices";
        if (queryParams && queryParams.fields) {
            url += `?fields=${queryParams.fields}`;
        }
        let conf = {
            method: "post",
            url: url,
            headers,
            ...options,
        };
        return httpAuth(conf);
    },

    fetch_providers_by_pcp_practice_id: function ({ options, data } = {}) {
        let conf = {
            method: "post",
            url: "/v1/admin/get_providers_by_pcp_practice_id",
            headers,
            data,
            ...options,
        };
        return httpAuth(conf);
    },

    fetch_admin_profile: function ({ options } = {}) {
        let conf = {
            method: "post",
            url: "/v1/admin/profile",
            ...options,
        };
        return httpAuth(conf);
    },

    fetch_patient_list: function ({ urlParams }) {
        let url = "/v1/provider/patient_list";

        if (urlParams.practiceId) {
            url += `?practice_id=${urlParams.practiceId}`;
        } else {
            url += `?provider_id=${urlParams.providerId}`;
        }

        let conf = {
            method: "post",
            url,
            headers,
            params: { showLoader: false },
        };
        return httpAuth(conf);
    },

    post_practice: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/practice/add_practice",
            headers,
            data,
        };
        return httpAuth(conf);
    },

    update_practice: function ({ data, queryParams }) {
        let conf = {
            method: "post",
            url: `/v1/practice/update_practice?practice_id=${queryParams.practiceId}`,
            data,
            headers,
            params: { showLoader: false },
        };
        return httpAuth(conf);
    },

    fetch_practice_providers: function ({ data, urlParams }) {
        let conf = {
            method: "post",
            url: `/v1/practice/all_practice_providers?practice_id=${urlParams.practiceId}`,
            data,
            headers,
            params: { showLoader: false },
        };
        return httpAuth(conf);
    },

    fetch_all_csm: function ({ options } = {}) {
        let conf = {
            method: "post",
            url: "/v1/admin/all_csm",
            headers,
            ...options,
        };
        return httpAuth(conf);
    },

    create_user: function ({ user }) {
        let conf = { method: "post", url: "/v1/auth/signup", data: user };
        return httpAuth(conf);
    },

    delete_provider_from_care_team: function ({ urlParams, queryParams }) {
        const { providerId } = queryParams;
        const { patientId } = urlParams;
        let conf = {
            method: "post",
            url: `/v1/patient_management/${patientId}/delete_provider_from_care_team?providerId=${providerId}`,
            headers,
        };
        return httpAuth(conf);
    },

    fetch_providers: function ({ queryParams }) {
        const { providerTypes } = queryParams;
        let conf = {
            method: "post",
            url: `/v1/admin/get_providers?provider_types=${providerTypes}`,
            headers,
            params: { showLoader: false },
        };
        return httpAuth(conf);
    },

    fetch_insurance_eligibility_records: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/insurance_eligibility/get_eligibility",
            data,
        };
        return httpAuth(conf);
    },

    reset_patient_password: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/admin/reset_user_password",
            data,
        };
        return httpAuth(conf);
    },

    change_patient_email: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/admin/change_patient_email",
            data,
        };
        return httpAuth(conf);
    },

    change_patient_practice: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/admin/change_patient_practice",
            data,
        };
        return httpAuth(conf);
    },

    trigger_email_verification_email: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/admin/trigger_email_verification_email",
            data,
        };
        return httpAuth(conf);
    },

    restrict_patient_scheduling: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/admin/restrict_patient_scheduling",
            data,
        };
        return httpAuth(conf);
    },

    set_provider_universal_access: function ({ data }) {
        let conf = {
            method: "post",
            url: "/v1/admin/set_provider_universal_access",
            data,
        };
        return httpAuth(conf);
    },
};

export default adminRequests;
