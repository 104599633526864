// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import ProfileDetailsCard from "./ProfileDetailsCard";
import { Form, Formik } from "formik";
import Label from "../../UI/Label";
import InputError from "../../UI/InputError";
import images from "../../../utils/images";
import Button from "../../UI/Button";
import ImageFullPreview from "../../Common/ImageFullPreview";
import FormikInput from "../../UI/FormikInput";
import {
    completeAutoSignUp,
    resetInsuranceProgress,
    setInsuranceImagesFromReferral,
    signUp,
    unsetInsImage,
    uploadInsImage,
} from "../../../redux/actions/auth";
import { api } from "../../../APIRequests";
import {
    PRI_INSURANCE_SCHEMA,
    SEC_INSURANCE_SCHEMA,
} from "../../../containers/Dashboard/Patient/schema/PatientProfileInfoSchema";
import * as yup from "yup";
import { connect } from "react-redux";
import SubmitControls from "./SubmitControls";
import Hr from "../../UI/Hr";
import FormikMobileInput from "../../UI/formikMobileInput";
import {
    formatDate,
    dobValidationSchemaFactory,
    noInsuranceCarrierValidationSchemaFactory,
} from "../../../utils/yupSchemaUtils";
import { PRI, SEC } from ".";
import { ReadOnlyInsuranceInfo } from "./ReadOnlyInsuranceInfo";
import _ from "lodash";
import { ProviderMemberProfileInsuranceHOC } from "../../../component-library/hocs/ProviderMemberProfileInsuranceHOC";
import { showAlertWithAction } from "../../../redux/actions/alerts";
import { MemberProfileInsurance } from "../../../component-library/MemberProfileInsurance";
import { UploadFileModalHOC } from "../../../component-library";
import { carrierMemberIdValidationSchema } from "../../Common/InsuranceEligibility/carrierMetadata";
import { CustomForm } from "../../../component-library/CustomForm";

yup.addMethod(yup.date, "format", formatDate); // we are extending data schema by adding our custom method

const dateValidationSchema = dobValidationSchemaFactory("MMDDYYYY");
const insuranceValidationSchema = yup.object().shape({
    policy_dob: noInsuranceCarrierValidationSchemaFactory(
        "carrier",
        dateValidationSchema.nullable(),
        true,
    ),
    state: noInsuranceCarrierValidationSchemaFactory("carrier", yup.string().nullable()),
    carrier: yup.string().required("Carrier is required").nullable(),
    member_id: noInsuranceCarrierValidationSchemaFactory(
        "carrier",
        carrierMemberIdValidationSchema,
    ),
    policy_holder_name: noInsuranceCarrierValidationSchemaFactory(
        "carrier",
        yup
            .string()
            .matches(/^[a-z A-Z-]+$/, "Digits, space & special characters are not allowed")
            .nullable(),
    ),
    policy_relationship: noInsuranceCarrierValidationSchemaFactory(
        "carrier",
        yup.string().required("Policy Holder is required").nullable(),
    ),
});

class InsuranceInfo extends Component {
    constructor(props) {
        super(props);
        this.setFieldValue = null;
        this.state = {
            profileData: props.data,
            validationSchema: insuranceValidationSchema,

            carriersOptions: [],
            statesOptions: [],

            showInsuranceCard: false,
            frontImg: null,
            backImg: null,
            frontImgPreview: null,
            bckImgProgress: null,
        };
    }

    componentDidMount() {
        let frontImg, backImg;
        if (PRI === this.props.type) {
            frontImg = this.props.insuranceImageUrls.pfUrl;
            backImg = this.props.insuranceImageUrls.pbUrl;
        } else {
            frontImg = this.props.insuranceImageUrls.sfUrl;
            backImg = this.props.insuranceImageUrls.sbUrl;
        }

        this.setState({
            frontImg: frontImg,
            backImg: backImg,
        });

        const options = { params: { showLoader: false } };
        api.patient
            .fetch_insurance_list({ options })
            .then((cleanResponse) => {
                let carriersOptions = cleanResponse.carriers.map((carriers) => {
                    return { value: carriers, label: carriers };
                });
                let statesOptions = cleanResponse.state.map((states) => {
                    return { value: states, label: states };
                });
                // modify original schema with received options list defined and set them in state
                PRI_INSURANCE_SCHEMA.forEach((el) =>
                    this.setInsOptions(el, carriersOptions, statesOptions),
                );
                SEC_INSURANCE_SCHEMA.forEach((el) =>
                    this.setInsOptions(el, carriersOptions, statesOptions),
                );
                this.setState({
                    pri_insurance_schema: PRI_INSURANCE_SCHEMA,
                    sec_insurance_schema: SEC_INSURANCE_SCHEMA,
                    carriersOptions: carriersOptions,
                    statesOptions: statesOptions,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    setInsOptions = (el, carriersOptions, statesOptions) => {
        if (el.elementConfig && el.elementConfig.label === "Carrier") {
            el.elementConfig.options = carriersOptions;
        } else if (el.elementConfig && el.elementConfig.label === "State") {
            el.elementConfig.options = statesOptions;
        }
    };

    showInsuranceUploadPopUp = () => {
        this.setState({
            showInsuranceCard: true,
        });
    };

    onFileDelete = (fileName) => {
        if (fileName && fileName.includes("front")) {
            this.setFieldValue("card_front", null);
        }
        if (fileName && fileName.includes("back")) {
            this.setFieldValue("card_back", null);
        }
    };

    renderInsForm = (formikProps) => {
        this.setFieldValue = formikProps.setFieldValue;

        let frontInsuranceImage, backInsuranceImage;
        if (this.props.type === PRI) {
            frontInsuranceImage = _.get(this.props, "insuranceImageUrls.pfUrl");
            backInsuranceImage = _.get(this.props, "insuranceImageUrls.pbUrl");
            if (this.state.frontImgPreview) {
                frontInsuranceImage = this.state.frontImgPreview;
                backInsuranceImage = this.state.backImgPreview;
            }
        }

        if (this.props.type === SEC) {
            frontInsuranceImage = _.get(this.props, "insuranceImageUrls.sfUrl");
            backInsuranceImage = _.get(this.props, "insuranceImageUrls.sbUrl");
            if (this.state.frontImgPreview) {
                frontInsuranceImage = this.state.frontImgPreview;
                backInsuranceImage = this.state.backImgPreview;
            }
        }

        return (
            <ProviderMemberProfileInsuranceHOC
                className={"profile-insurance"}
                isPrimaryInsurance={this.props.type === PRI}
                insurance={this.props.insurance}
                formikProps={formikProps}
                emptyTitle={this.props.emptyTitle}
                emptyDetails={this.props.emptyDetails}
                editTitle={this.props.editTitle}
                completedTitle={this.props.completedTitle}
                isEditing={this.props.isEditing}
                onEditButtonClick={this.props.onEditButtonClick}
                onAddButtonClick={this.props.onAddButtonClick}
                onCancelButtonClick={() => {
                    formikProps.resetForm();
                    this.props.onCancelButtonClick();
                }}
                onSubmitButtonClick={async () => {
                    await formikProps.submitForm();
                }}
                insuranceCompaniesOptions={this.state.carriersOptions}
                stateOfInsurancePlanOptions={this.state.statesOptions}
                onBrowseButtonClicked={this.showInsuranceUploadPopUp}
                insuranceCardImgFrontSrc={frontInsuranceImage}
                insuranceCardImgBackSrc={backInsuranceImage}
                onInsuranceCardImgFrontClick={this.props.onFrontImageClick}
                onInsuranceCardImgBackClick={this.props.onBackImageClick}
                onFileDelete={this.onFileDelete}
                addAcknowledgement={this.props.addAcknowledgement}
            />
        );
    };

    submitInsHandler = (formData, action) => {
        let data = { ...formData };
        if (data.policy_dob) {
            data.policy_dob = transformDOBsToServerStrings(formData.policy_dob);
        }

        if (data.card_front) {
            data.card_front = transformInsuranceImageUrls(formData.card_front);
        }

        if (data.card_back) {
            data.card_back = transformInsuranceImageUrls(formData.card_back);
        }

        if (PRI === this.props.type) {
            data = {
                primary_insurance: data,
            };
        }

        if (SEC === this.props.type) {
            data = {
                secondary_insurance: data,
            };
        }
        this.props.submit(data, action);
    };

    frInpHandler = (e, type) => {
        let value = null;
        let fileName = "";
        let uuid = this.state.profileData.username;
        let selectedFile = e.target.files[0];
        if (PRI === this.props.type) {
            value = "pf"; //primary front
            fileName = "primary_front_" + uuid;
        }
        if (SEC === this.props.type) {
            value = "sf"; //secondary front
            fileName = "secondary_front_" + uuid;
        }
        let fileType = selectedFile.type;
        this.props.uploadInsImage({ file: selectedFile, fileName, fileType }, value, (value) => {
            let image = {};
            if (PRI === this.props.type) {
                image = this.props.priInsFront;
            }
            if (SEC === this.props.type) {
                image = this.props.secInsFront;
            }

            this.setState({
                frontImgPreview: { ...image },
            });
        });
    };

    bckInpHandler = (e, type) => {
        let value = null;
        let fileName = "";
        let uuid = this.state.profileData.username;
        let selectedFile = e.target.files[0];
        if (PRI === this.props.type) {
            value = "pb"; //primary back
            fileName = "primary_back_" + uuid;
        }
        if (SEC === this.props.type) {
            value = "sb"; //secondary back
            fileName = "secondary_back_" + uuid;
        }

        let fileType = selectedFile.type;
        this.props.uploadInsImage({ file: selectedFile, fileName, fileType }, value, (value) => {
            let image = {};
            if (PRI === this.props.type) {
                image = this.props.priInsBack;
            }
            if (SEC === this.props.type) {
                image = this.props.secInsBack;
            }
            this.setState({
                backImgPreview: { ...image },
            });
        });
    };

    removeInsImg = (type, attr) => {
        if (attr === "f") {
            this.setState({ frontImgPreview: null });
            this.setFieldValue("card_front", null);
        } else {
            this.setState({ backImgPreview: null });
            this.setFieldValue("card_back", null);
        }
        this.props.resetInsuranceProgress(attr);
    };

    hideInsuranceUploadPopUp = (type) => {
        this.setState({
            showInsuranceCard: false,
            frontImgPreview: null,
            backImgPreview: null,
            frImgProgress: null,
            bckImgProgress: null,
        });

        this.props.resetInsuranceProgress("f");
        this.props.resetInsuranceProgress("b");
    };

    onInsuranceDone = (insType) => {
        if (!this.state.frontImgPreview && !this.state.backImgPreview) {
            this.props.unsetInsImage(insType);
            this.setState({
                showInsuranceCard: false,
            });
            return;
        }

        if (PRI === this.props.type) {
            this.setFieldValue("card_front", this.props.priInsFront?.url);
            this.setFieldValue("card_back", this.props.priInsBack?.url);
        }

        if (SEC === this.props.type) {
            this.setFieldValue("card_front", this.props.secInsFront?.url);
            this.setFieldValue("card_back", this.props.secInsBack?.url);
        }

        this.setState({
            frontImgPreview: null,
            backImgPreview: null,
            showInsuranceCard: false,
        });

        this.props.resetInsuranceProgress();
    };

    transformDOBToString = (insurance) => {
        if (insurance && insurance.policy_dob) {
            insurance.policy_dob = insurance.policy_dob.replace(/\//g, "");
        }

        return insurance;
    };

    render() {
        return (
            <>
                {this.state.showInsuranceCard && (
                    <UploadFileModalHOC
                        showInsuranceCard={this.state.showInsuranceCard}
                        insuranceHide={this.hideInsuranceUploadPopUp}
                        onFrontInputChange={this.frInpHandler}
                        onBackInputChange={this.bckInpHandler}
                        done={this.onInsuranceDone}
                    />
                )}

                <CustomForm
                    initialValues={this.transformDOBToString(this.props.insurance)}
                    validationSchema={this.state.validationSchema}
                    onSubmit={this.submitInsHandler}
                    render={this.renderInsForm}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        priInsFront: state.auth.priInsFront,
        priInsBack: state.auth.priInsBack,
        secInsFront: state.auth.secInsFront,
        secInsBack: state.auth.secInsBack,
        frImgProgress: state.auth.frImgProgress,
        bckImgProgress: state.auth.bckImgProgress,
    };
};

export default connect(mapStateToProps, {
    uploadInsImage,
    unsetInsImage,
    resetInsuranceProgress,
    setInsuranceImagesFromReferral,
})(InsuranceInfo);

export const trasformDOBsToValidStrings = (data) => {
    let updatedData = _.cloneDeep(data);
    const transformedDOBData = {
        primary_insurance: { policy_dob: "" },
        secondary_insurance: { policy_dob: "" },
    };
    if (
        updatedData.hasOwnProperty("primary_insurance") &&
        updatedData["primary_insurance"].hasOwnProperty("policy_dob") &&
        updatedData["primary_insurance"]["policy_dob"] != null
    ) {
        updatedData["primary_insurance"]["policy_dob"] = transformedDOBData["primary_insurance"] = {
            policy_dob: updatedData["primary_insurance"]["policy_dob"].replace(/\//g, ""),
        };
    }

    if (
        updatedData.hasOwnProperty("secondary_insurance") &&
        updatedData["secondary_insurance"].hasOwnProperty("policy_dob") &&
        updatedData["secondary_insurance"]["policy_dob"] != null
    ) {
        updatedData["secondary_insurance"]["policy_dob"] = transformedDOBData[
            "secondary_insurance"
        ] = { policy_dob: updatedData["secondary_insurance"]["policy_dob"].replace(/\//g, "") };
    }

    return transformedDOBData;
};

export const transformDOBsToServerStrings = (policy_dob) => {
    if (policy_dob) {
        return `${policy_dob.substring(0, 2)}/${policy_dob.substring(2, 4)}/${policy_dob.substring(
            4,
            8,
        )}`;
    }
};

export const transformInsuranceImageUrls = (insuranceImageUrl) => {
    if (insuranceImageUrl && !insuranceImageUrl.startsWith("backend/insurance_cards/")) {
        return `backend/insurance_cards/${insuranceImageUrl}`;
    }

    return insuranceImageUrl;
};
