import React, { Fragment, useEffect, useState } from "react";
import { Field } from "formik";
import { uploadInsImage } from "../../../containers/Dashboard/Providers/providerAPI";
import crypto from "crypto";
import { ChildSignupInsuranceHOC } from "../../../component-library/hocs/ChildSignupFlowInsuranceHOC";
import { UploadFileModalHOC } from "../../../component-library";

const InsuranceForm = (props) => {
    const uuid = crypto.randomBytes(64).toString("hex");

    const [formikProps, setFormikProps] = useState(props.formikProps);

    const [priInsFront, setPriInsFront] = useState(null);
    const [priInsBack, setPriInsBack] = useState(null);

    const [frImgProgress, setFrImgProgress] = useState(null);
    const [bckImgProgress, setBckImgProgress] = useState(null);

    const [frontImgPreview, setFrontImgPreview] = useState(null);
    const [backImgPreview, setBackImgPreview] = useState(null);

    const [insEditPri, setInsEditPri] = useState(false);

    const [showInsuranceCard, setShowInsuranceCard] = useState(false);
    const [insCardType, setInsCardType] = useState(null);

    const setValues = formikProps.setFieldValue;

    useEffect(() => {
        if (props.formikProps && props.formikProps !== formikProps) {
            setFormikProps(props.formikProps);
        }
    }, [props.initialValues, props.formikProps]);

    const showInsuranceUploadPopUp = (type) => {
        let frPreview = null;
        let bckPreview = null;
        if (type === "pri") {
            frPreview = !priInsFront ? null : { ...priInsFront };
            bckPreview = !priInsBack ? null : { ...priInsBack };
        }
        setShowInsuranceCard(true);
        setInsCardType(type);
        setFrontImgPreview(frPreview);
        setBackImgPreview(bckPreview);
    };

    const hideInsuranceUploadPopUp = (type) => {
        if (!insEditPri && type === "pri") {
            setFrontImgPreview(null);
            setBackImgPreview(null);
            setPriInsFront(null);
            setPriInsBack(null);
            setFrImgProgress(null);
            setBckImgProgress(null);
        }
        setShowInsuranceCard(false);
        setInsCardType(null);
    };

    const onFrontUploadProgress = (progress) => setFrImgProgress(progress);

    const onBackUploadProgress = (progress) => setBckImgProgress(progress);

    const frInpHandler = (e, type) => {
        let value = null;
        if (type === "pri") {
            value = "pf"; //primary front
        }
        let fileName = "";
        let selectedFile = e.target.files[0];
        if (type === "pri") {
            fileName = "primary_front_" + uuid;
        }
        let fileType = selectedFile.type;
        uploadInsImage(
            { file: selectedFile, fileName, fileType },
            value,
            (value, data) => {
                if (value === "pf") {
                    setFrontImgPreview(data); // Returns thumbnail preview image
                    setPriInsFront(data);
                }
            },
            onFrontUploadProgress,
        );
    };

    const bckInpHandler = (e, type) => {
        let value = null;
        if (type === "pri") {
            value = 2; //primary back
        }
        let fileName = "";
        let selectedFile = e.target.files[0];
        if (type === "pri") {
            fileName = "primary_back_" + uuid;
        }
        let fileType = selectedFile.type;
        uploadInsImage(
            { file: selectedFile, fileName, fileType },
            value,
            (value, data) => {
                if (value === 2) {
                    setBackImgPreview(data); // No more image preview
                    setPriInsBack(data);
                }
            },
            onBackUploadProgress,
        );
    };

    const resetInsUploadProgress = (attr) => {
        if (attr === "f") {
            setFrImgProgress(null);
        } else {
            setBckImgProgress(null);
        }
    };

    const onInsuranceDone = (insType) => {
        if (insType === "pri" && insEditPri) {
            if (!frontImgPreview && !backImgPreview) {
                if (insType === "pri") {
                    setPriInsFront(null);
                    setPriInsBack(null);
                    setFrImgProgress(null);
                    setBckImgProgress(null);
                }
                setShowInsuranceCard(false);
                setInsCardType(null);
                setInsEditPri(insType === "pri" ? false : insEditPri);
                return;
            }
        }
        if (insType === "pri") {
            setValues("insurance.card_front", priInsFront?.url);
            setValues("insurance.card_back", priInsBack?.url);
        }

        setFrontImgPreview(null);
        setBackImgPreview(null);
        setShowInsuranceCard(false);
        setInsCardType(null);
        setInsEditPri(insType === "pri" ? true : insEditPri);
        setFrImgProgress(null);
        setBckImgProgress(null);
    };

    const removeInsImg = (type, attr) => {
        if (attr === "f") {
            setFrontImgPreview(null);
        } else {
            setBackImgPreview(null);
        }
        resetInsUploadProgress(attr);
    };

    const onFileDelete = (fileUrl) => {
        if (priInsFront && priInsFront.url === fileUrl) {
            setPriInsFront(null);
        }

        if (priInsBack && priInsBack.url === fileUrl) {
            setPriInsBack(null);
        }
    };

    return (
        <>
            <Field
                name="insurance" // name = "insurance must be same as define in initialValues schema of formik"
                render={() => {
                    return (
                        <Fragment>
                            {/*  Primary insurance section */}
                            <ChildSignupInsuranceHOC
                                className="TeenChildSignup--add-insurance"
                                insuranceCompanyOptions={props.insuranceCompaniesList}
                                isPrimaryInsurance={true}
                                onBrowseButtonClicked={() => showInsuranceUploadPopUp("pri")}
                                formikProps={formikProps}
                                onFileDelete={onFileDelete}
                                submitAcknowledgementHandler={props.submitAcknowledgementHandler}
                                closeAcknowledgementHandler={props.closeAcknowledgementHandler}
                                showAcknowledgementPopup={props.showAcknowledgementPopup}
                            />
                        </Fragment>
                    );
                }}
            />
            {showInsuranceCard && (
                <UploadFileModalHOC
                    showInsuranceCard={showInsuranceCard}
                    insuranceHide={hideInsuranceUploadPopUp}
                    onFrontInputChange={frInpHandler}
                    onBackInputChange={bckInpHandler}
                    done={onInsuranceDone}
                />
            )}
        </>
    );
};

export default InsuranceForm;
