// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import "../../GuidedVideoCall/styles.scss";
import { VideoViewBase } from "../index";
import Button from "../../../UI/Button";
import { api } from "../../../../APIRequests";
import { Select } from "../../../UI/StyledInput";
import store from "../../../../redux/store";
import { showLoader } from "../../../../redux/actions/loader";
import { arrayToIndexedObject } from "../../../../utils/arrayUtil";
import { generateVideoCallErrorLog } from "../../../../containers/VideoClient";

export const RatingOptions = (props) => {
    let {
        questionSet,
        questionData,
        questionId,
        ratingAnswerOnClick,
        optionLabelMap,
        optionWrapperStyle,
        optionStyle,
        disabled,
    } = props;
    if (!optionWrapperStyle) {
        optionWrapperStyle = { margin: "0px 20px" };
    }
    if (!optionStyle) {
        optionStyle = { height: 25 };
    }
    let getOptionClasses = (optionId, answer) => {
        return (
            "RatingOption mb-3 w-100" +
            (answer !== null && answer === optionId ? " RatingOption-active" : "")
        );
    };
    let classes = {
        label: "col-4 text-left",
        options: "d-flex justify-content-between col-8",
    };
    if (questionSet === 1) {
        classes = {
            label: "col-2 text-left",
            options: "d-flex justify-content-between col-10",
        };
    }
    if (!questionData.label) {
        classes.options = "d-flex justify-content-between col-12";
    }
    return (
        <div className={"row"}>
            {questionData.label && (
                <div className={classes.label}>
                    <div>{questionData.label}</div>
                </div>
            )}
            <div className={classes.options}>
                {Object.keys(optionLabelMap).map((optionId) => {
                    optionId = parseInt(optionId);
                    return (
                        <div
                            key={questionSet + questionId + optionId}
                            style={optionWrapperStyle}
                            className={"w-100"}>
                            <button
                                style={optionStyle}
                                id={`${optionId}`}
                                disabled={disabled ? "disabled" : null}
                                onClick={(e) =>
                                    ratingAnswerOnClick(questionSet, questionId, optionId)
                                }
                                className={getOptionClasses(optionId, questionData.answer)}
                                type={"button"}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

class QuestionsStep extends Component {
    state = {
        questionSet: null,
        questionSets: null,
        errors: "",
    };

    async componentDidMount() {
        try {
            const data = {
                callId: this.props.callDetails["callId"],
            };
            const response = await api.shared.get_patient_call_question_sets({ data });
            this.setState({ questionSets: response });
        } catch (error) {
            let data = generateVideoCallErrorLog(
                this.props.callDetails.callId,
                error,
                "QuestionStep.componentDidMount",
            );
            api.shared.report_video_call_error({ data });
            this.props.startCall();
        }
    }

    ratingAnswerOnClick = (questionSet, questionId, answer) => {
        const newState = { ...this.state.questionSets };
        newState[questionSet].questions[questionId].answer = parseInt(answer);
        this.setState(newState);
    };

    selectOnClick = (questionSet, questionId, answer) => {
        const newState = { ...this.state.questionSets };
        newState[questionSet].options[questionId].answer = answer;

        if (questionId === "none") {
            Object.entries(newState[questionSet].options).forEach(([key, value]) => {
                if (key !== "none") {
                    value.answer = false;
                }
            });
        } else {
            if (newState[questionSet].options["none"]) {
                newState[questionSet].options["none"].answer = false;
            }
        }

        this.setState(newState);
    };

    getPreQuestionInfo = () => {
        return (
            <>
                <p className={"fs-28 mb-4 mx-auto mt-auto"}>Your session will start very soon.</p>
                <p className="fs-18 font-weight-normal my-4 text-dark">
                    Your session will start very soon. To better understand how you are and improve
                    your care, please take 1-2 minutes to complete a few questions.
                </p>
                <button
                    className="GuidedVideoCall__center-btn  mt-4 Btn mx-auto mb-auto"
                    onClick={() => this.setState({ questionSet: 0 })}>
                    Begin Questions
                </button>
            </>
        );
    };

    nextButtonOnClick = async (isLastQuestionSet) => {
        const currentQuestionSet = this.state.questionSets[this.state.questionSet];
        const validationString = "Please answer all questions.";
        if (["phq", "gad", "rating"].includes(currentQuestionSet.type)) {
            if (
                Object.values(currentQuestionSet.questions).find(
                    (response) => response.answer === null,
                )
            ) {
                this.setState({ errors: validationString });
                return;
            }
        } else {
            if (!Object.values(currentQuestionSet.options).find((response) => response.answer)) {
                this.setState({ errors: validationString });
                return;
            }
        }
        if (isLastQuestionSet) {
            store.dispatch(showLoader());
            try {
                const data = {
                    callId: this.props.callDetails["callId"],
                    patient_question_sets: this.state.questionSets,
                };
                await api.shared.update_call({ data });
            } catch (error) {
                let data = generateVideoCallErrorLog(
                    this.props.callDetails.callId,
                    error,
                    "QuestionStep.nextButtonOnClick",
                );
                api.shared.report_video_call_error({ data });
            }
            this.props.startCall();
            store.dispatch(showLoader());
        } else {
            this.setState({ questionSet: this.state.questionSet + 1, errors: "" });
        }
    };

    getQuestionSet = () => {
        const questionSetId = this.state.questionSet;
        const questionSetData = this.state.questionSets[questionSetId];
        const isLastQuestionSet = Object.keys(this.state.questionSets).length - 1 === questionSetId;

        return (
            <>
                <p className={"fs-28 fw-bold mx-auto mt-auto"}>{questionSetData.header}</p>
                {questionSetData.type === "rating" &&
                    this.getRatingQuestionSet(questionSetData, questionSetId)}
                {questionSetData.type === "multi-select" &&
                    this.getMultiSelectQuestionSet(questionSetData, questionSetId)}
                {this.state.errors && <div className={"txt-error"}>{this.state.errors}</div>}
                <button
                    className="GuidedVideoCall__center-btn mt-5 mx-auto mb-auto"
                    onClick={() => this.nextButtonOnClick(isLastQuestionSet)}>
                    {isLastQuestionSet ? "Enter Session" : "Next Question"}
                </button>
            </>
        );
    };

    getMultiSelectQuestionSet(questionSetData, questionSetId) {
        return (
            <div className={"row p-lg-0 px-5 py-3"}>
                <div className={"row p-3 w-100"}>
                    {Object.entries(questionSetData.options).map((option) => {
                        const currentAnswer = questionSetData.options[option[0]].answer;
                        return (
                            <div
                                key={option[0]}
                                className="col-lg-4 col-md-4 col-6 justify-content-center my-3">
                                <Button
                                    onClick={() => {
                                        this.selectOnClick(
                                            questionSetId,
                                            option[0],
                                            !currentAnswer,
                                        );
                                    }}
                                    className={
                                        "Multi-Select-Btn Btn cursor w-100 fw-normal text-break px-2 px-lg-3 text-center " +
                                        (currentAnswer
                                            ? "Btn--sec activeOptionBtn"
                                            : "Btn--otl-gry inactiveOptionBtn")
                                    }>
                                    {option[1].label}
                                </Button>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    getRatingQuestionSet(questionSetData, questionSetId) {
        let col = "col-4";
        let width = "w-100";

        if (this.state.questionSet === 1) {
            col = "col-2";
            width = "w-75";
        }

        const optionLabelMap = arrayToIndexedObject(questionSetData.rating_labels);

        return (
            <>
                <div className={`d-lg-block d-md-block d-none ${width}`}>
                    <div className={"row mb-2"}>
                        {!questionSetData.hide_row_labels && <div className={col} />}
                        <div
                            className={
                                questionSetData.hide_row_labels
                                    ? "col-12 d-flex justify-content-between"
                                    : this.state.questionSet === 1
                                    ? "d-flex justify-content-between col-10"
                                    : "d-flex justify-content-between col-8"
                            }>
                            {Object.values(optionLabelMap).map((optionLabel) => (
                                <div key={optionLabel} className={"w-100"}>
                                    <div className={"w-50"} style={{ margin: "0 auto" }}>
                                        {optionLabel}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {Object.entries(questionSetData.questions).map((question) => {
                        return (
                            <RatingOptions
                                key={question[0]}
                                optionLabelMap={optionLabelMap}
                                questionSet={questionSetId}
                                questionId={question[0]}
                                questionData={question[1]}
                                ratingAnswerOnClick={this.ratingAnswerOnClick}
                            />
                        );
                    })}
                </div>
                <div className={"d-lg-none d-md-none d-block px-5 py-3"}>
                    {Object.entries(questionSetData.questions).map((question) => {
                        return (
                            <div className={"mb-3"} key={questionSetId + question[0]}>
                                {question[1].label && (
                                    <div className={"text-left"}>{question[1].label}</div>
                                )}
                                <Select
                                    name={question[0]}
                                    changeHandler={(e) =>
                                        this.ratingAnswerOnClick(
                                            questionSetId,
                                            question[0],
                                            e.target.value,
                                        )
                                    }
                                    value={`${questionSetData.questions[question[0]].answer}`}
                                    options={Object.entries(optionLabelMap)
                                        .reverse()
                                        .map((option) => {
                                            return { value: `${option[0]}`, display: option[1] };
                                        })}
                                />
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }

    getContent = () => {
        return (
            <div className="position-relative GuidedVideoCall__wrapper">
                <div className={"GuidedVideoCall__settings_message text-black fs-18 text-center"}>
                    <div className={"GuidedVideoCall__question_container "}>
                        {this.state.questionSet === null && this.getPreQuestionInfo()}
                        {this.state.questionSet !== null &&
                            this.state.questionSets &&
                            this.getQuestionSet()}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return <VideoViewBase content={this.getContent()} />;
    }
}

export default QuestionsStep;
