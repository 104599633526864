// Copyright aptihealth, inc. 2019 All Rights Reserved

import { httpAuth, http } from "../../../utils/axios";
import { getUsernameFromToken } from "../../../redux/actions/auth";
import { api } from "../../../APIRequests";
import { getQueryParams } from "../../../utils/filters";
import { showAlert } from "../../../redux/actions/alerts";
import store from "../../../redux/store";

export const fetchPatientList = ({ options }) => {
    return api.provider.fetch_patient_list({ options });
};
export const uploadInsImage = (imgData, attr, cb, progressCb) => {
    const { file, fileName, fileType } = imgData;
    let base64Img = "";
    let reader = new FileReader();

    reader.onload = (e) => {
        base64Img = reader.result;
    };
    reader.readAsDataURL(file);
    try {
        const fileNameSplit = file.name.split(".");
        let extension = fileNameSplit[fileNameSplit.length - 1];

        if (extension) {
            extension = extension.toLowerCase();
        }

        if (
            extension !== "jpeg" &&
            extension !== "jpg" &&
            extension !== "heif" &&
            extension !== "png" &&
            extension !== "pdf" &&
            extension !== "heic"
        ) {
            showAlert("Invalid file type", store.dispatch);
            throw new Error("Invalid file type");
        }

        let data = { fileName, fileType };

        api.provider
            .upload_insurance_image({ data })
            .then((cleanResponse) => {
                let url = cleanResponse && cleanResponse.url;
                if (url) {
                    api.auth
                        .file_upload({ data: file, url, fileType, progressCb })
                        .then((cleanResponse) => {
                            cb(attr, { url: fileName, preview: base64Img });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    } catch (err) {
        console.log(err);
    }
};

//deprecated use fileUtil
export const uploadFile = (fileData, patientId, cb) => {
    const { file, fileName, fileType } = fileData;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    try {
        let urlParams = { patientId };
        let data = { fileName, fileType };
        api.provider
            .upload_patient_file({ data, urlParams })
            .then((cleanResponse) => {
                let url = cleanResponse && cleanResponse.url;
                if (url) {
                    let options = {
                        headers: {
                            "Content-Type": fileType,
                        },
                    };
                    http.put(url, file, options)
                        .then((cleanResponse) => {
                            cb(fileName);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    } catch (err) {
        console.log(err);
    }
};

//deprecated use fileUtil
export const getFile = (patientId, key) => {
    let urlParams = { patientId };
    let data = { key: key };
    api.shared
        .fetch_patient_file({ data, urlParams })
        .then((response) => {
            let url = response.message;
            if (url) {
                window.open(url);
            }
        })
        .catch((err) => {
            let errMsg =
                (err.response && err.response.data.results.message) ||
                "Some unexpected error occured";
            console.log(errMsg);
        });
};

export const fetchAvatar = (avatarKey, username) => {
    return httpAuth.post("/v1/patient_management/" + username + "/get_file", {
        key: avatarKey,
    });
};

export const getScreeningAnswers = async (type, props, state, cb) => {
    try {
        let urlParams = {
            patientId: state.patientId,
            type: type,
            day: getQueryParams("day", props.location.search.trim()),
        };
        let response = await api.shared.fetch_patient_phq9_gad7({ urlParams });
        if (response && response.result) {
            cb(response.result);
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * Transforms question list received from api to the form
 * expected by submit api.
 *
 * @param {Array.<{id: number, question: string}>} qArray Array of question objects
 *
 * @param answers response from previously submitted screening that we wish to display
 */
export const transformScreeningQuestionsArray = (qArray, answers) => {
    let transformedQuestionsArray;
    if (!answers) {
        transformedQuestionsArray = qArray.map((q) => {
            return { question_id: q.id, answer: null, question: q.question };
        });
    } else {
        transformedQuestionsArray = qArray.map((q, i) => {
            return { question_id: q.id, answer: parseInt(answers[i].rating), question: q.question };
        });
    }
    return transformedQuestionsArray;
};
