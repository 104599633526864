// Copyright aptihealth, inc. 2019 All Rights Reserved
import { Form } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { api } from "../../../APIRequests";
import { CustomForm } from "../../../component-library/CustomForm";
import { providerRoute, provider_case_manager, provider_peer } from "../../../config/roles";
import { PROVIDER_BASIC_SCHEMA } from "../../../containers/Dashboard/Providers/schema/providerProfileBasicSchema";
import {
    CARE_MANAGER_VALIDATION_SCHEMA,
    VALIDATION_SCHEMA,
} from "../../../containers/Dashboard/Providers/schema/providerProfileBasicValidation";
import {
    getUsernameFromToken,
    isAuthorized,
    isUserAdmin,
    isUserPCP,
    updateProviderProfile,
} from "../../../redux/actions/auth";
import { setDynamicRouteConfiguration } from "../../../redux/actions/navbar";
import images from "../../../utils/images";
import ProfileLoader from "../../Common/Loaders/ProfileLoader";
import SubmitControls from "../../Patient/Profile/SubmitControls";
import Button from "../../UI/Button";
import FormikInput from "../../UI/FormikInput";
import Input from "../../UI/Input";
import FormikMobileInput from "../../UI/formikMobileInput";
import AcceptedAgeRange from "./AcceptedAgeRange";
import ProfileDetailsCard from "./ProfileDetailsCard";
import StatesToPractice from "./StatesToPractice";
import "./styles.scss";
import ProviderUniversalAccessManagementCard from "../../Admin/Provider/ProviderUniversalAccessManagementCard";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editBasic: false,
            profileData: null,
            showInsurancePopUp: false,
            updatedInsurance: [],
            providerAvatar: null,
            providerId: this.props.match.params.providerId
                ? this.props.match.params.providerId
                : null,
            acceptNewPatients: true,
            validationSchema: isUserPCP() ? CARE_MANAGER_VALIDATION_SCHEMA : VALIDATION_SCHEMA,
            isOnBehalfOf: getUsernameFromToken() !== this.props.match.params.providerId,
            stateModal: null,
            acceptedAgeRanges: [],
        };
    }

    toggleStateModal = () => {
        this.setState({ stateModal: !this.state.stateModal });
    };

    iterableBasicInfo = () => [
        { title: "Full Name", value: this.state.profileData.name || "" },
        { title: "Provider Type", value: this.state.profileData.provider_type },
        { title: "Email", value: this.state.profileData.email || "" },
        { title: "Mobile", value: this.state.profileData.mobile || "" },
        {
            title: "CP Credential",
            value: this.state.profileData.cp_credentials || "",
        },
        { title: "NPI Number", value: this.state.profileData.npi_number || "" },
        { title: "Practice", value: this.state.profileData.practice || "" },
    ];

    componentWillUnmount() {
        this.props.setDynamicRouteConfiguration();
    }

    getProviderAvatar = async () => {
        //         try {
        // api.provider.fetch_
        //         }
        //         catch (err) {
        //         }
    };

    componentDidMount() {
        const dynamicRouteConfig = {};
        dynamicRouteConfig["logoSection"] = { title: "My Profile" };
        this.props.setDynamicRouteConfiguration(dynamicRouteConfig);
        /** Fetch provider's profile  */
        getProfile(this, this.state.providerId).then((response) => {
            const options = { params: { showLoader: false } };
            api.patient
                .fetch_insurance_list({ options })
                .then((cleanResponse) => {
                    let insuranceList = cleanResponse.carriers.map((carriers) => {
                        return { value: carriers, label: carriers };
                    });
                    this.state.profileData.accepted_insurance.forEach((ai) => {
                        if (!cleanResponse.carriers.includes(ai)) {
                            insuranceList.push({ value: ai, label: ai });
                        }
                    });
                    let acceptedInsuranceList = this.state.profileData.accepted_insurance.map(
                        (ai) => insuranceList.find((ins) => ins.value === ai),
                    );
                    this.setState({
                        insuranceList: insuranceList,
                        acceptedInsuranceList: acceptedInsuranceList,
                        tempAcceptedInsuranceList: acceptedInsuranceList,
                    });
                })
                .catch((err) => {
                    // TODO: how do we emit metrics and alarms on client side errors?
                    console.log(err);
                });
            /** Fetch provider's avatar  */
            this.getProviderAvatar();
        });
    }

    formatMobileNumber = (val) => {
        let firstNumber = val.substring(0, 3);
        let middleNumber = val.substring(3, 6);
        let lastNumber = val.substring(6, 10);
        let formattedMobileNumber = `(${firstNumber}) ${middleNumber}-${lastNumber}`;
        return formattedMobileNumber;
    };

    /**
     * Below function returns the rendered details which can be reused.
     * @param {string} title - title of the value
     * @param {string} value - value to go below title
     */
    renderTitleWithValue = function (title, value) {
        return (
            <div className="mb-4">
                <div className="fs-14 fw-bold mb-2">
                    <span>{title}</span>
                </div>
                <div className="fs-14">
                    <span>{title == "Mobile" ? this.formatMobileNumber(value) : value}</span>
                </div>
            </div>
        );
    };

    shortDetails = () => (
        <div className="ProviderProfile__short-details">
            <div className="py-4 py-lg-0 d-flex flex-row flex-lg-column align-items-center justify-content-lg-center">
                <div className="ProviderProfile__avatar-wpr position-relative mx-3 mx-lg-0 mt-lg-5 mb-lg-4">
                    <img
                        className="mx-auto d-block"
                        src={this.props.avatar || images("./common/avatar.png")}
                        alt="provider avatar"
                    />
                </div>
                <div className="text-lg-center text-white ">
                    <div className="fw-bold fs-16 fs-lg-18 mb-lg-2">
                        {this.state.profileData.name}
                    </div>
                    <div className="fs-14">{this.state.profileData.provider_type}</div>
                </div>
            </div>
        </div>
    );

    toggleEditBasicInfo = () => {
        this.setState((ps) => {
            return {
                editBasic: !ps.editBasic,
            };
        });
    };

    handleOnBehalfOfRequest = async (data) => {
        if (this.state.isOnBehalfOf) {
            api.provider.update_profile({
                data,
                queryParams: { providerId: this.state.providerId },
            });
        } else {
            this.props.updateProviderProfile(data, this.state.providerId);
        }
    };

    renderFormElement = (formEl, formikProps) => {
        if (
            formEl.name == "provider_type" ||
            formEl.name === "email" ||
            formEl.name === "practice"
        ) {
            return (
                <FormikInput
                    disabled
                    inputBorderDisabled="border-0 px-0"
                    formEl={formEl}
                    errors={formikProps.errors}
                    touched={formikProps.touched}
                />
            );
        } else if (formEl.name == "mobile") {
            return (
                <FormikMobileInput
                    value={formikProps && formikProps.values.mobile}
                    onChange={async (val) => {
                        await formikProps.setFieldValue("mobile", val.value);
                        formikProps.setFieldTouched("mobile");
                    }}
                    formEl={formEl}
                    errors={formikProps.errors}
                    touched={formikProps.touched}
                />
            );
        } else if (isUserAdmin()) {
            return (
                <FormikInput
                    formEl={formEl}
                    errors={formikProps.errors}
                    touched={formikProps.touched}
                />
            );
        }

        return (
            <FormikInput
                disabled
                inputBorderDisabled="border-0 px-0"
                formEl={formEl}
                errors={formikProps.errors}
                touched={formikProps.touched}
            />
        );
    };

    renderForm = (formikProps) => {
        return (
            <Form>
                <div className="mx-auto px-0 ">
                    <div className="container px-0">
                        <div className="row">
                            {PROVIDER_BASIC_SCHEMA.map((formEl) => {
                                return (
                                    <div key={formEl.name} className={"col-12 col-lg-6"}>
                                        {this.renderFormElement(formEl, formikProps)}
                                    </div>
                                );
                            })}
                        </div>
                        {!isUserAdmin() && (
                            <div>
                                <div>
                                    <p className="ProviderProfile__toUpdateMessage">
                                        To update read-only fields, contact{" "}
                                        <a href="mailto:support@aptihealth.com">
                                            support@aptihealth.com
                                        </a>
                                        .
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <SubmitControls cancelHandler={this.toggleEditBasicInfo} />
            </Form>
        );
    };

    submitHandler = (formData, actions) => {
        actions.setSubmitting(false);
        let data = { ...formData };

        const userData = {
            mobile: data["mobile"],
            ...(isUserAdmin() && {
                name: data["name"],
                cp_credentials: data["cp_credentials"],
                npi_number: data["npi_number"],
            }),
        };

        this.handleOnBehalfOfRequest(userData);

        this.setState({ profileData: data });
        this.toggleEditBasicInfo();
    };

    basicInfo = () => (
        <ProfileDetailsCard
            onclick={this.toggleEditBasicInfo}
            edit={this.state.editBasic}
            heading="Basic Information"
            isEditable>
            <div className="ProviderProfile__basic-info row no-gutters my-2 pt-4 px-2">
                {!this.state.editBasic &&
                    this.iterableBasicInfo().map((item) => {
                        return (
                            <div className="col-12 col-lg-6">
                                {this.renderTitleWithValue(item.title, item.value)}
                            </div>
                        );
                    })}
                {this.state.editBasic && (
                    <CustomForm
                        initialValues={this.state.profileData}
                        validationSchema={this.state.validationSchema}
                        onSubmit={this.submitHandler}
                        render={this.renderForm}
                    />
                )}
            </div>
        </ProfileDetailsCard>
    );

    acceptNewPatientsChangeHandler = () => {
        let data = { ...this.state.profileData };
        data["accept_new_patients"] = !this.state.acceptNewPatients;

        this.handleOnBehalfOfRequest({ accept_new_patients: data["accept_new_patients"] });
        this.setState({
            profileData: data,
            acceptNewPatients: !this.state.acceptNewPatients,
        });
    };

    acceptNewPatients = () => (
        <div className="ProviderProfile__ProfileDetailsCard p-4 mb-3 mb-lg-4" data-public={"true"}>
            <div className="d-flex justify-content-between">
                <h1 className="fs-16 fw-bold ml-2 mb-3 pb-1">
                    Check the box to accept new patients
                </h1>
                {/* to remove icon add props -> checkedIcon={false} uncheckedIcon={false} */}

                <Input
                    checked={this.state.acceptNewPatients}
                    disabled={isAuthorized(provider_case_manager) || isAuthorized(provider_peer)}
                    type="checkbox"
                    name="accept_new_patients"
                    className="AddPatientInput mr-2 form-check-input"
                    onChange={this.acceptNewPatientsChangeHandler}
                />
            </div>
        </div>
    );

    showInsPopUp = () => {
        this.setState((ps) => {
            return {
                showInsurancePopUp: !ps.showInsurancePopUp,
            };
        });
    };

    confirmInsuranceUpdate = () => {
        let data = { ...this.state.profileData };
        data.accepted_insurance = [...this.state.tempAcceptedInsuranceList].map((i) => i.value);
        this.handleOnBehalfOfRequest({ accepted_insurance: data.accepted_insurance });
        this.showInsPopUp();
        this.setState({
            profileData: data,
            acceptedInsuranceList: [...this.state.tempAcceptedInsuranceList],
        });
    };

    handleInputChange = (selection, action) => {
        if (action) {
            let updatedInsurance;
            switch (action.action) {
                case "select-option": {
                    updatedInsurance = [...this.state.tempAcceptedInsuranceList];
                    updatedInsurance.push(action.option);
                    break;
                }
                case "remove-value": {
                    updatedInsurance = [...this.state.tempAcceptedInsuranceList].filter(
                        (val) => val.value !== action.removedValue.value,
                    );
                    break;
                }
                case "clear": {
                    updatedInsurance = [];
                    break;
                }
                default:
            }
            this.setState({
                tempAcceptedInsuranceList: updatedInsurance,
            });
        }
    };

    commitAndSubmitStateLicensureDataToServer = (updatedStatesToPractice) => {
        const newProfileData = { ...this.state.profileData };
        // Remove View level concerns from data level concerns
        newProfileData.states_to_practice = updatedStatesToPractice.map(
            ({ state, licenseNumber, expirationDate }) => ({
                state,
                licenseNumber,
                expirationDate,
            }),
        );
        this.setState({ profileData: newProfileData });

        this.handleOnBehalfOfRequest({ states_to_practice: newProfileData.states_to_practice });

        this.toggleStateModal();
    };

    shouldAcceptedInsuranceBeDisabled = () => {
        return ["CASE_MANAGER", "PEER"].includes(this.state.profileData.provider_type);
    };

    insuranceInfo = () => (
        <>
            {isUserAdmin() ? (
                <ProfileDetailsCard
                    heading="Accepted Insurance"
                    onclick={this.showInsPopUp}
                    edit={this.shouldAcceptedInsuranceBeDisabled()}>
                    <div
                        className="ProviderProfile__insurance-info my-2 pt-4 px-2"
                        data-public={"true"}>
                        <div>
                            <ul className="ProviderProfile__insurance-info-list fs-14">
                                {this.state.profileData &&
                                    this.state.profileData.accepted_insurance.sort().map((ins) => {
                                        return <li key={ins}>{ins}</li>;
                                    })}
                            </ul>
                        </div>
                    </div>
                </ProfileDetailsCard>
            ) : (
                <ProfileDetailsCard heading="Accepted Insurance" onclick={this.showInsPopUp} edit>
                    <div
                        className="ProviderProfile__insurance-info my-2 pt-4 px-2"
                        data-public={"true"}>
                        <div>
                            <ul className="ProviderProfile__insurance-info-list fs-14">
                                {this.state.profileData &&
                                    this.state.profileData.accepted_insurance.sort().map((ins) => {
                                        return <li key={ins}>{ins}</li>;
                                    })}
                            </ul>
                        </div>
                    </div>
                    <p className="ProviderProfile__toUpdateMessage">
                        To update accepted insurance, contact{" "}
                        <a href="mailto:support@aptihealth.com">support@aptihealth.com</a>.
                    </p>
                </ProfileDetailsCard>
            )}
        </>
    );

    renderEachWorkingHourDetail = function (weekDay) {
        let localeTime =
            this.state.profileData.availability[weekDay] &&
            this.state.profileData.availability[weekDay].slots.map((MilitaryhrsArr) => {
                return MilitaryhrsArr.map((Militaryhrs) => {
                    return moment(Militaryhrs, "HH:mm").format("hh:mm a");
                });
            });
        return (
            <div className="mb-4">
                <div className="fs-14 fw-bold mb-2">
                    <span>{weekDay}</span>
                </div>
                <div className="fs-14 d-flex">
                    {this.state.profileData.availability[weekDay] &&
                        localeTime.map((hrs) => {
                            return <span className="w-50">{hrs.join(" to ")}</span>;
                        })}
                    {this.state.profileData.availability[weekDay] != undefined
                        ? this.state.profileData.availability[weekDay].slots.length < 1 &&
                          "No Slots Defined"
                        : "No Slots Defined"}
                </div>
            </div>
        );
    };
    editWorkingHours = () => {
        let url = providerRoute + "/profile/hours";
        url = this.state.providerId ? `${url}/${this.state.providerId}` : url;
        this.props.history.push(url);
    };

    workingHours = () => (
        <ProfileDetailsCard
            heading="Working Hours"
            onclick={this.editWorkingHours}
            // This is confusing but the edit prop being true means that the edit option is hidden 😵‍💫
            edit={isAuthorized(provider_case_manager) || isAuthorized(provider_peer)}>
            <div className="ProviderProfile__working-hours my-2 pt-4 px-2" data-public={"true"}>
                {this.state.profileData.availability && (
                    <div>
                        {this.renderEachWorkingHourDetail("Monday")}
                        {this.renderEachWorkingHourDetail("Tuesday")}
                        {this.renderEachWorkingHourDetail("Wednesday")}
                        {this.renderEachWorkingHourDetail("Thursday")}
                        {this.renderEachWorkingHourDetail("Friday")}
                        {this.renderEachWorkingHourDetail("Saturday")}
                        {this.renderEachWorkingHourDetail("Sunday")}
                    </div>
                )}
                {!this.state.profileData.availability && "No Working Hours Defined"}
            </div>
        </ProfileDetailsCard>
    );

    safeGetStatesOfPractice = () => {
        if (_.isNil(this.state.profileData)) {
            return [];
        } else if (
            _.isNil(this.state.profileData.states_to_practice) ||
            _.isEmpty(this.state.profileData.states_to_practice)
        ) {
            return [];
        }

        return this.state.profileData.states_to_practice;
    };

    setAcceptedAgeRangesV2 = (ageRanges, apiVersion) => {
        const data = { accepted_age_range: ageRanges };
        api.provider.update_profile({
            data,
            queryParams: { providerId: this.state.providerId },
        });
        const profileData = this.state.profileData;
        profileData["accepted_age_range"] = ageRanges;
        this.setState({
            acceptedAgeRanges: ageRanges,
            profileData: profileData,
        });
    };

    render() {
        const updateStatesToPracticeProps = {
            toggleStateModal: this.toggleStateModal,
            showUpdateStateModal: this.state.stateModal,
            states_to_practice: this.safeGetStatesOfPractice(),
            dateContext: this.state.dateContext,
            commitAndSubmitStateLicensureDataToServer:
                this.commitAndSubmitStateLicensureDataToServer,
            isEditable: true,
        };
        return (
            <div>
                {this.state.showInsurancePopUp && (
                    <div
                        className="insurancePopUp position-fixed d-flex align-items-center justify-content-center"
                        data-public={"true"}>
                        <div className="insurancePopUp__inner d-flex flex-column text-center position-absolute">
                            <h2 className="fs-17 fw-bold mt-4">Accepted Insurance</h2>
                            <span className="fs-15 mt-2">Accepted Insurance Types</span>
                            <div style={{ width: "80%", margin: "0 auto" }}>
                                {this.state.insuranceList && (
                                    <Select
                                        closeMenuOnSelect={true}
                                        isMulti
                                        options={this.state.insuranceList}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        defaultValue={this.state.acceptedInsuranceList}
                                        onChange={this.handleInputChange}
                                    />
                                )}
                            </div>
                            <div className="insurancePopUp__btn-wpr  d-flex justify-content-center pt-3 my-4">
                                <Button
                                    onClick={this.showInsPopUp}
                                    type="button"
                                    className="Btn Btn--otl-pri Btn-sm mx-2">
                                    Cancel
                                </Button>
                                <Button
                                    type="button"
                                    className="Btn Btn--pri Btn-sm mx-2"
                                    onClick={this.confirmInsuranceUpdate}>
                                    Update
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                <div className="container px-3">
                    <h1 className="d-none d-lg-block fs-22 fw-bold my-4 py-2">My Profile</h1>
                    {this.state.profileData ? (
                        <div className="ProviderProfile" data-public={"true"}>
                            <div className="row no-gutters">
                                <div className="col-12 col-lg-3 mt-3 mt-lg-0 mb-3 mb-lg-0">
                                    {this.shortDetails()}
                                </div>
                                <div className="col-12 col-lg-9 px-lg-4">
                                    {(this.state.profileData.provider_type.startsWith(
                                        "BEHAVIORAL",
                                    ) ||
                                        this.state.profileData.provider_type === "PRESCRIBE" ||
                                        isAuthorized(provider_case_manager) ||
                                        isAuthorized(provider_peer)) &&
                                        this.acceptNewPatients()}
                                    <ProviderUniversalAccessManagementCard
                                        providerDetails={this.state.profileData}
                                    />
                                    {this.basicInfo()}
                                    <StatesToPractice {...updateStatesToPracticeProps} />
                                    {(this.state.profileData.provider_type.startsWith(
                                        "BEHAVIORAL",
                                    ) ||
                                        this.state.profileData.provider_type === "PRESCRIBE" ||
                                        isAuthorized(provider_case_manager) ||
                                        isAuthorized(provider_peer)) && (
                                        <AcceptedAgeRange
                                            acceptedAgeRanges={this.state.acceptedAgeRanges}
                                            setAcceptedAgeRanges={this.setAcceptedAgeRangesV2}
                                        />
                                    )}
                                    {this.insuranceInfo()}
                                    {this.workingHours()}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <ProfileLoader />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        flags: state?.flags,
    };
};

export default connect(mapStateToProps, {
    setDynamicRouteConfiguration,
    updateProviderProfile,
})(Profile);

export const getProfile = async (that, providerId) => {
    try {
        const options = { params: { showLoader: false } };
        const queryParams = { providerId: providerId };
        let response = await api.provider.fetch_provider_profile({
            options,
            queryParams,
        });
        let profileData = response && response.user;
        if (!profileData.hasOwnProperty("availability")) {
            profileData["availability"] = {};
        }
        that.setState({
            profileData: profileData,
            acceptNewPatients: profileData.accept_new_patients,
            acceptedAgeRanges: _.cloneDeep(_.get(profileData, "accepted_age_range", [])),
        });
    } catch (err) {
        console.log(err);
    }
};
