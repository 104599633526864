import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import { addToast } from "../../../../redux/actions/toaster";
import { fetchPaymentProcessorSessionToken } from "../../../../redux/actions/paymentProcessor";
import {
    fetchPatientCardInfo,
    putPatientCardInfo,
} from "../../../../redux/actions/patientCardInfo";
import { displayElavonCreditCardModal } from "../../../../utils/PaymentProvider/elavon";
import Captcha from "../../../../components/Common/Captcha/Captcha";

import { Text, TextColors, TextTypes } from "../../../../component-library";

import CardSection from "./CardSection";

import "./styles.scss";
import {shouldShowVulnerablePages} from "../../../../utils/authUtils";

const PAYMENT_EXPIRED_MESSAGE = "Your card has expired. Please update it.";

const PaymentsPage = ({
    cardInfo,
    fetchPatientCardInfo,

    profile,

    dispatch,
    history,
}) => {
    const [errorMessage, setErrorMessage] = useState();
    const [captchaVerificationToken, setCaptchaVerificationToken] = useState(false);

    const {
        lastFourDigits, //XXXX
        expirationDate, //MMYY
    } = cardInfo;

    const expirationDateMoment = expirationDate ? moment(expirationDate) : undefined;
    const isExpired = expirationDateMoment ? moment().diff(expirationDateMoment) > 0 : false;

    useEffect(() => {
        if (!shouldShowVulnerablePages(profile.status)) {
            history.push("/app/home");
        }

        if (
            (profile && !cardInfo.username) ||
            (profile && cardInfo.username !== profile.username)
        ) {
            fetchPatientCardInfo();
        }
    }, [cardInfo.username, profile?.username]);

    const onAddCardButtonClick = () => {
        displayElavonCreditCardModal(
            dispatch,
            profile.username,
            profile.first_name,
            profile.last_name,
            setErrorMessage,
            "MEMBER_PROFILE",
            null,
            null,
            null,
            null,
            true,
            captchaVerificationToken
        );
        setCaptchaVerificationToken(null);
    };

    const captchaComponent = <Captcha setCaptchaVerificationToken={setCaptchaVerificationToken} />;

    return (
        <>
            <Text className="PaymentsPage__title" type={TextTypes.heading1} color={TextColors.grey}>
                Payment
            </Text>
            <CardSection
                profile={profile}
                lastFourDigits={lastFourDigits}
                expirationDate={expirationDateMoment}
                isExpired={isExpired}
                errorMessage={
                    errorMessage ? errorMessage : isExpired ? PAYMENT_EXPIRED_MESSAGE : undefined
                }
                onAddCardButtonClick={onAddCardButtonClick}
                onReplaceCardButtonClick={onAddCardButtonClick}
                captchaComponent={captchaComponent}
                captchaCompleted={captchaVerificationToken}
                resetCaptcha={() => setCaptchaVerificationToken(null)}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    cardInfo: state.patientCardInfo,
    profile: state.auth.profile
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    ...bindActionCreators(
        {
            addToast,
            fetchPatientCardInfo,
            putPatientCardInfo,
            fetchPaymentProcessorSessionToken,
        },
        dispatch,
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsPage);
