/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const HomeIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Home"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 6.73312L26 14.5109V28H18V20C18 19.2713 17.7367 18.7983 17.3982 18.4974C17.0412 18.1801 16.537 18 16 18C15.4631 18 14.9589 18.1801 14.6019 18.4974C14.2633 18.7983 14 19.2713 14 20V28H6.00004V14.5109L16 6.73312ZM8.00004 15.4891V26H12V20C12 18.7286 12.4867 17.7016 13.2732 17.0026C14.0412 16.3199 15.037 16 16 16C16.9631 16 17.9589 16.3199 18.7269 17.0026C19.5133 17.7016 20 18.7286 20 20V26H24V15.4891L16 9.26685L8.00004 15.4891Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 2.22455L28.6207 12.2159C29.0538 12.5587 29.1269 13.1877 28.7841 13.6207C28.4413 14.0537 27.8124 14.1268 27.3793 13.784L16 4.77542L4.62074 13.784C4.18773 14.1268 3.5588 14.0537 3.21599 13.6207C2.87319 13.1877 2.94632 12.5587 3.37934 12.2159L16 2.22455Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Home"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 6.73312L26 14.5109V28H18V20C18 19.2713 17.7367 18.7983 17.3982 18.4974C17.0412 18.1801 16.537 18 16 18C15.4631 18 14.9589 18.1801 14.6019 18.4974C14.2633 18.7983 14 19.2713 14 20V28H6.00004V14.5109L16 6.73312Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 2.22455L28.6207 12.2159C29.0538 12.5587 29.1269 13.1877 28.7841 13.6207C28.4413 14.0537 27.8124 14.1268 27.3793 13.784L16 4.77542L4.62074 13.784C4.18773 14.1268 3.5588 14.0537 3.21599 13.6207C2.87319 13.1877 2.94632 12.5587 3.37934 12.2159L16 2.22455Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Home"
                {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM29.4656 17.7435L20 10.25L10.5345 17.7435C10.2097 18.0006 10.1549 18.4723 10.412 18.7971C10.6691 19.1219 11.1408 19.1767 11.4656 18.9196L20 12.1632L28.5345 18.9196C28.8593 19.1767 29.331 19.1219 29.5881 18.7971C29.8452 18.4723 29.7903 18.0006 29.4656 17.7435ZM20 14.1065L27.125 19.6482V29.2066H21.875V23.5816C21.875 22.9333 21.6357 22.4747 21.2978 22.1744C20.953 21.8679 20.4826 21.7066 20 21.7066C19.5174 21.7066 19.0471 21.8679 18.7023 22.1744C18.3644 22.4747 18.125 22.9333 18.125 23.5816V29.2066H12.875V19.6482L20 14.1065Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

HomeIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

HomeIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
