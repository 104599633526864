import RemoteVideo from "../RemoteVideo";
import LocalVideo from "../LocalVideo";
import { PatientInfoWidget } from "./PatientInfoWidget";
import CardSecondary from "../../../../UI/Card/CardSecondary";
import React from "react";
import { PatientCarePlanWidget } from "./PatientCarePlanWidget";
import Badge from "../../../../UI/Badge";
import images from "../../../../../utils/images";
import { arrayToIndexedObject } from "../../../../../utils/arrayUtil";
import {
    RATING_OPTIONS_LABEL_MAP,
    SCREENING_OPTIONS_LABEL_MAP,
} from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotes";
import { BehavioralGoalsRating } from "../../../../Provider/PatientView/MemberProfile/MemberProfileNotesBehavioralGoals";

export const DisplayWorkflowStepWithVideoClient = (componentProps) => {
    const { step, props, state, jumpWorkflowStep, endCall } = componentProps;
    const { patientDetails, currentStep, progressNote, patientDiagnosis } = state;
    let [fullScreenMode, setFullScreenMode] = React.useState(
        state.fullScreen || step.fullScreen || window.innerWidth < 1200,
    );

    React.useEffect(() => {
        function handleResize() {
            setFullScreenMode(state.fullScreen || step.fullScreen || window.innerWidth < 1200);
        }

        window.addEventListener("resize", handleResize);

        return (_) => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    let videoGroup = (
        <>
            <RemoteVideo
                {...props}
                endCall={endCall}
                fullscreenMode={fullScreenMode}
                note={progressNote}
            />
            <LocalVideo {...props} />
        </>
    );
    let content;

    if (fullScreenMode) {
        content = (
            <>
                <div className="GuidedVideoCall__whole_screen_view">{videoGroup}</div>
            </>
        );
    } else {
        content = (
            <div className="GuidedVideoCall__guided_step row">
                <div className="col-12 col-md-12 col-lg-7">
                    <div className="d-none d-md-none d-lg-block">
                        <PatientInfoWidget
                            patientDetails={patientDetails}
                            patientDiagnosis={patientDiagnosis}
                            isLarger={false}
                        />
                    </div>
                    <div className={"GuidedVideoCall__guided_step_view"}>{videoGroup}</div>
                    <div className="d-none d-md-none d-lg-block">
                        <PatientCarePlanWidget
                            patientDetails={patientDetails}
                            carePlanTips={step.carePlanTips}
                        />
                    </div>
                </div>
                <div className="GuidedVideoCall__guided_widget px-0 d-none d-md-none d-lg-block col-lg-5">
                    <CardSecondary className="h-100 Background">
                        <div className="GuidedVideoCall__guided_widget_header">
                            {step["caption"]}
                        </div>
                        <div className="GuidedVideoCall__guided_widget_content">
                            {step.subComponent && state.refresh && (
                                <step.subComponent {...componentProps} />
                            )}
                        </div>
                        {!step.customTransition && (
                            <div className="GuidedVideoCall__guided_widget_footer d-flex justify-content-around w-100">
                                <div
                                    className="my-1 mx-3 p-1 d-flex justify-content-around align-items-center"
                                    style={{
                                        backgroundColor: "#6B7A81",
                                        width: "35%",
                                        borderRadius: 5,
                                        textAlign: "right",
                                    }}
                                    onClick={() => jumpWorkflowStep(currentStep - 1)}>
                                    <div>
                                        <img src={images("./common/back.svg")} />
                                    </div>
                                    <div>
                                        <div>Previous Step</div>
                                        <div className="fs-10">
                                            {state.workflow.steps[currentStep - 1].short}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="my-1 mx-3 p-1 d-flex justify-content-around align-items-center"
                                    style={{
                                        backgroundColor: "#0CD0A7",
                                        width: "35%",
                                        borderRadius: 5,
                                        textAlign: "left",
                                    }}
                                    onClick={() => jumpWorkflowStep(currentStep + 1)}>
                                    <div>
                                        <div>Next Step</div>
                                        <div className="fs-10">
                                            {state.workflow.steps[currentStep + 1].short}
                                        </div>
                                    </div>
                                    <div>
                                        <img
                                            src={images("./common/back.svg")}
                                            style={{
                                                height: 15,
                                                width: 15,
                                                transform: "rotate(180deg)",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </CardSecondary>
                </div>
            </div>
        );
    }

    return <>{content}</>;
};

export const ExpandingTextArea = (props) => {
    let [expand, setExpand] = React.useState(false);

    return (
        <CardSecondary className="p-3 m-3" style={{ height: expand ? "95%" : "25%" }}>
            <p className="fw-bold">{props.label}</p>
            <textarea
                className="w-100"
                style={{ height: !expand ? "60%" : "90%", maxHeight: "90%", resize: "none" }}
                value={props.value}
                onChange={props.onChange}
            />
            <div>
                <Badge
                    type="light-grey"
                    onClick={() => setExpand(!expand)}
                    text={!expand ? "Expand" : "Collapse"}
                    classes="m-auto"
                />
            </div>
        </CardSecondary>
    );
};

export const ExpandingSelect = (props) => {
    let [selecting, setSelecting] = React.useState(false);

    let controls = (
        <>
            <div className={`float-left ${props.labelClass}`}>{props.label}</div>
            <div className="float-right" onClick={() => setSelecting(!selecting)}>
                <img
                    src={images("./common/chevron.svg")}
                    style={{
                        transform: selecting ? "rotate(180deg)" : "rotate(0)",
                        width: 15,
                        height: 15,
                    }}
                />
            </div>
        </>
    );

    let answer = Object.entries(props.options)
        .filter(([optionKey, optionValue]) => optionValue.answer)
        .map(([optionKey, optionValue]) => optionValue.label)
        .join(", ");

    answer = answer ? answer : "None";

    let displayContent = (
        <CardSecondary className={"px-2 py-3 " + props.className} style={props.style}>
            <div>{controls}</div>
            <br />
            <div>{answer}</div>
        </CardSecondary>
    );

    let selectingContent = (
        <CardSecondary
            className={"px-2 py-3 position-sticky " + props.className}
            style={props.style}>
            <div>{controls}</div>
            <br />
            {Object.entries(props.options)
                .sort((a, b) => a[1].order - b[1].order)
                .map(([optionKey, optionValue]) => {
                    return (
                        <div key={`${optionValue.label}${optionValue.order}`}>
                            <label className="position-absolute">
                                <input
                                    type="checkbox"
                                    className="custom-checkbox mr-2"
                                    value={{ optionKey, optionValue }}
                                    disabled={props.disabled ? "disabled" : null}
                                    checked={optionValue.answer ? optionValue.answer : null}
                                    onChange={(e) => props.onChange(e, optionKey, "answer")}
                                />
                                {optionValue.label}
                            </label>
                            <br />
                        </div>
                    );
                })}
        </CardSecondary>
    );

    return selecting ? selectingContent : displayContent;
};

export const IndividualSymptomTrend = (props) => {
    const { answer } = props;
    let labelOptions;

    if (answer.rating_labels) {
        labelOptions = arrayToIndexedObject(answer.rating_labels);
    } else {
        labelOptions =
            answer.type == null || answer.type === "rating"
                ? RATING_OPTIONS_LABEL_MAP
                : SCREENING_OPTIONS_LABEL_MAP;
    }

    return (
        <CardSecondary className="m-3 p-3 grey-container">
            <p className="fw-bold fs-12">{answer.label}</p>
            <div className="d-flex justify-content-start">
                {Object.values(labelOptions).map((optionLabel) => (
                    <div className="w-25 fs-10 fw-bold text-center">
                        <div className="m-auto">{optionLabel}</div>
                    </div>
                ))}
            </div>
            <div className="d-flex justify-content-start">
                <BehavioralGoalsRating
                    optionLabelMap={labelOptions}
                    progress={answer}
                    isEditing={false}
                    containerClass="p-0"
                />
            </div>
        </CardSecondary>
    );
};
