import { addToast, toastMessageTypes } from "../../redux/actions/toaster";
import { fetchPaymentProcessorSessionToken } from "../../redux/actions/paymentProcessor";
import { putPatientCardInfo } from "../../redux/actions/patientCardInfo";
import { reportCreditCardError } from "../reportCreditCardError";
import LogRocket from "logrocket";

const WHITELISTED_ELAVON_LOGGING_FIELDS = [
    "ssl_add_token_response",
    "ssl_amount",
    "ssl_approval_code",
    "ssl_avs_response",
    "ssl_card_short_description",
    "ssl_card_type",
    "ssl_issuer_response",
    "ssl_merchant_initiated_unscheduled",
    "ssl_result",
    "ssl_result_message",
    "ssl_token_response",
    "ssl_transaction_reference_number",
    "ssl_transaction_type",
    "errorName",
    "errorMessage",
    "errorCode",
];
export const LOG_ROCKET_PAYMENT_EVENT_NAME = "PAYMENT_EVENT";

const PayWithConverge = window.PayWithConverge;

const PAYMENT_SUCCESS_MESSAGE = "New card added.";
const PAYMENT_ERROR_MESSAGE = "Your card couldn’t be added. Please try again.";

const buildCancelledLoggingDetails = (response) => {
    return {
        extraDetails: {},
        message: `${LOG_ROCKET_PAYMENT_EVENT_NAME}: Cancelled Transaction`,
    };
};

export const buildLoggingDetails = (response, isCancelled = false) => {
    if (isCancelled) {
        return buildCancelledLoggingDetails(response);
    }
    const extraDetails = {};

    if (typeof response === "string" || !response) {
        return { extraDetails, message: `${LOG_ROCKET_PAYMENT_EVENT_NAME}: ${response}` };
    }

    const result = response?.errorName ? response?.errorName : response?.ssl_result_message;
    Object.keys(response).forEach((key) => {
        if (WHITELISTED_ELAVON_LOGGING_FIELDS.includes(key)) {
            extraDetails[key] = response?.[key];
        }
    });
    return { extraDetails, message: `${LOG_ROCKET_PAYMENT_EVENT_NAME}: ${result}` };
};

export const displayElavonCreditCardModal = async (
    dispatch,
    username,
    first_name,
    last_name,
    setErrorMessage,
    workflow,

    onError,
    onCancelled,
    onDeclined,
    onApproval,
    useDefaultMessages = true,
    captchaVerificationToken,

) => {
    const sessionToken = await fetchPaymentProcessorSessionToken({
        dispatch,
        firstName: first_name,
        lastName: last_name,
        captchaVerificationToken: captchaVerificationToken,
    });

    const paymentFields = {
        ssl_txn_auth_token: sessionToken,
    };

    const cardAddedCallback = () => {
        if (useDefaultMessages) {
            setErrorMessage(undefined);
            dispatch(
                addToast({
                    message: PAYMENT_SUCCESS_MESSAGE,
                    messageType: toastMessageTypes.success,
                }),
            );
        }
    };

    const onErrorDefault = onError
        ? onError
        : (response) => {
              if (useDefaultMessages) {
                  setErrorMessage(PAYMENT_ERROR_MESSAGE);
              }
              reportCreditCardError("error", response);
          };

    const onCancelledDefault = onCancelled
        ? onCancelled
        : (response) => {
              if (useDefaultMessages) {
                  setErrorMessage(PAYMENT_ERROR_MESSAGE);
              }
          };

    const onDeclinedDefault = onDeclined
        ? onDeclined
        : (response) => {
              if (useDefaultMessages) {
                  setErrorMessage(PAYMENT_ERROR_MESSAGE);
              }
          };

    const onApprovalDefault = onApproval
        ? onApproval
        : (response) => {
              dispatch(
                  putPatientCardInfo({
                      username: username,
                      workflow: workflow,
                      onSuccessCallback: cardAddedCallback,
                      ...parsePaymentResponse(response),
                  }),
              );
          };

    const callback = {
        onError: (response) => {
            const { extraDetails, message } = buildLoggingDetails(response);
            console.log(message, {
                paymentApi: "Elavon",
                ...extraDetails,
            });
            LogRocket.captureMessage(message, {
                tags: {
                    paymentApi: "Elavon",
                    ...extraDetails,
                },
            });
            onErrorDefault(response);
        },

        onCancelled: (response) => {
            const { extraDetails, message } = buildLoggingDetails(response, true);
            console.log(message, {
                paymentApi: "Elavon",
                ...extraDetails,
            });
            onCancelledDefault(response);
        },

        onDeclined: (response) => {
            const { extraDetails, message } = buildLoggingDetails(response);
            console.log(message, {
                paymentApi: "Elavon",
                ...extraDetails,
            });
            onDeclinedDefault(response);
        },
        onApproval: (response) => {
            const { extraDetails, message } = buildLoggingDetails(response);
            console.log(message, {
                paymentApi: "Elavon",
                ...extraDetails,
            });
            onApprovalDefault(response);
        },
    };
    PayWithConverge.open(paymentFields, callback);
};

export const parsePaymentResponse = (response) => ({
    lastFourDigits: response.ssl_card_number.slice(-4),
    expirationDate: response.ssl_exp_date,
    cardToken: response.ssl_token,
});
