// Copyright aptihealth, inc. 2019 All Rights Reserved

/*
 * ==========================
 *   AUTHENTICATION
 * ==========================
 */
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const PW_RESET_REQUIRED = "PW_RESET_REQUIRED";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const LOG_OUT = "LOG_OUT";
export const REFRESH_ACCESS_TOKEN = "REFRESH_ACCESS_TOKEN";
export const RS_PW_CODE_SENT = "RS_PW_CODE_SENT"; // reset password verification code via email
export const RS_PW_SUCCESS = "RS_PW_SUCCESS";
export const RS_FORGOT_PW_STEPS = "RS_FORGOT_PW_STEPS";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const AUTH_CHALLENGE = "AUTH_CHALLENGE";
export const CLEAR_AUTH_CHALLENGE = "CLEAR_AUTH_CHALLENGE";
export const HIDE_RS_PW_SUCCESS = "HIDE_RS_PW_SUCCESS";

export const PROFILE_FETCH_SUCCESS = "PROFILE_FETCH_SUCCESS";
export const PROFILE_FETCH_FAIL = "PROFILE_FETCH_FAIL";

export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const REFERRAL_START = "REFERRAL_START";
export const REFERRAL_SUCCESS = "REFERRAL_SUCCESS";
export const REFERRAL_FAIL = "REFERRAL_FAIL";

export const LOAD_TOKEN = "LOAD_TOKEN";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const SET_SESSION_TIMER = "SET_SESSION_TIMER";
export const SHOW_EMAIL_VERIFIED = "SHOW_EMAIL_VERIFIED";
export const UPDATE_JWT = "UPDATE_JWT";

/*
 * ============================
 *   MOBILE TOP NAV
 *
 *   These actions are to set
 *   controls/ heading on top
 *   nav of mobile view
 * ============================
 */

export const SET_DYNAMIC_ROUTE_CONFIGURATION = "SET_DYNAMIC_ROUTE_CONFIGURATION";
export const SET_OMNISEARCH_DISPLAY_STATUS = "SET_OMNISEARCH_DISPLAY_STATUS";


/*
 * ==========================
 *   LOADER
 * ==========================
 */
export const SHOW_SPINNER = "SHOW_SPINNER";
export const HIDE_SPINNER = "HIDE_SPINNER";

/*
 * ==========================
 *   COMMON
 * ==========================
 */
export const SET_FRONT_PRI_INS_IMAGE = "SET_FRONT_PRI_INS_IMAGE"; //setting front primary insurance card image
export const SET_BACK_PRI_INS_IMAGE = "SET_BACK_PRI_INS_IMAGE"; //setting back primary insurance card image
export const SET_FRONT_SEC_INS_IMAGE = "SET_FRONT_SEC_INS_IMAGE";
export const SET_BACK_SEC_INS_IMAGE = "SET_BACK_SEC_INS_IMAGE";

export const UNSET_FRONT_PRI_INS_IMAGE = "UNSET_FRONT_PRI_INS_IMAGE";
export const UNSET_BACK_PRI_INS_IMAGE = "UNSET_BACK_PRI_INS_IMAGE";
export const UNSET_FRONT_SEC_INS_IMAGE = "UNSET_FRONT_SEC_INS_IMAGE";
export const UNSET_BACK_SEC_INS_IMAGE = "UNSET_BACK_SEC_INS_IMAGE";

export const UNSET_PRI_INS_IMAGES = "UNSET_PRI_INS_IMAGES";
export const UNSET_SEC_INS_IMAGES = "UNSET_SEC_INS_IMAGES";

export const SET_FR_UPLOAD_PROGRESS = "SET_FR_UPLOAD_PROGRESS";
export const SET_BCK_UPLOAD_PROGRESS = "SET_BCK_UPLOAD_PROGRESS";

export const RESET_INS_UPLOAD_PROGRESS = "RESET_INS_UPLOAD_PROGRESS";
export const CONFIGS_LOADED = "CONFIGS_LOADED";
export const CONFIGS_NOT_LOADED = "CONFIGS_NOT_LOADED";

export const CLEAR_FLAG_INTERVALS = "CLEAR_FLAG_INTERVALS";
export const FLAGS_LOADED = "FLAGS_LOADED";
/*
 * ==========================
 *   ALERT
 * ==========================
 */
export const SET_ALERT = "SET_ALERT";
export const UNSET_ALERT = "UNSET_ALERT";

/*
 * ==========================
 *   EXCEPTION REPORTING
 * ==========================
 */
export const SET_EXCEPTION_REPORT = "SET_EXCEPTION_REPORT";
export const UNSET_EXCEPTION_REPORT = "UNSET_EXCEPTION_REPORT";

/*
 * ==========================
 *   PATIENT
 * ==========================
 */
export const START_ASSESSMENT = "START_ASSESSMENT";
export const NEXT_QUESTION = "NEXT_QUESTION";
export const PREVIOUS_QUESTION = "PREVIOUS_QUESTION";
export const OPTION_SELECTED = "OPTION_SELECTED";
export const SET_ASSESSMENT_STATUS = "SET_ASSESSMENT_STATUS";
export const START_RATING_SCALE = "START_RATING_SCALE";
export const ASSESSMENT_COMPLETED = "ASSESSMENT_COMPLETED";
export const SURVEY_QUESTION = "SURVEY_QUESTION";
export const UPDATE_SCHEDULE_LIST = "UPDATE_SCHEDULE_LIST";
export const UPDATE_ACTIVITY_LIST = "UPDATE_ACTIVITY_LIST";
export const UPDATE_INSURANCE_LIST = "UPDATE_INSURANCE_LIST";
export const UPDATE_CRISIS_RISK = "UPDATE_CRISIS_RISK";
export const ALLOW_LOGIN_POP_UPS = "ALLOW_LOGIN_POP_UPS";
export const DISABLE_LOGIN_POP_UPS = "DISABLE_LOGIN_POP_UPS";
export const NO_ASSESSMENT = "NO_ASSESSMENT";

/*
 * ==========================
 *   PATIENT CARD INFO
 * ==========================
 */

export const UPDATE_PATIENT_CARD_INFO = "UPDATE_PATIENT_CARD_INFO";
export const UPDATE_PATIENT_PAYWALL_INFO = "UPDATE_PATIENT_PAYWALL_INFO";

/*
 * ==========================
 *   PAYMENT PROCESSOR
 * ==========================
 */

export const UPDATE_PAYMENT_PROCESSOR_SESSION = "UPDATE_PAYMENT_PROCESSOR_SESSION";

/*
 * ==========================
 *   PROVIDER
 * ==========================
 */

export const ADD_PATIENT_SUCCESS = "ADD_PATIENT_SUCCESS";

export const PROVIDER_UPDATE_SCHEDULE_CALL_LIST = "PROVIDER_UPDATE_SCHEDULE_CALL_LIST";

/*
 * ==========================
 *   CALENDAR
 * ==========================
 */
export const CALENDAR_INITIALIZE = "CALENDAR_INITIALIZE";
export const CALENDAR_INITIALIZE_PROVIDER = "CALENDAR_INITIALIZE_PROVIDER";
export const CALENDAR_INITIALIZE_BEHALF_OF_PROVIDER_PATIENT =
    "CALENDAR_INITIALIZE_BEHALF_OF_PROVIDER_PATIENT";
export const CALENDAR_UPDATE_SCHEDULE_CALL_LIST = "CALENDAR_UPDATE_SCHEDULE_CALL_LIST";
export const CALENDAR_FETCH_PATIENT_OPTIONS = "CALENDAR_FETCH_PATIENT_OPTIONS";
export const CALENDAR_UPDATE_DATE = "CALENDAR_UPDATE_DATE";
export const CALENDAR_FETCH_EVENT_TYPE_OPTIONS = "CALENDAR_FETCH_EVENT_TYPE_OPTIONS";
export const CALENDAR_RESET_APPOINTMENT_SELECTIONS = "CALENDAR_RESET_APPOINTMENT_SELECTIONS";
export const CALENDAR_UPDATE_APPOINTMENT_SELECTIONS = "CALENDAR_UPDATE_APPOINTMENT_SELECTIONS";
export const CALENDAR_UPDATE_VIEW_TYPE = "CALENDAR_UPDATE_VIEW_TYPE";
export const CALENDAR_UPDATE_SELECTED_EVENTS = "CALENDAR_UPDATE_SELECTED_EVENTS";
export const OPEN_APPOINTMENT_PICKER = "OPEN_APPOINTMENT_PICKER";
export const CLOSE_DRAWER = "CLOSE_DRAWER";
export const CALENDAR_EDIT_APPOINTMENT_SELECTED = "CALENDAR_EDIT_APPOINTMENT_SELECTED";
export const CALENDAR_UPDATE_APPOINTMENT_SELECTION_ERRORS =
    "CALENDAR_UPDATE_APPOINTMENT_SELECTION_ERRORS";
export const CALENDAR_UPDATE_APPOINTMENT_EDIT_SELECTION_ERRORS =
    "CALENDAR_UPDATE_APPOINTMENT_EDIT_SELECTION_ERRORS";
export const CALENDAR_RESET_APPOINTMENT_EDIT_SELECTION_ERRORS =
    "CALENDAR_RESET_APPOINTMENT_EDIT_SELECTION_ERRORS";
export const CALENDAR_UPSERT_UNCONFIRMED_EVENT = "CALENDAR_UPSERT_UNCONFIRMED_EVENT";
export const CALENDAR_DELETE_UNCONFIRMED_EVENT = "CALENDAR_DELETE_UNCONFIRMED_EVENT";
export const CALENDAR_UPDATE_FILTERS = "CALENDAR_UPDATE_FILTERS";
export const CALENDAR_RESET_FILTERS = "CALENDAR_RESET_FILTERS";
export const CALENDAR_TOGGLE_DATEPICKER = "CALENDAR_TOGGLE_DATEPICKER";
export const CALENDAR_UPDATE_TIMEZONE = "CALENDAR_UPDATE_TIMEZONE";
export const CALENDAR_RESET_SCHEDULING_MODE = "CALENDAR_RESET_SCHEDULING_MODE";

/*
 * ==========================
 *   REPORTS
 * ==========================
 */

export const UPDATE_CRISIS_RISK_REPORT = "UPDATE_CRISIS_RISK_REPORT";

/*
 * ==========================
 *   A5 SCREENING
 * ==========================
 */
export const A5_NEXT_QUESTION = "A5_NEXT_QUESTION";
export const A5_PREVIOUS_QUESTION = "A5_PREVIOUS_QUESTION";
export const A5_OPTION_SELECTED = "A5_OPTION_SELECTED";
export const A5_UPDATE = "A5_UPDATE";

/*
 * ==========================
 *   TOASTER
 * ==========================
 */
export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";
export const CLEAR_ALL_TOASTS = "CLEAR_ALL_TOASTS";
