import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button } from "../Button";
import { ButtonTypes } from "../constants";

import { createClassNameString } from "../utils";

import "./styles.scss";

export const Toast = ({
    children,
    className,
    toastId,
    messageType,
    dismissMessage,
    hideDismissButton,
    dismissTimeout,

    onDismissButtonClick,
    ...props
}) => {
    if (dismissTimeout) {
        setTimeout(() => {
            onDismissButtonClick({ toastId });
        }, dismissTimeout);
    }

    return (
        <div className={createClassNameString(["apti-Toast", className, messageType])} {...props}>
            {children}
            {!hideDismissButton && (
                <Button
                    className="apti-Toast__button"
                    buttonType={ButtonTypes.primary}
                    onClick={() => onDismissButtonClick({ toastId })}>
                    {dismissMessage}
                </Button>
            )}
        </div>
    );
};

Toast.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    className: PropTypes.string,
    messageType: PropTypes.oneOf(["success", "warning", "error", "error_v2", "success_v2"]),
    dismissMessage: PropTypes.string,
    hideDismissButton: PropTypes.bool,
    dismissTimeout: PropTypes.number,
};

Toast.defaultProps = {
    className: undefined,
    messageType: "success",
    dismissMessage: "Dismiss",
    hideDismissButton: false,
    dismissTimeout: 10000,
};
