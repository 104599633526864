// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component, Fragment } from "react";
import MobileCalenderView from "./MobileCalenderView";
import "./styles.scss";
import moment from "moment-timezone";
import images from "../../../utils/images";
import { prefixZero } from "../../../utils/filters";

class DatePicker extends Component {
    weekDaysFull = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    mobileSliderRef = React.createRef(); // passed to MobileCalenderView

    constructor(props) {
        super(props);
        let today = moment();
        this.state = {
            dateContext: props.externalCtx || today, // externalCtx must be a moment object
            today: today,
            selectedDay: (props.externalCtx && props.externalCtx.format("D")) || today.format("D"),
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.externalCtx !== this.props.externalCtx) {
            let today = moment();

            this.setState({
                dateContext: this.props.externalCtx || today,
                today: today,
                selectedDay:
                    (this.props.externalCtx && this.props.externalCtx.format("D")) ||
                    today.format("D"),
            });
        }
    }

    // ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
    tHeadWeekDays = moment.weekdaysShort().map((day) => <td key={day}>{day[0]}</td>);

    year = () => {
        return this.state.dateContext.format("Y");
    };

    month = () => {
        return this.state.dateContext.format("MMM");
    };

    monthNumber = () => {
        return this.state.dateContext.month(this.month()).format("MM");
    };

    daysInMonth = () => {
        return this.state.dateContext.daysInMonth();
    };

    getStartOfMonth = () => {
        return moment(this.state.dateContext).startOf("month");
    };

    /**
     *  calander controls related handlers
     */

    nextMonth = () => {
        let toBeNextMonth = parseInt(this.monthNumber()) + 1;
        let currentMonth = parseInt(moment(this.state.today).month()) + 1;
        let dateContext = Object.assign({}, this.state.dateContext);
        dateContext = moment(dateContext).add(1, "month");
        this.monthHandler(toBeNextMonth, currentMonth, dateContext);
    };

    prevMonth = () => {
        let toBePrevMonth = parseInt(this.monthNumber()) - 1;
        let currentMonth = parseInt(moment(this.state.today).month()) + 1;
        let dateContext = Object.assign({}, this.state.dateContext);
        dateContext = moment(dateContext).subtract(1, "month");
        this.monthHandler(toBePrevMonth, currentMonth, dateContext);
    };

    monthHandler = (toBeMonth, currentMonth, dateContext) => {
        let selectedDay =
            this.state.today.format("DD") > dateContext.daysInMonth()
                ? dateContext.daysInMonth()
                : this.state.today.format("DD");
        if (parseInt(toBeMonth) !== parseInt(currentMonth)) {
            selectedDay = "01";
        }

        this.setState({
            dateContext: dateContext,
            selectedDay: selectedDay,
        });

        let selectedYear = dateContext.format("Y");
        let selectedMonth = dateContext.format("MM");
        let dateStr = selectedYear + "-" + selectedMonth + "-" + selectedDay;
        this.props.onDaySelect(dateStr, "monthChange");

        this.resetMobileScrollPosition();
    };

    resetMobileScrollPosition = () => {
        if (this.props.mobileCardView) {
            this.mobileSliderRef.current.scrollLeft = 0;
        }
    };

    onDayClick = (e, day) => {
        let selectedYear = this.year();
        let selectedMonth = this.monthNumber();
        this.setState({
            selectedDay: day,
        });
        let dateStr = selectedYear + "-" + selectedMonth + "-" + day;
        this.props.onDaySelect(dateStr);
    };

    getAllowPrevStatus = () => {
        let currentYear = this.state.today.format("YYYY");
        let selectedYear = this.state.dateContext.format("YYYY");
        let currentMonth = moment(this.state.today).month();
        let selectedMonth = moment(this.state.dateContext).month();
        return selectedYear > currentYear ? true : selectedMonth > currentMonth;
    };

    render() {
        // start
        // Description: For rendering tiles version of datepicker on mobile
        let tilesArray = [];
        let dayIndex = this.getStartOfMonth().day();
        let daysInSelectedMonth = this.daysInMonth(); // days in the selected month

        for (let day = 1; day <= daysInSelectedMonth; day++) {
            let transformedDay = prefixZero(day);
            tilesArray.push({
                weekDay: this.weekDaysFull[dayIndex],
                monthDay: day,
                prefixedDay: transformedDay,
            });
            dayIndex = dayIndex === 6 ? 0 : dayIndex + 1;
        }

        //end
        let allowPrev = this.getAllowPrevStatus();
        let blankDays = [];
        for (let i = 0; i < this.getStartOfMonth().format("d"); i++) {
            blankDays.push(
                <td key={i * 80} className="muted-day">
                    {""}
                </td>,
            );
        }

        let daysInMonth = [];
        for (let d = 1; d <= this.daysInMonth(); d++) {
            let transformedDay = prefixZero(d);
            let className = d == this.state.selectedDay ? "days selected-day" : "days";
            daysInMonth.push(
                <td key={d} className={className}>
                    <div
                        className="d-flex align-items-center justify-content-center"
                        onClick={(e) => {
                            this.onDayClick(e, transformedDay);
                        }}>
                        {d}
                    </div>
                </td>,
            );
        }

        var totalSlots = [...blankDays, ...daysInMonth];
        let rows = [];
        let cells = [];

        totalSlots.forEach((td, i) => {
            if (i % 7 !== 0) {
                cells.push(td);
            } else {
                let insertRow = cells.slice();
                rows.push(insertRow);
                cells = [];
                cells.push(td);
            }
            if (i === totalSlots.length - 1) {
                let insertRow = cells.slice();
                rows.push(insertRow);
            }
        });

        let renderedDays = rows.map((d, i) => {
            return (
                <tr className="" key={i * 100}>
                    {d}
                </tr>
            );
        });

        let datePickerControls = (
            <div className="DatePicker__controls d-flex justify-content-between">
                <span className="DatePicker__current-month-year">
                    <span className="mr-2">{this.month()}</span>
                    <span>{this.year()}</span>
                </span>
                <span>
                    <button
                        onClick={this.prevMonth}
                        type={"button"}
                        className="DatePicker__control-btn cursor mr-2">
                        <img src={images("./common/cal_prev.svg")} alt="previous img" />
                    </button>
                    <button
                        onClick={this.nextMonth}
                        type={"button"}
                        className="DatePicker__control-btn cursor">
                        <img src={images("./common/cal_next.svg")} alt="next img" />
                    </button>
                </span>
            </div>
        );
        let datePickerClasses =
            "DatePicker ff-pri" + (this.props.mobileCardView ? " d-none d-lg-block" : "");

        return (
            <Fragment>
                <div className={datePickerClasses}>
                    {datePickerControls}
                    <table className="DatePicker__table mt-3 pt-1 w-100">
                        <thead className="DatePicker__thead fs-15">
                            <tr>{this.tHeadWeekDays}</tr>
                        </thead>
                        <tbody className="DatePicker__tbody fs-15">{renderedDays}</tbody>
                    </table>
                </div>
                {this.props.mobileCardView && (
                    <MobileCalenderView
                        month={this.month()}
                        ref={this.mobileSliderRef}
                        allowPrev={allowPrev}
                        onDayClick={this.onDayClick}
                        prev={this.prevMonth}
                        next={this.nextMonth}
                        year={this.year()}
                        selectedDay={this.state.selectedDay}
                        weekDay={this.state.today.day()}
                        noOfSlots={this.props.noOfSlots}
                        loadingSlots={this.props.loadingSlots}
                        tilesArray={tilesArray}
                    />
                )}
            </Fragment>
        );
    }
}

export default DatePicker;
