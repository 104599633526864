import React from "react";
import PropTypes from "prop-types";

import { createClassNameString } from "../../utils";
import { TextTypes } from "../../constants";

import { FormField } from "../../FormField";
import { Button } from "../../Button";
import { Text } from "../../Text";
import { Input } from "../../Input";
import { DateInput } from "../../DateInput";
import { Dropdown } from "../../Dropdown";
import { UploadFile } from "../../UploadFile";
import { PageBreak } from "../../PageBreak";

import "./styles.scss";

const uploadFileInfoContent =
    "Please upload images of the front and back of your insurance card. Acceptable file formats: .jpg, .jpeg, .heic, .pdf, .png, .tiff, .tif, .heif. Max file size: 20MB";

export const EditProfileInsurance = ({
    className,
    title,

    // Formik props
    values,
    errors,
    touched,
    handleFocus,
    handleBlur,
    handleChange,

    insuranceCompaniesOptions,
    insuranceCompanyFilterOptions,
    stateOfInsurancePlanOptions,
    policyHolderOptions,

    files,
    onBrowseButtonClicked,
    onFileDelete,

    onSubmitButtonClick,
    onCancelButtonClick,

    // Carrier Specific
    memberIdPlaceholder,
    memberIdTip,
    memberIdTooltipContents,
    groupIdTooltipContents,

    noInsurance,
    ...props
}) => {
    let insuranceDisabledClassName = "";
    if (noInsurance) {
        insuranceDisabledClassName = "apti-disabled";
    }

    return (
        <div className={createClassNameString(["apti-EditProfileInsurance", className])} {...props}>
            <Text className="apti-EditProfileInsurance--header" type={TextTypes.label}>
                {title}
            </Text>
            <PageBreak className="apti-EditProfileInsurance--header" />
            <div className="apti-EditProfileInsurance--grid">
                <FormField
                    className="apti-EditProfileInsurance--field"
                    label="Insurance Company"
                    sublabel="(Optional)"
                    errorMessage={touched.insuranceCompany ? errors.insuranceCompany : ""}>
                    <Dropdown
                        name={"insuranceCompany"}
                        value={values.insuranceCompany}
                        placeholder="Select Insurance Company"
                        options={insuranceCompaniesOptions}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.insuranceCompany && errors.insuranceCompany}
                        filterOptions={insuranceCompanyFilterOptions}
                        onFocus={handleFocus}
                    />
                </FormField>
                <FormField
                    className={createClassNameString([
                        "apti-EditProfileInsurance--field",
                        insuranceDisabledClassName,
                    ])}
                    label={"Policy Holder"}
                    errorMessage={touched.policyHolder ? errors.policyHolder : ""}>
                    <Dropdown
                        className={insuranceDisabledClassName}
                        isDisabled={noInsurance}
                        name="policyHolder"
                        value={values.policyHolder}
                        placeholder="Select Policy Holder"
                        options={policyHolderOptions}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.policyHolder && errors.policyHolder}
                        onFocus={handleFocus}
                    />
                </FormField>
                <FormField
                    className={createClassNameString([
                        "apti-EditProfileInsurance--field",
                        insuranceDisabledClassName,
                    ])}
                    label="Member ID"
                    errorMessage={touched.memberId ? errors.memberId : ""}>
                    {memberIdTip && <div className={"txt-gry mb-2"}>{memberIdTip}</div>}
                    <Input
                        className={insuranceDisabledClassName}
                        disabled={noInsurance}
                        name="memberId"
                        placeholder={memberIdPlaceholder}
                        value={values.memberId}
                        error={touched.memberId && errors.memberId}
                        infoContent={memberIdTooltipContents}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                </FormField>
                <FormField
                    className={createClassNameString([
                        "apti-EditProfileInsurance--field",
                        insuranceDisabledClassName,
                    ])}
                    label="Group ID"
                    sublabel="(Optional)"
                    errorMessage={touched.groupId ? errors.groupId : ""}>
                    <Input
                        className={insuranceDisabledClassName}
                        disabled={noInsurance}
                        name="groupId"
                        placeholder="Enter Group ID"
                        value={values.groupId}
                        error={touched.groupId && errors.groupId}
                        infoContent={groupIdTooltipContents}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                </FormField>
                <UploadFile
                    className={createClassNameString([
                        "apti-EditProfileInsurance--upload",
                        insuranceDisabledClassName,
                    ])}
                    disabled={noInsurance}
                    infoContent={uploadFileInfoContent}
                    files={files}
                    onBrowseButtonClicked={onBrowseButtonClicked}
                    onFileDelete={onFileDelete}
                />
                <FormField
                    className={createClassNameString([
                        "apti-EditProfileInsurance--field",
                        insuranceDisabledClassName,
                    ])}
                    label="State of Insurance Plan"
                    sublabel="(Optional)"
                    errorMessage={touched.stateOfInsurance ? errors.stateOfInsurance : ""}>
                    <Dropdown
                        className={insuranceDisabledClassName}
                        isDisabled={noInsurance}
                        name="stateOfInsurance"
                        value={values.stateOfInsurance}
                        placeholder="Select State"
                        options={stateOfInsurancePlanOptions}
                        error={touched.stateOfInsurance && errors.stateOfInsurance}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                    />
                </FormField>
                <FormField
                    className={createClassNameString([
                        "apti-EditProfileInsurance--field",
                        insuranceDisabledClassName,
                    ])}
                    label="Policy Holder Name"
                    sublabel="(Optional)"
                    errorMessage={touched.policyHolderName ? errors.policyHolderName : ""}>
                    <Input
                        className={insuranceDisabledClassName}
                        disabled={noInsurance}
                        name="policyHolderName"
                        placeholder="Enter Policy Holder Name"
                        value={values.policyHolderName}
                        error={touched.policyHolderName && errors.policyHolderName}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                </FormField>
                <FormField
                    className={createClassNameString([
                        "apti-EditProfileInsurance--field",
                        insuranceDisabledClassName,
                    ])}
                    label="Policy Holder Date of Birth"
                    sublabel="(Optional)"
                    errorMessage={touched.policyHolderDOB ? errors.policyHolderDOB : ""}>
                    <DateInput
                        className={insuranceDisabledClassName}
                        disabled={noInsurance}
                        name="policyHolderDOB"
                        placeholder="MM/DD/YYYY"
                        value={values.policyHolderDOB}
                        error={touched.policyHolderDOB && errors.policyHolderDOB}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                </FormField>
            </div>
            <div className="apti-EditProfileInsurance--buttons">
                <Button className={"primary-outline"} onClick={onCancelButtonClick}>
                    Cancel
                </Button>
                <Button className={"primary"} onClick={onSubmitButtonClick} type="submit">
                    Save
                </Button>
            </div>
        </div>
    );
};

EditProfileInsurance.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,

    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleFocus: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,

    insuranceCompaniesOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    stateOfInsurancePlanOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    policyHolderOptions: PropTypes.arrayOf(PropTypes.object).isRequired,

    files: PropTypes.arrayOf(PropTypes.string).isRequired,
    onBrowseButtonClicked: PropTypes.func.isRequired,
    onFileDelete: PropTypes.func.isRequired,

    onSubmitButtonClick: PropTypes.func.isRequired,
    onCancelButtonClick: PropTypes.func.isRequired,

    noInsurance: PropTypes.bool,
};

EditProfileInsurance.defaultProps = {
    className: undefined,
    title: "",

    values: {},
    errors: {},
    touched: {},
    handleFocus: () => {},
    handleBlur: () => {},
    handleChange: () => {},

    insuranceCompaniesOptions: [],
    stateOfInsurancePlanOptions: [],
    policyHolderOptions: [],

    files: [],
    onBrowseButtonClicked: () => {},
    onFileDelete: () => {},

    onSubmitButtonClick: () => {},
    onCancelButtonClick: () => {},

    noInsurance: false,
};
