import React, { useState } from "react";
import { CarePlanCard } from "../CarePlanCard";
import { CarePlanIconButton } from "../CarePlanIconButton";
import {
    Button,
    ButtonSizes,
    ButtonTypes,
    CustomForm,
    EditIcon,
    Modal,
} from "../../../../../component-library";
import { CloseIcon } from "../../../../Icons/CloseIcon";
import { ICON_SIZES, ICON_TYPES } from "../../../../../constants/icons";
import { Form } from "formik";
import { FormTextArea } from "../../../../../component-library/FormFields/FormTextArea/FormTextArea";
import * as yup from "yup";
import { api } from "../../../../../APIRequests";
import { hideLoader, showLoader } from "../../../../../redux/actions/loader";
import { useDispatch, useSelector } from "react-redux";
import { localTimestampFromStandardTimestamp } from "../../../../../utils/momentUtils";
import "./styles.scss";
import { addToast, toastMessageTypes } from "../../../../../redux/actions/toaster";
import { ProviderTypes } from "../../../../../constants/user";
import CloseOnOutsideClick from "../../../../Common/CloseOnOutsideClick/CloseOnOutsideClick";
import { XSS_REGEX } from "../../../../../constants/security";
import { getAutomationDataAttr } from "../../../../../utils/automationUtils";

const DESCRIPTION_TEXT = "How would the patient like to change their life?";

export const PatientArticulatedGoals = (props) => {
    const [showModal, setShowModal] = useState(false);
    const profile = useSelector((state) => state.auth.profile);
    const { existingGoals, refreshGoals } = props;
    const dispatch = useDispatch();

    const onModalSubmission = async (newArticulatedGoals) => {
        dispatch(showLoader(true));
        const response = await api.carePlan.update_patient_articulated_goals({
            data: {
                current_version: existingGoals.current_version,
                patient_articulated_goals: newArticulatedGoals,
            },
            urlParams: { patientId: props.patientId },
            params: { showLoader: false },
            errorTypeConfig: {
                CarePlanInvalidVersionException: {
                    toastErrorType: toastMessageTypes.error_v2,
                },
            },
        });
        await refreshGoals();
        dispatch(hideLoader());
        closeModal();

        dispatch(
            addToast({
                message: response?.message || "Updated",
                messageType: toastMessageTypes.success_v2,
            }),
        );
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const renderExistingGoals = () => {
        const { patient_articulated_goals, updated_by_name, updated_timestamp } = existingGoals;
        return (
            <div {...getAutomationDataAttr("PatientArticulatedGoals_existingGoals")}>
                <p className={"PatientGoalsStrengthsCautions--readonly-text"}>
                    {patient_articulated_goals}
                </p>
                <div className="PatientGoalsStrengthsCautions--meta-data">
                    <p className="PatientGoalsStrengthsCautions--meta-data-text">
                        Last Updated by {updated_by_name}
                    </p>
                    <p className="PatientGoalsStrengthsCautions--meta-data-text">
                        {localTimestampFromStandardTimestamp(updated_timestamp, "M/D/YYYY")}
                    </p>
                </div>
            </div>
        );
    };

    const renderNoGoalsSpecified = () => {
        const isAuthorized = isAuthorizedToEdit();
        const noPermissionText =
            "aptihealth behavioral specialist, prescriber, case manager and peer can edit patient articulated goals.";
        const hasPermissionText = "Choose “Add” to add goals.";
        const textBackgroundClass = `PatientGoalsStrengthsCautions--empty${
            isAuthorized ? "" : " no-permission"
        }`;

        return (
            <div
                className={"PatientGoalsStrengthsCautions--empty-container"}
                {...getAutomationDataAttr("PatientArticulatedGoals_emptyGoals")}>
                <div className={textBackgroundClass}>
                    <p className={"PatientGoalsStrengthsCautions--empty-text"}>
                        {isAuthorized ? hasPermissionText : noPermissionText}
                    </p>
                </div>
            </div>
        );
    };

    const isAuthorizedToEdit = () => {
        return (
            profile?.provider_type &&
            [
                ProviderTypes.BEHAVIORAL,
                ProviderTypes.CASE_MANAGER,
                ProviderTypes.PEER,
                ProviderTypes.PRESCRIBE,
            ].includes(profile.provider_type)
        );
    };

    return (
        <CarePlanCard
            title="Patient Articulated Goals"
            testId={"PatientArticulatedGoals_container"}
            className="PatientGoalsStrengthsCautions--element-container"
            actions={
                isAuthorizedToEdit() && (
                    <CarePlanIconButton
                        testId={"PatientArticulatedGoals_editButton"}
                        onClick={() => setShowModal(true)}
                        text={existingGoals?.patient_articulated_goals ? "Edit" : "Add"}
                        Icon={EditIcon}
                        iconClassName="PatientGoalsStrengthsCautions--icon"
                        iconButtonClassName="PatientGoalsStrengthsCautions--icon-button"
                    />
                )
            }>
            <p className="PatientGoalsStrengthsCautions--subtitle">{DESCRIPTION_TEXT}</p>
            {existingGoals?.patient_articulated_goals
                ? renderExistingGoals()
                : renderNoGoalsSpecified()}
            {showModal && (
                <PatientArticulatedGoalsModal
                    show={showModal}
                    onClose={closeModal}
                    onModalSubmission={onModalSubmission}
                    existingGoals={existingGoals}
                />
            )}
        </CarePlanCard>
    );
};

const PatientArticulatedGoalsModal = (props) => {
    return (
        <Modal
            className="PatientGoalsStrengthsCautions--modal"
            show={props.show}
            cardClassName="PatientGoalsStrengthsCautions--modal_dialog">
            <CloseOnOutsideClick setShowComponent={props.onClose}>
                <CloseIcon
                    onClick={props.onClose}
                    className={"PatientGoalsStrengthsCautions--modal_close_icon"}
                    iconSize={ICON_SIZES.SMALL}
                    iconType={ICON_TYPES.OUTLINE}
                />
                <PatientArticulatedGoalsForm
                    onClose={props.onClose}
                    onModalSubmission={props.onModalSubmission}
                    existingGoals={props.existingGoals}
                />
            </CloseOnOutsideClick>
        </Modal>
    );
};

const PatientArticulatedGoalsForm = (props) => {
    const { patient_articulated_goals: initialGoals } = props.existingGoals;
    const maxGoalsCharacters = 4000;

    const patientArticulatedGoalsSchema = yup.object().shape({
        patientArticulatedGoals: yup
            .string()
            .max(maxGoalsCharacters, `Limit text to ${maxGoalsCharacters} characters`)
            .test("special characters", "Special characters are not allowed", (value) => {
                return !XSS_REGEX.test(value);
            }),
    });

    const renderForm = (formikProps) => {
        return (
            <Form>
                <div className="PatientGoalsStrengthsCautions--modal_form">
                    <div data-public className="FormField-header_container">
                        <p className="PatientGoalsStrengthsCautions--modal_subtitle">
                            {DESCRIPTION_TEXT}
                        </p>
                    </div>

                    <FormTextArea
                        inputClassName="PatientArticulatedGoals--modal-free-text-input"
                        name="patientArticulatedGoals"
                        formikProps={formikProps}
                        triggerErrorOnChange
                        testId={"PatientArticulatedGoals_textInput"}
                    />

                    <div className="PatientGoalsStrengthsCautions-button_wrapper">
                        <Button
                            type="submit"
                            buttonSize={ButtonSizes.small}
                            buttonType={ButtonTypes.primaryV2}>
                            Save
                        </Button>
                        <Button
                            type="button"
                            onClick={props.onClose}
                            buttonType={ButtonTypes.primaryOutlineV2}
                            buttonSize={ButtonSizes.small}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Form>
        );
    };

    return (
        <div className="PatientArticulatedGoals--modal">
            <div className="PatientGoalsStrengthsCautions--modal_header">
                <h1 className="PatientGoalsStrengthsCautions--modal_title">
                    {`${initialGoals ? "Edit" : "Add"} Patient Articulated Goals`}
                </h1>
            </div>
            <CustomForm
                initialValues={{
                    patientArticulatedGoals: initialGoals ?? "",
                }}
                validationSchema={patientArticulatedGoalsSchema}
                validateOnChange={true}
                validateOnBlur={true}
                render={renderForm}
                onSubmit={async (formData) => {
                    await props.onModalSubmission(formData.patientArticulatedGoals);
                }}
            />
        </div>
    );
};
