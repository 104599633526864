/* THIS FILE WAS AUTO-GENERATED SEE README.MD AND generation/src/icons FOR MORE DETAILS */
import "./styles.scss";

import React from "react";
import PropTypes from "prop-types";
import { ICON_TYPES, ICON_SIZES } from "../../../constants/icons";
import { IconWrapper } from "./IconWrapper";

export const CardIcon = ({ className, iconType, iconSize, ...props }) => {
    if (iconType === ICON_TYPES.OUTLINE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Card"
                {...props}>
                <path d="M19 21H25V19H19V21Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 22C3 23.6569 4.34315 25 6 25H26C27.6569 25 29 23.6569 29 22V10C29 8.34315 27.6569 7 26 7H6C4.34315 7 3 8.34315 3 10V22ZM26 9H6C5.44772 9 5 9.44772 5 10V11H27V10C27 9.44772 26.5523 9 26 9ZM27 22V14H5V22C5 22.5523 5.44772 23 6 23H26C26.5523 23 27 22.5523 27 22Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.FILLED) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Card"
                {...props}>
                <path d="M3 10C3 8.34315 4.34315 7 6 7H26C27.6569 7 29 8.34315 29 10V11H3V10Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 14H29V22C29 23.6569 27.6569 25 26 25H6C4.34315 25 3 23.6569 3 22V14ZM19 21H25V19H19V21Z"
                />
            </IconWrapper>
        );
    }

    if (iconType === ICON_TYPES.CIRCLE) {
        return (
            <IconWrapper
                className={className}
                iconSize={iconSize}
                iconType={iconType}
                iconName="Card"
                {...props}>
                <path d="M26.75 23.75H22.25V22.25H26.75V23.75Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM12.5 13.25C11.2574 13.25 10.25 14.2574 10.25 15.5V16.25H29.75V15.5C29.75 14.2574 28.7426 13.25 27.5 13.25H12.5ZM29.75 24.5V18.5H10.25V24.5C10.25 25.7426 11.2574 26.75 12.5 26.75H27.5C28.7426 26.75 29.75 25.7426 29.75 24.5Z"
                />
            </IconWrapper>
        );
    }

    return null;
};

CardIcon.propTypes = {
    className: PropTypes.string,
    iconType: PropTypes.oneOf(Object.values(ICON_TYPES)),
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
};

CardIcon.defaultProps = {
    className: undefined,
    iconType: ICON_TYPES.OUTLINE,
    iconSize: ICON_SIZES.MEDIUM,
};
